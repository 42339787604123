import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemText,
  Link as MuiLink
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { Text } from '~/components/shared'

import { useAccordionsStyles } from '../faq.styles'

const AccountProfile: React.FC = () => {
  const s = useAccordionsStyles()

  return (
    <>
      <Accordion className={s.accordion}>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          classes={{ expandIcon: s.expandIcon, expanded: s.expanded }}
          expandIcon={<ExpandMoreIcon />}
          className={s.summeryAccordoin}
        >
          <Text>Q: How do I change my Password?</Text>
        </AccordionSummary>

        <AccordionDetails className={s.detailsAccordion}>
          <Text mb={2}>
            There are three possible ways to change your password. Choose the
            method that applies to you.
          </Text>

          <Text mb={2}>
            <i>Method 1:</i> Using third-party authentication such as Facebook
            or Google. If you created an account using your Facebook or Google
            account, your PlanMyKids account is authenticated using this third
            party service. Any changes to your password should be done through
            the third party service you chose to create your PlanMyKids account
            with.
          </Text>

          <Text>
            <i>Method 2:</i> Using forgot password. If you created an account
            with PlanMyKids using your email address and can’t remember your
            password, you can use the Forgot Password feature by following these
            steps:
          </Text>

          <List className={s.listItem}>
            <ListItem>
              <ListItemText>
                1. In the right-hand corner of any PlanMyKids page, you can{' '}
                <strong>Sign In</strong> to your account.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                2. From the Sign In form click <b>Forgot your password?</b>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                3. You’ll be asked to provide the <strong>Email</strong> address
                you created your account with.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                4. Click <strong>Send the reset password link.</strong>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                5. A verification email will be sent to the email address you
                provided.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                6. Check your email for a message titled Forgot Password for
                PlanMyKids.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                7. Follow the instructions in the email.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                8. After providing your new password click{' '}
                <strong>Reset.</strong>
              </ListItemText>
            </ListItem>
          </List>

          <Text>
            <i>Method 3:</i> Change your password from your profile page If you
            know your password but you want to change it, follow these steps:
          </Text>

          <List className={s.listItem}>
            <ListItem>
              <ListItemText>
                1. In the right-hand corner of any PlanMyKids page, you can{' '}
                <strong>Sign In</strong> to your account.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                2. After signing in, the right-hand corner will greet you with
                your account options in a pull-down menu.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                3. Select <strong>Profile.</strong>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                4. Scroll down until you find the section of the profile page
                titled <strong>Change Password.</strong>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>5. Enter your new password.</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                6. Click <strong>Save Changes.</strong>
              </ListItemText>
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion className={s.accordion}>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
          classes={{ expandIcon: s.expandIcon, expanded: s.expanded }}
          className={s.summeryAccordoin}
        >
          <Text>Q: How do I add another child?</Text>
        </AccordionSummary>

        <AccordionDetails className={s.detailsAccordion}>
          <Text>
            More children can be added to your profile by creating a new
            questionnaire.
          </Text>

          <Text>Follow these steps:</Text>

          <List className={s.listItem}>
            <ListItem>
              <ListItemText>
                1. After signing in, navigate to your profile page by choosing{' '}
                <strong>Profile</strong> from the menu in the top right hand
                corner OR clicking <strong>Profile</strong> at the top of the
                page.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                2. Scroll down to the <strong>Children</strong> section of your
                profile page.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                3. Click <strong>Start new child plan.</strong>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                4. After you complete the questionnaire a new child profile will
                be created that will appear in the children section of your
                profile page.
              </ListItemText>
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion className={s.accordion}>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
          classes={{ expandIcon: s.expandIcon, expanded: s.expanded }}
          className={s.summeryAccordoin}
        >
          <Text>Q: How do I change my child’s profile?</Text>
        </AccordionSummary>

        <AccordionDetails className={s.detailsAccordion}>
          <Text>
            You can change a child’s profile for an itinerary that is in
            progress by following these steps:
          </Text>

          <List className={s.listItem}>
            <ListItem>
              <ListItemText>
                1. After signing in, navigate to your profile page by choosing{' '}
                <strong>Profile</strong> from the menu in the top right hand
                corner OR clicking <strong>Profile</strong> at the top of the
                page.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                2. Scroll down to the section of your profile page titled{' '}
                <strong>Children.</strong>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                3. Make changes to the necessary fields and click{' '}
                <strong>Save Changes.</strong>
              </ListItemText>
            </ListItem>
          </List>

          <Text>
            <i>Note:</i> Once an itinerary has been approved, the child profile
            associated with it cannot be changed. A new child profile is created
            when the <strong>Start new child plan</strong> or{' '}
            <strong>Start plan for this child</strong> process is completed.
          </Text>
        </AccordionDetails>
      </Accordion>

      <Accordion className={s.accordion}>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
          classes={{ expandIcon: s.expandIcon, expanded: s.expanded }}
          className={s.summeryAccordoin}
        >
          <Text>
            Q: What is the difference between “start plan for this child” and
            “start new child plan”?
          </Text>
        </AccordionSummary>

        <AccordionDetails className={s.detailsAccordion}>
          <Text mb={2}>
            The <strong>Start plan for this child</strong> action will copy your
            child&apos;s profile information from the current profile into a new
            questionnaire. Your child&apos;s information and preferences can be
            edited while completing the questionnaire. This will create a new
            profile for your child.
          </Text>

          <Text>
            The <strong>Start new child plan</strong> action starts a blank
            questionnaire. It can be used to add more children or a completely
            new child profile.
          </Text>
        </AccordionDetails>
      </Accordion>

      <Accordion className={s.accordion}>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
          classes={{ expandIcon: s.expandIcon, expanded: s.expanded }}
          className={s.summeryAccordoin}
        >
          <Text>Q: How do I upgrade my account?</Text>
        </AccordionSummary>

        <AccordionDetails className={s.detailsAccordion}>
          <Text mb={2}>
            You can upgrade your account from your profile page by following
            these steps
          </Text>

          <List className={s.listItem}>
            <ListItem>
              <ListItemText>
                1. After signing in, navigate to your profile page by choosing{' '}
                <strong>Profile</strong> from the menu in the top right hand
                corner OR clicking <strong>Profile</strong> at the top of the
                page.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                2. In the upper right corner of your Profile page look for the
                area titled <strong>Current subscription type.</strong>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                3. Click <strong>Upgrade</strong> and follow the instructions
                for payment.
              </ListItemText>
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion className={s.accordion}>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
          classes={{ expandIcon: s.expandIcon, expanded: s.expanded }}
          className={s.summeryAccordoin}
        >
          <Text>
            Q: How do I downgrade my subscription back to a free plan?
          </Text>
        </AccordionSummary>

        <AccordionDetails className={s.detailsAccordion}>
          <Text mb={2}>
            To downgrade your subscription to a free plan, send an email request
            to{' '}
            <MuiLink
              color="secondary"
              href="mailto:help@planmykids.com"
              style={{ fontWeight: 700 }}
            >
              help@planmykids.com
            </MuiLink>{' '}
            and we will kindly fulfill your request.
          </Text>
        </AccordionDetails>
      </Accordion>

      <Accordion className={s.accordion}>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
          classes={{ expandIcon: s.expandIcon, expanded: s.expanded }}
          className={s.summeryAccordoin}
        >
          <Text>Q: How do I delete my account?</Text>
        </AccordionSummary>

        <AccordionDetails className={s.detailsAccordion}>
          <Text mb={2}>
            You can delete your account from your Profile page by following
            these steps:
          </Text>

          <List className={s.listItem}>
            <ListItem>
              <ListItemText>
                1. After signing in, navigate to your Profile page by choosing{' '}
                <strong>Profile</strong> from the menu in the top right hand
                corner OR clicking <strong>Profile</strong> at the top of the
                page.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                2. Scroll down to the bottom of the page.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                3. In the bottom right-hand corner click{' '}
                <strong>Delete account.</strong>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                4. You will be prompted to confirm your action, click{' '}
                <strong>Confirm</strong> to continue with deletion or click{' '}
                <strong>Cancel</strong> to return to your profile page.
              </ListItemText>
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default AccountProfile
