import React from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router'

import { isShare } from '~/state/modules/user'

import ROUTES from '~/constants/routes'
import { AppState } from '~/state/store'

// Pages
import Edit from './edit'
import Calendar from './calendar'
import Itineraries from './itineraries'
import ItineraryComment from './itineraries/[id]'
import ItinerariesFriends from './itineraries-friends'
import Payment from './payment'

const ProfileRoute: React.FC<RouteComponentProps> = ({
  match: { path },
  location: { pathname }
}): JSX.Element => {
  const withShare = useSelector(isShare)
  const withInvited = useSelector<AppState, boolean>(
    (state) => state.user.invited_to_itinerary
  )

  if (withInvited && pathname !== ROUTES.itinerariesFriends) {
    return <Redirect to={ROUTES.itinerariesFriends} />
  }

  return (
    <Switch>
      <Route exact path={path} component={Edit} />
      <Route path={`${path}/calendar`} component={Calendar} />
      <Route exact path={`${path}/itineraries`} component={Itineraries} />
      <Route path={`${path}/itineraries/:id`} component={ItineraryComment} />
      <Route path={`${path}/payment/:id`} component={Payment} />
      {withShare && (
        <Route
          path={`${path}/itineraries-friends`}
          component={ItinerariesFriends}
        />
      )}

      <Redirect from={path} to={path} />
    </Switch>
  )
}

export default ProfileRoute
