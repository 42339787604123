import { object } from 'yup'

import { password } from '../fields'

const ResetPasswordSchema = object().shape({
  password,
  password_confirmation: password
    .passwordConfirmation('Passwords are not the same!')
    .required('Password confirmation is required!')
})

export default ResetPasswordSchema
