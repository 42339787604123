import React, { Fragment, useState } from 'react'
import {
  GoogleMapProps,
  Circle,
  GoogleMap as Map,
  InfoWindow,
  Marker as GoogleMarker
} from '@react-google-maps/api'
import { Divider } from '@material-ui/core'
import { CSSProperties } from '@material-ui/styles'

import Text from './Text'

import MarkerIcon from '~/assets/icons/marker.svg'

const circleOptions = {
  strokeColor: '#703965',
  strokeOpacity: 1,
  strokeWeight: 1,
  fillColor: 'rgba(112, 57, 101, 0.2)',
  fillOpacity: 1,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  zIndex: 1
}

type MapProps = {
  center: Coords
  height: number
  markers?: Marker[]
  radius?: number
  mapStyles?: CSSProperties
}

type AllProps = MapProps & GoogleMapProps

const GoogleMap: React.FC<AllProps> = ({
  center,
  height,
  markers,
  radius,
  mapStyles,
  ...rest
}) => {
  const [isOpenId, setIsOpenId] = useState('')

  return (
    <Map
      id="map"
      zoom={7}
      center={center}
      mapContainerStyle={{
        width: '100%',
        height,
        ...mapStyles
      }}
      {...rest}
    >
      {!!markers?.length &&
        markers?.map(({ id, position, location, info }) => (
          <GoogleMarker
            key={id}
            position={position}
            onClick={(): void => {
              setIsOpenId(id)
            }}
          >
            {isOpenId === id && (
              <InfoWindow
                options={{
                  maxWidth: 400
                }}
              >
                <>
                  <Text fontSize={15} mb={1}>
                    <Text component="span" style={{ color: '#575757' }}>
                      Location:
                    </Text>{' '}
                    {location || '-'}
                  </Text>

                  {info.map(({ title, description }, idx) => {
                    const isLast = idx === info.length - 1

                    return (
                      <Fragment key={idx}>
                        <Text
                          fontSize={18}
                          fontWeight={600}
                          mb={description ? 1 : 0}
                        >
                          {title}
                        </Text>

                        <Text fontSize={14} mb={isLast ? 0 : 1}>
                          {description}
                        </Text>

                        {!isLast && <Divider style={{ margin: '10px 0' }} />}
                      </Fragment>
                    )
                  })}
                </>
              </InfoWindow>
            )}
          </GoogleMarker>
        ))}

      {radius && (
        <>
          <GoogleMarker
            position={center}
            icon={{
              url: MarkerIcon,
              // @ts-ignore
              anchor: new google.maps.Point(7.5, 7.5)
            }}
          />
          <Circle center={center} options={circleOptions} radius={radius} />
        </>
      )}
    </Map>
  )
}

export default GoogleMap
