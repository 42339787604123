import { makeStyles, createStyles } from '@material-ui/core'

const useItineraryCommentStyles = makeStyles(
  (theme) =>
    createStyles({
      wrapper: {
        padding: '50px 125px',
        margin: theme.spacing(3, 0, 6),

        [theme.breakpoints.down('sm')]: {
          padding: '50px'
        },

        [theme.breakpoints.down('xs')]: {
          margin: 0,
          padding: '50px 20px',
          borderRadius: 'inherit',
          boxShadow: 'none'
        }
      },

      backButton: {
        display: 'inline-block',
        color: theme.palette.text.secondary,
        fontWeight: 400,
        marginBottom: theme.spacing(1),

        '& svg': {
          marginRight: theme.spacing(1)
        }
      },

      commentField: {
        minWidth: 390,
        marginBottom: '20px',

        [theme.breakpoints.down('xs')]: {
          minWidth: '100%'
        }
      },

      content: {
        padding: '20px',
        border: ' 1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: '10px'
      },

      select: {
        marginBottom: '10px'
      },

      checkboxGroup: {
        marginBottom: '10px'
      }
    }),
  {
    name: 'ItineraryComment'
  }
)

export default useItineraryCommentStyles
