import React, { useEffect } from 'react'
import { toastr } from 'react-redux-toastr'
import { useLoadScript } from '@react-google-maps/api'

import { Loader } from '~/components/shared'

const GoogleMapsProvider: React.FC = ({ children }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: ['places'],
    language: 'en'
  })

  useEffect(() => {
    if (loadError) {
      toastr.error('Error', loadError.message)
    }
  }, [loadError])

  if (isLoaded) {
    return <>{children}</>
  }

  return <Loader height="100vh" noGutters />
}

export default GoogleMapsProvider
