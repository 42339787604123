import { makeStyles } from '@material-ui/core'

export default makeStyles({
  tooltip: {
    maxWidth: '450px',
    boxShadow: '0px 6px 24px rgba(0, 0, 0, 0.08)',
    borderRadius: 6,
    fontSize: '16px'
  }
})
