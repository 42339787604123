import { makeStyles, createStyles } from '@material-ui/core'

const useStaticStyles = makeStyles(
  (theme) =>
    createStyles({
      container: {
        margin: theme.spacing(3, 'auto', 6),

        '&.withWidth': {
          [theme.breakpoints.up('sm')]: {
            maxWidth: 1100
          }
        },

        [theme.breakpoints.down('xs')]: {
          padding: 0,
          margin: '100px 0 0'
        }
      },

      fullWidth: {
        [theme.breakpoints.down('xs')]: {
          width: '100vw',
          position: 'relative',
          left: '50%',
          right: '50%',
          margin: '100px -50vw 0'
        }
      },

      paper: {
        padding: '50px 125px',

        [theme.breakpoints.down('sm')]: {
          padding: '50px'
        },

        [theme.breakpoints.down('xs')]: {
          padding: '50px 20px',
          borderRadius: 'inherit',
          boxShadow: 'none'
        }
      },

      aboutUsImg: {
        width: '100%',
        height: 'auto',

        [theme.breakpoints.down('sm')]: {
          display: 'block',
          margin: '0 auto',
          width: 'auto',
          maxHeight: 150
        }
      },

      underline: {
        textDecoration: 'underline'
      },

      overflowText: {
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    }),
  {
    name: 'Static'
  }
)

export default useStaticStyles
