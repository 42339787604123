import PMK from '~/services/PMK'
import Deserializer from '~/services/Deserializer'
import ErrorHandler from '~/utils/errorHandler'

import { AppThunk } from '~/state/store'

import { request, success, update } from './questionnaires.slice'

// Actions

/**
 * Get questionnaires action
 */
export const getQuestionnaires = (): AppThunk<Promise<void>> => async (
  dispatch
): Promise<void> => {
  dispatch(request())

  try {
    const res = await PMK.getParentItineraries()
    const payload = await Deserializer.deserialize(res)

    dispatch(success(payload))
  } catch (error) {
    throw new ErrorHandler(error)
  }
}

/**
 * Update questionnaire action
 */
export const updateQuestionaire = (
  id: string,
  itineraryId: string,
  fields: Fields
): AppThunk<Promise<void>> => async (dispatch): Promise<void> => {
  try {
    const res = await PMK.updateQuestionaire(id, fields)
    const payload = await Deserializer.deserialize(res)

    dispatch(update({ questionnaireData: payload, itineraryId }))
  } catch (error) {
    throw new ErrorHandler(error)
  }
}
