import React from 'react'

import Desktop from '~/containers/Desktop'
import Mobile from '~/containers/Mobile.tsx'

import FooterDesktop from './Footer.desktop'
import FooterMobile from './Footer.mobile'

export type FooterProps = {
  navs: NavLinkOption[]
}

const Footer: React.FC<FooterProps> = (props) => {
  return (
    <>
      <Desktop>
        <FooterDesktop {...props} />
      </Desktop>

      <Mobile>
        <FooterMobile {...props} />
      </Mobile>
    </>
  )
}

export default Footer
