import React, { FC } from 'react'
import { withStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox'
import { useModal } from 'react-modal-hook'
import { useDispatch } from 'react-redux'

import ConfirmDialog from '~/components/dialogs/ConfirmDialog'
import { markOrderAsProcessed } from '~/state/modules/orders'
import { AsyncThunkAction } from '@reduxjs/toolkit'

const ProcessedCheckbox = withStyles({
  root: {
    color: '#703950',
    '&$checked': {
      color: '#703950'
    }
  },

  checked: {}
})((props: CheckboxProps) => <Checkbox color="default" {...props} />)

interface OrderCheckboxFieldProps {
  id: string
  processed: boolean
}

const OrderCheckboxField: FC<OrderCheckboxFieldProps> = ({ id, processed }) => {
  const dispatch = useDispatch()

  const [showModal, hideModal] = useModal((props) => (
    <ConfirmDialog
      {...props}
      onClose={hideModal}
      onConfirm={(): AsyncThunkAction<
        Orders,
        { id: string; processed: boolean },
        {}
      > => dispatch(markOrderAsProcessed({ id, processed: !processed }))}
      cancelText="Cancel"
      confirmText="Confirm"
      text="You have already processed this Parent/Order, are you sure? "
      isMoreText
      secondText=""
      maxWidth="sm"
    />
  ))

  const handleChange = (): void => {
    if (!processed) {
      showModal()
    } else {
      dispatch(markOrderAsProcessed({ id, processed: !processed }))
    }
  }

  return (
    <FormControlLabel
      control={
        <ProcessedCheckbox
          checked={processed}
          onChange={handleChange}
          name="checkedG"
        />
      }
      label="Processed"
    />
  )
}

export default OrderCheckboxField
