import React, { useEffect } from 'react'
import {
  makeStyles,
  createStyles,
  Grid,
  Box,
  useMediaQuery,
  Theme
} from '@material-ui/core'

import CampDirectorTemplate from '../camp-director.template'
import OrdersAccordion from './components/OrdersAccordion'
import OrderCheckboxField from '~/pages/camp-director/orders/components/OrderCheckboxField'
import { Flexbox, Loader, Text } from '~/components/shared'

import { useDispatch, useSelector } from 'react-redux'
import {
  getOrders,
  ordersMetaSelector,
  ordersSelector
} from '~/state/modules/orders'
import { Pagination } from '@material-ui/lab'
import {
  changeOrdersPage,
  deleteOrders
} from '~/state/modules/orders/orders.slice'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      accordionsContainer: {
        [theme.breakpoints.up('sm')]: {
          padding: theme.spacing(0, 2)
        }
      },

      empty: {
        width: '100%',
        justifyContent: 'center',
        backgroundColor: '#F8FAFD',
        padding: 20,
        '& p': {
          fontSize: '20px !Important'
        }
      },

      ckeckboxWrapper: {
        paddingLeft: '15px',
        '& .MuiFormControlLabel-root': {
          marginRight: 0
        }
      }
    }),
  {
    name: 'OrdersStyles'
  }
)

const Orders: React.FC = () => {
  const s = useStyles()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))

  const { data: orders, loading, loaded } = useSelector(ordersSelector)
  const { page, pages } = useSelector(ordersMetaSelector)

  useEffect(() => {
    dispatch(getOrders({ page }))

    return (): void => {
      dispatch(deleteOrders())
    }
  }, [page])

  const handleChangePage = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ): void => {
    dispatch(changeOrdersPage(value))
  }

  return (
    <CampDirectorTemplate>
      {!loading && loaded ? (
        <>
          {orders.length > 0 ? (
            <Grid container spacing={2} className={s.accordionsContainer}>
              {orders.map((order) => (
                <Grid
                  key={order?.id}
                  item
                  xs={12}
                  data-cy="dashboard-mobile-active-tabs"
                >
                  <Flexbox>
                    <OrdersAccordion order={order} />
                    <Flexbox className={s.ckeckboxWrapper}>
                      <OrderCheckboxField
                        id={order?.id}
                        processed={order?.processed}
                      />
                    </Flexbox>
                  </Flexbox>
                </Grid>
              ))}
              <Box mt={1.5} width="100%">
                <Pagination
                  count={pages}
                  page={page}
                  siblingCount={isMobile ? 0 : 1}
                  color="primary"
                  onChange={handleChangePage}
                />
              </Box>
            </Grid>
          ) : (
            <Flexbox className={s.empty}>
              <Text>There are no orders yet!</Text>
            </Flexbox>
          )}
        </>
      ) : (
        <Loader />
      )}
    </CampDirectorTemplate>
  )
}

export default Orders
