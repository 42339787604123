import { makeStyles, createStyles } from '@material-ui/core'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      container: {
        margin: theme.spacing(3, 'auto', 6),

        '&.withWidth': {
          [theme.breakpoints.up('sm')]: {
            maxWidth: 530
          }
        },

        [theme.breakpoints.down('xs')]: {
          padding: 0,
          margin: '100px 0 0'
        }
      },

      fullWidth: {
        [theme.breakpoints.down('xs')]: {
          width: '100vw',
          position: 'relative',
          left: '50%',
          right: '50%',
          margin: '100px -50vw 0'
        }
      },

      paper: {
        padding: '20px',
        marginBottom: 30,

        [theme.breakpoints.down('sm')]: {
          padding: '50px'
        },

        [theme.breakpoints.down('xs')]: {
          padding: '50px 20px',
          borderRadius: 'inherit',
          boxShadow: 'none'
        }
      },

      withPrice: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: '16px',
        marginBottom: 2
      },

      descriptionTex: {
        color: '#575757'
      },

      mainTex: {
        color: '#1B1B1B'
      },

      wrapText: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      },

      tooltipTex: {
        color: '#A9AFBF'
      },

      divider: {
        marginBottom: 30
      }
    }),
  {
    name: 'Payment'
  }
)

export default useStyles
