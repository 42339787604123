import React, { useEffect, useMemo } from 'react'
import { compose } from 'redux'
import { Form, reduxForm, InjectedFormProps, getFormValues } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import {
  CardContent,
  makeStyles,
  Card,
  Theme,
  Grid,
  Button
} from '@material-ui/core'

import FullWidthMobile from '~/containers/FullWidthMobile'

import ActivityService from '~/services/ActivitiesService'
import { asyncValidate } from '~/utils/asyncValidate'
import { removeCommasFromString } from '~/utils/functions'
import ActivitySchema from '~/schemas/activity'
import {
  createActivity,
  getCampDirectorInterests,
  updateCampDirectorActivity,
  duplicateActivity
} from '~/state/modules/programs'
import ROUTES from '~/constants/routes'
import { AppState } from '~/state/store'
import skillDevelopmentOptions from '~/data/camp-director/skillDevelopmentOptions'
import isEmpty from 'lodash.isempty'
import ActivityFormTemplate from './ActivityFormTemplate'
import { ExtendedButton, Flexbox, Title } from '~/components/shared'
import InfoDialog from '~/components/dialogs/InfoDialog'
import useModal from '~/hooks/useModal'

const useStyles = makeStyles((theme: Theme) => ({
  formCard: {
    width: '100%',

    '& button[type="submit"]': {
      minWidth: 115
    },

    [theme.breakpoints.down('xs')]: {
      borderRadius: 'inherit',
      borderWidth: '1px 0 1px 0'
    }
  }
}))

interface ActivityFormProps {
  isEdit?: boolean
  isDuplicate?: boolean
  isEditDuplicate?: boolean
}

const ActivityForm: React.FC<InjectedFormProps & ActivityFormProps> = ({
  dirty,
  submitting,
  isEdit,
  form,
  isDuplicate,
  isEditDuplicate,
  valid,
  handleSubmit,
  change
}) => {
  const s = useStyles()
  const dispatch = useDispatch()
  const interests = useSelector((state: AppState) => state.programs.interests)

  useEffect(() => {
    dispatch(getCampDirectorInterests())
  }, [])

  const interestsOption = useMemo(
    () =>
      interests.map((interest) => ({
        label: interest.name,
        value: interest.id
      })),
    [interests]
  )

  const {
    age_level = [],
    grade_level_start,
    grade_level_end,
    interest_ids = [],
    reserved_seats,
    total_seats
  } = useSelector<AppState, ProgramsFormValues>(
    (state) => (getFormValues(form)(state) as any) || {}
  )
  useEffect(() => {
    const totalSeatsValue = removeCommasFromString(total_seats)
    const reservedSeatsValue = removeCommasFromString(reserved_seats)

    const availableSeats = totalSeatsValue - reservedSeatsValue
    const isNoAvailableSeats = availableSeats === 0

    const availableSeatsValue = isNoAvailableSeats ? '0' : availableSeats

    change('available_seats', availableSeatsValue)
  }, [reserved_seats, total_seats])

  const isGradeOrAgeLevelsFilled =
    !isEmpty(age_level) ||
    (!isEmpty(grade_level_start) && !isEmpty(grade_level_end))

  const showGradeAndAgeModal = !isGradeOrAgeLevelsFilled && valid

  const generateSkillDevelopment = useMemo(() => {
    const equalInterests = interests
      .filter((x) => interest_ids.includes(x.id))
      .map((el) => el.name)

    const generateOptionsSkill = skillDevelopmentOptions
      .map((skill) => {
        if (equalInterests.includes(skill.interestLabel)) {
          return skill
        }
      })
      .filter(Boolean)

    return generateOptionsSkill
  }, [interests, interest_ids])

  const { hideModal, open, showModal } = useModal()

  const duplicateText = isDuplicate ? 'Duplicate' : 'Create activity'

  const buttonText = isEdit ? 'Save changes' : duplicateText

  const buttonType = !showGradeAndAgeModal ? 'submit' : 'button'

  return (
    <FullWidthMobile>
      <Card variant="outlined" className={s.formCard}>
        <CardContent>
          <Form onSubmit={handleSubmit}>
            <ActivityFormTemplate
              interestsOption={interestsOption}
              isDuplicate={isDuplicate}
              isEditDuplicate={isEditDuplicate}
              generateSkillDevelopment={generateSkillDevelopment}
            />

            <Grid item xs={12}>
              <Flexbox justifyContent="flex-end">
                <ExtendedButton
                  data-cy="parent-form-button"
                  type={buttonType}
                  {...(showGradeAndAgeModal && {
                    onClick: showModal
                  })}
                  loading={submitting}
                  disabled={!dirty}
                >
                  {buttonText}
                </ExtendedButton>
              </Flexbox>
            </Grid>
          </Form>
        </CardContent>
      </Card>

      {open && (
        <InfoDialog
          open={open}
          onClose={hideModal}
          title="Parents would love to know the age level and/or grade level for this activity."
          maxWidth="sm"
        >
          <Title variant="h3" mb={2}>
            Please specify at least one of these.
          </Title>

          <Button
            variant="outlined"
            fullWidth
            color="primary"
            onClick={hideModal}
          >
            Return
          </Button>
        </InfoDialog>
      )}
    </FullWidthMobile>
  )
}

const withForm = reduxForm<
  ActivityFormValues,
  RouteComponentProps & ActivityFormProps
>({
  form: 'Activity_Form',
  enableReinitialize: true,
  shouldAsyncValidate: () => true,
  asyncValidate: asyncValidate(ActivitySchema),
  destroyOnUnmount: false,
  // @ts-ignore
  onSubmit: async (
    values,
    dispatch,
    { isEdit, isDuplicate, isEditDuplicate, match: { params } }
  ) => {
    const { id, activityId } = params as {
      id: string
      activityId: string
    }

    const valuesDTO = ActivityService.generateCampDirectorValuesDTO(values)
    const valuesDuplicateDTO = ActivityService.generateCampDirectorDuplicateValuesDTO(
      values
    )
    try {
      if (isEdit) {
        // @ts-ignore
        const data = await dispatch<any>(
          updateCampDirectorActivity({
            id,
            activityId,
            values: isEditDuplicate ? valuesDuplicateDTO : valuesDTO
          })
        )

        if (!data?.error) {
          toastr.success('Success', 'The activity was successfully edited')
        }
      } else if (isDuplicate) {
        // @ts-ignore
        const data = await dispatch<any>(
          // @ts-ignore
          duplicateActivity({ parent_activity_id: activityId, ...valuesDTO })
        )
        if (!data?.error) {
          toastr.success('Success', 'The activity was successfully duplicated')
        }
      } else {
        // @ts-ignore
        const data = await dispatch<any>(
          createActivity({ id, values: valuesDTO })
        )
        if (!data?.error) {
          toastr.success('Success', 'The activity was successfully created')
        }
      }
    } catch (error) {
      // @ts-ignore
      throw new Error(error)
    }
  },
  onSubmitSuccess: (_v, _d, { history, match: { params } }) => {
    const { id } = params as { id: string }

    history.push(`${ROUTES.campDirectorProgram}/${id}`)
  }
})

export default compose<React.FC<ActivityFormProps & { initialValues?: any }>>(
  withRouter,
  withForm
)(ActivityForm)
