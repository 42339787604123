import { AppState } from '~/state/store'

export const ordersSelector = (
  state: AppState
): { data: Orders[]; loading: boolean; loaded: boolean } => {
  const { data, loading, loaded } = state.orders

  return { data, loading, loaded }
}

export const ordersMetaSelector = (
  state: AppState
): { count: number; pages: number; page: number } => {
  const { count, pages, page } = state.orders.ordersMeta

  return { count, pages, page }
}
