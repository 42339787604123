import PMK from '~/services/PMK'
import Deserializer from '~/services/Deserializer'
import ErrorHandler from '~/utils/errorHandler'

import { STATUSES_SHARED } from '~/constants/statusesItinerary'
import { AppThunk } from '~/state/store'

import {
  rejectItinerary,
  request,
  success,
  successItem
} from './friendsItineraries.slice'

// Actions

/**
 * Get friend itineraries action
 */
export const getFriendsItineraries = (): AppThunk<Promise<void>> => async (
  dispatch,
  getState
): Promise<void> => {
  const userId = getState().user.id

  dispatch(request())

  try {
    const res = await PMK.getFriendsItineraries()
    const payload = await Deserializer.deserialize(res)

    const promises = payload.map(async (itinerary: Itinerary) => {
      const parentItinerary = itinerary?.parents_itineraries?.find(
        ({ parent_id }) => parent_id === Number(userId)
      )

      const parentActivities = await Deserializer.deserialize(
        parentItinerary?.activities
      )

      return {
        ...itinerary,
        activities: parentActivities
      }
    })

    const itinerariesData: Itinerary[] = await Promise.all(promises)

    dispatch(success(itinerariesData))
  } catch (error) {
    throw new ErrorHandler(error)
  }
}

/**
 * Reject friend itinerary action
 */
export const rejectFriendItinerary = (
  id: string,
  values: ItineraryFriendInput
): AppThunk<Promise<void>> => async (dispatch): Promise<void> => {
  try {
    await PMK.updateFriendItinerary(id, {
      status: STATUSES_SHARED.declined,
      comment: values?.comment
    })

    dispatch(rejectItinerary(id))
  } catch (error) {
    throw new ErrorHandler(error)
  }
}

/**
 * Accepted friend itinerary action
 */
export const acceptedFriendItinerary = (
  id: string,
  values: ItineraryFriendInput
): AppThunk<Promise<void>> => async (dispatch, getState): Promise<void> => {
  const userId = getState().user.id

  try {
    const res = await PMK.updateFriendItinerary(id, {
      status: STATUSES_SHARED.accepted,
      comment: values?.comment
    })
    const payload = await Deserializer.deserialize(res)
    const parentItinerary = payload?.parents_itineraries?.find(
      ({ parent_id }) => parent_id === Number(userId)
    )

    const parentActivities = await Deserializer.deserialize(
      parentItinerary?.activities
    )

    dispatch(
      successItem({
        ...payload,
        activities: parentActivities
      })
    )
  } catch (error) {
    throw new ErrorHandler(error)
  }
}
