import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'

import {
  enoughtQuestionnaire,
  isAuthorized,
  isLogged,
  isPremiumPaid,
  userPackagesType
} from '~/state/modules/user'

import ROUTES from '~/constants/routes'

const withPremium = (isQuestionaary?: boolean) => <P extends object>(
  Component: React.ComponentType<P>
): React.FC<P> => (props): JSX.Element => {
  const premium = useSelector(isPremiumPaid)
  const limitQuestionnaire = useSelector(enoughtQuestionnaire)
  const isPackagesType = useSelector(userPackagesType)
  const isLoggedUser = useSelector(isLogged)
  const isAuthorizedUser = useSelector(isAuthorized)

  const blockPage = useMemo(() => {
    if (isQuestionaary) {
      const needRedirect = isLoggedUser
        ? !premium || limitQuestionnaire
        : limitQuestionnaire

      return needRedirect
    } else {
      return !premium && !isPackagesType
    }
  }, [limitQuestionnaire, premium])

  const showVerifyPage = isQuestionaary && !blockPage && !isAuthorizedUser

  if (isLoggedUser && showVerifyPage)
    return <Redirect to={ROUTES.signUpVerify} />

  return blockPage ? (
    <Redirect to={ROUTES.packages} />
  ) : (
    <Component {...props} />
  )
}

export default withPremium
