import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import { isAuthorized, isLogged, isPremium } from '~/state/modules/user'
import { useCallback, useState } from 'react'
import { PACKAGES_TYPE } from '~/constants/packages'
import ROUTES from '~/constants/routes'
import { STORAGE } from '~/constants/storage'
import {
  annualAdvantages,
  onyTimeAdvantages
} from '~/utils/constants/packages.constants'

const usePackages = () => {
  const history = useHistory()

  const authorized = useSelector(isAuthorized)
  const isLoggedUser = useSelector(isLogged)
  const premium = useSelector(isPremium)

  const [packageUrl, setPackageUrl] = useState<PackagesTypeUnion>(
    PACKAGES_TYPE.one_time
  )

  const handleChangeSwitch = useCallback((value): void => {
    const currentPackage = value
      ? PACKAGES_TYPE.annually
      : PACKAGES_TYPE.one_time

    setPackageUrl(currentPackage)
  }, [])

  const handleClickPremium = useCallback((): void => {
    const redirectUrl = isLoggedUser
      ? `${ROUTES.checkout}/${packageUrl}`
      : ROUTES.confirm

    if (!isLoggedUser) {
      localStorage.setItem(
        STORAGE.redirect_from,
        `${ROUTES.checkout}/${packageUrl}`
      )
    }

    history.push(redirectUrl)
  }, [isLoggedUser, packageUrl, history])

  const isOneTimePackage = packageUrl === PACKAGES_TYPE.one_time

  const currentAdvantages = isOneTimePackage
    ? onyTimeAdvantages
    : annualAdvantages

  const isPremiumAccount = authorized && premium

  return {
    currentAdvantages,
    handleChangeSwitch,
    handleClickPremium,
    isPremiumAccount
  }
}

export default usePackages
