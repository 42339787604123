import { makeStyles } from '@material-ui/core'
import palette from '~/theme/palette'

export default makeStyles({
  wrapper: {
    height: '500px',
    overflowY: 'hidden',
    borderRadius: '10px',
    marginBottom: '24px',
    backgroundImage: 'url(/static/media/pattern.1fffcce0.png)',
    backgroundSize: '100% auto',
    backgroundRepeat: 'repeat-y',
    backgroundPosition: 'top',
    boxShadow: '5px 5px 15px rgba(35, 35, 35, 0.15) inset'
  },

  scroll: {
    height: '100%',
    padding: '16px',
    overflowY: 'auto',
    overflowX: 'hidden',

    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent'
    },

    '&::-webkit-scrollbar': {
      width: 3,
      backgroundColor: 'transparent'
    },

    '&::-webkit-scrollbar-thumb': {
      borderRadius: 3,
      backgroundColor: palette.secondary.main
    }
  }
})
