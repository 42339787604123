import React, { useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogProps,
  Grid,
  Button,
  useMediaQuery
} from '@material-ui/core'

import DialogTitle from './DialogTitle'
import { Text } from '../shared'

type Props = {
  in: boolean
  onClose: () => void
  onConfirm: () => void
  confirmText?: string
  cancelText?: string
  text?: string
  isMoreText?: boolean
  secondText?: string
  maxWidth?: string
}

const ConfirmDialog: React.FC<Props & DialogProps> = ({
  in: open,
  onClose,
  onConfirm,
  confirmText,
  cancelText,
  text = '',
  isMoreText,
  secondText = '',
  maxWidth = 'xs',
  ...rest
}) => {
  const [action, setAction] = useState<boolean>(false)
  const isMobile = useMediaQuery('(max-width: 490px)')
  const buttonWidth = isMobile ? 12 : 6

  const handleConfirm = (): void => {
    if (!action) {
      setAction(true)
      onConfirm()
      onClose()
    }
  }

  return (
    <Dialog
      {...rest}
      maxWidth={maxWidth}
      open={open}
      onClose={onClose}
      data-cy="confirm-dialog"
    >
      <DialogTitle onClose={onClose}>{text}</DialogTitle>

      {isMoreText && (
        <Text
          variant="h3"
          fontFamily="DM Serif Display, serif"
          p="0 30px 40px"
          textAlign="center"
          mt={text && '-20px'}
        >
          {secondText}
        </Text>
      )}

      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={buttonWidth}>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={onClose}
              fullWidth
            >
              {cancelText || 'Cancel'}
            </Button>
          </Grid>

          <Grid item xs={buttonWidth}>
            <Button
              data-cy="dialog-confirm-button"
              variant="contained"
              size="large"
              color="primary"
              onClick={handleConfirm}
              fullWidth
              disabled={action}
            >
              {confirmText || 'Confirm'}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default ConfirmDialog
