export const PACKAGES: Record<PackagesUnion, PackagesUnion> = {
  Basic: 'Basic',
  Premium: 'Premium'
} as const

export const ORDERS: Record<OrdersUnion, OrdersUnion> = {
  draft: 'draft',
  paid: 'paid',
  payment_failed: 'payment_failed',
  pending: 'pending'
} as const

export const PACKAGES_TYPE = {
  one_time: 'one_time',
  monthly: 'monthly',
  annually: 'annually'
} as const

export const PACKAGES_MESSAGES = {
  [PACKAGES_TYPE.one_time]: 'one-time',
  [PACKAGES_TYPE.monthly]: 'monthly',
  [PACKAGES_TYPE.annually]: 'annually'
} as const
