import moment, { Moment } from 'moment'
import { ItineraryCardProps } from '~/components/cards/ItineraryCard/ItineraryCard'
import { PACKAGES_TYPE } from '~/constants/packages'
import ActivitiesService from '~/services/ActivitiesService'
import IntlService from '~/services/IntlService'

/**
 * Deep merges two objets.
 * @param  {Object} object destination object
 * @param  {Object} source source obejct
 *
 * @returns {Object} new object
 */
export const merge = (obj: any, source: any): any => {
  if (obj === source) return obj
  const newValue: any = {
    ...obj,
    ...source
  }

  Object.entries(source).forEach(([key, value]) => {
    newValue[key] =
      obj[key] && typeof obj[key] === 'object' ? merge(obj[key], value) : value
  })

  return newValue
}

/**
 * Return a copy of an object excluding the given key, or array of keys.
 *
 * @param {object} obj - initial object
 * @param {(string|string[])} props - values to be omitted
 * @param {function} [fn] - an optional filter function
 */
export function omit(obj: any, props: any, fn?: any): any {
  if (!obj || obj.constructor !== Object) return {}
  if (typeof props === 'function') {
    fn = props
    props = []
  }
  if (typeof props === 'string') {
    props = [props]
  }
  const isFunction = typeof fn === 'function'
  const keys = Object.keys(obj)
  const res: any = {}
  // tslint:disable-next-line: prefer-for-of
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i]
    const val = obj[key]
    if (
      !props ||
      (props.indexOf(key) === -1 && (!isFunction || fn(val, key, obj)))
    ) {
      res[key] = val
    }
  }
  return res
}

/**
 * Sort array by name
 *
 * @param {array} options - array
 * @param {string} field - field by which to sort
 */
export const sortByName = <T = Option>(options: T[], field: string): T[] => {
  return options.sort((elementA, elementB) => {
    const nameA = elementA[field].toUpperCase().trim()
    const nameB = elementB[field].toUpperCase().trim()

    if (nameA < nameB) return -1
    else if (nameA > nameB) return 1
    else return 0
  })
}

/**
 * Sort array by date
 *
 * @param {array} options - array
 * @param {string} field - field by which to sort
 * @param {string} postField - field by which to get to sorting field
 * @param {boolean} isDesc - sort in reverse order
 */
export const sortByDate = <T = Option>(
  options: T[],
  field: string,
  isDesc?: boolean,
  postField?: string
): T[] => {
  return options
    ?.filter(Boolean)
    .slice()
    .sort((elementA, elementB) => {
      const objField = field
      const objPostField = postField

      const dateA = objPostField
        ? new Date(elementA[objPostField]?.[objField])
        : new Date(elementA[objField])
      const dateB = objPostField
        ? new Date(elementB[objPostField]?.[objField])
        : new Date(elementB[objField])

      if (isDesc) {
        return ((dateB as unknown) as number) - ((dateA as unknown) as number)
      }

      return ((dateA as unknown) as number) - ((dateB as unknown) as number)
    })
}

/**
 * Uppercase the first letter of a string
 *
 * @param {string} string - string
 */
export const capitalize = (string: string): string => {
  if (typeof string !== 'string') return ''
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * Change date format
 *
 * @param {string} date - string
 * @param {string} format - format date string from moment.js
 */
export const humanizeDate = (
  date: string | Moment,
  format = 'MMM D, YYYY'
): string => {
  return moment(date).format(format)
}

/**
 * Change date format
 *
 * @param {string} seconds - string
 */
export const humanizeTimeFromSeconds = (seconds: string): string => {
  return moment.unix(Number(seconds)).format('hh:mm A')
}

/**
 * Change date format
 *
 * @param {string} seconds - string
 */
export const humanizeTime = (time: string): string => {
  return moment(time, 'HH:mm:ss A').format('hh:mm A')
}

/**
 * Return a difference from two dates
 *
 * @param {string} date_2
 * @param {string} date_1
 */
export const generateDifferenceDates = (
  date_1: string,
  date_2: string
): string => {
  const endTime = moment(date_2)
  const startTime = moment(date_1)
  const duration = moment.duration(endTime.diff(startTime))

  duration.add(moment.duration(1, 'd'))

  const days = Math.ceil(Math.abs(duration.asDays()))

  if (!days) {
    return '1 day'
  }

  return `${days} day${days > 1 ? 's' : ''}`
}

/**
 * Creates options for Autocomplete
 *
 * @param {array} data - data array
 * @param {string} key1 - object key1
 * @param {string} key2 - object key2
 */
export const createOptions = (
  data: Array<{
    [key: string]: any
  }>,
  key1 = 'id',
  key2 = 'name'
): {
  value: string
  label: string
  [key: string]: string
}[] => {
  if (!data || !Array.isArray(data)) return []

  return data
    .filter(Boolean)
    .map(({ [key1]: value, [key2]: label, ...rest }) => ({
      value,
      label,
      ...rest
    }))
}

/**
 * Return metres from location slider
 *
 * @param {number} value - slider value
 * @param {boolean} kilometers - return kilometers
 *
 */
export const createSliderDistance = (value: number): number => {
  const step = 1609.344

  const radius = value * step

  return radius
}

/**
 * Delete unnecessary key in object
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const deleteKey = (key, { [key]: deleteKey, ...others }): any => others

/**
 * Transform kebab case to default text
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const transformText = (text: string): any => {
  const splitText = text.split('_')

  if (splitText.length > 0) {
    return capitalize(splitText.join(' '))
  } else {
    return capitalize(text)
  }
}

/**
 * Transform kebab case to default text with capitalize for every word
 */

export const toUpperCaseFirstLetterForEveryWord = (text: string): any => {
  const splitText = text?.split('_')?.map((word) => capitalize(word))

  return capitalize(splitText?.join(' '))
}

export const removeCommasFromString = (value: string | number): any => {
  return typeof value === 'string' ? Number(value.replace(/,/g, '')) : value
}

/**
 * Trim string value, and remove spaces more than one
 */

export const trimValues = (values: any): any => {
  return Object.entries(values || {}).reduce((acc, [key, value]) => {
    if (typeof value === 'string') {
      acc[key] = value.trim().split(/ +/).join(' ')
    } else {
      acc[key] = value
    }
    return acc
  }, {})
}

export const transformZeroToString = (
  value: string | number
): string | number => {
  if (typeof value === 'number' && value === 0) {
    return '0'
  }
  return value
}

export const testMediaVideo = (value: string): boolean => {
  const youTubeRegExp = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/gm

  return youTubeRegExp.test(value)
}

export const dateFormat = (date: Date): string => {
  return new Date(date).toLocaleString('LT', {
    year: 'numeric',
    month: '2-digit',
    day: 'numeric'
  })
}

export const parentItineraryActivitiesFormating = (
  values: Activity
): ItineraryCardProps => {
  const {
    id,
    name,
    start_date,
    end_date,
    age_level,
    grade_level,
    camp_type,
    price,
    description,
    url,
    media_link,
    provider_name,
    provider_logo,
    location,
    start_time,
    end_time,
    cancellation_policy,
    available_seats,
    reserved_seats,
    total_seats,
    optional_media_link,
    payment_status,
    sku,
    waiver_form_url
  } = values || {}

  const duration =
    start_date && end_date && start_date !== end_date
      ? generateDifferenceDates(start_date, end_date)
      : '1 day'

  const {
    camp_info_time,
    camp_info_title
  } = ActivitiesService.generateCampInfo(start_time, end_time, camp_type)

  return {
    id,
    optional_media_link,
    subtitle: `by ${provider_name}`,
    title: name,
    img: media_link || provider_logo,
    date: `${start_date ? `${humanizeDate(start_date)}` : ''} - ${
      end_date ? `${humanizeDate(end_date)}` : ''
    }`,
    start_time,
    end_time,
    duration,
    camp_info_title,
    camp_info_time,
    age: age_level ? `${age_level} years` : undefined,
    grade: grade_level,
    type: camp_type,
    prices: IntlService.currencyFormat(price),
    location,
    description,
    url,
    payment_status: toUpperCaseFirstLetterForEveryWord(payment_status),
    cancellation_policy,
    available_seats,
    reserved_seats,
    total_seats,
    sku,
    waiver_form_url
  }
}

export const generatePlaceholderForImage = (text: string): string => {
  const textSplit = text.split(' ')
  const firstLetters = textSplit.reduce((acc, word, currentIndex) => {
    if (currentIndex > 1) return acc

    return `${acc}${word[0]}`
  }, '')

  return firstLetters.toUpperCase()
}

export const getDashboardSubscriptionName = (packageType?: string): string => {
  const isOneTimePackageType = packageType === PACKAGES_TYPE.one_time
  const isOldMonthlyType = packageType?.toLowerCase() === PACKAGES_TYPE.monthly

  const currentPackageName = isOneTimePackageType ? 'One-Time' : 'Annually'
  const packageTypeName = isOldMonthlyType ? 'Monthly' : currentPackageName
  const packageName = !!packageType ? packageTypeName : 'Not Subscribed'

  return packageName
}
