import React, { FC, useCallback, useState } from 'react'
import { useHistory } from 'react-router'
import { useMediaQuery, Theme } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'

type Props = {
  count: number
  activePage: number
  link: string
  locationState: object
  onChange(page: number): void
}

const ActivitiesPagination: FC<Props> = ({
  count,
  activePage,
  link,
  locationState,

  onChange
}) => {
  const history = useHistory()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const [page, setPage] = useState(activePage)

  const handleChangePage = useCallback(
    (_event: React.ChangeEvent<unknown>, value: number): void => {
      setPage(value)
      onChange(value)
      history.push(`${link}?page-${value}`, locationState)
    },
    [link, locationState, history, onChange]
  )

  return (
    <Pagination
      count={count}
      page={page}
      color="primary"
      onChange={handleChangePage}
      siblingCount={isMobile ? 0 : 1}
    />
  )
}

export default ActivitiesPagination
