import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  requestAction,
  successAction,
  failureAction,
  successItemAction
} from '~/utils/reducerFunctions'

export interface ActivitiesState {
  item: Partial<Activity>
  activity_provider: Partial<ActivityProvider>
  data: Activity[]
  loading: boolean
  loaded: boolean
  error: any
  meta: ActivitiesMeta
}

const initialState: ActivitiesState = {
  data: [],
  item: {},
  activity_provider: {},
  loading: false,
  loaded: false,
  meta: {
    count: 0,
    pages: 1,
    max_price: 0,
    min_price: 2000,
    camp_types: [],
    activity_providers: {},
    interests: [],
    skill_development: []
  },
  error: null
}

const activitiesSlice = createSlice({
  name: 'activities',
  initialState,
  reducers: {
    request: requestAction,

    success: (
      state,
      action: PayloadAction<{ data: Activity[]; meta: ActivitiesMeta }>
    ): void => {
      successAction(state, action, 'data', (state) => {
        const {
          payload: { data, meta }
        } = action

        state.data = data
        state.meta = meta
      })
    },

    successItem: successItemAction,

    updateItem: (
      state,
      {
        payload: { note, id: activityId }
      }: PayloadAction<{ note: string; id?: string }>
    ): void => {
      if (activityId) {
        const activity = state.data.find(({ id }) => id === activityId)

        activity.specialist_notes = note
      } else {
        state.item.specialist_notes = note
      }
    },

    successActivityProviderItem: (
      state,
      action: PayloadAction<ActivityProvider>
    ): void => {
      successAction(state, action, 'activity_provider')
    },

    updateActivityProviderItem: (
      state,
      { payload: { note, id } }: PayloadAction<{ note: string; id?: string }>
    ): void => {
      if (id) {
        const activities = state.data.filter(
          ({ activity_provider }) => activity_provider?.id === id
        )

        activities.forEach((activity) => {
          activity.activity_provider.specialist_notes = note
        })
      } else {
        state.activity_provider.specialist_notes = note
      }
    },

    failure: failureAction
  }
})

export const {
  request,
  success,
  successItem,
  updateItem,
  successActivityProviderItem,
  updateActivityProviderItem,
  failure
} = activitiesSlice.actions

export default activitiesSlice.reducer
