import { object, string, array } from 'yup'

const RequestActivity = object().shape({
  requestChange: array().of(
    object().shape({
      more_information: string().required('Comment is required'),
      activity_id: string().required('Activity is required'),
      reason: object().test(
        'checkboxValidation',
        'Check at least one checkbox',
        (value) => {
          if (Object.values(value || {}).some((value) => value)) {
            return true
          }

          return false
        }
      )
    })
  )
})

export default RequestActivity
