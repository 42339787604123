import React, { FC } from 'react'
import {
  Card,
  CardMedia,
  CardContent,
  createStyles,
  makeStyles
} from '@material-ui/core'

import { Text, DetailsList } from '~/components/shared'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      card: {
        height: '100%',
        border: `1px solid ${theme.palette.text.disabled}`,
        borderRadius: '20px',
        boxShadow: 'none',

        '& img': {
          height: 200,
          objectPosition: 'top'
        }
      }
    }),
  {
    name: 'CampCard'
  }
)

export type CampCardProps = {
  image: string
  title: string
  subtitle: string
  description: string
  items?: CourseDetail[]
}

const CampCard: FC<CampCardProps> = ({
  image,
  title,
  subtitle,
  description,
  items
}) => {
  const s = useStyles()

  return (
    <Card className={s.card}>
      <CardMedia component="img" image={image} />
      <CardContent>
        <Text color="textSecondary" mb={0.5}>
          {title}
        </Text>
        <Text fontWeight={600} mb={1}>
          {subtitle}
        </Text>
        {description && (
          <Text color="textSecondary" mb={1.5}>
            {description}
          </Text>
        )}

        <DetailsList items={items} isSmallPadding />
      </CardContent>
    </Card>
  )
}

export default CampCard
