import React, { FC, useCallback, useState } from 'react'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js'
import { FormHelperText, Grid } from '@material-ui/core'
import { Lock } from '@material-ui/icons'
import { Skeleton } from '@material-ui/lab'

import { ExtendedButton, Text } from '~/components/shared'
import CouponInput from './CouponInput'

import { login } from '~/state/modules/user/user.slice'
import ErrorHandler from '~/utils/errorHandler'
import PMK from '~/services/PMK'

import {
  ORDERS,
  PACKAGES,
  PACKAGES_MESSAGES,
  PACKAGES_TYPE
} from '~/constants/packages'
import ROUTES from '~/constants/routes'
import {
  cvcElementOptions,
  expiryElementOptions,
  numberElementOptions
} from './options'

import useStripeFormStyles from './StripeForm.styles'
import { isAuthorized, isGoogleUser } from '~/state/modules/user'

type Props = {
  currentPackage: Package
}

const StripeForm: FC<Props> = ({ currentPackage }) => {
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch()
  const h = useHistory()
  const s = useStripeFormStyles()
  const isGoogleAuth = useSelector(isGoogleUser)
  const isConfirmed = useSelector(isAuthorized)
  const isCurrentMonthly = currentPackage?.name === PACKAGES_TYPE.monthly
  const currentPackageType = currentPackage?.name || PACKAGES_TYPE.one_time
  const needToConfirmUser = isGoogleAuth && !isConfirmed

  const [succeeded, setSucceeded] = useState<boolean>(false)
  const [error, setError] = useState<any>(null)
  const [processing, setProcessing] = useState<boolean>(false)
  const [errorPayload, setErrorPayload] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(true)

  const [currentCoupon, setCurrentCoupon] = useState(null)
  const [finalPrice, setFinalPrice] = useState(null)

  const handleChange = useCallback((e) => {
    setDisabled(e.empty)
    setError(e.error ? e.error.code : '')
  }, [])

  const handleUpdateCoupon = useCallback((value: string): void => {
    setCurrentCoupon(value)
  }, [])

  const handleUpdatePrice = useCallback((value: number): void => {
    setFinalPrice(value)
  }, [])

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault()

      setProcessing(true)

      if (!stripe || !elements) {
        return
      }

      try {
        const payload = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardNumberElement)
        })

        if (payload.error) {
          setError(payload.error.code)
          setErrorPayload(true)
          setProcessing(false)

          toastr.error('Error', 'Oops something went wrong!')
        } else {
          await PMK.createSubscripton({
            package_id: currentPackage.id,
            payment_method_id: payload.paymentMethod?.id,
            promocode: currentCoupon
          })

          setError(null)
          setSucceeded(true)
          setErrorPayload(false)

          toastr.success(
            'Payment',
            'Your plan has been successfully sent for processing!'
          )
          dispatch(
            login({
              package: PACKAGES.Premium,
              order_status: ORDERS.pending,
              package_type: currentPackage?.name,
              ...(needToConfirmUser && {
                confirmed: true
              })
            })
          )
          h.push(ROUTES.profile)
        }
      } catch (error) {
        new ErrorHandler(error)
      } finally {
        setProcessing(false)
      }
    },
    [stripe, currentPackage, currentCoupon]
  )

  return (
    <Grid container spacing={2} className={s.container}>
      <Grid item xs={12}>
        <Text variant="h4" textAlign="center" fontWeight={600}>
          Upgrade to Premium
        </Text>
      </Grid>

      <Grid item xs={12}>
        <Text variant="h5" textAlign="center">
          ${currentPackage?.price} {PACKAGES_MESSAGES[currentPackageType]}
        </Text>
      </Grid>

      {isCurrentMonthly && (
        <Grid item xs={12}>
          <Text variant="subtitle1" textAlign="center" color="secondary">
            Use coupon code{' '}
            <Text variant="subtitle1" component="span" fontWeight={700}>
              MYTRIAL
            </Text>{' '}
            to get your first month free
          </Text>
        </Grid>
      )}

      <Grid item xs={12}>
        <CouponInput
          price={currentPackage?.price}
          updateCoupon={handleUpdateCoupon}
          updatePrice={handleUpdatePrice}
        />
      </Grid>

      <Grid item xs={12}>
        <form className={s.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Text variant="body2" mb={1} fontWeight={600}>
                Card number
              </Text>

              {!stripe ? (
                <Skeleton width="100%" height={50} variant="rect" />
              ) : (
                <CardNumberElement
                  options={numberElementOptions}
                  onChange={handleChange}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Text variant="body2" mb={1} fontWeight={600}>
                Expiration Date
              </Text>

              {!stripe ? (
                <Skeleton width="100%" height={50} variant="rect" />
              ) : (
                <CardExpiryElement
                  options={expiryElementOptions}
                  onChange={handleChange}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Text variant="body2" mb={1} fontWeight={600}>
                CVC Number
              </Text>

              {!stripe ? (
                <Skeleton width="100%" height={50} variant="rect" />
              ) : (
                <CardCvcElement
                  options={cvcElementOptions}
                  onChange={handleChange}
                />
              )}
            </Grid>

            {error && (
              <FormHelperText variant="filled" error className={s.errMsg}>
                {errorPayload ? 'Payment failed.' : null}{' '}
                {
                  {
                    ['incomplete_number']: 'Your card number is incomplete.',
                    ['invalid_number']: 'Your card number is invalid.',
                    ['invalid_expiry_year']: 'Your card date is invalid.',
                    ['incomplete_expiry']:
                      "Your card's expiration date is incomplete.",
                    ['incomplete_cvc']:
                      "Your card's security code is incomplete."
                  }[error]
                }
              </FormHelperText>
            )}

            <Grid item xs={12}>
              <Text textAlign="center" fontWeight={600} mb={-1}>
                <Lock
                  fontSize="small"
                  style={{
                    margin: '0 5px -2px 0'
                  }}
                />{' '}
                Secure payment
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Text textAlign="center" mt={2} mb={1}>
                Total Payment Due: $
                {finalPrice == null ? currentPackage?.price : finalPrice}
              </Text>

              <ExtendedButton
                type="submit"
                variant="contained"
                size="large"
                disabled={disabled || succeeded}
                loading={processing}
                fullWidth
              >
                Pay
              </ExtendedButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  )
}

export default StripeForm
