import React, { useMemo, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { compose } from 'redux'
import { toastr } from 'react-redux-toastr'
import { useDispatch, useSelector } from 'react-redux'
import {
  Form,
  Field,
  reduxForm,
  InjectedFormProps,
  getFormValues,
  change,
  destroy
} from 'redux-form'
import {
  CardContent,
  Grid,
  makeStyles,
  Card,
  Theme,
  useMediaQuery,
  Box,
  InputAdornment
} from '@material-ui/core'

import {
  AutocompleteField,
  NumberField,
  PhoneField,
  TextField
} from '~/components/fields'
import {
  FormControl,
  Flexbox,
  ExtendedButton,
  Loader,
  Text
} from '~/components/shared'
import FullWidthMobile from '~/containers/FullWidthMobile'
import UploadImageField from '../UploadImageField'

import {
  createProgram,
  getCampDirectorInterests,
  updateProgramById
} from '~/state/modules/programs'
import { asyncValidate } from '~/utils/asyncValidate'
import { transformText, trimValues } from '~/utils/functions'

import ProgramSchema from '~/schemas/program'
import ROUTES from '~/constants/routes'
import personalGrowthOptions from '~/data/personalGrowthOptions'
import specialNeedsOptions from '~/data/specialNeedsOptions'
import { AppState } from '~/state/store'
import ProgramService from '~/services/ProgramService'

const useStyles = makeStyles((theme: Theme) => ({
  formCard: {
    width: '100%',

    '& button[type="submit"]': {
      minWidth: 115
    },

    '& .MuiOutlinedInput-multiline': {
      padding: '15.5px 14px'
    },

    [theme.breakpoints.down('xs')]: {
      borderRadius: 'inherit',
      borderWidth: '1px 0 1px 0'
    }
  },
  input: {
    display: 'none'
  },
  info: {
    width: '230px',
    minWidth: '230px',
    borderRadius: 5,
    backgroundColor: theme.palette.text.disabled
  }
}))

interface ProgramFormProps {
  isEdit?: boolean
  loading?: boolean
  id?: string
}

const ProgramForm: React.FC<InjectedFormProps & ProgramFormProps> = ({
  handleSubmit,
  dirty,
  submitting,
  isEdit,
  loading,
  id,
  form
}) => {
  const s = useStyles()
  const dispatch = useDispatch()

  const interests = useSelector((state: AppState) => state.programs.interests)
  const { special_needs_array = [] } = useSelector<
    AppState,
    ProgramsFormValues
  >((state) => (getFormValues(form)(state) as any) || {})

  const interestsOption = useMemo(
    () =>
      interests.map((interes) => ({
        label: interes.name,
        value: interes.id
      })),
    [interests]
  )

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))

  useEffect(() => {
    dispatch(getCampDirectorInterests())
    if (!id) dispatch(destroy(form))
  }, [])

  return (
    <FullWidthMobile>
      {!loading ? (
        <Card variant="outlined" className={s.formCard}>
          <CardContent>
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl label="Program name" required>
                    <Field
                      id="name"
                      name="name"
                      placeholder="Program name"
                      component={TextField}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl label="Email" required>
                    <Field
                      id="email"
                      name="email"
                      placeholder="Email"
                      component={TextField}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl label="Address" required>
                    <Field
                      id="address"
                      name="address"
                      placeholder="Address"
                      autoComplete="email"
                      component={TextField}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl label="Address 2">
                    <Field
                      id="address2"
                      name="address2"
                      placeholder="Address 2"
                      autoComplete="second_address"
                      component={TextField}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl label="City" required>
                    <Field
                      id="city"
                      name="city"
                      placeholder="City"
                      autoComplete="city"
                      component={TextField}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl label="State" required>
                    <Field
                      id="state"
                      name="state"
                      placeholder="State"
                      autoComplete="state"
                      component={TextField}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl label="ZIP-code" required>
                    <Field
                      id="zip_code"
                      name="zip_code"
                      placeholder="ZIP-code"
                      autoComplete="email"
                      component={TextField}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl label="Website" required>
                    <Field
                      id="website"
                      name="website"
                      placeholder="Website"
                      autoComplete="website"
                      component={TextField}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl label="Phone" required>
                    <Field
                      id="phone_number"
                      name="phone_number"
                      placeholder="phone"
                      component={PhoneField}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl label="FAQLink">
                    <Field
                      id="faq"
                      name="faq"
                      placeholder="FAQLink"
                      autoComplete="email"
                      component={TextField}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl label="Interest" required>
                    <Field
                      id="interest_ids"
                      name="interest_ids"
                      placeholder="Interest"
                      component={AutocompleteField}
                      disabled={!interestsOption.length}
                      options={interestsOption}
                      multiple
                      onChangeKey="value"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl label="Waiver form">
                    <Field
                      id="waiver_form_url"
                      name="waiver_form_url"
                      placeholder="Waiver form"
                      autoComplete="Waiver form"
                      component={TextField}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl label="Logo">
                    <Flexbox
                      flexDirection={isMobile ? 'column' : 'row'}
                      alignItems={isMobile && 'center'}
                    >
                      <Box mr={2} mb={isMobile ? 2 : 0}>
                        <Field
                          id="logo"
                          name="logo"
                          placeholder="Logo"
                          autoComplete="image"
                          component={UploadImageField}
                          className={{ marginRight: 10 }}
                        />
                      </Box>

                      <Box className={s.info}>
                        <Text fontSize={14} p="8px 20px">
                          Add logo of your store to improve customer&apos;s
                          trust. You can add .jpg, .jpeg files until 1 MB only.
                        </Text>
                      </Box>
                    </Flexbox>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl label="Description">
                    <Field
                      id="description"
                      name="description"
                      placeholder="Description"
                      autoComplete="description"
                      component={TextField}
                      multiline
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl label="Special needs">
                    <Field
                      id="special_needs_array"
                      name="special_needs_array"
                      placeholder="Special needs"
                      autoComplete="special_needs"
                      options={specialNeedsOptions}
                      component={AutocompleteField}
                      multiple
                      onChangeKey="value"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl label="TaxID" required>
                    <Field
                      id="tax_id"
                      name="tax_id"
                      placeholder="TaxID"
                      autoComplete="tax_id"
                      component={TextField}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl label="Personal growth">
                    <Field
                      id="personal_growth"
                      name="personal_growth"
                      placeholder="Personal growth"
                      autoComplete="personal_growth"
                      component={AutocompleteField}
                      options={personalGrowthOptions}
                      onChangeKey="value"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl label="Financial Aid Description">
                    <Field
                      id="financial_aid_description"
                      name="financial_aid_description"
                      placeholder="Financial Aid Description"
                      autoComplete="financial_aid_description"
                      component={TextField}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl label="Financial Aid Percent">
                    <Field
                      id="financial_aid_percent"
                      name="financial_aid_percent"
                      placeholder="Financial Aid Percent"
                      autoComplete="financial_aid_percent"
                      component={NumberField}
                      inputProps={{ inputMode: 'numeric' }}
                      startAdornment={
                        <InputAdornment position="start">%</InputAdornment>
                      }
                      isAllowed={(values): boolean => {
                        const { value } = values

                        return value >= 0 && value <= 100
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl label="Financial Aid Link">
                    <Field
                      id="financial_aid_link"
                      name="financial_aid_link"
                      placeholder="Financial Aid Link"
                      autoComplete="financial_aid_link"
                      component={TextField}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl label="Cancellation policy" required>
                    <Field
                      id="cancellation_policy"
                      name="cancellation_policy"
                      placeholder="Cancellation policy"
                      autoComplete="cancellation_policy"
                      component={TextField}
                      multiline
                    />
                  </FormControl>
                </Grid>

                {special_needs_array?.length > 0 &&
                  special_needs_array.map((needs) => (
                    <Grid item xs={12} sm={6} key={needs}>
                      <FormControl label={transformText(needs)}>
                        <Field
                          id={needs}
                          name={`special_needs.${needs}`}
                          placeholder={transformText(needs)}
                          autoComplete={needs}
                          options={specialNeedsOptions}
                          component={TextField}
                        />
                      </FormControl>
                    </Grid>
                  ))}

                <Grid item xs={12}>
                  <Flexbox justifyContent="flex-end">
                    <ExtendedButton
                      data-cy="parent-form-button"
                      type="submit"
                      loading={submitting}
                      disabled={!dirty}
                    >
                      {isEdit ? ' Save changes' : 'Create program'}
                    </ExtendedButton>
                  </Flexbox>
                </Grid>
              </Grid>
            </Form>
          </CardContent>
        </Card>
      ) : (
        <Loader />
      )}
    </FullWidthMobile>
  )
}

const withForm = reduxForm<
  ProgramsFormValues,
  RouteComponentProps & ProgramFormProps
>({
  form: 'Dialer_Program_Form',
  enableReinitialize: true,
  shouldAsyncValidate: () => true,
  asyncValidate: asyncValidate(ProgramSchema),
  destroyOnUnmount: false,
  onChange: (values, dispatch, { form }) => {
    if (values?.special_needs_array) {
      const difference = specialNeedsOptions.filter(
        (x) => !(values?.special_needs_array).includes(x.value)
      )

      difference.forEach((v) => {
        dispatch(change(form, `special_needs.${v.value}`, null))
      })
    }
  },
  // @ts-ignore
  onSubmit: async (values, dispatch, { isEdit, match: { params } }) => {
    const id = (params as { id?: string })?.id

    const logo = (values?.logo?.signed_id as string) || null

    const valuesDTO = ProgramService.programValuesDTO(values)

    const trimedDto = trimValues(valuesDTO)

    try {
      if (isEdit) {
        // @ts-ignore
        const data = await dispatch<any>(
          updateProgramById({ id, values: { ...trimedDto, logo } })
        )
        if (!data?.error) {
          toastr.success('Success', 'The program was edited successfully')
        }
      } else {
        // @ts-ignore
        const data = await dispatch<any>(
          createProgram({ ...valuesDTO, label: 'Registered', logo })
        )
        if (!data?.error) {
          toastr.success('Success', 'The program was created successfully')
        }
      }
    } catch (error) {
      // @ts-ignore
      throw new Error(error)
    }
  },
  onSubmitSuccess: (_v, _d, { history }) => {
    history.push(ROUTES.campDirectorPrograms)
  }
})

export default compose<React.FC<ProgramFormProps & { initialValues?: any }>>(
  withRouter,
  withForm
)(ProgramForm)
