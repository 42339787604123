import { makeStyles, createStyles } from '@material-ui/core'

const useSearchStyles = makeStyles(
  ({ spacing, palette }) =>
    createStyles({
      title: {
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'start',
        marginBottom: spacing(3)
      },

      chipsWrapper: {
        padding: spacing(2),
        borderRadius: spacing(1),
        paddingBottom: spacing(2),
        marginBottom: spacing(2),
        border: `1px solid ${palette.text.disabled}`,
        borderBottom: `1px solid ${palette.text.disabled}`
      },

      icon: {
        marginRight: 12
      }
    }),
  {
    name: 'Search'
  }
)

export default useSearchStyles
