import { makeStyles, createStyles } from '@material-ui/core'

const useDashboardStyles = makeStyles(
  (theme) =>
    createStyles({
      container: {
        width: '100%',
        minHeight: 'calc(100vh - 330px)',
        margin: theme.spacing(3, 'auto', 6),
        padding: theme.spacing(5, 0),

        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(2),
          margin: 0,
          borderRadius: 'inherit',
          boxShadow: 'none'
        }
      },

      headerWrapper: {
        padding: theme.spacing(0, 3, 3, 3),

        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(5, 0, 3)
        }
      },

      header: {
        justifyContent: 'space-between',
        marginBottom: theme.spacing(3),

        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column',

          '& h4': {
            marginBottom: theme.spacing(2)
          }
        }
      },

      accordionsContainer: {
        [theme.breakpoints.up('sm')]: {
          padding: theme.spacing(0, 2)
        }
      }
    }),
  {
    name: 'Dashboard'
  }
)

export default useDashboardStyles
