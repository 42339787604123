import React, { FC, useCallback } from 'react'
import { WrappedFieldProps } from 'redux-form'
import { Pagination, PaginationProps } from '@material-ui/lab'

type Props = {
  count: number
}

type AllProps = WrappedFieldProps & Props & PaginationProps

const PaginationField: FC<AllProps> = ({
  input: { onChange, value },
  count,
  ...rest
}) => {
  const handleChangePage = useCallback(
    (_event: React.ChangeEvent<unknown>, value: number): void => {
      onChange(value)

      window.scrollTo(0, 0)
    },
    []
  )

  return (
    <Pagination
      count={count}
      page={+value}
      color="primary"
      onChange={handleChangePage}
      {...rest}
    />
  )
}

export default PaginationField
