import React, { FC } from 'react'

import { IconButton, Tooltip, TooltipProps } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
import palette from '~/theme/palette'

import useStyles from './TooltipInfo.styles'

interface TooltipInfo extends Omit<TooltipProps, 'children'> {}

const TooltipInfo: FC<TooltipInfo> = (props) => {
  const classes = useStyles()

  return (
    <Tooltip
      leaveTouchDelay={2500}
      enterTouchDelay={100}
      placement="bottom"
      classes={classes}
      {...props}
    >
      <IconButton aria-label="info" style={{ padding: '4px' }}>
        <InfoOutlined htmlColor={palette.primary.main} fontSize="small" />
      </IconButton>
    </Tooltip>
  )
}

export default TooltipInfo
