import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { WrappedFieldProps } from 'redux-form'
import {
  ButtonBase,
  FormHelperText,
  CircularProgress,
  IconButton,
  Box
} from '@material-ui/core'
import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles'
import { PhotoCamera, Close as CloseIcon } from '@material-ui/icons'

import PMK from '~/services/PMK'
import ErrorHandler from '~/utils/errorHandler'

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    logo: {
      width: 105,
      minWidth: 105,
      [theme.breakpoints.down(450)]: {
        width: '100%'
      },
      height: 105,
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.primary.main,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      borderRadius: '50%',
      border: `1px solid ${theme.palette.text.disabled}`
    },

    deleteIcon: {
      position: 'absolute',
      right: 0,
      top: 0,
      background: theme.palette.error.main,
      borderRadius: '50%',
      boxShadow: theme.shadows[0],

      '& .MuiSvgIcon-root': {
        width: 19,
        height: 19,
        color: theme.palette.common.white
      }
    },

    button: {
      padding: '0 15px',
      color: theme.palette.primary.main
    }
  })
)

type Props = {
  disabled?: boolean
  helperText?: string
  onProcess?: () => void
  onComplete?: () => void
  onFail?: () => void
  setDisabledBtn?: (disabledBtn: boolean) => void
}

const UploadImageField: React.FC<WrappedFieldProps & Props> = ({
  input: { value, onChange, ...input },
  meta: { touched, error },
  disabled,
  helperText,
  onProcess,
  onComplete,
  onFail
}) => {
  const cx = useStyles()
  const [loading, setLoading] = useState(false)

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png',
    maxSize: 1024 * 1024,

    onDrop: async (acceptedFiles) => {
      try {
        if (onProcess) onProcess()
        setLoading(true)

        const { origin, signed_id } = await PMK.directUploadOne(
          acceptedFiles[0]
        )
        onChange({ url: (URL as any).createObjectURL(origin), signed_id })
        if (onComplete) onComplete()
      } catch (err) {
        if (onFail) onFail()
      } finally {
        setLoading(false)
      }
    },
    onDropRejected: (fileRejection) => {
      try {
        if (fileRejection[0].errors[0].code === 'file-too-large') {
          throw new ErrorHandler('Photo size should be not more than 1Mb')
        }
      } catch (error) {
        if (onFail) onFail()
      }
    }
  })

  const errorMessage = touched && error

  return (
    <section>
      {(!disabled || value) && (
        <>
          <ButtonBase
            onClick={getRootProps().onClick}
            className={cx.logo}
            style={{
              backgroundImage: `url(${value?.url})`
            }}
            disabled={disabled}
            onDrop={(accepted): void => {
              getRootProps().onDrop(accepted)
            }}
          >
            {!loading && !value && <PhotoCamera />}
            {loading && <CircularProgress disableShrink />}
            {!disabled && value && (
              <Box className={cx.deleteIcon}>
                <IconButton
                  onClick={(e): void => {
                    e.stopPropagation()
                    e.preventDefault()
                    onChange(null)
                  }}
                  size="small"
                >
                  <CloseIcon color="inherit" />
                </IconButton>
              </Box>
            )}
          </ButtonBase>

          <input {...input} {...getInputProps()} />
        </>
      )}

      {(!!errorMessage || !!helperText) && (
        <FormHelperText error={!!errorMessage}>
          {errorMessage || helperText}
        </FormHelperText>
      )}
    </section>
  )
}

export default UploadImageField
