import { useEffect, useRef } from 'react'

const useScrollChat = (deps: unknown[] = []) => {
  const scrollRef = useRef<HTMLDivElement | null>(null)

  const scrollHeight = scrollRef.current?.scrollHeight

  const onScroll = () => {
    if (scrollRef.current) {
      scrollRef.current?.scrollTo({
        top: scrollHeight,
        behavior: 'smooth'
      })
    }
  }

  useEffect(() => {
    onScroll()
  }, [scrollHeight, ...deps])

  return { scrollRef }
}

export default useScrollChat
