import { useEffect } from 'react'
import { getChatbotMessages } from '~/state/modules/itineraries'
import { useDispatch } from 'react-redux'
import Actioncable from '~/services/Acable'
import Deserializer from '~/services/Deserializer'
import { mergeMessages } from '~/state/modules/itineraries/chatbot'

const useGetChatbotMessages = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    let channel: any = null

    dispatch(getChatbotMessages())

    if (!channel) {
      channel = Actioncable?.cable.subscriptions.create(
        { channel: `ChatsChannel` },
        {
          received: async (data) => {
            const message = await Deserializer.deserialize(data)

            dispatch(mergeMessages(message))
          }
        }
      )
    }

    return (): void => {
      if (channel?.unsubscribe) channel?.unsubscribe()
    }
  }, [])
}

export default useGetChatbotMessages
