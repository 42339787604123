import { makeStyles, createStyles } from '@material-ui/core'

const useItineraryBlockStyles = makeStyles(
  ({ spacing, palette, breakpoints }) =>
    createStyles({
      root: {
        [breakpoints.down('sm')]: {
          padding: spacing(0.5, 2, 2)
        }
      },

      card: {
        boxShadow: 'none',
        border: `1px solid ${palette.text.disabled}`
      },

      cardContent: {
        padding: spacing(2, 1)
      },

      pointIcon: {
        width: 13,
        height: 16
      },

      line: {
        flex: 1,
        width: 1,
        margin: spacing(0.5, 0),
        borderLeft: `1px dashed ${palette.text.hint}`
      }
    }),
  {
    name: 'ItineraryBlock'
  }
)

export default useItineraryBlockStyles
