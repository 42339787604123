import React, { FC, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import cn from 'classnames'
import { List, ListItem } from '@material-ui/core'

import { NavLink, Text } from '~/components/shared'

import ROUTES from '~/constants/routes'

import useSignInPopoverStyles from './SignInPopover.styles'
import '../ProfilePopover/css/transitions.css'

export const SignInTabsArray: Option[] = [
  {
    label: 'Parent / Guardian',
    value: ROUTES.signIn
  },
  {
    label: 'Provider',
    value: ROUTES.campDirectorSignIn
  }
]

const SignInPopover: FC = () => {
  const s = useSignInPopoverStyles()

  const [open, setOpen] = useState<boolean>(false)

  return (
    <div
      className={cn(s.btn)}
      onMouseEnter={(): void => {
        setOpen(true)
      }}
      onMouseLeave={(): void => {
        setOpen(false)
      }}
      onTouchEnd={(): void => {
        setOpen(!open)
      }}
      data-cy="profile-popover"
    >
      <Text className={s.text} fontWeight={700}>
        Sign in
      </Text>

      <CSSTransition in={open} timeout={200} classNames="fade" mountOnEnter>
        <div className={s.container}>
          <div className={s.dropdown}>
            <List disablePadding data-cy="profile-popover-button">
              {SignInTabsArray.map(({ label, value }) => {
                const ListItemComponent = (
                  <ListItem key={value} className={s.tabItem}>
                    <NavLink to={value} color="primary" exact underline="none">
                      <Text>{label}</Text>
                    </NavLink>
                  </ListItem>
                )

                return ListItemComponent
              })}
            </List>
          </div>
        </div>
      </CSSTransition>
    </div>
  )
}

export default SignInPopover
