import { makeStyles, createStyles } from '@material-ui/core'

const useProfileStyles = makeStyles(
  ({ palette, breakpoints, spacing }) =>
    createStyles({
      container: {
        margin: spacing(3, 'auto', 6),

        [breakpoints.down('xs')]: {
          width: '100vw',
          position: 'relative',
          left: '50%',
          right: '50%',
          margin: '100px -50vw 0',
          padding: 0,
          borderRadius: 'inherit',
          boxShadow: 'none'
        }
      },

      tabs: {
        position: 'relative',
        paddingTop: spacing(2),

        '&:after': {
          content: '""',
          position: 'absolute',
          background: palette.text.disabled,
          bottom: '0',
          left: '0',
          height: 1,
          width: '100%'
        },

        [breakpoints.down('xs')]: {
          paddingTop: spacing(3)
        }
      },

      tabsContainer: {
        justifyContent: 'center',

        '&.withShare': {
          [breakpoints.down('sm')]: {
            justifyContent: 'flex-start'
          }
        },

        '@media(max-width: 350px)': {
          justifyContent: 'flex-start'
        }
      },

      indicator: {
        zIndex: 1,
        height: 3
      },

      scrollable: {
        [breakpoints.down('xs')]: {
          margin: '0 -7px'
        }
      },

      tab: {
        minWidth: 'auto',
        padding: spacing(1),
        margin: spacing(0, 3.5),
        fontSize: 24,
        textTransform: 'initial',

        [breakpoints.down('xs')]: {
          fontSize: 18,
          margin: spacing(0, 1.5)
        }
      },

      selected: {
        fontWeight: 600
      },

      wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'baseline',
        padding: '50px 110px',
        minHeight: 600,

        [breakpoints.down('sm')]: {
          padding: '50px'
        },

        [breakpoints.down('xs')]: {
          padding: '50px 20px',
          minHeight: 'auto'
        }
      },

      formCard: {
        width: '100%',

        '& button[type="submit"]': {
          minWidth: 115
        },

        [breakpoints.down('xs')]: {
          borderRadius: 'inherit',
          borderWidth: '1px 0 1px 0'
        }
      },

      canceledText: {
        fontStyle: 'italic',
        color: palette.text.hint
      },

      similarBtnGrid: {
        display: 'flex',
        justifyContent: 'flex-end',

        [breakpoints.down('xs')]: {
          '& a': {
            width: '100%'
          }
        }
      }
    }),
  {
    name: 'Profile'
  }
)

export default useProfileStyles
