import React, { FC, useEffect, useState } from 'react'
import parse from 'html-react-parser'

import ZohoCampaign from './ZohoCampaign'

const ZohoCampaignEmail: FC = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const script = document.createElement('script')

    script.onload = (): void => {
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    }

    script.src = 'https://publ.maillist-manage.com/js/optin.min.js'
    document.body.appendChild(script)

    return (): void => {
      document.body.removeChild(script)
    }
  }, [])

  useEffect(() => {
    if (!loading) {
      const campaignButton = document.getElementById('zcWebOptin')
      campaignButton.classList.remove('submit_btn_disabled')

      campaignButton?.addEventListener(
        'mousedown',
        () => {
          // @ts-ignore
          window.setupSF(
            'sf934001741e12d7972ae4d02b29c202b122c9a454c9b9cdcf',
            'ZCFORMVIEW',
            false,
            'light',
            false,
            '0'
          )
        },
        {
          once: true
        }
      )
    }
  }, [loading])

  return <>{parse(ZohoCampaign)}</>
}

export default ZohoCampaignEmail
