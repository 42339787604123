import { filteredFields, initialAnswersFields } from './itineraryService.config'
import { capitalize } from '~/utils/functions'

const generateParentData = (fields: Fields, user: Partial<User>): Option[] => {
  return [
    {
      label: 'Title',
      value: fields.name_title
    },
    {
      label: 'Name',
      value: `${fields.first_name} ${fields.last_name}`
    },
    {
      label: 'Email',
      value: fields.email
    },
    {
      label: 'Phone number',
      value: user?.phone_number
    },
    {
      label: 'Address',
      value: fields.address_street
    },
    {
      label: 'ZIP-code',
      value: fields.zip_code
    },
    {
      label: 'Subscriptions',
      value: user.package
    }
  ]
}

const generateChildData = (fields: Partial<Fields>): Option[] => {
  return [
    {
      label: 'Name',
      value: fields?.child_first_name
    },
    {
      label: 'Age',
      value: fields?.age
    },
    {
      label: 'Rising grade level',
      value: fields?.rising_grade_level
    },
    {
      label: 'Interests',
      value: (fields?.interests || []).join(', ')
    },
    {
      label: 'Personal skills',
      value: (fields?.personal_skills || []).join(', ')
    },
    {
      label: 'Special needs',
      value: (fields?.special_needs || []).join(', ')
    }
  ]
}

const generateCommentsData = (fields: ItineraryChangeRequest[]): any => {
  const generatedData = []

  fields.forEach((el) => {
    generatedData.push(
      {
        label: 'Activity',
        value: el?.activity?.name
      },
      {
        label: 'Reason',
        value: el?.reason
      },
      {
        label: 'More information',
        value: el?.more_information
      }
    )
  })

  return generatedData
}

const generateAnswersData = (fields: Fields): PersonInfoOption[] => {
  const currentFields: Partial<Fields> = {}
  const inputFields = { ...initialAnswersFields, ...fields }

  Object.entries(inputFields).forEach(([key, value]) => {
    if (!filteredFields.some((field) => field === key)) {
      const renderLabel = capitalize(key.replace(/[_\s]+/g, ' '))
      const renderValue = Array.isArray(value) ? value.join(', ') : value

      currentFields[key] = {
        label: renderLabel,
        value: renderValue
      }
    }
  })

  const data2 = [
    currentFields.start_date,
    currentFields.end_date,
    currentFields.camp_type,
    currentFields.start_date_2,
    currentFields.end_date_2,
    currentFields.second_date_camp_preference,
    currentFields.start_date_3,
    currentFields.end_date_3,
    currentFields.third_date_camp_preference,
    currentFields.start_date_4,
    currentFields.end_date_4,
    currentFields.fourth_date_camp_preference,
    { isEmpty: true },
    currentFields.distance_preferences_day_camps,
    currentFields.day_camp_duration_preferences,
    currentFields.earliest_time_day_camp_drop_off,
    currentFields.early_drop_fee,
    currentFields.distance_preferences_overnight_camps,
    { isEmpty: true },
    currentFields.favorite_camps,
    currentFields.camp_name,
    currentFields.name_of_second_camp,
    currentFields.name_of_third_camp,
    currentFields.name_of_fourth_camp,
    { isEmpty: true },
    currentFields.amount_money,
    currentFields.highest_priority,
    { isEmpty: true },
    currentFields.academic_interests,
    currentFields.academic_proficiency,
    currentFields.art_interests,
    currentFields.art_proficiency,
    currentFields.cooking_baking_proficiency,
    currentFields.dance_interests,
    currentFields.dance_proficiency,
    currentFields.music_interests,
    currentFields.music_proficiency,
    currentFields.outdoor_interests,
    currentFields.outdoor_proficiency,
    currentFields.sport_interests,
    currentFields.sport_proficiency,
    currentFields.theater_interests,
    currentFields.theater_proficiency,
    { isEmpty: true },
    currentFields.other_interests,
    currentFields.specify_accessibility,
    currentFields.last_comments
  ]

  return data2 as PersonInfoOption[]
}

export default {
  generateParentData,
  generateChildData,
  generateAnswersData,
  generateCommentsData
}
