import React from 'react'

import GoogleMapsProvider from './GoogleMapsProvider'

const withGoogleMaps = (isSearch?: boolean) => <P extends object>(
  Component: React.ComponentType<P>
): React.FC<P> => (props): JSX.Element => {
  if (isSearch) {
    return (
      <GoogleMapsProvider>
        <Component {...props} />
      </GoogleMapsProvider>
    )
  }

  // @ts-ignore
  return window?.google?.maps ? (
    <Component {...props} />
  ) : (
    <GoogleMapsProvider>
      <Component {...props} />
    </GoogleMapsProvider>
  )
}

export default withGoogleMaps
