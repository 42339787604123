import React, { FC, useEffect, useState, useCallback, useRef } from 'react'
import {
  Fab,
  Zoom,
  useScrollTrigger,
  makeStyles,
  createStyles
} from '@material-ui/core'
import cn from 'classnames'

import { ReactComponent as ArrowUpIcon } from '~/assets/icons/arrow-scroll-to-top.svg'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      btn: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(7),
        width: 70,
        height: 70,
        zIndex: 10,
        boxShadow: '0px 4px 20px rgba(112, 57, 80, 0.3)',

        '&.isWhite': {
          backgroundColor: theme.palette.common.white,

          '& svg path': {
            stroke: theme.palette.primary.main
          }
        },

        [theme.breakpoints.down('sm')]: {
          right: theme.spacing(2),
          width: 56,
          height: 56,

          '& svg': {
            width: 24
          }
        }
      }
    }),
  {
    name: 'ScrollTop'
  }
)

const ScrollTop: FC = () => {
  const classes = useStyles()
  const refBtn = useRef<HTMLButtonElement>(null)
  const [isWhite, setIsWhite] = useState(false)
  const [isNearFooter, setIsNearFooter] = useState(false)

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 300
  })

  useEffect(() => {
    let listener = null
    const scrollTriger = document.getElementById('scroll-top-trigger')
    const footer = document.getElementById('footer')

    if (refBtn?.current && (scrollTriger || footer)) {
      const btnTop = refBtn.current.offsetTop
      const btnHight = refBtn.current.clientHeight
      const triggerHight = scrollTriger?.clientHeight

      listener = window.addEventListener('scroll', () => {
        if (scrollTriger) {
          const triggerTop = scrollTriger.getBoundingClientRect().top

          if (
            btnTop + btnHight - 60 < triggerTop ||
            btnTop > triggerTop + triggerHight
          ) {
            setIsWhite(false)
          } else {
            setIsWhite(true)
          }
        }

        if (footer) {
          const footerTop = footer.getBoundingClientRect().top

          if (btnTop + btnHight < footerTop) {
            setIsNearFooter(false)
          } else {
            setIsNearFooter(true)

            if (scrollTriger) {
              setIsWhite(true)
            }
          }
        }
      })
    }

    return (): void => {
      window.removeEventListener('scroll', listener)
    }
  }, [refBtn?.current])

  const handleTop = useCallback((): void => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  return (
    <Zoom in={trigger}>
      <Fab
        ref={refBtn}
        onClick={handleTop}
        color="secondary"
        className={cn(classes.btn, { isWhite })}
        style={{
          ...(isNearFooter && {
            bottom: 160,
            position: 'absolute'
          })
        }}
      >
        <ArrowUpIcon />
      </Fab>
    </Zoom>
  )
}

export default ScrollTop
