import { isEmpty } from 'lodash'
import { toUpperCaseFirstLetterForEveryWord } from '~/utils/functions'

export const programValuesDTO = (values: ProgramsFormValues): any => {
  const { special_needs_array, ...rest } = values

  const currentSpecialNeeds = special_needs_array?.reduce(
    (a, v) => ({ ...a, [v]: toUpperCaseFirstLetterForEveryWord(v) }),
    {}
  )

  const valuesDTO = { ...rest, special_needs: currentSpecialNeeds }
  return valuesDTO
}

export const generateInitialValues = (values: Partial<Program>): any => {
  const valuesDTO = {
    ...values,
    special_needs_array: !isEmpty(values.special_needs)
      ? Object.keys(values.special_needs).filter((key) =>
          Boolean(values.special_needs[key])
        )
      : []
  }
  return valuesDTO
}

export default { programValuesDTO, generateInitialValues }
