import React, { FC } from 'react'
import cn from 'classnames'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
  createStyles,
  Box
} from '@material-ui/core'
import { Variant } from '@material-ui/core/styles/createTypography'

import { CroppedTextTooltip, Flexbox, Text, Title } from '~/components/shared'

import { ReactComponent as ArrowDownIcon } from '~/assets/icons/arrow-down.svg'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      textRow: {
        '&:last-child p': {
          marginBottom: 0
        },

        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column'
        }
      },

      label: {
        width: '35%',
        marginRight: theme.spacing(1),

        '&.isRequestChange': {
          width: '100%'
        },

        [theme.breakpoints.down('xs')]: {
          marginRight: 0,
          marginBottom: theme.spacing(0.5)
        }
      },

      value: {
        width: '65%',
        marginBottom: theme.spacing(1),
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      },

      information: {
        '&:nth-child(3n)': {
          flexDirection: 'column',
          width: '100%',
          marginBottom: theme.spacing(1.5),
          borderBottom: `1px solid ${theme.palette.text.disabled}`,

          '& p:nth-child(1) ': {
            marginBottom: theme.spacing(1)
          },

          '& p:nth-child(2) ': {
            width: '100%',
            marginBottom: theme.spacing(1.5),
            textAlign: 'justify'
          }
        },

        '&:last-child': {
          border: 'none'
        }
      }
    }),
  {
    name: 'PersonInfoCard'
  }
)

type Props = {
  title: string
  data: PersonInfoOption[]
  variantTitle?: Variant
  isRequestChange?: boolean
}

const InfoAccordion: FC<Props> = ({
  title,
  data = [],
  variantTitle = 'h4',
  isRequestChange
}) => {
  const s = useStyles()

  return (
    <Accordion data-cy="info-accordion">
      <AccordionSummary expandIcon={<ArrowDownIcon />}>
        <Title variant={variantTitle} textAlign="start">
          {title}
        </Title>
      </AccordionSummary>

      <AccordionDetails>
        <Box mx={1.5} mb={2}>
          {data?.map(({ label, value, isEmpty }, idx) => {
            const currentValue = value || '-'

            return (
              <Flexbox
                key={idx}
                className={cn({
                  [s.information]: isRequestChange
                })}
              >
                {isEmpty ? (
                  <Text color="textSecondary" className={s.label} />
                ) : (
                  <>
                    <Text
                      color="textSecondary"
                      className={cn(s.label, {
                        isRequestChange: isRequestChange && (idx + 1) % 3 === 0
                      })}
                    >
                      {label}:
                    </Text>

                    <CroppedTextTooltip title={currentValue}>
                      <Text className={s.value}>{currentValue}</Text>
                    </CroppedTextTooltip>
                  </>
                )}
              </Flexbox>
            )
          })}
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

export default InfoAccordion
