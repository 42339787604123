import { STATUSES } from '~/constants/statusesItinerary'

export const DashboardTabsArray: {
  label: string
  value: StatusesUnion
}[] = [
  {
    label: 'New',
    value: STATUSES.draft
  },
  {
    label: 'In progress',
    value: STATUSES.in_progress
  },
  {
    label: 'In review',
    value: STATUSES.review
  },
  {
    label: 'Alternate requests',
    value: STATUSES.alternate_request
  },
  {
    label: 'Accepted',
    value: STATUSES.accepted
  },
  {
    label: 'Approved',
    value: STATUSES.approved
  },
  {
    label: 'Canceled',
    value: STATUSES.canceled
  }
]
