import React from 'react'
import { useCookies } from 'react-cookie'
import {
  Button,
  makeStyles,
  Card,
  CardContent,
  createStyles
} from '@material-ui/core'

import { NavLink, Title, Text } from '../shared'

import { COOKIE } from '~/constants/storage'
import ROUTES from '~/constants/routes'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        position: 'fixed',
        bottom: '0',
        left: '50%',
        transform: 'translateX(-50%)',
        width: 400,
        margin: theme.spacing(2, 'auto'),
        backgroundColor: theme.palette.secondary.light,
        border: 'none',
        zIndex: 9,
        transition: 'all 0.5s ease-out',

        [theme.breakpoints.down('sm')]: {
          right: '0',
          left: '0',
          transform: 'none',
          width: 'calc(100% - 140px)',
          marginLeft: theme.spacing(2)
        }
      }
    }),
  {
    name: 'CookieNotificationCard'
  }
)

const CookieNotificationCard: React.FC = () => {
  const s = useStyles()
  const [cookies, setCookies] = useCookies([COOKIE.PMK])

  if (!cookies || cookies[COOKIE.PMK]) return null

  return (
    <Card className={s.root} variant="outlined">
      <CardContent>
        <Title variant="h4" textAlign="start" mb={1}>
          Cookie Policy
        </Title>

        <Text mb={1}>
          This website uses cookies. By continuing to use this site, you accept
          our use of cookies. Please refer to our{' '}
          <NavLink to={ROUTES.privacy}>privacy policy</NavLink> for more
          information.
        </Text>

        <Button
          fullWidth
          variant="contained"
          color="secondary"
          onClick={(): void =>
            setCookies(COOKIE.PMK, true, {
              path: ROUTES.home,
              maxAge: 31536000
            })
          }
        >
          Accept & Close
        </Button>
      </CardContent>
    </Card>
  )
}

export default CookieNotificationCard
