import React, { FC, useMemo } from 'react'
import cn from 'classnames'

import { useHistory, useParams } from 'react-router-dom'
import { useModal } from 'react-modal-hook'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { Box, Button, Card, Theme, useMediaQuery } from '@material-ui/core'

import { DetailsList, Flexbox, Text } from '~/components/shared'
import { ConfirmDialog } from '~/components/dialogs'

import ROUTES from '~/constants/routes'
import { generateDetails } from '~/services/ActivitiesService/ActivitiesService'
import ActivitiesService from '~/services/ActivitiesService'
import { humanizeDate } from '~/utils/functions'
import { deleteActivity, fetchActivities } from '~/state/modules/programs'

import { useProgramActivitiesStyles } from './ProgramActivitiesCard.styles'
import { changeActivitiesPage } from '~/state/modules/programs/programs.slice'
import InfoDialog from '~/components/dialogs/InfoDialog'
import { ItineraryCard } from '~/components/cards'
import useModalHook from '~/hooks/useModal'

interface ProgramActivitiesProps extends Activity {
  page?: number
}

const ProgramActivitiesCard: React.FC<ProgramActivitiesProps> = ({
  id: activityId,
  name,
  provider_name,
  location,
  start_date,
  end_date,
  interests,
  skill_development,
  sku,
  duplicated_status,
  has_duplicates,
  parent_sku,
  ...props
}) => {
  const s = useProgramActivitiesStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = useParams<{ id: string }>()

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))

  const interestsInfo = ActivitiesService.generateCustomInterests(interests)
  const details = generateDetails(
    {
      id: activityId,
      name: name,
      provider_name,
      location,
      start_date,
      end_date,
      skill_development,
      ...props
    },
    interestsInfo
  )

  const duration =
    start_date && end_date && start_date !== end_date
      ? `${moment(end_date).diff(start_date, 'days') + 1} days`
      : '1 day'

  const {
    hideModal: hideDeletModal,
    open: openDeleteModal,
    showModal: showDeletModal
  } = useModalHook()

  const [showModal, hideModal] = useModal(
    (props) => (
      <ConfirmDialog
        onClose={hideModal}
        onConfirm={async (): Promise<void> => {
          const status = await dispatch<any>(deleteActivity({ id, activityId }))

          if (!status?.error) {
            dispatch(changeActivitiesPage(1))
          }
          if (!status?.error && deleteActivity.fulfilled.match(status)) {
            dispatch(fetchActivities({ id, params: { page: 1 } }))
          }
          if (status?.error) {
            showDeletModal()
          }
        }}
        cancelText="Cancel"
        confirmText="Delete"
        text="Are you sure want to delete the activity?"
        {...props}
      />
    ),
    [id, activityId]
  )

  const duplicateLink = `${ROUTES.campDirectorProgram}/${id}/duplicate-activity/${activityId}`

  const isNoDuplicateMobileCard = isMobile && !duplicated_status

  const activityValues = {
    id: activityId,
    name,
    provider_name,
    location,
    start_date,
    end_date,
    interests,
    skill_development,
    sku,
    duplicated_status,
    has_duplicates,
    parent_sku,
    ...props
  }
  const {
    open: openInfoDialog,
    hideModal: hideInfoModal,
    showModal: showInfoModal
  } = useModalHook()

  const { activitiesData } = useMemo(
    () => ({
      activitiesData: ActivitiesService.generateParentItineraryActivities(
        activityValues
      )
    }),
    [activityValues]
  )

  return (
    <Card className={s.card} data-cy="activity-card-desktop">
      <Box
        className={cn({
          [s.duplicate]: duplicated_status,
          [s.titleBlock]: true
        })}
      >
        <Text color="textSecondary" className={s.activityName} mr={1} mb={1}>
          {name}
        </Text>

        <Text
          variant="h5"
          color="textSecondary"
          mr={1}
          mb={1}
          className={s.wrapText}
        >
          {provider_name}
        </Text>

        {parent_sku && (
          <Flexbox mb={1}>
            <Text color="textSecondary" mr={1}>
              Parent sku:
            </Text>
            <Text color="textPrimary" className={s.activityName}>
              {parent_sku}
            </Text>
          </Flexbox>
        )}

        <Flexbox mb={1}>
          <Text color="textSecondary" mr={1}>
            Sku:
          </Text>
          <Text color="textPrimary" className={s.activityName}>
            {sku}
          </Text>
        </Flexbox>

        <Flexbox mb={1}>
          <Text color="textSecondary" mr={1}>
            Location:
          </Text>
          <Text color="textPrimary">{location}</Text>
        </Flexbox>

        <Flexbox mb={1}>
          <Text color="textSecondary" mr={1}>
            Date:
          </Text>
          <Text color="textPrimary" mr={0.5}>
            {humanizeDate(start_date, 'll')} - {humanizeDate(end_date, 'll')}{' '}
          </Text>
        </Flexbox>

        <Flexbox mb={2}>
          <Text color="textSecondary" mr={1}>
            Duration:
          </Text>
          <Text color="textPrimary" mr={0.5}>
            {duration}
          </Text>
        </Flexbox>

        <Flexbox className={s.actionContainer}>
          <Box className={s.actionBtn} mb={isMobile && 2} mr={!isMobile && 2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={(): void => {
                history.push(
                  `${ROUTES.campDirectorProgram}/${id}/edit-activity/${activityId}`
                )
              }}
            >
              Edit activity
            </Button>
          </Box>

          {!duplicated_status && (
            <Box className={s.actionBtn} mr={!isMobile && 2}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={(): void => {
                  history.push(duplicateLink)
                }}
              >
                Duplicate
              </Button>
            </Box>
          )}

          <Box className={s.actionBtn} mt={isNoDuplicateMobileCard && 2}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              disabled={has_duplicates}
              onClick={(): void => {
                showModal()
              }}
            >
              Delete
            </Button>
          </Box>

          {duplicated_status && (
            <Text
              variant="h4"
              color="primary"
              className={s.duplicateInfo}
              ml={2}
              mt={0.5}
              mr={!isMobile && 4.7}
            >
              Duplicate
            </Text>
          )}
        </Flexbox>
      </Box>

      <Flexbox className={s.optionsBlock}>
        <Flexbox>
          <Box className={s.firstRowDetails}>
            <DetailsList items={details} />

            <Flexbox justifyContent="flex-end" mt={isMobile && 2}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={showInfoModal}
                className={s.actionBtn}
              >
                Preview
              </Button>
            </Flexbox>
          </Box>
        </Flexbox>
      </Flexbox>

      {openDeleteModal && (
        <InfoDialog
          open={openDeleteModal}
          onClose={hideDeletModal}
          title="You can't delete this activity"
          maxWidth="sm"
        >
          <Box maxWidth={400} margin="0 auto">
            <Text textAlign="center">
              This activity has been shared with one or more parents and cannot
              be deleted. Change the activity&apos;s seat availability to limit
              further interest OR contact your PlanMyKids administrator to
              resolve.
            </Text>
          </Box>
        </InfoDialog>
      )}

      {openInfoDialog && (
        <InfoDialog
          open={openInfoDialog}
          onClose={hideInfoModal}
          title="Preveiw"
        >
          <ItineraryCard {...activitiesData[0]} isPreview />
        </InfoDialog>
      )}
    </Card>
  )
}

export default ProgramActivitiesCard
