import React, { FC } from 'react'
import { Box, createStyles, makeStyles } from '@material-ui/core'

import { Chip, Flexbox, Text } from '~/components/shared'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      card: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: 320,
        textAlign: 'center',

        '& img': {
          width: '100%',
          height: 'auto',
          maxWidth: 250,
          marginBottom: theme.spacing(2)
        }
      }
    }),
  {
    name: 'InfoCard'
  }
)

export type InfoCardProps = {
  image: string
  description: string
  title: string
  isBeta?: boolean
}

const InfoCard: FC<InfoCardProps> = ({ image, description, title, isBeta }) => {
  const s = useStyles()

  return (
    <Flexbox className={s.card}>
      <img src={image} alt="info" />

      <Box display="flex" mb={1}>
        <Text variant="h5" fontWeight="700">
          {title}
        </Text>

        {isBeta && <Chip label="BETA" />}
      </Box>

      <Text color="textSecondary">{description}</Text>
    </Flexbox>
  )
}

export default InfoCard
