const ROUTES = {
  home: '/',
  advertising: '/wcpss',

  // auth pages
  signIn: '/sign-in',
  signUp: '/sign-up',
  signUpVerify: '/sign-up/verify',
  confirmationEmail: '/confirmation/:token',
  forgotPassword: '/forgot-password',
  forgotPasswordVerify: '/forgot-password/verify',
  resetPassword: '/reset-password',
  confirmationPassword: '/reset-password/:token',
  confirmationParent: '/confirmation-parent',

  // static pages
  aboutUs: '/about-us',
  contactUs: '/contact-us',
  howItWorks: '/how-it-works',
  faqs: '/faqs',
  pricing: '/pricing',
  resources: '/resources',
  terms: '/terms',
  businessTerms: '/business-terms',
  privacy: '/privacy-policy',

  //parent pages
  questionnaire: '/questionnaire',
  consultation: 'https://calendly.com/planmykids/planmykids-consultation ',
  congratulations: '/congratulations',
  packages: '/packages',
  confirm: '/confirm',
  checkout: '/checkout',
  profile: '/profile',
  calendar: '/profile/calendar',
  itineraries: '/profile/itineraries',
  itinerariesFriends: '/profile/itineraries-friends',
  payment: '/profile/payment',

  // specialist pages
  signInSpecialist: '/specialist/sign-in',
  itinerary: '/itinerary',
  activity: '/activity',
  activityProvider: '/activity-provider',
  dashboard: '/dashboard',
  search: '/search',

  // camp-director pages
  campDirector: '/camp-director',
  campDirectorPrograms: '/camp-director/programs',
  campDirectorOrders: '/camp-director/orders',
  campDirectorSignIn: '/camp-director/sign-in',
  campDirectorSignUp: '/camp-director/sign-up',
  campDirectorResetPassword: '/camp-director/reset-password',
  campDirectorForgotPassword: '/camp-director/forgot-password',
  campDirectorProgram: '/camp-director/program',
  campDirectorProfile: '/camp-director/profile',
  createCampDirectorProgram: '/camp-director/create-program',
  campDirectorProvider: '/camp-director/provider-profile',
  campDirectorCongratulations: '/camp-director/congratulations',
  campDirectorStripe: '/camp-director/stripe',
  campDirectorVerify: '/camp-director/stripe-pending',
  campDirectorFailure: '/camp-director/stripe-failure',

  notFound: '/404'
}

export default ROUTES
