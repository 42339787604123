import { makeStyles, createStyles } from '@material-ui/core'

import banner from '~/assets/images/home/banner.jpg'

const useBannerStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        position: 'relative',
        height: 'calc(470px + 30vw)',
        maxHeight: 'calc(100vh - 20px)',
        overflow: 'hidden',
        background: `url(${banner}) no-repeat`,
        backgroundSize: 'cover',

        '&::after': {
          content: '""',
          display: 'block',
          width: '100%',
          height: '100%',
          background:
            'linear-gradient(90deg, rgba(34, 34, 34, 0.7) 0.14%, rgba(34, 34, 34, 0) 100%)',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 1
        },

        [theme.breakpoints.down('sm')]: {
          backgroundSize: 'auto 100%',
          backgroundPosition: '50% 0%'
        }
      },

      rootLandscape: {
        [theme.breakpoints.down('sm')]: {
          backgroundSize: 'cover',
          backgroundPosition: '100% 0%'
        }
      },

      container: {
        position: 'absolute',
        top: '52%',
        left: 'calc(50% + 110px)',
        transform: 'translate(-50%, -50%)',
        zIndex: 2,

        [theme.breakpoints.down('sm')]: {
          left: '50%'
        }
      },

      wrapper: {
        maxWidth: 420,
        color: theme.palette.common.white,

        '& h1': {
          textAlign: 'start',
          fontSize: 72,

          [theme.breakpoints.down('sm')]: {
            fontSize: 50
          }
        },

        '& h4': {
          color: '#F0F0F0',
          lineHeight: '36px',

          [theme.breakpoints.down('sm')]: {
            fontSize: 20,
            lineHeight: '32px'
          }
        }
      }
    }),
  {
    name: 'Banner'
  }
)

export default useBannerStyles
