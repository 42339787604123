import { makeStyles, createStyles } from '@material-ui/core'

const useItineraryStyles = makeStyles(
  (theme) =>
    createStyles({
      wrapper: {
        padding: theme.spacing(2, 3),
        margin: theme.spacing(3, 0, 6),

        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(2),
          margin: 0,
          borderRadius: 'inherit',
          boxShadow: 'none'
        }
      },

      titleWrapper: {
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(3),

        '& h4': {
          margin: theme.spacing(0, 1.5, 0, 0)
        },

        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column',

          '& h4': {
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            margin: theme.spacing(0, 0, 1.5, 0)
          },

          '& button': {
            width: '100%'
          }
        }
      },

      backButton: {
        display: 'inline-block',
        color: theme.palette.text.secondary,
        fontWeight: 400,
        marginBottom: theme.spacing(1),

        '& svg': {
          marginRight: theme.spacing(1)
        }
      },

      container: {
        marginBottom: theme.spacing(5),

        '& > *': {
          display: 'flex',
          flexDirection: 'column',

          [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(3)
          }
        },

        [theme.breakpoints.down('sm')]: {
          marginBottom: theme.spacing(2)
        }
      },

      saveButton: {
        margin: theme.spacing(3, 0)
      },

      total: {
        color: theme.palette.text.primary,
        fontWeight: 600
      }
    }),
  {
    name: 'Itinerary'
  }
)

export default useItineraryStyles
