import React, { FC } from 'react'
import ReactCarousel from 'react-slick'
import { Hidden, Grid } from '@material-ui/core'
import cn from 'classnames'

import { InfoCard } from '~/components/cards'

import { InfoCardProps } from '~/components/cards/InfoCard'

import ArrowLeft from '~/assets/images/home/arrow-left-carousel.svg'
import ArrowRight from '~/assets/images/home/arrow-right-carousel.svg'

import useHelpBlockStyles from './HelpBlock.styles'

const SampleNextArrow = (props): JSX.Element => {
  const s = useHelpBlockStyles()
  const { className, style, onClick } = props

  return (
    <div className={cn(className, s.button)} style={style} onClick={onClick}>
      <img src={ArrowRight} alt="Arrow right" />
    </div>
  )
}

const SamplePrevArrow = (props): JSX.Element => {
  const s = useHelpBlockStyles()
  const { className, style, onClick } = props

  return (
    <div className={cn(className, s.button)} style={style} onClick={onClick}>
      <img src={ArrowLeft} alt="Arrow left" />
    </div>
  )
}

const carouselSettings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  focusOnSelect: true,
  centerPadding: '0',
  autoplay: true,
  speed: 1000,
  pauseOnHover: true,
  autoplaySpeed: 4000,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />
}

type Props = {
  data: InfoCardProps[]
}

const HelpBlock: FC<Props> = ({ data }) => {
  const s = useHelpBlockStyles()

  return (
    <>
      <Hidden xsDown>
        <Grid container spacing={3} justify="space-around">
          {data.map((card, idx) => (
            <Grid key={idx} item sm={5} md={3}>
              <InfoCard {...card} />
            </Grid>
          ))}
        </Grid>
      </Hidden>

      <Hidden smUp>
        <ReactCarousel className={s.carousel} {...carouselSettings}>
          {data.map((card, idx) => (
            <div key={idx} className={s.imageWrapper}>
              <InfoCard {...card} />
            </div>
          ))}
        </ReactCarousel>
      </Hidden>
    </>
  )
}

export default HelpBlock
