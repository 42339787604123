import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useModal } from 'react-modal-hook'
import { useDispatch, useSelector } from 'react-redux'
import { Pagination } from '@material-ui/lab'
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Grid,
  Box
} from '@material-ui/core'

import { Flexbox, Loader, Text } from '~/components/shared'
import Desktop from '~/containers/Desktop'
import Mobile from '~/containers/Mobile'
import CampDirectorTemplate from '../camp-director.template'
import ProgramsAccordion from './components/ProgramsAccordion'
import { ConfirmDialog } from '~/components/dialogs'

import {
  programsSelector,
  getPrograms,
  deleteProgramById,
  programMetaSelector
} from '~/state/modules/programs'
import {
  changeProgramsPage,
  deletePrograms,
  updateActiveTab
} from '~/state/modules/programs/programs.slice'
import { PROGRAM_STATUS } from '~/constants'
import ROUTES from '~/constants/routes'

import useProgramStyles from './programs.styles'
import InfoDialog from '~/components/dialogs/InfoDialog'
import useModalHook from '~/hooks/useModal'

const Programs: React.FC = () => {
  const s = useProgramStyles()
  const dispatch = useDispatch()

  const [currentId, setCurrentId] = useState<string>('')
  const { programs, loaded, loading } = useSelector(programsSelector)
  const { page, pages } = useSelector(programMetaSelector)

  useEffect(() => {
    dispatch(updateActiveTab(PROGRAM_STATUS.information))
  }, [dispatch])

  useEffect(() => {
    dispatch(getPrograms({ page }))

    return (): void => {
      dispatch(deletePrograms())
    }
  }, [page])

  const {
    hideModal: hideDeletModal,
    open: openDeleteModal,
    showModal: showDeletModal
  } = useModalHook()

  const [showModal, hideModal] = useModal(
    (props: any) => (
      <ConfirmDialog
        onClose={hideModal}
        onConfirm={async (): Promise<any> => {
          const status = await dispatch<any>(deleteProgramById(currentId))

          if (!status?.error) {
            dispatch(changeProgramsPage(1))
          }
          if (!status?.error && deleteProgramById.fulfilled.match(status)) {
            dispatch(getPrograms({ page: 1 }))
          }
          if (status?.error) {
            showDeletModal()
          }
        }}
        cancelText="Cancel"
        confirmText="Delete"
        text="Are you sure want to delete the program?"
        {...props}
      />
    ),
    [currentId]
  )

  const handleDelete = (id: string): void => {
    setCurrentId(id)
    showModal()
  }

  const handleChangePage = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ): void => {
    dispatch(changeProgramsPage(value))
  }

  return (
    <CampDirectorTemplate>
      <Flexbox width="100%" justifyContent="end" mb={2}>
        <Button
          component={Link}
          to={ROUTES.createCampDirectorProgram}
          variant="contained"
          color="secondary"
        >
          <Text fontWeight={600}>Create program</Text>
        </Button>
      </Flexbox>

      {!loading && loaded ? (
        <>
          {programs.length > 0 ? (
            <>
              <Desktop>
                <TableContainer>
                  <Table size="small" className={s.table}>
                    <TableHead data-cy="table-header-dashboard">
                      <TableRow>
                        <TableCell>Program</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {programs.map(({ id, name, location }) => (
                        <TableRow data-cy="dashboard-table-row" key={id}>
                          <TableCell className={s.name}>{name}</TableCell>
                          <TableCell className={s.location}>
                            {location}
                          </TableCell>
                          <TableCell>
                            <Flexbox justifyContent="flex-end">
                              <Button
                                component={Link}
                                to={`${ROUTES.campDirectorProgram}/${id}`}
                                variant="contained"
                                color="secondary"
                                className={s.actionBtn}
                              >
                                <Text fontWeight={600}>Edit</Text>
                              </Button>

                              <Button
                                variant="outlined"
                                color="primary"
                                className={s.actionBtn}
                                onClick={(): void => {
                                  handleDelete(id)
                                }}
                              >
                                <Text fontWeight={600}>Delete</Text>
                              </Button>
                            </Flexbox>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                {openDeleteModal && (
                  <InfoDialog
                    open={openDeleteModal}
                    onClose={hideDeletModal}
                    title="You can't delete this program"
                    maxWidth="sm"
                  >
                    <Box maxWidth={400} margin="0 auto">
                      <Text textAlign="center">
                        This program cannot be deleted because it has activities
                        associated with it. Please delete associated activities.
                      </Text>
                    </Box>
                  </InfoDialog>
                )}
              </Desktop>

              <Mobile>
                <Grid container spacing={2} className={s.accordionsContainer}>
                  {programs.map((program) => (
                    <Grid
                      key={program.id}
                      item
                      xs={12}
                      data-cy="dashboard-mobile-active-tabs"
                    >
                      <ProgramsAccordion
                        {...program}
                        handleDelete={handleDelete}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Mobile>

              <Box mt={1.5} width="100%">
                <Pagination
                  count={pages}
                  page={page}
                  color="primary"
                  onChange={handleChangePage}
                />
              </Box>
            </>
          ) : (
            <Flexbox className={s.empty}>
              <Text variant="h6">There are no programs yet!</Text>
            </Flexbox>
          )}
        </>
      ) : (
        <Loader />
      )}
    </CampDirectorTemplate>
  )
}

export default Programs
