import AdvantageImage1 from '~/assets/images/congratulations/advantage-1.png'
import AdvantageImage2 from '~/assets/images/congratulations/advantage-2.png'
import AdvantageImage3 from '~/assets/images/congratulations/advantage-3.png'
import AdvantageImage4 from '~/assets/images/congratulations/advantage-4.png'
import AdvantageImage5 from '~/assets/images/congratulations/advantage-5.png'
import AdvantageImage6 from '~/assets/images/congratulations/advantage-6.png'
import AdvantageImage7 from '~/assets/images/congratulations/advantage-7.png'
import AdvantageImage8 from '~/assets/images/congratulations/advantage-8.png'

import { InfoCardProps } from '~/components/cards/InfoCard'

const advantagesCards: InfoCardProps[] = [
  {
    image: AdvantageImage1,
    title: 'Personalized Itineraries',
    description:
      'Recommendations for kid’s activities are tailored to your family’s needs and child’s interests.'
  },
  {
    image: AdvantageImage2,
    title: 'Multiple Kids',
    description:
      'Create plans that fit your entire family and manage them in a centralized location. No more searching for schedules.'
  },
  {
    image: AdvantageImage3,
    title: 'Friends and Family Collaboration',
    description:
      'Invite your friends to collaborate on activities for your kids to attend together. Coordinate with family and care-givers to keep them abreast of the kid’s activities.'
  },
  {
    image: AdvantageImage4,
    title: 'Calendar Planner',
    description:
      "See all your kids' activities charted out on a calendar, so you don’t miss a date and have quick access to times and places."
  },
  {
    image: AdvantageImage5,
    title: 'Easy Booking',
    description:
      'Book your kids activities quickly with our vast network of kid’s activity providers.'
  },
  {
    image: AdvantageImage6,
    title: 'Buy now, pay later',
    description:
      'It’s like layaway for your kids’ activities.  Choose the payment plan that works for you.'
  },
  {
    image: AdvantageImage7,
    title: 'Cancellation Assistance',
    description:
      'Life happens and things change.  Quickly find cancellation policies and we’ll help you make a cancellation.'
  },
  {
    image: AdvantageImage8,
    title: 'MIA Virtual Concierge',
    isBeta: true,
    description:
      'Using her extensive knowledge about kid’s activities, you can have a conversation with her to research, find and plan the activities best suited for you.'
  }
]

export default advantagesCards
