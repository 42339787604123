import React, { FC, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { Field, formValueSelector } from 'redux-form'
import { Box, ClickAwayListener } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { ExpandMore } from '@material-ui/icons'
import cn from 'classnames'

import { Flexbox, GoogleMap, Text } from '~/components/shared'
import { SliderField } from '~/components/fields'

import { createSliderDistance } from '~/utils/functions'

import useDistanceFilterStyles from './DistanceFilter.styles'

const CustomSlider = withStyles(({ palette }) => ({
  root: {
    color: palette.secondary.main,
    margin: '5px 10px 0',
    width: 'calc(100% - 25px)'
  },

  thumb: {
    height: 18,
    width: 18,
    border: '1px solid currentColor',
    marginTop: -9,
    marginLeft: -9,
    '&:focus, &:hover, &$active': {
      boxShadow: 'none'
    }
  },

  active: {},

  valueLabel: {
    left: 'calc(-50% - 7px)',
    top: -48,

    '& > span': {
      width: 47,
      height: 47,
      borderRadius: '35% 80% 35% 0',

      '& > span': {
        fontSize: 11
      }
    }
  }
}))(SliderField)

type Props = {
  center: Coords
}

const DistanceFilter: FC<Props> = ({ center }) => {
  const s = useDistanceFilterStyles()

  const [open, setOpen] = useState<boolean>(false)

  const selector = formValueSelector('Search_Filters_Form')

  const radius = useSelector((state) => selector(state, 'radius'))

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen)
  }, [])

  return (
    <ClickAwayListener
      onClickAway={(): void => {
        setOpen(false)
      }}
    >
      <Box className={s.wrapper}>
        <Flexbox
          className={cn(s.btn, {
            active: open
          })}
          onClick={handleToggle}
        >
          <Text className={s.text}>Distance</Text>
          <ExpandMore className="arrow" />
        </Flexbox>

        {open && (
          <Box className={s.container}>
            <Box className={s.dropdown}>
              <Flexbox alignItems="center" mb={2}>
                <Field
                  name="radius"
                  component={CustomSlider}
                  min={1}
                  max={500}
                />

                <Text ml={2} whiteSpace="nowrap">
                  + {radius} mi
                </Text>
              </Flexbox>

              <GoogleMap
                height={300}
                center={center}
                radius={createSliderDistance(radius)}
                zoom={5}
                options={{
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
    </ClickAwayListener>
  )
}

export default DistanceFilter
