import React, { FC } from 'react'
import {
  Field,
  InjectedFormProps,
  reduxForm,
  SubmissionError
} from 'redux-form'
import { toastr } from 'react-redux-toastr'
import { Dialog, DialogContent, Grid, Box } from '@material-ui/core'

import { TextField } from '~/components/fields'
import { ExtendedButton, FormControl, Text } from '~/components/shared'
import DialogTitle from '~/components/dialogs/DialogTitle'

import {
  updateActivity,
  updateActivityProvider
} from '~/state/modules/activities'

type Props = {
  in: boolean
  name: string
  id: string
  isProvider?: boolean
  isSearch?: boolean
  onClose: () => {}
}

type AllProps = Props & InjectedFormProps<LeaveNoteInput, Props>

const LeaveNoteDialog: FC<AllProps> = ({
  in: open,
  onClose,
  name,
  isProvider,

  submitting,
  handleSubmit
}) => {
  return (
    <Dialog maxWidth="sm" open={open} onClose={onClose} fullWidth>
      <DialogTitle onClose={onClose}>Leave a note</DialogTitle>

      <DialogContent>
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Text variant="subtitle1" textAlign="center">
                Note about {name} activity {isProvider && 'provider'}
              </Text>
            </Grid>

            <Grid item xs={12}>
              <FormControl label="Note">
                <Field
                  data-cy="specialist-notes-field"
                  name="specialist_notes"
                  placeholder="Note"
                  component={TextField}
                  rowsMax={10}
                  rows={4}
                  multiline
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <ExtendedButton
                data-cy="leave-note-dialog-button"
                size="large"
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                loading={submitting}
              >
                Send
              </ExtendedButton>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

const withForm = reduxForm<LeaveNoteInput, Props>({
  form: 'LeaveNote_Form',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  onSubmit: async (values, dispatch, { id, isProvider, isSearch }) => {
    const request = isProvider ? updateActivityProvider : updateActivity

    try {
      await dispatch<any>(request(values, id, isSearch))
    } catch (error) {
      throw new SubmissionError(error.errors)
    }
  },
  onSubmitSuccess: (_values, _dispatch, { onClose, name }) => {
    toastr.success('Note', `${name}'s note was successfully updated!`)

    onClose()
  }
})

export default withForm(LeaveNoteDialog)
