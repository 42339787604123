import React, { FC, useEffect, useMemo } from 'react'
import {
  Link as RouterLink,
  Redirect,
  RouteComponentProps
} from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useModal } from 'react-modal-hook'
import {
  Button,
  Container,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core'
import { PersonAdd } from '@material-ui/icons'

import withGoogleMaps from '~/containers/withGoogleMaps'
import FullWidthMobile from '~/containers/FullWidthMobile'
import { ItineraryCard } from '~/components/cards'
import { Title, Link, Text, Loader, Flexbox } from '~/components/shared'
import { ConfirmDialog } from '~/components/dialogs'
import InfoDataBlock from './components/InfoDataBlock'
import MessageForm from './components/MessageForm'
import { InfoAccordion } from '~/components/accordions'

import { getItineraryById, reviewItinerary } from '~/state/modules/dashboard'
import ItineraryService from '~/services/ItineraryService'
import ActivitiesService from '~/services/ActivitiesService'
import { sortByDate } from '~/utils/functions'

import { STATUSES, STATUSES_SHARED } from '~/constants/statusesItinerary'
import ROUTES from '~/constants/routes'
import { AppState } from '~/state/store'

import { ReactComponent as BackIcon } from '~/assets/icons/back-icon.svg'

import useItineraryStyles from './itinerary.styles'

type ReduxProps = {
  data: Partial<Itinerary>
  loading: boolean
  loaded: boolean
  error: null | boolean
}

type dispatchProps = {
  getItineraryById: (id: string) => void
  reviewItinerary: (id: string, isUpdate: boolean) => Promise<void>
}

type RouterState = {
  approve: boolean
}

type AllProps = ReduxProps &
  dispatchProps &
  RouteComponentProps<
    {
      id: string
    },
    {},
    RouterState
  >

const Itinerary: FC<AllProps> = ({
  match: { params },
  location,
  history,

  loading,
  loaded,
  data = {},
  error,

  getItineraryById,
  reviewItinerary
}) => {
  const s = useItineraryStyles()
  const approve = location?.state?.approve
  const isApproved = data.status === STATUSES.approved

  useEffect(() => {
    if (params?.id) {
      getItineraryById(params?.id)
    }
  }, [])

  const [showModal, hideModal] = useModal(
    (props: any) => (
      <ConfirmDialog
        onClose={hideModal}
        onConfirm={async (): Promise<void> => {
          try {
            await reviewItinerary(
              data?.id,
              data?.status === STATUSES?.alternate_request
            )

            history.push(ROUTES?.dashboard)
          } catch (error) {
            console.error(error)
          }
        }}
        text="Are you sure want to send itinerary to parent?"
        {...props}
      />
    ),
    [data]
  )

  const fields = useMemo(() => data?.questionnaire?.fields, [data])

  const {
    activitiesData,
    activitiesSum,
    activitiesStripeSum,
    isEdit,
    parentName
  } = useMemo(
    () => ({
      activitiesData: ActivitiesService.generateParentItineraryActivities(
        sortByDate(data?.itinerary_activities, 'start_date', false, 'activity')
      ),
      activitiesSum: ActivitiesService.generateActivitiesSum(data?.activities),
      activitiesStripeSum: ActivitiesService.generatePaymentActivitiesSum(
        data?.itinerary_activities
      ),
      isEdit: [STATUSES?.in_progress, STATUSES?.alternate_request]?.some(
        (status) => status === data?.status
      ),
      parentName: data?.parent_name?.split(' ')[0]
    }),
    [data]
  )

  if (error) {
    return <Redirect to={ROUTES?.notFound} />
  }

  return (
    <FullWidthMobile>
      <Container maxWidth="lg" disableGutters>
        <Paper className={s.wrapper}>
          {approve ? (
            <Link
              to="#"
              underline="hover"
              className={s?.backButton}
              onClick={(e: React.MouseEvent<HTMLElement>): void => {
                e.preventDefault()

                // @ts-ignore
                history.goBack()
              }}
            >
              <BackIcon />
              Back to Search
            </Link>
          ) : (
            <Link
              to={ROUTES?.dashboard}
              underline="hover"
              className={s?.backButton}
            >
              <BackIcon />
              Back to Dashboard
            </Link>
          )}

          {loading ? (
            <Loader />
          ) : loaded && fields ? (
            <>
              <Grid container spacing={3} className={s?.container}>
                <Grid item xs={12} md={6}>
                  <InfoDataBlock
                    status={data?.status}
                    title="Parent info"
                    data={ItineraryService.generateParentData(
                      fields,
                      data?.parent
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <InfoDataBlock
                    status={data?.status}
                    title="Child info"
                    data={ItineraryService.generateChildData(fields)}
                  />
                </Grid>

                {!!data?.change_requests?.length && (
                  <Grid item xs={12} md={6}>
                    <InfoAccordion
                      title="Change requests"
                      isRequestChange
                      data={ItineraryService.generateCommentsData(
                        data?.change_requests
                      )}
                    />
                  </Grid>
                )}

                {!!data?.parents_itineraries?.length && (
                  <Grid item xs={12}>
                    <Title variant="h4" mb={3} textAlign="start">
                      {parentName}&apos;s invitation
                    </Title>

                    <List disablePadding style={{ marginBottom: 30 }}>
                      {data?.parents_itineraries?.map(
                        ({ id, friend_name, status, comment }) => {
                          let inviteText = ''

                          switch (status) {
                            case STATUSES_SHARED?.draft:
                              inviteText = `A ${parentName} has sent an invitation to this itinerary to a ${friend_name}`
                              break
                            case STATUSES_SHARED?.accepted:
                              inviteText = `A ${friend_name} accepted the invitation to this itinerary.`
                              break
                            case STATUSES_SHARED?.declined:
                              inviteText = `A ${friend_name} declined the invitation to this itinerary.`
                              break
                          }

                          return (
                            <ListItem key={id} alignItems="flex-start">
                              <ListItemIcon>
                                <PersonAdd />
                              </ListItemIcon>
                              <ListItemText
                                primary={inviteText}
                                secondary={
                                  !!comment && (
                                    <Flexbox>
                                      <Text
                                        variant="body2"
                                        color="textPrimary"
                                        mr={0.5}
                                      >
                                        Comment:
                                      </Text>
                                      <Text variant="body2">{comment}</Text>
                                    </Flexbox>
                                  )
                                }
                              />
                            </ListItem>
                          )
                        }
                      )}
                    </List>
                  </Grid>
                )}

                {!!activitiesData?.length && (
                  <Grid item xs={12}>
                    <Title variant="h4" mb={3} textAlign="start">
                      {fields?.child_first_name}&apos;s Itinerary
                    </Title>

                    {activitiesData?.map((activity, idx) => (
                      <ItineraryCard
                        key={activity?.id}
                        isLast={idx === activitiesData?.length - 1}
                        isApproved={isApproved}
                        {...activity}
                      />
                    ))}

                    <Flexbox>
                      <Text
                        color="textSecondary"
                        fontSize={18}
                        mt={2}
                        mb={3}
                        mr={2.5}
                      >
                        Total:{' '}
                        <Text component="span" className={s?.total}>
                          {activitiesSum}
                        </Text>
                      </Text>
                      {activitiesStripeSum && (
                        <Text color="textSecondary" fontSize={18} mt={2} mb={3}>
                          Online payment via Stripe :{' '}
                          <Text component="span" className={s?.total}>
                            {activitiesStripeSum}
                          </Text>
                        </Text>
                      )}
                    </Flexbox>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <InfoDataBlock
                    status={data?.status}
                    title="Questionnaires"
                    data={ItineraryService.generateAnswersData(fields)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <MessageForm id={data?.id} message={data?.message} />
                </Grid>
              </Grid>

              {approve ? (
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  className={s?.saveButton}
                  onClick={showModal}
                >
                  Send itinerary to parent
                </Button>
              ) : (
                isEdit && (
                  <Button
                    data-cy="itinerary-button"
                    component={RouterLink}
                    to={{
                      pathname: ROUTES?.search,
                      state: {
                        itineraryId: data?.id
                      }
                    }}
                    size="large"
                    variant="contained"
                    color="primary"
                    className={s?.saveButton}
                  >
                    {data?.status === STATUSES?.alternate_request
                      ? 'Edit itinerary'
                      : 'Start planning'}
                  </Button>
                )
              )}
            </>
          ) : (
            <Text px={3}>No results</Text>
          )}
        </Paper>
      </Container>
    </FullWidthMobile>
  )
}

const mapStateToProps = ({
  dashboard: { loading, loaded, item, error }
}: AppState): ReduxProps => {
  return {
    loading,
    loaded,
    data: item,
    error
  }
}

const withConnect = connect(mapStateToProps, {
  getItineraryById,
  reviewItinerary
})

export default compose<React.FC>(withGoogleMaps(), withConnect)(Itinerary)
