import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

import {
  fetchingCampDirectorActivity,
  getActivitySelector
} from '~/state/modules/programs'
import ProgramTemplate from '../../program.template'
import { Flexbox, Loader, Text, Title } from '~/components/shared'
import ActivityForm from '../components/ActivityForm'

import ActivityService from '~/services/ActivitiesService'
import { deleteActivityItem } from '~/state/modules/programs/programs.slice'
import { Tooltip } from '@material-ui/core'

import { ReactComponent as InfoIcon } from '~/assets/icons/info-icon.svg'

const DuplicateActivity: React.FC = () => {
  const { id, activityId } = useParams<{ id: string; activityId: string }>()

  const dispatch = useDispatch()
  const { activity, loading } = useSelector(getActivitySelector)

  useEffect(() => {
    if (id && activityId) {
      dispatch(fetchingCampDirectorActivity({ id, activityId }))
    }

    return (): void => {
      dispatch(deleteActivityItem())
    }
  }, [id, activityId])

  return (
    <ProgramTemplate title="Back to Activities" programId={id}>
      <Flexbox mb={2}>
        <Title variant="h4" textAlign="start">
          Duplicate activity
        </Title>

        <Tooltip
          title="Fields that are inherited from the parent activity, cannot be changed."
          arrow
          enterTouchDelay={0}
          leaveTouchDelay={3000}
        >
          <Text px={1.5} mt={0.5} variant="body2" component="span">
            <InfoIcon width={20} height={20} />
          </Text>
        </Tooltip>
      </Flexbox>

      {loading ? (
        <Loader />
      ) : (
        <ActivityForm
          isDuplicate
          initialValues={
            ActivityService.generateCampDirectorInitialValues(activity, true) ||
            {}
          }
        />
      )}
    </ProgramTemplate>
  )
}

export default DuplicateActivity
