import React from 'react'

import { Link } from '@material-ui/core'

import { ReactComponent as ActivitiesIcon } from '~/assets/icons/search-courses/activities.svg'
import { Flexbox, Text } from '~/components/shared'
import { DESCRIPTION_GENERATOR_LINK } from '~/constants'

const DescriptionLabel = () => (
  <Flexbox
    alignItems="center"
    justifyContent="space-between"
    flexWrap="wrap"
    mb={1}
  >
    <Text variant="body2">Description</Text>

    <Link href={DESCRIPTION_GENERATOR_LINK} target="_blank">
      <Flexbox alignItems="center">
        <ActivitiesIcon
          style={{
            width: 10,
            marginRight: 5
          }}
        />

        <Text variant="body2">Description Generator</Text>
      </Flexbox>
    </Link>
  </Flexbox>
)

export default DescriptionLabel
