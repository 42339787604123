import PMK from '~/services/PMK'
import Deserializer from '~/services/Deserializer'
import ErrorHandler from '~/utils/errorHandler'

import { AppThunk } from '~/state/store'

import {
  request,
  success,
  successItem,
  updateItem,
  successActivityProviderItem,
  updateActivityProviderItem,
  failure
} from './activities.slice'

// Actions

/**
 * Get activities action
 */
export const getActivities = (
  params: SearchDTO
): AppThunk<Promise<void>> => async (dispatch): Promise<void> => {
  dispatch(request())

  try {
    const { activities, pagy } = await PMK.getActivities(params)
    const payload = await Deserializer.deserialize(activities)

    dispatch(
      success({
        data: payload,
        meta: {
          count: pagy.count,
          pages: pagy.pages,
          max_price: pagy.vars.max_price,
          min_price: pagy.vars.min_price,
          camp_types: pagy.vars.camp_types,
          activity_providers: pagy.vars.activity_providers,
          interests: pagy.vars.interests,
          skill_development: pagy.vars.skill_development
        }
      })
    )
  } catch (error) {
    throw new ErrorHandler(error)
  }
}

/**
 * Get activity action
 */
export const getActivityById = (id: string): AppThunk<Promise<void>> => async (
  dispatch
): Promise<void> => {
  dispatch(request())

  try {
    const res = await PMK.getActivityById(id)
    const payload = await Deserializer.deserialize(res)

    dispatch(successItem(payload))
  } catch (error) {
    dispatch(failure(true))
  }
}

export const updateActivity = (
  values: LeaveNoteInput,
  id: string,
  isFromSearch?: boolean
): AppThunk<Promise<void>> => async (dispatch): Promise<void> => {
  try {
    await PMK.updateActivity(id, values)

    dispatch(
      updateItem({
        note: values.specialist_notes,
        id: isFromSearch ? id : ''
      })
    )
  } catch (error) {
    dispatch(failure(true))
  }
}

/**
 * Get activity provider action
 */
export const getActivityProviderById = (
  id: string
): AppThunk<Promise<void>> => async (dispatch): Promise<void> => {
  dispatch(request())

  try {
    const res = await PMK.getActivityProviderById(id)
    const payload = await Deserializer.deserialize(res)

    dispatch(successActivityProviderItem(payload))
  } catch (error) {
    dispatch(failure(true))
  }
}

export const updateActivityProvider = (
  values: LeaveNoteInput,
  id: string,
  isFromSearch?: boolean
): AppThunk<Promise<void>> => async (dispatch): Promise<void> => {
  try {
    await PMK.updateActivityProvider(id, values)

    dispatch(
      updateActivityProviderItem({
        note: values.specialist_notes,
        id: isFromSearch ? id : ''
      })
    )
  } catch (error) {
    dispatch(failure(true))
  }
}
