import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import { Container, Button, useMediaQuery } from '@material-ui/core'

import { Flexbox, NavLink, Logo } from '~/components/shared'
import ProfilePopover from './ProfilePopover'
import SignInPopover from './SignInPopover'

import { HeaderProps } from './Header'

import { useDesktopStyles } from './Header.styles'
import { isCampDirector } from '~/state/modules/user'

const HeaderDesktop: FC<HeaderProps> = ({
  navs,
  isGradient,
  logged,
  specialist,
  limitQuestionnaire,

  handleConsultationDialog,
  logout
}): JSX.Element => {
  const s = useDesktopStyles()
  const isMobile = useMediaQuery('(max-width:1100px )')
  const campDirector = useSelector(isCampDirector)

  return (
    <header
      className={cn(s.root, {
        gradient: isGradient
      })}
    >
      <Container maxWidth="xl">
        <Flexbox className={s.container}>
          <Logo isWhite={isGradient} isMobile={isMobile} />

          <nav className={s.links}>
            {navs.map(({ label, to, outwardLink }) =>
              outwardLink ? (
                <NavLink
                  component={(props): JSX.Element => <a {...props} />}
                  key={to}
                  href={to}
                  target="_blank"
                  rel="noopener noreferrer"
                  customClassName="link"
                >
                  {label}
                </NavLink>
              ) : (
                <NavLink key={to} to={to} customClassName="link">
                  {label}
                </NavLink>
              )
            )}
          </nav>

          <Flexbox
            className={cn(s.btns, {
              gradient: isGradient
            })}
          >
            {!specialist && !campDirector && (
              <Button
                variant="contained"
                color="secondary"
                onClick={handleConsultationDialog}
              >
                Schedule Consultation
              </Button>
            )}

            {logged ? (
              specialist ? (
                <Button
                  variant="outlined"
                  onClick={logout}
                  className={s.logOut}
                >
                  Log Out
                </Button>
              ) : (
                <ProfilePopover
                  limitQuestionnaire={limitQuestionnaire}
                  logout={logout}
                  isGradient={isGradient}
                />
              )
            ) : (
              <SignInPopover />
            )}
          </Flexbox>
        </Flexbox>
      </Container>
    </header>
  )
}

export default HeaderDesktop
