import React from 'react'

import { BlankLink } from '~/components/shared'

import programImage1 from '~/assets/images/home/program-1.png'
import programImage2 from '~/assets/images/home/program-2.png'
import programImage3 from '~/assets/images/home/program-3.png'
import programImage4 from '~/assets/images/home/program-4.webp'
import programImage5 from '~/assets/images/home/program-5.webp'
import programImage6 from '~/assets/images/home/program-6.webp'
import programImage7 from '~/assets/images/home/program-7.webp'
import programImage8 from '~/assets/images/home/program-8.webp'
import programImage9 from '~/assets/images/home/program-9.webp'
import programImage14 from '~/assets/images/home/program-14.webp'
import programImage13 from '~/assets/images/home/program-13.webp'
import programImage12 from '~/assets/images/home/program-12.webp'
import programImage11 from '~/assets/images/home/program-11.webp'
import programImage10 from '~/assets/images/home/program-10.webp'

import Ages from '~/assets/icons/search-courses/ages.svg'
import Type from '~/assets/icons/search-courses/type.svg'
import Activity from '~/assets/icons/search-courses/activity.svg'
import Activities from '~/assets/icons/search-courses/activities.svg'

export const programSpotlight = [
  {
    image: programImage10,
    title: 'Apex',
    subtitle: 'Lune spark',
    description:
      'A center for creativity that nurtures young creative minds as they pursue their passions in the arts.',
    items: [
      {
        icon: Ages,
        label: 'Ages',
        value: '7-12 years'
      },
      {
        icon: Activity,
        label: 'Interests',
        value: 'Music, Theater, Academics'
      },
      {
        icon: Type,
        label: 'Camp Types',
        value: 'Day Camp, Track-out'
      },
      {
        icon: Activities,
        label: 'Spotlight',
        value: (
          <BlankLink href="https://blog.planmykids.com/resources/f/spotlight---lune-spark">
            Learn more
          </BlankLink>
        )
      }
    ]
  },
  {
    image: programImage11,
    title: 'Raleigh',
    subtitle: 'Learning to engage, Gain and Obtain',
    description:
      'Utilizing the infinite applications of Lego® in the learning process to develop essential skills.',
    items: [
      {
        icon: Ages,
        label: 'Ages',
        value: '6-18 years'
      },
      {
        icon: Activity,
        label: 'Interests',
        value: 'Arts & Crafts, Academics'
      },
      {
        icon: Type,
        label: 'Camp Types',
        value: 'Day Camp'
      },
      {
        icon: Activities,
        label: 'Spotlight',
        value: (
          <BlankLink href="https://blog.planmykids.com/resources/f/spotlight---learning-to-engage-gain-and-obtain">
            Learn more
          </BlankLink>
        )
      }
    ]
  },
  {
    image: programImage12,
    title: 'Cary',
    subtitle: 'We rock the spectrum',
    description:
      'An indoor gym that provides kids of all abilities a place to play and grow together.',
    items: [
      {
        icon: Ages,
        label: 'Ages',
        value: '2-12 years'
      },
      {
        icon: Activity,
        label: 'Interests',
        value: 'Arts & Crafts, Sports'
      },
      {
        icon: Type,
        label: 'Camp Types',
        value: 'Day Camp, Track-out'
      },
      {
        icon: Activities,
        label: 'Spotlight',
        value: (
          <BlankLink href="https://blog.planmykids.com/resources/f/program-spotlight---inclusive-play-at-we-rock-the-spectrum">
            Learn more
          </BlankLink>
        )
      }
    ]
  },
  {
    image: programImage13,
    title: 'Raleigh',
    subtitle: 'Knuckle up Gym',
    description:
      'Prepare your child for life with boxing and self-defense skills while creating discipline and self-confidence.',
    items: [
      {
        icon: Ages,
        label: 'Ages',
        value: '5 and up'
      },
      {
        icon: Activity,
        label: 'Interests',
        value: 'Sports'
      },
      {
        icon: Type,
        label: 'Camp Types',
        value: 'Classes'
      },
      {
        icon: Activities,
        label: 'Spotlight',
        value: (
          <BlankLink href="https://blog.planmykids.com/resources/f/spotlight---knuckle-up-kids-boxing-gym">
            Learn more
          </BlankLink>
        )
      }
    ]
  },
  {
    image: programImage14,
    title: 'Holly Springs',
    subtitle: 'Beyond School Age Care',
    description:
      'Foster wonderful experiences and friendships that will last a lifetime through adventure and fun.',
    items: [
      {
        icon: Ages,
        label: 'Grades',
        value: 'K-5'
      },
      {
        icon: Activity,
        label: 'Interests',
        value: 'Academics, Outdoor activities, Sports'
      },
      {
        icon: Type,
        label: 'Camp Types',
        value: 'Day Camp, Track-Out'
      },
      {
        icon: Activities,
        label: 'Spotlight',
        value: (
          <BlankLink href="https://blog.planmykids.com/resources/f/program-spotlight---beyond-school-age-care">
            Learn more
          </BlankLink>
        )
      }
    ]
  },
  {
    image: programImage1,
    title: 'Raleigh',
    subtitle: 'Arts Together',
    description: 'Cultivating individual creativity through arts education.',
    items: [
      {
        icon: Ages,
        label: 'Ages',
        value: 'All'
      },
      {
        icon: Activity,
        label: 'Interests',
        value: 'Arts & Crafts, Music, Theater'
      },
      {
        icon: Type,
        label: 'Camp Types',
        value: 'Day Camp'
      },
      {
        icon: Activities,
        label: 'Spotlight',
        value: (
          <BlankLink href="https://blog.planmykids.com/resources/f/spotlight-arts-together">
            Learn more
          </BlankLink>
        )
      }
    ]
  },
  {
    image: programImage2,
    title: 'Cary, Raleigh, Wake Forest',
    subtitle: 'Camp Chillin',
    description: '',
    items: [
      {
        icon: Ages,
        label: 'Ages',
        value: '6-12 years'
      },
      {
        icon: Activity,
        label: 'Interests',
        value: 'Sports'
      },
      {
        icon: Type,
        label: 'Camp Types',
        value: 'Day Camp'
      },
      {
        icon: Activities,
        label: 'Spotlight',
        value: (
          <BlankLink href="https://blog.planmykids.com/resources/f/spotlight-camp-chillin">
            Learn more
          </BlankLink>
        )
      }
    ]
  },
  {
    image: programImage3,
    title: 'Apex, Fuquay-Varina',
    subtitle: 'Rock Solid Warrior',
    description: '',
    items: [
      {
        icon: Ages,
        label: 'Ages',
        value: '5-12 years'
      },
      {
        icon: Activity,
        label: 'Interests',
        value: 'Outdoor Activities, Sports, Rock Climbing'
      },
      {
        icon: Type,
        label: 'Camp Types',
        value: 'Day Camp, Track-Out'
      },
      {
        icon: Activities,
        label: 'Spotlight',
        value: (
          <BlankLink href="https://blog.planmykids.com/resources/f/spotlight---rock-solid-warrior">
            Learn more
          </BlankLink>
        )
      }
    ]
  },
  {
    image: programImage4,
    title: 'Cary',
    subtitle: 'Stemarga',
    description:
      'Rethinking education from the ground up and to make education a fun-filled, imaginative & personal journey for learners.',
    items: [
      {
        icon: Ages,
        label: 'Ages',
        value: '3-8 years'
      },
      {
        icon: Activity,
        label: 'Interests',
        value: 'Academic'
      },
      {
        icon: Type,
        label: 'Camp Types',
        value: 'Day Camp'
      },
      {
        icon: Activities,
        label: 'Spotlight',
        value: (
          <BlankLink href="https://blog.planmykids.com/resources/f/spotlight---stemarga">
            Learn more
          </BlankLink>
        )
      }
    ]
  },
  {
    image: programImage5,
    title: 'Cary, Holly Spring, Raleigh',
    subtitle: 'Zebra Robotics',
    description:
      'Robotics and coding classes for the youngest members of your family.',
    items: [
      {
        icon: Ages,
        label: 'Ages',
        value: '4-8 years'
      },
      {
        icon: Activity,
        label: 'Interests',
        value: 'Academic'
      },
      {
        icon: Type,
        label: 'Camp Types',
        value: 'Day Camp, Track-out'
      },
      {
        icon: Activities,
        label: 'Spotlight',
        value: (
          <BlankLink href="https://blog.planmykids.com/resources/f/spotlight---zebra-robotics">
            Learn more
          </BlankLink>
        )
      }
    ]
  },
  {
    image: programImage6,
    title: 'Chapel Hill, Durham, Hillsboro, Morrisville, Raleigh',
    subtitle: 'Triangle Ultimate',
    description:
      'Building community and engagement through learning, playing, and teaching the sport of Ultimate.',
    items: [
      {
        icon: Ages,
        label: 'Ages',
        value: '7-19 years'
      },
      {
        icon: Activity,
        label: 'Interests',
        value: 'Sports'
      },
      {
        icon: Type,
        label: 'Camp Types',
        value: 'Day Camp'
      },
      {
        icon: Activities,
        label: 'Spotlight',
        value: (
          <BlankLink href="https://blog.planmykids.com/resources/f/spotlight-triangle-ultimate">
            Learn more
          </BlankLink>
        )
      }
    ]
  },
  {
    image: programImage7,
    title: 'Raleigh',
    subtitle: 'Horses for Hope',
    description:
      'Hands-on horse interactions, crafts/games, and riding lessons.',
    items: [
      {
        icon: Ages,
        label: 'Ages',
        value: '4-14 years'
      },
      {
        icon: Activity,
        label: 'Interests',
        value: 'Outdoor Activites'
      },
      {
        icon: Type,
        label: 'Camp Types',
        value: 'Day Camp'
      },
      {
        icon: Activities,
        label: 'Spotlight',
        value: (
          <BlankLink href="https://blog.planmykids.com/resources/f/spotlight-horses-for-hope">
            Learn more
          </BlankLink>
        )
      }
    ]
  },
  {
    image: programImage8,
    title: 'Raleigh',
    subtitle: 'Pinecone Bluegrass Camp',
    description:
      'Build and develop musical skills while encouraging confidence, creativity, and collaboration.',
    items: [
      {
        icon: Ages,
        label: 'Ages',
        value: '8-17 years'
      },
      {
        icon: Activity,
        label: 'Interests',
        value: 'Music'
      },
      {
        icon: Type,
        label: 'Camp Types',
        value: 'Day Camp'
      },
      {
        icon: Activities,
        label: 'Spotlight',
        value: (
          <BlankLink href="https://blog.planmykids.com/resources/f/spotlight-pinecone-bluegrass-camps">
            Learn more
          </BlankLink>
        )
      }
    ]
  },
  {
    image: programImage9,
    title: 'Raleigh',
    subtitle: 'Artspace',
    description:
      'Professional teaching artists bring high-quality, one-of-a-kind art camps to students.',
    items: [
      {
        icon: Ages,
        label: 'Ages',
        value: '3-12 years'
      },
      {
        icon: Activity,
        label: 'Interests',
        value: 'Arts & Crafts'
      },
      {
        icon: Type,
        label: 'Camp Types',
        value: 'Day Camp'
      },
      {
        icon: Activities,
        label: 'Spotlight',
        value: (
          <BlankLink href="https://blog.planmykids.com/resources/f/spotlight-artspace-summer-art-camp">
            Learn more
          </BlankLink>
        )
      }
    ]
  }
]

export default programSpotlight
