import React, { FC } from 'react'
import { compose } from 'redux'
import {
  InjectedFormProps,
  Form,
  Field,
  reduxForm,
  SubmissionError
} from 'redux-form'
import { RouteComponentProps, withRouter } from 'react-router'
import { Grid, Button, Box } from '@material-ui/core'

import { TextField } from '~/components/fields'
import { FormControl, Text } from '~/components/shared'

import { sendResetLink, sendResetDirectorLink } from '~/state/modules/user'
import { asyncValidate } from '~/utils/asyncValidate'
import ForgotPasswordSchema from '~/schemas/forgot-password/email'

import { STORAGE } from '~/constants/storage'
import ROUTES from '~/constants/routes'
import { HELPER_ROLES } from '~/constants'

const ForgotPasswordForm: FC<InjectedFormProps> = ({ handleSubmit }) => {
  return (
    <Box
      component={Form}
      display="flex"
      flexDirection="column"
      onSubmit={handleSubmit}
      data-cy="forgot-password-form"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Text color="textSecondary" align="center">
            Enter your email and we will send you the reset password link
          </Text>
        </Grid>
        <Grid item xs={12}>
          <FormControl label="Email">
            <Field
              id="email"
              name="email"
              placeholder="Email"
              autoComplete="email"
              component={TextField}
              normalize={(value): string => value?.toLowerCase()}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
          >
            Send the reset password link
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

const withForm = reduxForm<ForgotPasswordInput, RouteComponentProps>({
  form: 'Forgot_Password_Form',
  shouldAsyncValidate: () => true,
  asyncValidate: asyncValidate(ForgotPasswordSchema),
  onSubmit: async (values, _dispatch, { location }) => {
    try {
      const isDirector = location.pathname.includes(
        HELPER_ROLES.director.replace('_', '-')
      )

      if (isDirector) {
        await sendResetDirectorLink(values)
      } else {
        await sendResetLink(values)
      }

      return values
    } catch (error) {
      throw new SubmissionError(error.errors)
    }
  },
  onSubmitSuccess: ({ email }, _dispatch, { history }) => {
    localStorage.setItem(STORAGE.userEmail, email)

    history.push(ROUTES.forgotPasswordVerify, { hide: true })
  }
})

export default compose<React.FC>(withRouter, withForm)(ForgotPasswordForm)
