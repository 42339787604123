import React from 'react'
import { Link } from 'react-router-dom'
import {
  Box,
  Button,
  ListItemText,
  List,
  ListItem,
  ListItemIcon,
  createStyles,
  makeStyles
} from '@material-ui/core'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

import { Flexbox, Text, Title } from '~/components/shared'
import PMK from '~/services/PMK'

import ROUTES from '~/constants/routes'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      list: {
        '& .MuiListItem-root': {
          paddingTop: 0,
          paddingBottom: 0
        },

        '& li': {
          color: theme.palette.text.secondary
        },

        '& svg': {
          width: 10,
          height: 10
        }
      },

      listIcon: {
        minWidth: 30
      },

      contact: {
        borderLeft: `4px solid ${theme.palette.secondary.main}`
      }
    }),
  {
    name: 'Stripe'
  }
)

const StripeInformation: React.FC = () => {
  const s = useStyles()

  const handleClick = async (): Promise<void> => {
    const { url } = await PMK.createStripeLink()

    if (url) {
      window.location = url
    }
  }

  return (
    <Flexbox flexDirection="column" alignItems="center">
      <Box maxWidth={640} mb={2} mt={5}>
        <Title mb={2}>Create Stripe account</Title>

        <Flexbox flexDirection="column" mb={2}>
          <Text color="textSecondary">
            Stripe is one of the leading payment processors in the industry
            supporting online payments for both small and large companies.
          </Text>
        </Flexbox>

        <List className={s.list}>
          <ListItem>
            <ListItemText>
              <b>The benefits of using Stripe:</b>
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon classes={{ root: s.listIcon }}>
              <FiberManualRecordIcon />
            </ListItemIcon>

            <ListItemText>Easy onboarding process</ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon classes={{ root: s.listIcon }}>
              <FiberManualRecordIcon />
            </ListItemIcon>

            <ListItemText>
              Collect payments to your bank account quickly
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon classes={{ root: s.listIcon }}>
              <FiberManualRecordIcon />
            </ListItemIcon>

            <ListItemText>
              Fraud protection with machine learning to prevent against
              malicious actors
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon classes={{ root: s.listIcon }}>
              <FiberManualRecordIcon />
            </ListItemIcon>

            <ListItemText>PCI compliant service to protect data</ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon classes={{ root: s.listIcon }}>
              <FiberManualRecordIcon />
            </ListItemIcon>

            <ListItemText>
              Reporting insights to help you stay on top of your transactions
            </ListItemText>
          </ListItem>
        </List>

        <List className={s.list}>
          <ListItem>
            <ListItemText>
              <b>Why do you need a stripe account?</b>
            </ListItemText>
          </ListItem>

          <Text color="textSecondary">
            In order to participate in our community and to collect payments for
            bookings to your program you must have a Stripe Account.
          </Text>
        </List>

        <List className={s.list}>
          <ListItem>
            <ListItemText>
              <b>What if you already have a Stripe Account?</b>
            </ListItemText>
          </ListItem>

          <Text color="textSecondary">
            Provide your stripe account information during setup.
          </Text>
        </List>
      </Box>

      <Button
        style={{ maxWidth: '230px', marginBottom: 40 }}
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        Create Stripe account
      </Button>

      <Box width="100%" maxWidth={640} mb={2.5}>
        <Flexbox flexDirection="column" className={s.contact}>
          <Text color="textSecondary" mb={2} ml={2}>
            Have questions, not sure, find yourself scratching your head? No
            problem, send us a message and we&apos;ll try to help. (No
            guarantees to stop itchy heads).
          </Text>

          <Button
            component={Link}
            to={ROUTES.contactUs}
            style={{ maxWidth: '230px', marginLeft: 20 }}
            variant="outlined"
            color="primary"
          >
            Contact us
          </Button>
        </Flexbox>
      </Box>
    </Flexbox>
  )
}

export default StripeInformation
