import React, { FC, useCallback } from 'react'
import { WrappedFieldProps } from 'redux-form'
import MomentUtils from '@date-io/moment'

import {
  FormHelperText,
  makeStyles,
  TextField,
  createStyles
} from '@material-ui/core'
import { LocalizationProvider, TimePicker } from '@material-ui/pickers'

const useStyles = makeStyles(
  () =>
    createStyles({
      timePicker: {
        '& .MuiPickersTimePickerToolbar-hourMinuteLabel': {
          alignItems: 'center'
        }
      }
    }),
  {
    name: 'TimePicker'
  }
)

const TimePickerField: FC<
  WrappedFieldProps & { label?: string; placeholder?: string }
> = ({
  input: { value, onChange, onBlur, ...input },
  meta: { touched, error, submitting },
  label = '',
  placeholder = '',
  ...rest
}) => {
  const s = useStyles()

  const handleChange = useCallback(
    (date) => {
      return Date.parse(date) ? onChange(date) : onChange(null)
    },
    [onChange]
  )

  const errorMessage = touched && error

  return (
    <LocalizationProvider dateAdapter={MomentUtils}>
      <>
        <TimePicker
          {...input}
          className={s.timePicker}
          value={value ? new Date(value.toString()) : null}
          renderInput={(props): JSX.Element => {
            return (
              <TextField
                {...props}
                variant="outlined"
                helperText={null}
                placeholder="Sob"
                label={label}
                error={!!errorMessage}
                inputProps={{
                  ...props.inputProps,
                  placeholder
                }}
              />
            )
          }}
          disabled={submitting}
          // @ts-ignore
          onBlur={(): void => onBlur(value ? new Date(value) : null)}
          onChange={handleChange}
          {...rest}
        />

        {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
      </>
    </LocalizationProvider>
  )
}

export default TimePickerField
