import React, { useState, useEffect, FC } from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Confirmation from './Confirmation'

import { parentAutoSignIn } from '~/state/modules/user/user.actions'

import ROUTES from '~/constants/routes'
import { isAuthorized, isLogged, userPackagesType } from '~/state/modules/user'

const ConfirmationParent: FC<RouteComponentProps> = ({
  location: { search }
}) => {
  const dispatch = useDispatch()
  const [redirect, setRedirect] = useState<string>('')
  const formId = new URLSearchParams(search).get('form_id')

  const isLoggedUser = useSelector(isLogged)
  const isPackagesType = useSelector(userPackagesType)
  const isAuthorizedUser = useSelector(isAuthorized)

  const authParent = async (): Promise<void> => {
    try {
      await dispatch<any>(parentAutoSignIn(formId))

      setRedirect(ROUTES.congratulations)
    } catch (error) {
      console.error(error)

      setRedirect(ROUTES.home)
    }
  }

  const isRedirect = (): boolean => {
    if (isLoggedUser) {
      if (isAuthorizedUser) {
        setRedirect(ROUTES.home)

        return true
      }

      if (isPackagesType) {
        setRedirect(ROUTES.signUpVerify)

        return true
      }

      setRedirect(ROUTES.congratulations)

      return true
    }

    return false
  }

  useEffect(() => {
    if (isRedirect()) return

    authParent()
  }, [dispatch, formId])

  if (!redirect) return <Confirmation />

  return <Redirect to={redirect} />
}

export default ConfirmationParent
