import {
  Box,
  createStyles,
  IconButton,
  makeStyles,
  Modal
} from '@material-ui/core'
import React, { FC, useRef } from 'react'

import ReactImageGallery, { ReactImageGalleryProps } from 'react-image-gallery'
import ReactPlayer from 'react-player'

import { ReactComponent as CloseIcon } from '~/assets/icons/close.svg'
import thumbnail from '~/assets/images/thumbnail.png'

type ImageGalleryDialogProps = {
  open: boolean
  items: any[]
  onClose: () => void
  setOuterSlide: () => void
}

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      gallery: {
        outline: 'none',

        '& .image-gallery-content': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignContent: 'space-between',
          height: 'calc(100vh - 20px)'
        },

        '& .image-gallery-swipe': {
          margin: 'auto 0'
        },

        '& .image-gallery-slide-wrapper': {
          display: 'flex',
          flexDirection: 'column',
          height: '90%'
        },

        '& .image-gallery-icon:hover': {
          color: 'primary.main'
        },

        '& .image-gallery-thumbnail.active, .image-gallery-thumbnail:focus': {
          border: 'none'
        },

        '& .image-gallery-slides': {
          display: 'flex',
          marginTop: '150px',
          height: '550px'
        },

        '& .image-gallery-thumbnail': { '& img': { height: 85 } },

        '& .image-gallery-slide': {
          opacity: 0,
          transition: 'opacity 450ms ease-out !important',
          [theme.breakpoints.down('xs')]: {
            marginTop: 0
          }
        },

        '& .image-gallery-slide.center': {
          opacity: 1
        },

        '& .image-gallery-thumbnails-container': {
          marginBottom: 0,

          '& button:nth-child(2) ': {
            marginLeft: 60
          },

          [theme.breakpoints.down('sm')]: {
            marginBottom: '30px',
            paddingRight: '45px',

            '& button:nth-child(1) ': {
              marginRight: 20
            }
          }
        }
      },

      imageWrapper: {
        overflow: 'hidden',
        position: 'absolute',
        inset: '0px',

        '& img': {
          width: '100%',
          objectFit: 'contain',

          [theme.breakpoints.down('sm')]: {
            marginBottom: '30px',
            objectFit: 'cover',

            '& button:nth-child(1) ': {
              marginRight: 20
            }
          }
        }
      },

      closeIcon: { position: 'absolute', top: 100, right: 50, zIndex: 1000 },

      player: {
        width: '100% !important',
        height: '100% !important'
      },

      thumbnail: {
        maxWidth: 150
      }
    }),
  {
    name: 'ImageGalery'
  }
)

const ImageGalleryDialog: FC<
  ImageGalleryDialogProps & ReactImageGalleryProps
> = ({ open, onClose, items, setOuterSlide, ...rest }) => {
  const s = useStyles()

  const modalGalleryRef = useRef(null)

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={s.gallery}>
        <Box className={s.closeIcon}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <ReactImageGallery
          ref={modalGalleryRef}
          bigSize
          items={items}
          renderItem={(rest) => {
            return (
              <>
                {rest.isVideo ? (
                  <ReactPlayer
                    className={s.player}
                    url={rest.src}
                    controls={true}
                    playing={true}
                    loop={true}
                    muted={true}
                    playsinline={true}
                  />
                ) : (
                  <Box className={s.imageWrapper}>
                    <img src={rest.src} />
                  </Box>
                )}
              </>
            )
          }}
          renderThumbInner={(item) => {
            return (
              <>
                {item.isVideo ? (
                  <img
                    src={thumbnail}
                    alt={item.thumbnailAlt}
                    className={s.thumbnail}
                  />
                ) : (
                  <img
                    src={item.thumbnail}
                    alt={item.thumbnailAlt}
                    className={s.thumbnail}
                  />
                )}
              </>
            )
          }}
          {...rest}
        />
      </Box>
    </Modal>
  )
}

export default ImageGalleryDialog
