import React, { FC } from 'react'
import { Box, Button, Container } from '@material-ui/core'
import cn from 'classnames'

import { Text, Title } from '~/components/shared'
import ContainerFull from '~/components/layouts/ContainerFull'

import useWelcomeDialog from '~/hooks/useWelcomeDialog'

import painting1 from '~/assets/images/home/title-painting-1.png'

import useBannerStyles from './Banner.styles'
import useScreenOrientation from '~/hooks/useScreenOrientation'
import { useSelector } from 'react-redux'
import { isBlockQuestionnaire, isLogged } from '~/state/modules/user'
import Premium from '~/containers/Premium'

const Banner: FC = () => {
  const s = useBannerStyles()
  const { handleWelcomeDialog } = useWelcomeDialog()
  const orientationLandscape = useScreenOrientation()
  const isLoggedUser = useSelector(isLogged)
  const isQuestionnaireBlock = useSelector(isBlockQuestionnaire)

  const isBlockPlanButton = isQuestionnaireBlock && isLoggedUser

  return (
    <ContainerFull
      className={cn(s.root, { [s.rootLandscape]: orientationLandscape })}
    >
      <Container maxWidth="xl" className={s.container}>
        <Box className={s.wrapper}>
          <Title
            variant="h1"
            paintingOptions={{
              image: painting1,
              customStyles: {
                bottom: 5
              }
            }}
            mb={2}
          >
            Let us plan for you
          </Title>

          <Text variant="h4" mb={4}>
            We make planning and attending kids activities easy.
          </Text>

          <Premium
            customBlock={{
              isBlock: isBlockPlanButton,
              useBlock: true
            }}
          >
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={handleWelcomeDialog}
            >
              Start my plan
            </Button>
          </Premium>
        </Box>
      </Container>
    </ContainerFull>
  )
}

export default Banner
