import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Flexbox, Loader, Text, Title } from '~/components/shared'
import ActivityForm from '../../components/ActivityForm'
import ProgramTemplate from '../../../program.template'

import {
  fetchingCampDirectorActivity,
  getActivitySelector
} from '~/state/modules/programs'
import ActivityService from '~/services/ActivitiesService'
import { deleteActivityItem } from '~/state/modules/programs/programs.slice'
import { Tooltip } from '@material-ui/core'

import { ReactComponent as InfoIcon } from '~/assets/icons/info-icon.svg'

const EditActivity: React.FC = () => {
  const { id, activityId } = useParams<{ id: string; activityId: string }>()

  const dispatch = useDispatch()
  const { activity, loading } = useSelector(getActivitySelector)

  useEffect(() => {
    if (id && activityId) {
      dispatch(fetchingCampDirectorActivity({ id, activityId }))
    }

    return (): void => {
      dispatch(deleteActivityItem())
    }
  }, [id, activityId])

  return (
    <ProgramTemplate title="Back to Activities" programId={id}>
      <Flexbox mb={2}>
        <Title variant="h4" textAlign="start">
          Edit activity
        </Title>

        {activity?.duplicated_status && (
          <Tooltip
            title="Fields that are inherited from the parent activity, cannot be changed."
            arrow
            enterTouchDelay={0}
            leaveTouchDelay={3000}
          >
            <Text px={1.5} mt={0.5} variant="body2" component="span">
              <InfoIcon width={20} height={20} />
            </Text>
          </Tooltip>
        )}
      </Flexbox>

      {loading ? (
        <Loader />
      ) : (
        <ActivityForm
          isEdit
          isEditDuplicate={activity?.duplicated_status}
          initialValues={
            ActivityService.generateCampDirectorInitialValues(activity) || {}
          }
        />
      )}
    </ProgramTemplate>
  )
}

export default EditActivity
