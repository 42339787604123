import React from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, Box, Button } from '@material-ui/core'

import { Text } from '~/components/shared'

import useInfoBlockStyles from './InfoBlock.style'

import banner from '~/assets/images/home/banner.jpg'

const InfoBlock: React.FC = () => {
  const s = useInfoBlockStyles()
  const history = useHistory()

  return (
    <Grid item xs={12}>
      <Box className={s.infoBlock}>
        <img src={banner} alt="info block" className={s.infoImg} />

        <Box className={s.infoDescription}>
          <Text variant="h5" mb={1} className={s.infoTitle}>
            Itinerary
          </Text>
          <Text>
            To create itinerary for the parent and their child go to the
            dashboard tab.
          </Text>
        </Box>

        <Box className={s.infoBtn}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            fullWidth
            onClick={(): void => {
              history.push('/dashboard')
            }}
          >
            Go to the dashboard
          </Button>
        </Box>
      </Box>
    </Grid>
  )
}

export default InfoBlock
