import React, { FC } from 'react'
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core'

import { CroppedTextTooltip, Flexbox, Text } from '~/components/shared'
import ItineraryButtons from '../ItineraryButtons'

import { getDashboardSubscriptionName, humanizeDate } from '~/utils/functions'

import { useTableStyles } from './DashboardTable.styles'
import { ORDERS } from '~/constants/packages'

type Props = {
  data: Itinerary[]
  activeTab: StatusesUnion
}

const DashboardTable: FC<Props> = ({ data, activeTab }) => {
  const s = useTableStyles()

  return (
    <TableContainer>
      <Table size="small" className={s.table}>
        <TableHead data-cy="table-header-dashboard">
          <TableRow>
            <TableCell>Parent name</TableCell>

            <TableCell>Email</TableCell>

            <TableCell>Subscriptions</TableCell>

            <TableCell>Child</TableCell>

            <TableCell>Date</TableCell>

            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data.map(
            ({
              id,
              created_at,
              questionnaire: {
                parent: {
                  name,
                  email,
                  package_type: packageType,
                  order_status: orderStatus
                },
                fields: { child_first_name }
              }
            }) => {
              const subscription = getDashboardSubscriptionName(packageType)
              const isPending = orderStatus === ORDERS.pending
              const isPaymentFailed = orderStatus === ORDERS.payment_failed
              const hasPaid = !isPending && !isPaymentFailed && !!packageType

              return (
                <TableRow key={id} data-cy="dashboard-table-row">
                  <TableCell>
                    <CroppedTextTooltip title={name}>
                      <Text>{name}</Text>
                    </CroppedTextTooltip>
                  </TableCell>

                  <TableCell>
                    <CroppedTextTooltip title={email}>
                      <Text>{email}</Text>
                    </CroppedTextTooltip>
                  </TableCell>

                  <TableCell>{subscription}</TableCell>

                  <TableCell>
                    <CroppedTextTooltip title={child_first_name}>
                      <Text>{child_first_name}</Text>
                    </CroppedTextTooltip>
                  </TableCell>

                  <TableCell>
                    {humanizeDate(created_at, 'MMM D, YYYY, H:mm A')}
                  </TableCell>

                  <TableCell>
                    <Flexbox className={s.btns}>
                      <ItineraryButtons
                        id={id}
                        activeTab={activeTab}
                        hasChild={!!child_first_name}
                        hasPaid={hasPaid}
                      />
                    </Flexbox>
                  </TableCell>
                </TableRow>
              )
            }
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default DashboardTable
