import React, { FC } from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
  createStyles,
  useMediaQuery
} from '@material-ui/core'
import cn from 'classnames'

import { CroppedTextTooltip, Flexbox, Text } from '~/components/shared'

import { humanizeDate } from '~/utils/functions'

import { ReactComponent as ArrowDownIcon } from '~/assets/icons/arrow-down.svg'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      accordionContainer: {
        flexGrow: 1,
        width: 0
      },

      accordionContainerColor: {
        opacity: 0.4
      },

      content: {
        display: 'flex',
        flexWrap: 'wrap',
        paddingRight: theme.spacing(1),
        overflow: 'hidden'
      },

      nowrapText: {
        whiteSpace: 'nowrap',
        width: '49%'
      },

      fulRow: {
        width: '100%'
      },

      wrapText: {
        alignItems: 'center',
        flexWrap: 'wrap',
        marginBottom: theme.spacing(1.5),

        '& span': {
          color: theme.palette.text.secondary,
          fontSize: theme.typography.body1.fontSize
        }
      },

      innerWrapText: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      },

      wordBreak: {
        wordBreak: 'break-word'
      },

      accordOrdersBtn: {
        width: '70%',
        margin: theme.spacing(0.2, 0, 2.2),
        padding: theme.spacing(0.6, 1.5),
        '& span:first-child': {
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: '24px',
          textAlign: 'center'
        }
      },

      flexText: {
        display: 'flex',
        gap: `${theme.spacing(0.5)}px`
      }
    }),
  {
    name: 'OrdersAccordion'
  }
)

interface OrdersAccordion {
  order: Orders
}

const OrdersAccordion: FC<OrdersAccordion> = ({ order }) => {
  const s = useStyles()
  const isMobile = useMediaQuery('(max-width:996px)')
  const startDate = order?.activity?.start_date
  const endDate = order?.activity?.end_date
  const parentEmail = order?.parent_email || 'not specified'
  const parentAddress = `${order?.parent_address || 'not specified'}, ${
    order?.parent_zip_code || 'not specified'
  }`

  return (
    <Accordion
      className={cn(s.accordionContainer, {
        [s.accordionContainerColor]: order?.processed
      })}
    >
      <AccordionSummary
        expandIcon={<ArrowDownIcon />}
        classes={{
          content: s.content
        }}
      >
        <Text
          color="textSecondary"
          mr={0.5}
          className={cn(s.nowrapText, s.flexText, { [s.fulRow]: isMobile })}
        >
          <b>Activity :</b>{' '}
          <CroppedTextTooltip title={order?.activity?.name}>
            <Text>{order?.activity?.name}</Text>
          </CroppedTextTooltip>
        </Text>

        <Text
          color="textSecondary"
          className={cn(s.nowrapText, s.flexText, { [s.fulRow]: isMobile })}
        >
          <b>Parent :</b>{' '}
          <CroppedTextTooltip title={order?.parent_name}>
            <Text>{order?.parent_name}</Text>
          </CroppedTextTooltip>
        </Text>
      </AccordionSummary>

      <AccordionDetails>
        <Flexbox mx={1.5} flexDirection="column">
          {order?.activity?.sku && (
            <Flexbox className={s.wrapText}>
              <Text component="span" marginRight="5px" fontWeight="bold">
                SKU:
              </Text>
              <Text color="textSecondary" className={s.wordBreak}>
                {order?.activity?.sku}
              </Text>
            </Flexbox>
          )}

          <Flexbox className={s.wrapText}>
            <Text component="span" marginRight="5px" fontWeight="bold">
              Program:
            </Text>

            <CroppedTextTooltip title={order.activity.provider_name}>
              <Text color="textSecondary" className={s.innerWrapText}>
                {order.activity.provider_name}
              </Text>
            </CroppedTextTooltip>
          </Flexbox>

          <Flexbox className={s.wrapText}>
            <Text component="span" marginRight="5px" fontWeight="bold">
              Activity start and end date:
            </Text>
            {`${startDate ? `${humanizeDate(startDate)}` : ''} - ${
              endDate ? `${humanizeDate(endDate)}` : ''
            }`}
          </Flexbox>

          <Flexbox className={s.wrapText}>
            <Text component="span" marginRight="5px" fontWeight="bold">
              Parent email address:
            </Text>

            <CroppedTextTooltip title={parentEmail}>
              <Text color="textSecondary" className={s.innerWrapText}>
                {parentEmail}
              </Text>
            </CroppedTextTooltip>
          </Flexbox>

          <Flexbox className={s.wrapText}>
            <Text component="span" marginRight="5px" fontWeight="bold">
              Parent phone number:
            </Text>
            <Text color="textSecondary" className={s.innerWrapText}>
              {order?.parent_phone_number || 'not specified'}
            </Text>
          </Flexbox>

          <Flexbox className={s.wrapText}>
            <Text component="span" marginRight="5px" fontWeight="bold">
              Parent mailing address (address, zip):
            </Text>

            <CroppedTextTooltip title={parentAddress}>
              <Text color="textSecondary" className={s.innerWrapText}>
                {parentAddress}
              </Text>
            </CroppedTextTooltip>
          </Flexbox>

          <Flexbox className={s.wrapText}>
            <Text component="span" marginRight="5px" fontWeight="bold">
              Child:
            </Text>
            <Text color="textSecondary" className={s.innerWrapText}>
              {order?.child_name || 'not specified'}
            </Text>
          </Flexbox>
        </Flexbox>
      </AccordionDetails>
    </Accordion>
  )
}

export default OrdersAccordion
