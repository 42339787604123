import { useEffect, useMemo, useState } from 'react'
import { getTooltipProps } from './getTooltipProps'

export const useTooltip = () => {
  const [open, setOpen] = useState(false)
  const [enabled, setEnabled] = useState(false)

  const isTouchScreen = matchMedia('(hover: none), (pointer: coarse)').matches
  const eventType = useMemo(() => (isTouchScreen ? 'touch' : 'hover'), [
    isTouchScreen
  ])

  const disableTooltip = () => {
    setEnabled(false)
  }

  useEffect(() => {
    window.addEventListener('resize', disableTooltip)

    return () => {
      window.removeEventListener('resize', disableTooltip)
    }
  }, [])

  const checkOverflow = (element: HTMLElement) => {
    const { clientWidth, scrollWidth } = element
    const enabledTooltip = scrollWidth > clientWidth

    if (isTouchScreen) {
      setOpen(enabledTooltip)
    }

    if (!isTouchScreen) {
      setEnabled(enabledTooltip)
    }
  }

  const showTooltip = (event: React.MouseEvent<HTMLElement>) => {
    const { currentTarget } = event

    if (currentTarget instanceof HTMLElement) {
      checkOverflow(currentTarget)
    }
  }

  const closeTooltip = () => {
    if (isTouchScreen) {
      setOpen(false)
    }
  }

  const tooltipProps = useMemo(
    () =>
      getTooltipProps({
        eventType,
        open,
        enabled,
        showTooltip
      }),
    [open, enabled, eventType]
  )

  return {
    tooltipProps,
    closeTooltip
  }
}
