import PMK from '~/services/PMK'
import Deserializer from '~/services/Deserializer'
import ErrorHandler from '~/utils/errorHandler'
import { createAsyncThunk } from '@reduxjs/toolkit'

/**
 * Get chatbot messages
 */
export const getChatbotMessages = createAsyncThunk<ChatbotMessageModel[]>(
  'chatbotMessages/fetchChatbotMessages',
  async () => {
    try {
      const res = await PMK.getChatbotMessages()
      const payload = await Deserializer.deserialize(res)

      return payload
    } catch (error) {
      throw new ErrorHandler(error)
    }
  }
)
