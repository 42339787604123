import React, { FC } from 'react'
import { Box, Card, createStyles, makeStyles } from '@material-ui/core'

import { Text, Title } from '~/components/shared'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      imageWrapper: {
        position: 'relative',
        margin: theme.spacing(0, 'auto', 3),
        width: '100%',
        maxWidth: 'max-content'
      },

      card: {
        display: 'flex',
        justifyContent: 'center',
        maxHeight: 380,
        borderRadius: 20,

        '& img': {
          height: 'auto',
          objectFit: 'contain'
        },

        [theme.breakpoints.down('xs')]: {
          maxHeight: 280
        }
      },

      painting: {
        position: 'absolute',
        height: 'auto',
        objectFit: 'cover'
      },

      title: {
        marginBottom: theme.spacing(1.5),
        textAlign: 'start',

        [theme.breakpoints.down('xs')]: {
          marginRight: theme.spacing(6.5)
        }
      }
    }),
  {
    name: 'StepCard'
  }
)

const StepCard: FC<Step> = ({
  image,
  label,
  title,
  description,
  painting,
  paintingStyles
}) => {
  const s = useStyles()

  return (
    <Box>
      <Box className={s.imageWrapper}>
        <Card className={s.card}>
          <img src={image} alt="step" />
        </Card>

        <img
          src={painting}
          className={s.painting}
          style={paintingStyles.mobile}
          alt="painting"
        />
      </Box>

      <Text color="primary" mb={0.5} fontWeight={600}>
        {label}
      </Text>
      <Title variant="h3" customClassName={s.title}>
        {title}
      </Title>
      <Text color="textSecondary">{description}</Text>
    </Box>
  )
}

export default StepCard
