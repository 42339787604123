import React, { FC } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import {
  InjectedFormProps,
  Form,
  reduxForm,
  SubmissionError,
  Field
} from 'redux-form'
import { Grid, Card, CardContent } from '@material-ui/core'

import FullWidthMobile from '~/containers/FullWidthMobile'
import { PhoneField, TextField } from '~/components/fields'
import { FormControl, ExtendedButton, Flexbox } from '~/components/shared'

import { updateUser } from '~/state/modules/user'
import { asyncValidate } from '~/utils/asyncValidate'
import ParentProfileSchema from '~/schemas/parent-profile'

import { AppState } from '~/state/store'

import useProfileStyles from '../../profile.styles'

const ParentProfileForm: FC<InjectedFormProps> = ({
  handleSubmit,
  dirty,
  submitting
}) => {
  const s = useProfileStyles()

  return (
    <FullWidthMobile>
      <Card variant="outlined" className={s.formCard}>
        <CardContent>
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl label="Full name">
                  <Field
                    id="name"
                    name="name"
                    placeholder="Full name"
                    component={TextField}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl label="Email">
                  <Field
                    id="email"
                    name="email"
                    placeholder="Email"
                    autoComplete="email"
                    component={TextField}
                    normalize={(value): string => value?.toLowerCase()}
                    disabled
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl label="Phone number">
                  <Field
                    id="phone_number"
                    name="phone_number"
                    placeholder="Phone number"
                    component={PhoneField}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl label="Address">
                  <Field
                    id="address"
                    name="address"
                    placeholder="Address"
                    autoComplete="email"
                    component={TextField}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl label="ZIP-code">
                  <Field
                    id="zip_code"
                    name="zip_code"
                    placeholder="ZIP-code"
                    autoComplete="email"
                    component={TextField}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Flexbox justifyContent="flex-end">
                  <ExtendedButton
                    data-cy="parent-form-button"
                    type="submit"
                    loading={submitting}
                    disabled={!dirty}
                  >
                    Save changes
                  </ExtendedButton>
                </Flexbox>
              </Grid>
            </Grid>
          </Form>
        </CardContent>
      </Card>
    </FullWidthMobile>
  )
}

const mapStateToProps = ({
  user: { name, email, phone_number, address, zip_code }
}: AppState): any => {
  return {
    initialValues: {
      name,
      email,
      phone_number,
      address,
      zip_code
    }
  }
}

const withConnect = connect(mapStateToProps)

const withForm = reduxForm<UpdateUserInput>({
  form: 'Parent_Profile_Form',
  enableReinitialize: true,
  shouldAsyncValidate: () => true,
  asyncValidate: asyncValidate(ParentProfileSchema),
  onSubmit: async (values, dispatch) => {
    try {
      await dispatch<any>(updateUser(values))
    } catch (error) {
      throw new SubmissionError(error.errors)
    }
  },
  onSubmitSuccess: () => {
    toastr.success('Profile update', 'The profile was successfully updated!')
  }
})

export default compose<React.FC>(withConnect, withForm)(ParentProfileForm)
