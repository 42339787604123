export const ZOHO = {
  questionnaire: {
    key: process.env.REACT_APP_ZOHO_FORM,
    divId: function (): string {
      return `zf_div_${this.key}`
    }
  },
  questionnaireSecondPart: {
    key: process.env.REACT_APP_ZOHO_FORM_SECOND_PART,
    divId: function (): string {
      return `zf_div_${this.key}`
    }
  },
  contactUs: {
    key: 'CG8zskpM52tTWVLZ7KyCu6gRJ7Nu6NQaxn2GKti957Q',
    divId: function (): string {
      return `zf_div_${this.key}`
    }
  },
  campDirector: {
    production: '8nZGzevIREOtGgfnH2cGrkICheFfBUrHqk-FU6usnvw',
    staging: 'WlaQaxDqbopGsyz60vjBL6unip2yNuX4moOqFmE_At4',
    divIdProduction: function (): string {
      return `zf_div_${this.production}`
    },
    divIdStaging: function (): string {
      return `zf_div_${this.staging}`
    }
  }
}
