import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { PROGRAM_STATUS } from '~/constants'

import {
  deleteActivity,
  fetchActivities,
  fetchingCampDirectorActivity,
  getCampDirectorInterests,
  getProgramById,
  getPrograms,
  updateCampDirectorActivity,
  updateProgramById
} from '.'

const initialState: ProgramsState = {
  data: [],
  item: {},
  activeTab: PROGRAM_STATUS.information,
  activities: [],
  activityItem: {},
  interests: [],
  programsMeta: { count: 0, pages: 1, page: 1 },
  activitiesMeta: { count: 0, pages: 1, page: 1 },
  loading: false,
  loaded: false,
  error: null
}

const questionnariesSlice = createSlice({
  name: 'programs',
  initialState,
  reducers: {
    deleteProgramItem(state): void {
      state.item = {}
    },
    deletePrograms(state): void {
      state.data = []
    },
    updateActiveTab(state, { payload }: PayloadAction<string>): void {
      state.activeTab = payload
    },
    deleteActivityItem(state): void {
      state.activityItem = {}
    },
    deleteProgramActivities(state): void {
      state.activities = []
    },
    changeProgramsPage(state, { payload }: PayloadAction<number>): void {
      state.programsMeta.page = payload
    },
    changeActivitiesPage(state, { payload }: PayloadAction<number>): void {
      state.activitiesMeta.page = payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPrograms.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getPrograms.fulfilled, (state, { payload }) => {
      state.loading = false
      state.loaded = true
      state.data = payload.data
      state.programsMeta = payload.meta
    })
    builder.addCase(getPrograms.rejected, (state) => {
      state.loading = false
      state.error = true
    })
    builder.addCase(getProgramById.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getProgramById.fulfilled, (state, { payload }) => {
      state.loading = false

      state.item = {
        ...payload,
        activities: payload.activities.filter(Boolean)
      }
    })
    builder.addCase(updateProgramById.fulfilled, (state, { payload }) => {
      const foundIndex = state.data.findIndex(({ id }) => id === payload.id)

      state.data[foundIndex] = payload
    })
    builder.addCase(fetchActivities.pending, (state) => {
      state.loading = true
      state.loaded = false
    })
    builder.addCase(fetchActivities.fulfilled, (state, { payload }) => {
      state.loading = false
      state.loaded = true
      state.activities = payload.data
      state.activitiesMeta = payload.meta
    })
    builder.addCase(
      updateCampDirectorActivity.fulfilled,
      (state, { payload }) => {
        const foundIndex = state.activities.findIndex(
          ({ id }) => id === payload.id
        )

        state.activities[foundIndex] = payload
      }
    )
    builder.addCase(updateCampDirectorActivity.rejected, (state) => {
      state.loading = false
      state.error = true
    })
    builder.addCase(fetchingCampDirectorActivity.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      fetchingCampDirectorActivity.fulfilled,
      (state, { payload }) => {
        state.loading = false
        state.activityItem = payload
      }
    )
    builder.addCase(deleteActivity.fulfilled, (state, { payload }) => {
      state.activities = state.activities.filter(({ id }) => id !== payload)
    })
    builder.addCase(
      getCampDirectorInterests.fulfilled,
      (state, { payload }) => {
        state.interests = payload
      }
    )
  }
})

export const {
  deleteProgramItem,
  updateActiveTab,
  deleteActivityItem,
  changeProgramsPage,
  changeActivitiesPage,
  deleteProgramActivities,
  deletePrograms
} = questionnariesSlice.actions
export default questionnariesSlice.reducer
