import React from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'
import { createStyles, Link, LinkProps, makeStyles } from '@material-ui/core'
import cn from 'classnames'

import { fonts } from '~/theme/typography'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      link: {
        fontFamily: fonts.serif,
        fontSize: 16,

        '&.active': {
          color: theme.palette.primary.main
        }
      }
    }),
  {
    name: 'Navlink'
  }
)

type Props = {
  customClassName?: string
}

type AllProps = Props & LinkProps & Partial<NavLinkProps>

const Navlink: React.FC<AllProps> = ({
  children,
  customClassName,
  ...props
}) => {
  const s = useStyles()

  return (
    <Link
      component={NavLink}
      underline="hover"
      color="textPrimary"
      className={cn(s.link, customClassName)}
      activeClassName="active"
      {...props}
    >
      {children}
    </Link>
  )
}

export default Navlink
