import PMK from '~/services/PMK'
import Deserializer from '~/services/Deserializer'
import ErrorHandler from '~/utils/errorHandler'

import { STATUSES } from '~/constants/statusesItinerary'
import { AppThunk } from '~/state/store'

import { request, success, successItem } from './parentsItineraries.slice'

// Actions

/**
 * Get parent itineraries action
 */
export const getParentItineraries = (): AppThunk<Promise<void>> => async (
  dispatch
): Promise<void> => {
  dispatch(request())

  try {
    const res = await PMK.getParentItineraries()
    const payload = await Deserializer.deserialize(res)

    dispatch(success(payload))
  } catch (error) {
    throw new ErrorHandler(error)
  }
}

/**
 * Get parent itinerary by id action
 */
export const getParentItineraryById = (
  id: string
): AppThunk<Promise<void>> => async (dispatch): Promise<void> => {
  dispatch(request())

  try {
    const res = await PMK.getParentItineraryById(id)
    const payload = await Deserializer.deserialize(res)

    dispatch(successItem(payload))
  } catch (error) {
    throw new ErrorHandler(error)
  }
}

/**
 * Add comment to itinerary action
 */
export const addCommentItinerary = (
  id: string,
  values: ActivityChangeInput[]
): AppThunk<Promise<void>> => async (dispatch): Promise<void> => {
  try {
    const change_requests_attributes = values.map((item) => {
      const transformReason = Object.entries(item.reason)
        .map(([key, value]) => {
          if (value) return key
        })
        .filter(Boolean)

      return { ...item, reason: transformReason.join(', ') }
    })

    const res = await PMK.updateItinerary(id, {
      change_requests_attributes,
      status: STATUSES.alternate_request
    })

    const payload = await Deserializer.deserialize(res)

    dispatch(successItem(payload))
  } catch (error) {
    throw new ErrorHandler(error)
  }
}

/**
 * Accept itinerary action
 */
export const acceptItinerary = (id: string): AppThunk<Promise<void>> => async (
  dispatch
): Promise<void> => {
  try {
    const res = await PMK.updateItinerary(id, {
      status: STATUSES.accepted
    })

    const payload = await Deserializer.deserialize(res)

    dispatch(successItem(payload))
  } catch (error) {
    throw new ErrorHandler(error)
  }
}

export const sendInviteFriend = (
  values: InviteFriendInput
): AppThunk<Promise<void>> => async (dispatch): Promise<void> => {
  try {
    const res = await PMK.sendInviteFriend(values)
    const payload = await Deserializer.deserialize(res)

    dispatch(successItem(payload))
  } catch (error) {
    throw new ErrorHandler(error)
  }
}
