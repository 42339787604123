import { string } from 'yup'

import './methods'

export const fullName = string()
  .required('Full name is required')
  .max(255)
  .trim()
export const firstName = string().required('First name is required')
export const lastName = string().required('Last name is required')

export const email = string()
  .email('Invalid email')
  .required('Email is required')

export const password = string()
  .min(8, 'Password is too short!')
  .max(50, 'Password is too long!')
  .required('Password is required')

export const phone = string()
  .phoneNumberRequired()
  .nullable()
  .required('Phone number is required')

export const address = string()
  .nullable()
  .required('Address is required')
  .trim()

export const country = string()
export const city = string()
export const street = string()
export const zipCode = string().trim()
