import { makeStyles, createStyles } from '@material-ui/core'

export const useActivityCardDesktopStyles = makeStyles(
  ({ spacing, breakpoints, palette }) =>
    createStyles({
      card: {
        boxShadow: 'none',
        border: `1px solid ${palette.text.disabled}`
      },

      titleBlock: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        padding: spacing(2),
        backgroundColor: palette.grey[100],

        [breakpoints.down('md')]: {
          flex: 1
        }
      },

      seeMore: {
        display: 'inline-flex',
        alignItems: 'center',
        marginRight: spacing(1.5),

        '& svg': {
          marginRight: spacing(1)
        }
      },

      optionsBlock: {
        display: 'flex',
        flexDirection: 'column',
        flex: 2,
        padding: spacing(2),
        borderTop: `1px solid ${palette.text.disabled}`,

        [breakpoints.down('md')]: {
          flex: 1,
          flexDirection: 'column'
        }
      },

      firstRowDetails: {
        flex: 1,
        margin: spacing(0, 2, 0, 0),

        [breakpoints.down('md')]: {
          margin: spacing(0, 0, 2, 0)
        }
      },

      opacity: { opacity: 0.5 },

      duration: {
        color: `${palette.text.secondary}`
      },

      campInfoTitle: {
        [breakpoints.down('sm')]: {
          whiteSpace: 'nowrap'
        }
      },

      campInfoTime: {
        [breakpoints.down('sm')]: {
          textOverflow: 'clip',
          maxWidth: '95px',
          whiteSpace: 'nowrap',
          overflow: 'hidden'
        }
      },

      iconBtn: {
        minHeight: 0,
        minWidth: 10,
        width: 20,
        height: 20,
        padding: 0,
        alignItems: 'end'
      },

      startIcon: {
        marginRight: 0
      },

      infoNote: {
        position: 'absolute',
        top: 0,
        right: -3,
        lineHeight: 0
      },

      providerInfo: {
        fontWeight: 'bold',
        marginBottom: spacing(1),
        color: palette.text.secondary,
        marginRight: spacing(1)
      },

      activitiesInfo: {
        fontWeight: 'bold',
        marginBottom: spacing(1.5),
        color: palette.common.black,
        marginRight: spacing(0.5)
      }
    }),
  {
    name: 'ActivityCardDesktop'
  }
)

export const useActivityCardMobileStyles = makeStyles(
  ({ spacing, palette }) =>
    createStyles({
      card: {
        boxShadow: 'none',
        border: `1px solid ${palette.text.disabled}`
      },

      titleBlock: {
        padding: spacing(1.5),
        backgroundColor: palette.grey[100]
      },

      header: {
        alignItems: 'flex-start',
        marginBottom: spacing(0.5),
        justifyContent: 'space-between'
      },

      activityCard: {
        flexDirection: 'column'
      },

      opacity: { opacity: 0.5 },

      subtitle: {
        flex: 1,
        color: palette.text.secondary,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      },

      arrowUp: {
        padding: spacing(0, 0.5),
        transition: 'transform 0.2s linear',

        '& svg path': {
          stroke: palette.text.hint
        },

        '&.open': {
          transform: 'rotate(180deg)'
        }
      },

      seeMore: {
        display: 'flex',
        alignItems: 'center',

        '& svg': {
          marginRight: spacing(1)
        }
      },

      optionsBlock: {
        padding: spacing(1.5, 1.5, 2)
      },

      btnsBlock: {
        padding: spacing(0, 1.5, 2),
        backgroundColor: palette.grey[100],
        transition: 'all .2s ease',

        '&.open': {
          backgroundColor: 'transparent'
        }
      },

      duration: {
        color: `${palette.text.secondary}`
      }
    }),
  {
    name: 'ActivityCardMobile'
  }
)
