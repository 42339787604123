import { makeStyles, createStyles } from '@material-ui/core'

export const useAccordionsStyles = makeStyles(
  (theme) =>
    createStyles({
      listItem: {
        '& li': {
          paddingTop: '4px',
          paddingBottom: '4px'
        }
      },

      accordion: {
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        borderTop: '1px solid rgba(0, 0, 0, .125)',
        borderRight: '0',
        borderLeft: '0',
        borderRadius: '0px !important',
        boxShadow: 'none',

        '&:not(:last-child)': {
          borderBottom: 0
        },

        '&:before': {
          display: 'none'
        },

        '&$expanded': {
          margin: 'auto'
        }
      },

      summeryAccordoin: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,

        '&$expanded': {
          minHeight: 56
        }
      },

      detailsAccordion: {
        padding: theme.spacing(2)
      },

      expandIcon: {
        transform: 'rotate(-90deg)',

        '&.Mui-expanded': {
          transform: 'rotate(0deg)'
        }
      },

      expanded: {}
    }),
  {
    name: 'FaqAccordion'
  }
)
