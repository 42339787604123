import { DeferFn } from 'react-async'

import PMK from '~/services/PMK'
import Deserializer from '~/services/Deserializer'

import ErrorHandler from '~/utils/errorHandler'

export const loadPackages: DeferFn<Package[]> = async () => {
  try {
    const res = await PMK.getPaymentPackages()

    return Deserializer.deserialize(res)
  } catch (error) {
    throw new ErrorHandler(error)
  }
}

export const checkCoupon: DeferFn<Coupon> = async (props) => {
  const [promocode, price] = props

  try {
    const res = await PMK.checkCoupon({
      promocode,
      price
    })

    return res
  } catch (error) {
    throw new ErrorHandler(error)
  }
}
