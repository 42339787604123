const specialNeedsOptions: Option[] = [
  {
    label: 'Physical Disabilities',
    value: 'physical_disabilities'
  },
  {
    label: 'Learning Disabilities',
    value: 'learning_disabilities'
  },
  {
    label: 'Dietary',
    value: 'dietary'
  }
]

export default specialNeedsOptions
