export const ININERARY_ACTIVITIES_STATUSES: Record<
  ItineraryActivitiesStatusesKeys,
  ItineraryActivitiesStatusesUnion
> = {
  bookingAvailable: 'booking_available',
  bookingNotAvailable: 'booking_not_available',
  paid: 'paid',
  easyBooking: 'easy_booking',
  availableForPayment: 'available_for_payment'
} as const

export const ININERARY_ACTIVITIES_LABEL: Record<
  ItineraryActivitiesStatusesKeys,
  ItineraryActivitiesStatusesLabel
> = {
  bookingAvailable: 'Booking available',
  bookingNotAvailable: 'Booking Not Available',
  easyBooking: 'Easy Booking',
  paid: 'Paid',
  availableForPayment: 'Available For Payment'
} as const
