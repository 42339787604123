import React, { FC, useEffect, useState } from 'react'
import { WrappedFieldProps } from 'redux-form'
import moment from 'moment'
import {
  TextField,
  TextFieldProps,
  Box,
  makeStyles,
  FormControl,
  FormHelperText,
  FormLabel
} from '@material-ui/core'
import { DateRangePicker, DateRange } from '@material-ui/pickers'

import { Text } from '~/components/shared'

import { humanizeDate } from '~/utils/functions'

const useStyles = makeStyles((theme) => ({
  dateStartField: {
    marginRight: theme.spacing(1),
    width: '50%'
  },

  dateEndField: {
    width: '50%'
  },

  dateFont: {
    '& h6': {
      fontSize: 18
    },

    '& button': {
      minHeight: 'auto'
    }
  },

  label: {
    marginBottom: 10
  }
}))

type Props = {
  label: string
  required?: boolean
  displayError?: boolean
}

const DateRangeField: FC<WrappedFieldProps & Props> = ({
  input: { onChange, value, onBlur },
  meta: { touched, error },
  displayError,
  required,
  label,
  ...rest
}) => {
  const [selectedDate, setSelectedDate] = useState<DateRange>([null, null])

  const classes = useStyles()
  const errorMessage = (touched && error) || displayError

  useEffect(() => {
    if (value) {
      const values = JSON.parse(value)
      const dates = [
        values?.[0] ? moment(values[0]) : null,
        values?.[1] ? moment(values[1]) : null
      ] as DateRange

      setSelectedDate(dates)
    }
  }, [value])

  return (
    <FormControl variant="outlined" fullWidth error={!!errorMessage}>
      {!!label && (
        <FormLabel focused={false} className={classes.label}>
          <Text>{label}</Text>
        </FormLabel>
      )}

      <DateRangePicker
        className={classes.dateFont}
        calendars={2}
        value={selectedDate}
        onChange={(date: DateRange): void => {
          setSelectedDate(date)
          onBlur(JSON.stringify(date))
          onChange(JSON.stringify(date))
        }}
        renderInput={(
          startProps: TextFieldProps,
          endProps: TextFieldProps
        ): JSX.Element => (
          <Box display="flex" width="100%">
            <TextField
              {...startProps}
              helperText={null}
              className={classes.dateStartField}
              label="Start date"
              data-required={required}
              error={!!errorMessage}
              inputProps={{
                ...startProps.inputProps,
                value: selectedDate[0]
                  ? humanizeDate(selectedDate[0], 'MM/DD/YYYY')
                  : startProps.inputProps.value
              }}
            />
            <TextField
              {...endProps}
              helperText={null}
              label="End date"
              className={classes.dateEndField}
              data-required={required}
              error={!!errorMessage}
              inputProps={{
                ...endProps.inputProps,
                value: selectedDate[1]
                  ? humanizeDate(selectedDate[1], 'MM/DD/YYYY')
                  : endProps.inputProps.value
              }}
            />
          </Box>
        )}
        {...rest}
      />

      {!!errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  )
}

export default DateRangeField
