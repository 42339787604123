import React, { FC, useCallback, useMemo, useState } from 'react'
import { Box, Button, Link } from '@material-ui/core'
import { useSelector } from 'react-redux'
import ReactPlayer from 'react-player'

import {
  Text,
  Flexbox,
  ShowMoreText,
  CroppedTextTooltip
} from '~/components/shared'

import { ININERARY_ACTIVITIES_LABEL } from '~/constants/itineraryActivitiesStatuses'

import { ReactComponent as MapIcon } from '~/assets/icons/map-pin.svg'

import useItineraryCardStyles from './ItineraryCard.styles'
import CancellationPolicyAccordion from '~/pages/profile/itineraries/components/CancellationPolicyAccordion'

import { isSpecialist } from '~/state/modules/user'
import ImageGalleryDialog from '~/components/ImageGalery/ImageGaleryDialog'
import useModal from '~/hooks/useModal'
import useIdleCallback from '~/hooks/useIdleCallback'

import 'react-image-gallery/styles/css/image-gallery.css'
import { testMediaVideo } from '~/utils/functions'

export type ItineraryCardProps = {
  id: string
  subtitle: string
  title: string
  img: string
  date: string
  description: string
  age: string
  grade: string
  type: string
  prices: string
  location: string
  duration: string
  url: string
  isLast?: boolean
  camp_info_title: string
  camp_info_time: string
  start_time: string
  end_time: string
  payment_status: string
  isApproved?: boolean
  cancellation_policy?: string
  available_seats?: string
  total_seats?: string
  reserved_seats?: string
  optional_media_link?: string
  sku: string
  isPreview?: boolean
  waiver_form_url: string
}

const ItineraryCard: FC<ItineraryCardProps> = ({
  id,
  subtitle,
  title,
  img,
  date,
  description,
  duration,
  age,
  grade,
  type,
  prices,
  location,
  url,
  isLast,
  camp_info_time,
  camp_info_title,
  payment_status,
  isApproved,
  cancellation_policy,
  available_seats,
  total_seats,
  optional_media_link,
  waiver_form_url,
  isPreview
}) => {
  const s = useItineraryCardStyles()
  const specialist = useSelector(isSpecialist)
  const [slide, setSlide] = useState<number>(0)
  const [isMounted, setMounted] = useState<boolean>(false)
  const { open, showModal, hideModal } = useModal()

  useIdleCallback(
    useCallback(() => {
      setMounted(true)
    }, [])
  )

  const isMediaVideo = testMediaVideo(img)
  const isOptionalMediaVideo = testMediaVideo(optional_media_link)

  const optionalMedia = optional_media_link && {
    src: optional_media_link,
    thumbnail: optional_media_link,
    isVideo: isOptionalMediaVideo
  }
  const media = img && { src: img, thumbnail: img, isVideo: isMediaVideo }

  const elements = [media, optionalMedia].filter(Boolean)

  const linkUrl = specialist
    ? url
    : `${url}?utm_source=planmykids&utm_medium=web&utm_campaign=PMK_activity[${id}]`

  const isVideo = useMemo(() => {
    if (img) {
      const youTubeRegExp = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/gm

      return youTubeRegExp.test(img)
    }
  }, [img])

  const handleClick = (index) => {
    setMounted(true)

    setSlide(index)
    if (!isPreview) showModal()
  }

  const availableSeatsYesOrNo =
    available_seats === 'Yes' || available_seats === 'No'

  const availableSeatsInfo = availableSeatsYesOrNo
    ? available_seats
    : `${available_seats} / ${total_seats}`

  return (
    <Flexbox width="100%" data-cy="itineraries-card">
      <Flexbox className={s.dottedLine}>
        <MapIcon className={s.pointIcon} />
      </Flexbox>

      <Box flex={1} mb={isLast ? 0 : 4} maxWidth="calc(100% - 37px)">
        <Text color="textSecondary" mb={0.5}>
          {subtitle}
        </Text>

        <Flexbox
          justifyContent="flex-start"
          mb={0.5}
          className={s.wrapComponent}
        >
          <CroppedTextTooltip title={title}>
            <Text variant="subtitle1" className={s.wrapText} mr={1}>
              {title}
            </Text>
          </CroppedTextTooltip>

          {payment_status !==
            ININERARY_ACTIVITIES_LABEL.bookingNotAvailable && (
            <Text variant="subtitle1" className={s.paymentStatus}>
              {payment_status}
            </Text>
          )}
        </Flexbox>

        <Flexbox className={s.contentWrapper}>
          <Box flex={1}>
            <Flexbox className={s.details}>
              <Box flex={1} mr={{ sm: 2 }}>
                <Text color="textSecondary">
                  Date: <Text component="span">{date}</Text>{' '}
                  <Text component="span" className={s.duration}>
                    ({duration})
                  </Text>
                </Text>
                <Text color="textSecondary">
                  {camp_info_title}{' '}
                  <Text component="span">{camp_info_time}</Text>{' '}
                </Text>

                <Text color="textSecondary">
                  Ages: <Text component="span">{age || '-'}</Text>
                </Text>
                <Text color="textSecondary">
                  Grade: <Text component="span">{grade || '-'}</Text>
                </Text>
              </Box>

              <Box flex={1}>
                <Text color="textSecondary">
                  Camp type: <Text component="span">{type || '-'}</Text>
                </Text>
                <Text color="textSecondary">
                  Prices: <Text component="span">{prices || '-'}</Text>
                </Text>

                <Text color="textSecondary">
                  Available seats:{' '}
                  <Text component="span">{availableSeatsInfo}</Text>
                </Text>
              </Box>
            </Flexbox>

            <Text color="textSecondary" className={s.location}>
              Location: <Text component="span">{location || '-'}</Text>
            </Text>

            <ShowMoreText>
              <Text color="textSecondary">{description}</Text>
            </ShowMoreText>
          </Box>

          <Flexbox flexDirection="column">
            {img && (
              <Box className={s.imgWrapper}>
                {isVideo ? (
                  <ReactPlayer
                    className={s.player}
                    url={img}
                    controls={true}
                    playing={true}
                    loop={true}
                    muted={true}
                    playsinline={true}
                  />
                ) : (
                  <>
                    {elements?.map((item, index) => {
                      return (
                        <Box
                          onClick={() => handleClick(index)}
                          key={item?.thumbnail}
                          className={s.imgWrapper}
                        >
                          <img src={item?.src} />
                        </Box>
                      )
                    })}
                  </>
                )}
              </Box>
            )}

            {elements.length > 0 && (
              <Button
                color="primary"
                variant="contained"
                onClick={() => handleClick(0)}
                className={s.buttonMore}
              >
                Click to Preview
              </Button>
            )}
          </Flexbox>
        </Flexbox>

        <Flexbox alignItems="center">
          {isPreview ? (
            <Text color="primary" className={s.openCampButton}>
              Open camp page
            </Text>
          ) : (
            <Link
              href={linkUrl}
              target="_blank"
              rel="noreferrer"
              color="primary"
              underline="hover"
              className={s.openCampButton}
            >
              Open camp page
            </Link>
          )}

          {waiver_form_url &&
            (isPreview ? (
              <Text color="primary" className={s.waiverFormUrl}>
                Waiver form
              </Text>
            ) : (
              <Link
                href={waiver_form_url}
                target="_blank"
                rel="noreferrer"
                color="primary"
                underline="hover"
                className={s.waiverFormUrl}
              >
                Waiver form
              </Link>
            ))}
        </Flexbox>

        {isApproved && (
          <CancellationPolicyAccordion
            cancellationPolicy={cancellation_policy}
          />
        )}
      </Box>

      {isMounted && (
        <ImageGalleryDialog
          open={open}
          setOuterSlide={setSlide}
          startIndex={slide}
          onClose={() => hideModal()}
          items={elements || []}
        />
      )}
    </Flexbox>
  )
}

export default ItineraryCard
