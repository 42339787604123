import React, { FC, useEffect, useMemo } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'
import { Container, Paper } from '@material-ui/core'

import withPremium from '~/containers/withPremium'
import FullWidthMobile from '~/containers/FullWidthMobile'
import { Title, Link, Text, Loader } from '~/components/shared'
import { ItineraryCard } from '~/components/cards'
import SpecialistCard from '../components/SpecialistCard'
import RequestActivityForm from './components/RequestActivityForm'

import { getParentItineraryById } from '~/state/modules/itineraries'
import { successItem } from '~/state/modules/itineraries/parents/parentsItineraries.slice'
import ActivitiesService from '~/services/ActivitiesService'
import { sortByDate } from '~/utils/functions'

import { STATUSES } from '~/constants/statusesItinerary'
import ROUTES from '~/constants/routes'
import { AppState } from '~/state/store'

import { ReactComponent as BackIcon } from '~/assets/icons/back-icon.svg'

import useItineraryCommentStyles from './itinerary-comment.styles'

type ReduxProps = {
  data: Partial<Itinerary>
  loading: boolean
  loaded: boolean
}

type dispatchProps = {
  getParentItineraryById: (id: string) => void
  successItineraryItem: (itinery: {}) => void
}

type AllProps = ReduxProps &
  dispatchProps &
  RouteComponentProps<{
    id: string
  }>

const ItineraryComment: FC<AllProps> = ({
  match: { params },

  loading,
  loaded,
  data,

  getParentItineraryById,
  successItineraryItem
}) => {
  const s = useItineraryCommentStyles()

  useEffect(() => {
    if (params?.id) {
      getParentItineraryById(params?.id)
    }
  }, [])

  const activitiesData = useMemo(
    () =>
      ActivitiesService.generateParentActivities(
        sortByDate(data?.activities, 'start_date')
      ),
    [data]
  )

  if (
    loaded &&
    ![STATUSES.review, STATUSES.alternate_request].some(
      (status) => status === data?.status
    )
  ) {
    successItineraryItem({})

    return <Redirect to={ROUTES.itineraries} />
  }

  return (
    <FullWidthMobile>
      <Container maxWidth="lg" disableGutters>
        <Paper className={s.wrapper}>
          <Link
            to={ROUTES.itineraries}
            underline="hover"
            className={s.backButton}
          >
            <BackIcon />
            Back to Itineraries
          </Link>

          <Title mb={4}>Add a comment about itinerary</Title>

          {loading ? (
            <Loader />
          ) : loaded ? (
            <>
              <Text variant="h5" fontWeight={600} mb={2}>
                {data?.questionnaire?.fields?.child_first_name}&apos;s Itinerary
              </Text>

              {data?.specialist && !!activitiesData?.length && (
                <SpecialistCard message={data?.message} {...data?.specialist} />
              )}

              {activitiesData?.length ? (
                activitiesData.map((activity, idx) => (
                  <ItineraryCard
                    key={activity?.id}
                    isLast={idx === activitiesData.length - 1}
                    {...activity}
                  />
                ))
              ) : (
                <Text mb={5} flex={1}>
                  No activities added yet.
                </Text>
              )}

              <RequestActivityForm />
            </>
          ) : (
            <Text px={3}>No results</Text>
          )}
        </Paper>
      </Container>
    </FullWidthMobile>
  )
}

const mapStateToProps = ({
  itineraries: {
    parents: { loading, loaded, item }
  }
}: AppState): ReduxProps => {
  return {
    loading,
    loaded,
    data: item
  }
}

const withConnect = connect(mapStateToProps, {
  getParentItineraryById,
  successItineraryItem: successItem
})

export default compose<FC>(withPremium(), withConnect)(ItineraryComment)
