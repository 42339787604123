const availableSeatsOption: Option[] = [
  {
    value: 'Yes',
    label: 'Yes'
  },
  {
    value: 'No',
    label: 'No'
  }
]

export default availableSeatsOption
