import React, { FC, useState } from 'react'
import {
  Card,
  CardContent,
  createStyles,
  IconButton,
  makeStyles
} from '@material-ui/core'

import { Text } from '~/components/shared'

import { ReactComponent as Close } from '~/assets/icons/cross.svg'

const useStyles = makeStyles(
  ({ spacing, palette }) =>
    createStyles({
      card: {
        boxShadow: 'none',
        backgroundColor: palette.grey[100]
      },

      cardContent: {
        position: 'relative',
        padding: spacing(1, 2.8, 1, 1)
      },

      title: {
        marginBottom: spacing(0.5),
        fontWeight: 600,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      },

      duration: {
        color: palette.text.hint
      },

      btnClose: {
        position: 'absolute',
        padding: 0,
        top: 5,
        right: 5,
        width: 18,
        height: 18
      }
    }),
  {
    name: 'ItinerarySearchCard'
  }
)

type ItinerarySearchCardProps = {
  title: string
  dates: string
  duration: string
  onDelete: () => void
}

const ItinerarySearchCard: FC<ItinerarySearchCardProps> = ({
  title,
  dates,
  duration,

  onDelete
}) => {
  const s = useStyles()

  const [disabled, setDisabled] = useState(false)

  return (
    <Card className={s.card}>
      <CardContent className={s.cardContent}>
        <Text className={s.title}>{title}</Text>
        <Text variant="body2" mb={0.5}>
          {dates}
        </Text>
        <Text variant="body2" className={s.duration}>
          ({duration})
        </Text>

        <IconButton
          className={s.btnClose}
          onClick={(): void => {
            setDisabled(true)
            onDelete()
          }}
          disabled={disabled}
        >
          <Close />
        </IconButton>
      </CardContent>
    </Card>
  )
}

export default ItinerarySearchCard
