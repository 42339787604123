import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { Button, makeStyles, createStyles } from '@material-ui/core'

import { Text, Flexbox } from '~/components/shared'

import ROUTES from '~/constants/routes'

const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      flexDirection: 'column',
      alignItems: 'center',
      margin: '50px 0',

      '& h2': {
        textAlign: 'center',
        marginBottom: 20
      },

      [theme.breakpoints.up('md')]: {
        margin: '100px 0'
      }
    }
  })
)

const Error: FC = () => {
  const cn = useStyles()

  return (
    <Flexbox className={cn.box}>
      <Text variant="h2">Oops! There’s an error</Text>

      <Button
        component={Link}
        to={ROUTES.home}
        variant="contained"
        size="large"
        color="primary"
      >
        Go to homepage
      </Button>
    </Flexbox>
  )
}

export default Error
