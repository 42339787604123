import { createStyles, makeStyles, Switch, Theme } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

export const CustomSwitch = withStyles(({ palette }: Theme) => ({
  root: {
    width: 75,
    height: 45,
    overflow: 'inherit'
  },

  switchBase: {
    top: -2,
    color: palette.secondary.light,

    '&$checked': {
      color: palette.secondary.light,
      transform: 'translateX(28px)'
    },

    '&$checked + $track': {
      backgroundColor: palette.grey[100],
      opacity: 1
    }
  },

  thumb: {
    width: 30,
    height: 30
  },

  checked: {},

  track: {
    borderRadius: 21,
    backgroundColor: palette.grey[100],
    border: `2px solid ${palette.secondary.main}`
  }
}))(Switch)

const usePaymentPlanCardStyles = makeStyles(
  (theme) =>
    createStyles({
      card: {
        border: `1px solid ${theme.palette.text.disabled}`,
        boxShadow: 'none',
        minHeight: '546px',
        height: '100%',

        '&.isPremium': {
          border: `2px solid ${theme.palette.primary.main}`
        },

        '& svg': {
          minWidth: 25
        }
      },

      cardContent: {
        padding: theme.spacing(3, 1.5),
        height: '100%'
      },

      title: {
        fontSize: 48,
        marginBottom: theme.spacing(2),

        '&.withTopPadding': {
          paddingTop: 50
        }
      },

      subtitleWrapper: {
        backgroundColor: theme.palette.background.default,
        borderRadius: '10px',
        padding: theme.spacing(1, 1.5),
        marginBottom: theme.spacing(2)
      },

      switchText: {
        textTransform: 'capitalize',

        '@media (max-width: 650px) and (min-width: 600px)': {
          fontSize: 15
        }
      }
    }),
  {
    name: 'PaymentPlan'
  }
)

export default usePaymentPlanCardStyles
