import { Box, Grid, makeStyles, Theme, useMediaQuery } from '@material-ui/core'
import React from 'react'
import { Field } from 'redux-form'

import {
  AutocompleteField,
  NumberField,
  SliderField,
  TextField
} from '~/components/fields'
import { Flexbox, FormControl } from '~/components/shared'
import FormControlWithTooltip from '~/components/shared/FormControlWithTooltip'
import campTypesOptions from '~/data/campTypesOptions'
import gradeLevelOptions from '~/data/gradeLevelOptions'
import { DateRangeField } from '~/pages/search/components/filters'

import DescriptionLabel from './DescriptionLabel'
import TimePickerField from '../TimePickerField'

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    display: 'none'
  },

  info: {
    width: '230px',
    minWidth: '230px',
    borderRadius: 5,
    backgroundColor: theme.palette.text.disabled
  },

  slider: {
    marginTop: 11
  }
}))

interface ActivityFormTemplateProps {
  interestsOption: Option[]
  generateSkillDevelopment: InterestOption[]
  isDuplicate?: boolean
  isEditDuplicate?: boolean
}

const ActivityFormTemplate: React.FC<ActivityFormTemplateProps> = ({
  interestsOption,
  generateSkillDevelopment,
  isDuplicate,
  isEditDuplicate
}) => {
  const s = useStyles()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))

  const disableInterests =
    !interestsOption.length || isDuplicate || isEditDuplicate

  const disableDuplicateActivity = isDuplicate || isEditDuplicate

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <FormControl label="Name" required>
          <Field
            id="name"
            disabled={disableDuplicateActivity}
            name="name"
            placeholder="Name"
            component={TextField}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl label="Activity URL" required>
          <Field
            id="url"
            name="url"
            placeholder="Activity URL"
            autoComplete="url"
            component={TextField}
            normalize={(value): string => value?.toLowerCase()}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl label="Price" required>
          <Field
            id="price"
            name="price"
            placeholder="Price"
            autoComplete="price"
            component={NumberField}
            inputProps={{ inputMode: 'numeric', allowNegative: false }}
            prefix="$"
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl label="Camp type" required>
          <Field
            id="camp_type"
            name="camp_type"
            placeholder="Camp type"
            autoComplete="camp_type"
            disabled={disableDuplicateActivity}
            component={AutocompleteField}
            options={campTypesOptions}
            onChangeKey="value"
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl label={<DescriptionLabel />}>
          <Field
            id="description"
            name="description"
            placeholder="Description"
            disabled={disableDuplicateActivity}
            autoComplete="description"
            component={TextField}
            multiline
            rows={5}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl label="Other info">
          <Field
            id="other_info"
            name="other_info"
            placeholder="Other info"
            disabled={disableDuplicateActivity}
            autoComplete="other_info"
            component={TextField}
            multiline
            rows={5}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl label="Grade level">
          <Flexbox>
            <Grid item xs={12} style={{ marginRight: 20 }}>
              <Field
                id="grade_level_start"
                name="grade_level_start"
                placeholder="Start level"
                label="Start level"
                disabled={disableDuplicateActivity}
                autoComplete="grade_level_start"
                component={AutocompleteField}
                options={gradeLevelOptions}
                onChangeKey="value"
              />
            </Grid>

            <Grid item xs={12}>
              <Field
                id="grade_level_end"
                name="grade_level_end"
                placeholder="End level"
                disabled={disableDuplicateActivity}
                label="End level"
                autoComplete="grade_level_end"
                component={AutocompleteField}
                options={gradeLevelOptions}
                onChangeKey="value"
              />
            </Grid>
          </Flexbox>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl label="Age level">
          <Field
            id="age_level"
            name="age_level"
            placeholder="Age level"
            autoComplete="age_level"
            component={SliderField}
            className={s.slider}
            disabled={disableDuplicateActivity}
            defaultValue={[4, 4]}
            min={4}
            max={18}
            step={1}
            valueLabelFormat={(v: number): number => v}
            valueLabelDisplay="auto"
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl label="Interests" required>
          <Field
            id="interest_ids"
            name="interest_ids"
            placeholder="Interests"
            options={interestsOption}
            disabled={disableInterests}
            component={AutocompleteField}
            multiple
            onChangeKey="value"
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl label="Skill development" required>
          <Field
            id="skill_development"
            name="skill_development"
            placeholder="Skill development"
            disabled={disableDuplicateActivity}
            component={AutocompleteField}
            options={generateSkillDevelopment || []}
            groupBy={(option: InterestOption): InterestsLabel =>
              option.interestLabel
            }
            multiple
            onChangeKey="value"
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl label="Date" required>
          <Field id="date" name="date" component={DateRangeField} />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl label="Time" required>
          <Flexbox>
            <Box mr={1}>
              <Field
                id="start_time"
                type="start_time"
                name="start_time"
                label="Start time"
                placeholder="Start time"
                component={TimePickerField}
              />
            </Box>

            <Box>
              <Field
                id="end_time"
                type="end_time"
                name="end_time"
                label="End time"
                placeholder="End time"
                component={TimePickerField}
              />
            </Box>
          </Flexbox>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl label="Media link" required>
          <Field
            id="media_link"
            name="media_link"
            placeholder="Media link"
            disabled={disableDuplicateActivity}
            autoComplete="media_link"
            component={TextField}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl label="Media link 2">
          <Field
            id="optional_media_link"
            name="optional_media_link"
            placeholder="Media link 2"
            disabled={disableDuplicateActivity}
            autoComplete="optional_media_link"
            component={TextField}
          />
        </FormControl>
      </Grid>

      {!isMobile && (
        <Grid item xs={12} sm={6}>
          <FormControl label="Total seats">
            <Field
              id="total"
              name="total_seats"
              placeholder="Total seats"
              autoComplete="total"
              component={NumberField}
              inputProps={{ allowNegative: false, decimalScale: 0 }}
            />
          </FormControl>
        </Grid>
      )}

      <Grid item xs={12} sm={6}>
        <FormControlWithTooltip label="Location" required>
          <Field
            id="location"
            name="location"
            disabled
            placeholder="Location"
            autoComplete="location"
            component={TextField}
          />
        </FormControlWithTooltip>
      </Grid>

      {!isMobile && (
        <Grid item xs={12} sm={6}>
          <FormControl label="Reserved seats">
            <Field
              id="reserved"
              name="reserved_seats"
              placeholder="Reserved seats"
              autoComplete="reserved"
              component={NumberField}
              inputProps={{ allowNegative: false, decimalScale: 0 }}
            />
          </FormControl>
        </Grid>
      )}

      <Grid item xs={12} sm={6}>
        <FormControl label="Fee">
          <Field
            id="fee"
            name="fee"
            placeholder="Fee"
            autoComplete="fee"
            disabled={disableDuplicateActivity}
            component={NumberField}
            inputProps={{ inputMode: 'numeric' }}
            prefix="$"
          />
        </FormControl>
      </Grid>

      {!isMobile && (
        <Grid item xs={12} sm={6}>
          <FormControl label="Available seats">
            <Field
              id="available"
              name="available_seats"
              placeholder="Available seats"
              autoComplete="available"
              component={NumberField}
              inputProps={{ decimalScale: 0 }}
              disabled
            />
          </FormControl>
        </Grid>
      )}

      <Grid item xs={12} sm={6}>
        <FormControl label="Instructor">
          <Field
            id="instructor"
            name="instructor"
            placeholder="Instructor"
            autoComplete="instructor"
            component={TextField}
          />
        </FormControl>
      </Grid>

      {isMobile && (
        <Grid item xs={12} sm={6}>
          <FormControl label="Total seats">
            <Field
              id="total"
              name="total_seats"
              placeholder="Total seats"
              autoComplete="total"
              component={NumberField}
              inputProps={{ allowNegative: false, decimalScale: 0 }}
            />
          </FormControl>
        </Grid>
      )}

      {isMobile && (
        <Grid item xs={12} sm={6}>
          <FormControl label="Reserved seats">
            <Field
              id="reserved"
              name="reserved_seats"
              placeholder="Reserved seats"
              autoComplete="reserved"
              component={NumberField}
              inputProps={{ allowNegative: false, decimalScale: 0 }}
            />
          </FormControl>
        </Grid>
      )}

      {isMobile && (
        <Grid item xs={12} sm={6}>
          <FormControl label="Available seats">
            <Field
              id="available"
              name="available_seats"
              placeholder="Available seats"
              autoComplete="available"
              component={NumberField}
              inputProps={{ decimalScale: 0 }}
              disabled
            />
          </FormControl>
        </Grid>
      )}
    </Grid>
  )
}

export default ActivityFormTemplate
