import React, { FC, useEffect, useMemo } from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { initialize } from 'redux-form'
import { useModal } from 'react-modal-hook'
import { Button, Container, Paper } from '@material-ui/core'
import { Notes } from '@material-ui/icons'

import withGoogleMaps from '~/containers/withGoogleMaps'
import FullWidthMobile from '~/containers/FullWidthMobile'
import { PersonInfoCard } from '~/components/cards'
import { Title, Link, Text, Loader, Flexbox } from '~/components/shared'
import { LeaveNoteDialog } from '~/components/dialogs'
import ActivitiesTable from './components/ActivitiesTable'
import ActivitiesPagination from './components/ActivitiesPagination'

import { getActivityProviderById } from '~/state/modules/activities'
import { getItineraryById } from '~/state/modules/dashboard'
import ActivitiesService from '~/services/ActivitiesService'
import usePagination from '~/hooks/usePagination'

import ROUTES from '~/constants/routes'
import { AppState } from '~/state/store'

import { ReactComponent as BackIcon } from '~/assets/icons/back-icon.svg'

import useItineraryStyles from '../itinerary/itinerary.styles'

type ReduxProps = {
  data: Partial<ActivityProvider>
  loading: boolean
  loaded: boolean
  error: null | boolean

  itinerary: Partial<Itinerary>
  loadingItinerary: boolean
  loadedItinerary: boolean
}

type dispatchProps = {
  getActivityProviderById: (id: string) => void
  getItineraryById: (id: string) => void
  initialize: (form: string, data: any) => void
}

type AllProps = ReduxProps &
  dispatchProps &
  RouteComponentProps<{
    id: string
  }>

const ActivityProvider: FC<AllProps> = ({
  match: { params },
  location,
  history,

  loading,
  loaded,
  data,
  error,

  loadingItinerary,
  loadedItinerary,
  itinerary,

  getActivityProviderById,
  getItineraryById,
  initialize
}) => {
  const s = useItineraryStyles()

  const itineraryId = location.state?.itineraryId

  useEffect(() => {
    if (itineraryId && !loadedItinerary) {
      getItineraryById(itineraryId)
    }
  }, [itineraryId, loadedItinerary])

  useEffect(() => {
    if (params?.id) {
      getActivityProviderById(params?.id)
    }
  }, [])

  const activitiesData = useMemo(
    () =>
      ActivitiesService.generateActivities<Activity>(
        data?.activities || [],
        itinerary?.activities,
        true
      ),
    [data?.activities, itinerary?.activities, loadedItinerary]
  )

  const { countPages, currentPage, handleChange, renderData } = usePagination(
    location?.search,
    activitiesData,
    10
  )

  const [showModal, hideModal] = useModal(
    (props: any) => (
      <LeaveNoteDialog
        {...props}
        onClose={hideModal}
        id={data?.id}
        name={data?.name}
        isProvider
      />
    ),
    [data]
  )

  if (error) {
    return <Redirect to={ROUTES.notFound} />
  }

  return (
    <FullWidthMobile>
      <Container maxWidth="lg" disableGutters>
        <Paper className={s.wrapper}>
          <Link
            to="#"
            underline="hover"
            className={s.backButton}
            onClick={(e: React.MouseEvent<HTMLElement>): void => {
              e.preventDefault()

              // @ts-ignore
              history.goBack()
            }}
          >
            <BackIcon />
            Back to Search
          </Link>

          {loading || (itineraryId ? loadingItinerary : false) ? (
            <Loader />
          ) : loaded ? (
            <>
              <Flexbox className={s.titleWrapper}>
                <Title variant="h4" textAlign="start">
                  {data?.name}
                </Title>

                <Button
                  data-cy="note-button"
                  startIcon={<Notes />}
                  color="primary"
                  variant="outlined"
                  onClick={(): void => {
                    initialize('LeaveNote_Form', {
                      specialist_notes: data?.specialist_notes || ''
                    })

                    showModal()
                  }}
                >
                  Note{!!data?.specialist_notes && '*'}
                </Button>
              </Flexbox>

              <PersonInfoCard
                data={ActivitiesService.generateActivityProviderData(data)}
              />

              <Title variant="h5" mt={4} mb={3} textAlign="start">
                Activities
              </Title>

              <ActivitiesTable data={renderData} itineraryId={itineraryId} />

              <Flexbox justifyContent="center" mt={3}>
                <ActivitiesPagination
                  count={countPages}
                  activePage={currentPage}
                  onChange={handleChange}
                  link={`${ROUTES.activityProvider}/${params?.id}`}
                  locationState={location.state}
                />
              </Flexbox>
            </>
          ) : (
            <Text px={3}>No results</Text>
          )}
        </Paper>
      </Container>
    </FullWidthMobile>
  )
}

const mapStateToProps = ({
  activities: { loading, loaded, activity_provider, error },
  dashboard
}: AppState): ReduxProps => {
  return {
    loading,
    loaded,
    data: activity_provider,
    error,

    loadingItinerary: dashboard.loading,
    loadedItinerary: dashboard.loaded,
    itinerary: dashboard.item
  }
}

const withConnect = connect(mapStateToProps, {
  getActivityProviderById,
  getItineraryById,
  initialize
})

export default compose<React.FC>(
  withGoogleMaps(),
  withConnect
)(ActivityProvider)
