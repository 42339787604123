import React from 'react'
import { Dialog, DialogContent } from '@material-ui/core'

import DialogTitle from './DialogTitle'

type InfoDialogProps = {
  open?: boolean
  onClose: () => void
  title: string
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

const InfoDialog: React.FC<InfoDialogProps> = ({
  open,
  onClose,
  children,
  title,
  maxWidth = 'lg',
  ...rest
}) => {
  return (
    <Dialog
      {...rest}
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
    >
      <DialogTitle onClose={onClose}>{title}</DialogTitle>

      <DialogContent>{children}</DialogContent>
    </Dialog>
  )
}

export default InfoDialog
