import { INTERESTS, INTERESTS_LABEL } from '~/constants/interests'

const interestsOptions: InterestOption[] = [
  // Academic Learning
  {
    value: 'Coding',
    label: 'Coding',
    interest: INTERESTS.academic,
    interestLabel: INTERESTS_LABEL.academic
  },
  {
    value: 'Digital Art',
    label: 'Digital Art',
    interest: INTERESTS.academic,
    interestLabel: INTERESTS_LABEL.academic
  },
  {
    value: 'Foreign language',
    label: 'Foreign language',
    interest: INTERESTS.academic,
    interestLabel: INTERESTS_LABEL.academic
  },
  {
    value: 'History',
    label: 'History',
    interest: INTERESTS.academic,
    interestLabel: INTERESTS_LABEL.academic
  },
  {
    value: 'Math',
    label: 'Math',
    interest: INTERESTS.academic,
    interestLabel: INTERESTS_LABEL.academic
  },
  {
    value: 'Robotics',
    label: 'Robotics',
    interest: INTERESTS.academic,
    interestLabel: INTERESTS_LABEL.academic
  },
  {
    value: 'Science',
    label: 'Science',
    interest: INTERESTS.academic,
    interestLabel: INTERESTS_LABEL.academic
  },
  {
    value: 'Space',
    label: 'Space',
    interest: INTERESTS.academic,
    interestLabel: INTERESTS_LABEL.academic
  },
  {
    value: 'Writing',
    label: 'Writing',
    interest: INTERESTS.academic,
    interestLabel: INTERESTS_LABEL.academic
  },

  // Dance
  {
    value: 'Ballet',
    label: 'Ballet',
    interest: INTERESTS.dance,
    interestLabel: INTERESTS_LABEL.dance
  },

  {
    value: 'Clogging',
    label: 'Clogging',
    interest: INTERESTS.dance,
    interestLabel: INTERESTS_LABEL.dance
  },
  {
    value: 'Contemporary',
    label: 'Contemporary',
    interest: INTERESTS.dance,
    interestLabel: INTERESTS_LABEL.dance
  },
  {
    value: 'Hip Hop',
    label: 'Hip Hop',
    interest: INTERESTS.dance,
    interestLabel: INTERESTS_LABEL.dance
  },
  {
    value: 'Tap',
    label: 'Tap',
    interest: INTERESTS.dance,
    interestLabel: INTERESTS_LABEL.dance
  },

  // Sports
  {
    value: 'Baseball',
    label: 'Baseball',
    interest: INTERESTS.sport,
    interestLabel: INTERESTS_LABEL.sport
  },
  {
    value: 'Basketball',
    label: 'Basketball',
    interest: INTERESTS.sport,
    interestLabel: INTERESTS_LABEL.sport
  },
  {
    value: 'Cheerleading',
    label: 'Cheerleading',
    interest: INTERESTS.sport,
    interestLabel: INTERESTS_LABEL.sport
  },
  {
    value: 'Cricket',
    label: 'Cricket',
    interest: INTERESTS.sport,
    interestLabel: INTERESTS_LABEL.sport
  },
  {
    value: 'Fencing',
    label: 'Fencing',
    interest: INTERESTS.sport,
    interestLabel: INTERESTS_LABEL.sport
  },
  {
    value: 'Football',
    label: 'Football',
    interest: INTERESTS.sport,
    interestLabel: INTERESTS_LABEL.sport
  },
  {
    value: 'Golf',
    label: 'Golf',
    interest: INTERESTS.sport,
    interestLabel: INTERESTS_LABEL.sport
  },
  {
    value: 'Gymnastics',
    label: 'Gymnastics',
    interest: INTERESTS.sport,
    interestLabel: INTERESTS_LABEL.sport
  },
  {
    value: 'Lacrosse',
    label: 'Lacrosse',
    interest: INTERESTS.sport,
    interestLabel: INTERESTS_LABEL.sport
  },
  {
    value: 'Martial Arts',
    label: 'Martial Arts',
    interest: INTERESTS.sport,
    interestLabel: INTERESTS_LABEL.sport
  },
  {
    value: 'Soccer',
    label: 'Soccer',
    interest: INTERESTS.sport,
    interestLabel: INTERESTS_LABEL.sport
  },
  {
    value: 'Swimming',
    label: 'Swimming',
    interest: INTERESTS.sport,
    interestLabel: INTERESTS_LABEL.sport
  },
  {
    value: 'Tennis',
    label: 'Tennis',
    interest: INTERESTS.sport,
    interestLabel: INTERESTS_LABEL.sport
  },
  {
    value: 'Volleyball',
    label: 'Volleyball',
    interest: INTERESTS.sport,
    interestLabel: INTERESTS_LABEL.sport
  },
  {
    value: 'eGames',
    label: 'eGames',
    interest: INTERESTS.sport,
    interestLabel: INTERESTS_LABEL.sport
  },

  // Arts and Crafts
  {
    value: 'Beads',
    label: 'Beads',
    interest: INTERESTS.art,
    interestLabel: INTERESTS_LABEL.art
  },
  {
    value: 'Drawing',
    label: 'Drawing',
    interest: INTERESTS.art,
    interestLabel: INTERESTS_LABEL.art
  },
  {
    value: 'Jewelry',
    label: 'Jewelry',
    interest: INTERESTS.art,
    interestLabel: INTERESTS_LABEL.art
  },
  {
    value: 'Painting',
    label: 'Painting',
    interest: INTERESTS.art,
    interestLabel: INTERESTS_LABEL.art
  },
  {
    value: '',
    label: '',
    interest: INTERESTS.art,
    interestLabel: INTERESTS_LABEL.art
  },
  {
    value: 'Papercraft',
    label: 'Papercraft',
    interest: INTERESTS.art,
    interestLabel: INTERESTS_LABEL.art
  },
  {
    value: 'Photography',
    label: 'Photography',
    interest: INTERESTS.art,
    interestLabel: INTERESTS_LABEL.art
  },
  {
    value: 'Pottery',
    label: 'Pottery',
    interest: INTERESTS.art,
    interestLabel: INTERESTS_LABEL.art
  },
  {
    value: 'Sewing',
    label: 'Sewing',
    interest: INTERESTS.art,
    interestLabel: INTERESTS_LABEL.art
  },
  {
    value: 'Woodworking',
    label: 'Woodworking',
    interest: INTERESTS.art,
    interestLabel: INTERESTS_LABEL.art
  },

  // Music
  {
    value: 'Brass',
    label: 'Brass',
    interest: INTERESTS.music,
    interestLabel: INTERESTS_LABEL.music
  },
  {
    value: 'Genre - Classical',
    label: 'Genre - Classical',
    interest: INTERESTS.music,
    interestLabel: INTERESTS_LABEL.music
  },
  {
    value: 'Genre - Contemporary',
    label: 'Genre - Contemporary',
    interest: INTERESTS.music,
    interestLabel: INTERESTS_LABEL.music
  },
  {
    value: 'Genre - Jazz',
    label: 'Genre - Jazz',
    interest: INTERESTS.music,
    interestLabel: INTERESTS_LABEL.music
  },
  {
    value: 'Guitar',
    label: 'Guitar',
    interest: INTERESTS.music,
    interestLabel: INTERESTS_LABEL.music
  },
  {
    value: 'Piano',
    label: 'Piano',
    interest: INTERESTS.music,
    interestLabel: INTERESTS_LABEL.music
  },
  {
    value: 'Strings',
    label: 'Strings',
    interest: INTERESTS.music,
    interestLabel: INTERESTS_LABEL.music
  },
  {
    value: 'Vocals',
    label: 'Vocals',
    interest: INTERESTS.music,
    interestLabel: INTERESTS_LABEL.music
  },
  {
    value: 'Woodwind',
    label: 'Woodwind',
    interest: INTERESTS.music,
    interestLabel: INTERESTS_LABEL.music
  },

  // Theater
  {
    value: 'Acting',
    label: 'Acting',
    interest: INTERESTS.theater,
    interestLabel: INTERESTS_LABEL.theater
  },
  {
    value: 'Circus / Aerial',
    label: 'Circus / Aerial',
    interest: INTERESTS.theater,
    interestLabel: INTERESTS_LABEL.theater
  },
  {
    value: 'Comedy / Improv',
    label: 'Comedy / Improv',
    interest: INTERESTS.theater,
    interestLabel: INTERESTS_LABEL.theater
  },
  {
    value: 'Costume / set design',
    label: 'Costume / set design',
    interest: INTERESTS.theater,
    interestLabel: INTERESTS_LABEL.theater
  },
  {
    value: 'Musical/Broadway',
    label: 'Musical/Broadway',
    interest: INTERESTS.theater,
    interestLabel: INTERESTS_LABEL.theater
  },
  {
    value: 'Puppeteering',
    label: 'Puppeteering',
    interest: INTERESTS.theater,
    interestLabel: INTERESTS_LABEL.theater
  },

  // Outdoor activities
  {
    value: 'Archery',
    label: 'Archery',
    interest: INTERESTS.outdoor,
    interestLabel: INTERESTS_LABEL.outdoor
  },
  {
    value: 'Boating/Sailing',
    label: 'Boating/Sailing',
    interest: INTERESTS.outdoor,
    interestLabel: INTERESTS_LABEL.outdoor
  },
  {
    value: 'Farming',
    label: 'Farming',
    interest: INTERESTS.outdoor,
    interestLabel: INTERESTS_LABEL.outdoor
  },
  {
    value: 'Fishing',
    label: 'Fishing',
    interest: INTERESTS.outdoor,
    interestLabel: INTERESTS_LABEL.outdoor
  },
  {
    value: 'Gardening',
    label: 'Gardening',
    interest: INTERESTS.outdoor,
    interestLabel: INTERESTS_LABEL.outdoor
  },
  {
    value: 'Hiking',
    label: 'Hiking',
    interest: INTERESTS.outdoor,
    interestLabel: INTERESTS_LABEL.outdoor
  },
  {
    value: 'Horseback Riding',
    label: 'Horseback Riding',
    interest: INTERESTS.outdoor,
    interestLabel: INTERESTS_LABEL.outdoor
  },
  {
    value: 'Hunting',
    label: 'Hunting',
    interest: INTERESTS.outdoor,
    interestLabel: INTERESTS_LABEL.outdoor
  },
  {
    value: 'Rock Climbing',
    label: 'Rock Climbing',
    interest: INTERESTS.outdoor,
    interestLabel: INTERESTS_LABEL.outdoor
  },
  {
    value: 'Surfing',
    label: 'Surfing',
    interest: INTERESTS.outdoor,
    interestLabel: INTERESTS_LABEL.outdoor
  },
  {
    value: 'Swimming',
    label: 'Swimming',
    interest: INTERESTS.outdoor,
    interestLabel: INTERESTS_LABEL.outdoor
  },
  {
    value: 'White Water Rafting',
    label: 'White Water Rafting',
    interest: INTERESTS.outdoor,
    interestLabel: INTERESTS_LABEL.outdoor
  }
]

export default interestsOptions
