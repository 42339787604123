import { makeStyles, createStyles } from '@material-ui/core'

const useHelpBlockStyles = makeStyles(
  (theme) =>
    createStyles({
      carousel: {
        '& .slick-dots': {
          bottom: -30,
          margin: '15px 0 0 0',

          '& li': {
            width: 25,
            height: 5,

            '& button': {
              width: 25,
              height: 5,
              padding: 0,

              '&:before': {
                content: '""',
                opacity: 1,
                backgroundColor: theme.palette.text.disabled,
                width: 25,
                height: 5,
                borderRadius: 3
              }
            },

            '&.slick-active button:before': {
              backgroundColor: theme.palette.primary.main
            }
          }
        }
      },

      imageWrapper: {
        display: 'flex !important',
        justifyContent: 'center',
        alignItems: 'center'
      },

      button: {
        display: 'flex !important',
        alignItems: 'center',
        justifyContent: 'center',
        height: 45,
        width: 45,
        boxShadow: '0px 4px 8px rgba(27, 27, 27, 0.1)',
        borderRadius: '50%',
        background: `${theme.palette.common.white} !important`,
        zIndex: 1,
        top: '35%',

        '& img': {
          height: 11
        },

        '&.slick-prev': {
          left: 0
        },

        '&.slick-next': {
          right: 0
        },

        '&:before': {
          display: 'none'
        }
      }
    }),
  {
    name: 'HelpBlock'
  }
)

export default useHelpBlockStyles
