import React, { FC } from 'react'
import ReactPlayer from 'react-player'
import { Box } from '@material-ui/core'

import useVideoBlockStyles from './VideoBlock.styles'

const VideoBlock: FC = () => {
  const s = useVideoBlockStyles()

  return (
    <Box className={s.root}>
      <Box className={s.container}>
        <ReactPlayer
          className={s.player}
          url="https://youtu.be/auWZVpB-2zc?si=LIsVMIyQ__l6UY5P"
          controls={true}
          playing={true}
          loop={true}
          muted={true}
          playsinline={true}
        />
      </Box>
    </Box>
  )
}

export default VideoBlock
