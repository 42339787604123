import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { Button, makeStyles, createStyles, Theme } from '@material-ui/core'

import { Text, Flexbox, Title } from '~/components/shared'

import ROUTES from '~/constants/routes'

import NotFoundImage from '~/assets/images/404.png'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      flexDirection: 'column',
      alignItems: 'center',
      margin: theme.spacing(7, 0)
    },

    image: {
      maxWidth: 300,
      maxHeight: 288,
      marginBottom: theme.spacing(2)
    }
  })
)

const NotFound: FC = () => {
  const s = useStyles()

  return (
    <Flexbox className={s.box}>
      <img src={NotFoundImage} className={s.image} alt="page-not-found" />

      <Title mb={1.5}>Sorry, page not found</Title>

      <Text color="textSecondary" mb={3}>
        We couldn&apos;t find the page you are looking for
      </Text>

      <Button
        component={Link}
        to={ROUTES.home}
        variant="contained"
        size="large"
        color="primary"
      >
        Go to homepage
      </Button>
    </Flexbox>
  )
}

export default NotFound
