import React, { FC } from 'react'
import { compose } from 'redux'
import {
  InjectedFormProps,
  Form,
  Field,
  reduxForm,
  SubmissionError
} from 'redux-form'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { Grid, Box } from '@material-ui/core'

import { TextField } from '~/components/fields'
import { ExtendedButton, Flexbox, FormControl } from '~/components/shared'

import { updateItinerary } from '~/state/modules/dashboard'

type Props = {
  id: string
  message: string
}

const MessageForm: FC<Props & InjectedFormProps> = ({
  submitting,
  dirty,
  handleSubmit
}) => {
  return (
    <Box
      component={Form}
      display="flex"
      flexDirection="column"
      onSubmit={handleSubmit}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl label="Message for the parent">
            <Field
              data-cy="message-form-field"
              name="message"
              placeholder="Message"
              component={TextField}
              rowsMax={10}
              rows={3}
              multiline
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Flexbox justifyContent="flex-end">
            <ExtendedButton
              data-cy="message-form-button"
              variant="outlined"
              color="primary"
              type="submit"
              loading={submitting}
              disabled={!dirty}
            >
              Send a message
            </ExtendedButton>
          </Flexbox>
        </Grid>
      </Grid>
    </Box>
  )
}

const mapStateToProps = (_state, { message }: Props): any => {
  return {
    initialValues: {
      message: message || ''
    }
  }
}

const withConnect = connect(mapStateToProps)

const withForm = reduxForm<MessageItineraryInput, Props>({
  form: 'Message_Itinerary_Form',
  enableReinitialize: true,
  onSubmit: async (values, dispatch, { id }) => {
    try {
      await dispatch<any>(updateItinerary(id, values))
    } catch (error) {
      throw new SubmissionError(error.errors)
    }
  },
  onSubmitSuccess: () => {
    toastr.success('Itinerary message', 'The message was successfully sent!')
  }
})

export default compose<React.FC<Props>>(withConnect, withForm)(MessageForm)
