import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Box, Paper, Tab, Tabs } from '@material-ui/core'
import cn from 'classnames'

import Premium from '~/containers/Premium'
import { isPremiumPaid, isShare, userPackagesType } from '~/state/modules/user'

import ROUTES from '~/constants/routes'

import useProfileStyles from './profile.styles'

export const ProfileTabsArray: Option[] = [
  {
    label: 'Profile',
    value: ROUTES.profile
  },
  {
    label: 'Calendar',
    value: ROUTES.calendar
  },
  {
    label: 'Itineraries',
    value: ROUTES.itineraries
  },
  {
    label: 'Share with me',
    value: ROUTES.itinerariesFriends
  }
]

const ProfileTemplate: FC = ({ children }) => {
  const s = useProfileStyles()
  const h = useHistory()

  const [activeTab, setActiveTab] = useState<string>(h.location.pathname)

  const withShare = useSelector(isShare)
  const premium = useSelector(isPremiumPaid)
  const isPackagesType = useSelector(userPackagesType)

  useEffect(() => {
    window.dispatchEvent(new CustomEvent('resize'))
  }, [])

  const handleChangeTab = useCallback(
    (_event: React.ChangeEvent<{}>, newValue: string): void => {
      setActiveTab(newValue)

      h.push(newValue)
    },
    [h]
  )

  return (
    <Paper className={s.container}>
      <Tabs
        textColor="primary"
        indicatorColor="primary"
        variant="scrollable"
        scrollButtons="on"
        value={activeTab}
        onChange={handleChangeTab}
        classes={{
          root: s.tabs,
          flexContainer: cn(s.tabsContainer, { withShare }),
          indicator: s.indicator,
          scrollable: s.scrollable
        }}
      >
        {ProfileTabsArray.map((tab) => {
          if (tab.value === ROUTES.itinerariesFriends && !withShare) {
            return null
          }

          const TabComponent = (
            <Tab
              data-cy="profile-tabs"
              key={tab.value}
              {...tab}
              classes={{
                root: s.tab,
                selected: s.selected
              }}
            />
          )

          const isPremiumWrap =
            tab.value === ROUTES.calendar && !premium && !isPackagesType

          if (isPremiumWrap) {
            return <Premium key={tab.value}>{TabComponent}</Premium>
          }

          return TabComponent
        })}
      </Tabs>

      <Box className={s.wrapper}>{children}</Box>
    </Paper>
  )
}

export default ProfileTemplate
