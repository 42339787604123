import { makeStyles } from '@material-ui/core'
import AdvantageBg from '~/assets/images/home/advantage-background.png'

export default makeStyles({
  advantageGrid: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    maxWidth: 310
  },
  icon: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 90,
    height: 97,
    backgroundImage: `url(${AdvantageBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%'
  },
  advantage: {
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center'
  }
})
