import { makeStyles, createStyles } from '@material-ui/core'

const useDistanceFilterStyles = makeStyles(
  ({ palette, breakpoints }) =>
    createStyles({
      wrapper: {
        position: 'relative',
        height: '100%'
      },

      btn: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        border: `1px solid ${palette.text.disabled}`,
        padding: '8px 10px 8px 14px',
        borderRadius: 10,
        height: 50,
        '-webkit-tap-highlight-color': 'rgba(255, 255, 255, 0)',

        '& .arrow': {
          color: palette.text.hint
        },

        '&.active': {
          '& .arrow': {
            transform: 'rotate(180deg)'
          }
        }
      },

      text: {
        width: '100%',
        marginRight: 10,
        color: palette.text.secondary
      },

      container: {
        position: 'absolute',
        bottom: 60,
        right: 0,
        zIndex: 101,
        width: '100%',

        [breakpoints.up('md')]: {
          bottom: 'auto',
          top: 54,
          minWidth: 450
        }
      },

      dropdown: {
        background: palette.common.white,
        padding: '8px 16px',
        boxShadow: '5px 5px 15px rgba(35, 35, 35, 0.15)',
        borderRadius: 10
      }
    }),
  {
    name: 'DistanceFilter'
  }
)

export default useDistanceFilterStyles
