import React, { FC, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Button, Drawer, IconButton } from '@material-ui/core'

import Mobile from '~/containers/Mobile'
import { Flexbox, Text } from '~/components/shared'

import { changeHeaderLevel } from '~/state/modules/appLayout/appLayout.slice'

import { ReactComponent as FilterIcon } from '~/assets/icons/filter.svg'
import { ReactComponent as CloseIcon } from '~/assets/icons/close.svg'

import useFiltersDrawerStyles from './FiltersDrawer.style'

type Props = {
  counter: number
}

const FiltersDrawer: FC<Props> = ({ children, counter }) => {
  const dispatch = useDispatch()
  const s = useFiltersDrawerStyles()
  const [open, setOpen] = useState(false)

  const handleClose = useCallback(() => {
    setOpen(false)
    dispatch(changeHeaderLevel(false))
  }, [])

  return (
    <Mobile>
      <>
        <Button
          size="small"
          variant="outlined"
          onClick={(): void => {
            setOpen(true)
            dispatch(changeHeaderLevel(true))
          }}
        >
          <FilterIcon className={s.icon} />

          <Text color="textSecondary" fontWeight={600}>
            Filters
          </Text>
        </Button>

        <Drawer
          anchor="bottom"
          open={open}
          onClose={handleClose}
          classes={{
            paper: s.paper
          }}
        >
          <Flexbox className={s.header}>
            <Text color="textSecondary" variant="subtitle1">
              Filters
            </Text>

            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Flexbox>

          <Box className={s.content}>{children}</Box>

          <Box className={s.footer}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={handleClose}
              fullWidth
            >
              Apply ({counter})
            </Button>
          </Box>
        </Drawer>
      </>
    </Mobile>
  )
}

export default FiltersDrawer
