import React, { FC, memo, useCallback } from 'react'
import { WrappedFieldProps } from 'redux-form'
import PhoneInput from 'react-phone-input-2'
import cx from 'classnames'
import { FormHelperText } from '@material-ui/core'

import { useStyles } from './PhoneField.styles'

type Props = {
  errorMessage: boolean
  country?: string
  required?: boolean
}

const PhoneField: FC<WrappedFieldProps & Props> = ({
  input: { onChange, onBlur, value, ...input },
  meta: { touched, error },
  errorMessage,
  country = 'us',
  ...props
}) => {
  const errorMessageField = (touched && error) || errorMessage

  const classes = useStyles()

  const handleChange = useCallback(
    (v) => {
      onChange(`+${v.replace(/[(,)]|\s/g, '')}`)
    },
    [onChange]
  )

  return (
    <>
      <PhoneInput
        country={country}
        onlyCountries={['us']}
        disableDropdown
        onChange={handleChange}
        value={value}
        containerClass={cx(
          'react-tel-input',
          {
            [classes.error]: errorMessageField
          },
          classes.label
        )}
        inputClass={classes.input}
        inputProps={{
          onBlur: (e: React.ChangeEvent<HTMLInputElement>): void => {
            onBlur(value)
            handleChange((e.target as HTMLInputElement).value.replace('+', ''))
          }
        }}
        {...props}
        {...input}
      />

      {errorMessageField && (
        <FormHelperText error className={classes.errorLabel}>
          {errorMessageField}
        </FormHelperText>
      )}
    </>
  )
}

export default memo(PhoneField)
