import { array, object } from 'yup'

import { email } from './fields'

const InviteFriendSchema = object().shape({
  email,
  activity_ids: array().nullable().required('Activities is required')
})

export default InviteFriendSchema
