import React from 'react'
import { Dialog, DialogContent } from '@material-ui/core'

import { Text } from '../shared'
import DialogTitle from './DialogTitle'

type Props = {
  in?: boolean
  onClose: () => {}
}

const CongratulationDialog: React.FC<Props> = ({
  in: open,
  onClose,
  ...rest
}) => {
  return (
    <Dialog {...rest} fullWidth open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>Payment Successful!</DialogTitle>

      <DialogContent>
        <Text color="textSecondary" textAlign="center" mb={1}>
          Your payment has been processed. The provider will provide more
          information regarding your booking. For cancelation, please see the
          provider&apos;s cancelation process.
        </Text>
      </DialogContent>
    </Dialog>
  )
}

export default CongratulationDialog
