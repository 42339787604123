import React from 'react'

import { ReactComponent as NotebookIcon } from '~/assets/icons/home/notebook.svg'
import { ReactComponent as PeopleIcon } from '~/assets/icons/home/people.svg'
import { ReactComponent as UserIcon } from '~/assets/icons/home/user.svg'
import { ReactComponent as CalendarIcon } from '~/assets/icons/home/calendar.svg'
import { ReactComponent as CartIcon } from '~/assets/icons/home/cart.svg'
import { ReactComponent as ChatIcon } from '~/assets/icons/home/chat.svg'
import { ReactComponent as CircleCheckIcon } from '~/assets/icons/home/circle-check.svg'

const mainAdvantages = [
  {
    icon: <NotebookIcon />,
    label: 'Personalised Itineraries for Your Kids'
  },
  {
    icon: <UserIcon />,
    label: 'MIA - Virtual Concierge'
  },
  {
    icon: <PeopleIcon />,
    label: 'Friends and Family Collaboration'
  },
  {
    icon: <CalendarIcon />,
    label: 'Calendar Planner'
  },
  {
    icon: <CircleCheckIcon />,
    label: 'Easy Booking'
  },
  {
    icon: <CartIcon />,
    label: 'Buy Now, Pay Later'
  },
  {
    icon: <ChatIcon />,
    label: 'Cancellation Assistance'
  }
]

export default mainAdvantages
