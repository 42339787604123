import { makeStyles, createStyles } from '@material-ui/core'

const useProfilePopoverStyles = makeStyles(
  ({ palette }) =>
    createStyles({
      btn: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        minWidth: 0,
        border: `1px solid ${palette.text.disabled}`,
        padding: '8px 10px 8px 14px',
        borderRadius: 10,
        maxWidth: 200,

        '& .arrow': {
          color: palette.text.hint,
          transition: 'transform 0.2s linear'
        },

        '&.active': {
          '& .arrow': {
            transform: 'rotate(180deg)'
          }
        },

        '&.isGradient': {
          borderColor: 'transparent',
          background: 'rgba(255, 255, 255, 0.5)'
        }
      },

      text: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginRight: 10
      },

      container: {
        position: 'absolute',
        top: 43,
        right: 0,
        zIndex: 101,
        paddingTop: 10
      },

      dropdown: {
        background: palette.common.white,
        padding: '7.5px 0',
        boxShadow: '0px 6px 24px rgba(0, 0, 0, 0.08)',
        borderRadius: 6,
        minWidth: 250
      },

      tabItem: {
        position: 'relative',
        padding: 0,
        cursor: 'pointer',

        '& > *': {
          padding: '7.5px 15px',
          width: '100%',
          color: palette.text.secondary,

          '&.active p': {
            fontWeight: 600
          }
        },

        '&:hover': {
          backgroundColor: palette.grey[100],

          '& > *': {
            color: palette.text.primary
          }
        }
      },

      logOut: {
        color: palette.text.hint
      }
    }),
  {
    name: 'ProfilePopover'
  }
)

export default useProfilePopoverStyles
