import React, { FC } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { InjectedFormProps, Form, reduxForm, Field } from 'redux-form'
import { toastr } from 'react-redux-toastr'
import {
  Grid,
  Card,
  CardContent,
  makeStyles,
  createStyles
} from '@material-ui/core'

import FullWidthMobile from '~/containers/FullWidthMobile'
import { PhoneField, TextField } from '~/components/fields'
import { ExtendedButton, Flexbox, FormControl } from '~/components/shared'

import { AppState } from '~/state/store'
import { asyncValidate } from '~/utils/asyncValidate'
import CampDirctorProfileSchema from '~/schemas/camp-director'
import { updateDirector } from '~/state/modules/user'

const useProfileStyles = makeStyles(
  ({ breakpoints }) =>
    createStyles({
      formCard: {
        width: '100%',

        '& button[type="submit"]': {
          minWidth: 115
        },

        [breakpoints.down('xs')]: {
          borderRadius: 'inherit',
          borderWidth: '1px 0 1px 0'
        }
      }
    }),
  {
    name: 'Profile'
  }
)

const DirectorProfileForm: FC<InjectedFormProps> = ({
  handleSubmit,
  submitting,
  dirty
}) => {
  const s = useProfileStyles()

  return (
    <FullWidthMobile>
      <Card variant="outlined" className={s.formCard}>
        <CardContent>
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl label="First name">
                  <Field
                    id="first_name"
                    name="first_name"
                    placeholder="First Name"
                    component={TextField}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl label="Last name">
                  <Field
                    id="last_name"
                    name="last_name"
                    placeholder="Last name"
                    component={TextField}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl label="Email">
                  <Field
                    id="email"
                    name="email"
                    placeholder="Email"
                    autoComplete="email"
                    component={TextField}
                    normalize={(value): string => value?.toLowerCase()}
                    disabled
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl label="Contact phone">
                  <Field
                    id="contact_phone"
                    name="contact_phone"
                    placeholder="Contact phone"
                    component={PhoneField}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Flexbox justifyContent="flex-end">
                  <ExtendedButton
                    data-cy="parent-form-button"
                    type="submit"
                    loading={submitting}
                    disabled={!dirty}
                  >
                    Save changes
                  </ExtendedButton>
                </Flexbox>
              </Grid>
            </Grid>
          </Form>
        </CardContent>
      </Card>
    </FullWidthMobile>
  )
}

const mapStateToProps = (state: AppState): any => {
  const { first_name, last_name, email, contact_phone } = state.user

  return {
    initialValues: {
      first_name,
      last_name,
      email,
      contact_phone
    }
  }
}

const withConnect = connect(mapStateToProps)

const withForm = reduxForm<any>({
  form: 'Director_Profile_Form',
  enableReinitialize: true,
  shouldAsyncValidate: () => true,
  asyncValidate: asyncValidate(CampDirctorProfileSchema),
  onSubmit: (values, dispatch) => {
    dispatch(updateDirector(values))
  },
  onSubmitSuccess: () => {
    toastr.success('Success', 'Your profile successfully updated.')
  }
})

export default compose<React.FC>(withConnect, withForm)(DirectorProfileForm)
