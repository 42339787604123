import { ZOHO } from '~/constants/zoho'

/**
 * Generate iframe for Zoho form
 */
export const generateIframe = (): void => {
  const f = document.createElement('iframe')

  f.src = `https://forms.planmykids.com/know/form/ContactForm/formperma/${ZOHO.contactUs.key}?zf_rszfm=1`
  f.style.border = 'none'
  f.style.height = '546px'
  f.style.width = '90%'
  f.style.transition = 'all 0.5s ease' // No I18N

  const d = document.getElementById(ZOHO.contactUs.divId())

  d?.appendChild(f)

  if (d) {
    window.addEventListener('message', function (event) {
      if (event.origin === 'https://forms.planmykids.com') {
        const zf_ifrm_data = event.data?.split('|')
        const zf_perma = zf_ifrm_data[0]
        const zf_ifrm_ht_nw = parseInt(zf_ifrm_data[1], 10) + 15 + 'px'
        const iframe = document
          .getElementById(ZOHO.contactUs.divId())
          ?.getElementsByTagName('iframe')[0]

        if (
          iframe?.src.indexOf('formperma') > 0 &&
          iframe?.src.indexOf(zf_perma) > 0
        ) {
          const prevIframeHeight = iframe.style.height

          if (prevIframeHeight !== zf_ifrm_ht_nw) {
            iframe.style.height = zf_ifrm_ht_nw
          }
        }
      }
    })
  }
}
