import React, { FC } from 'react'
import { WrappedFieldProps } from 'redux-form'
import {
  InputProps,
  TextField as TextInput,
  TextFieldProps
} from '@material-ui/core'

type Props = {
  displayError?: boolean
  isOptional?: boolean
  helperText?: string
}

type AllProps = WrappedFieldProps & TextFieldProps & InputProps & Props

const TextField: FC<AllProps> = ({
  input,
  meta: { touched, error },
  displayError,
  helperText,
  isOptional,
  ...rest
}) => {
  const errorMessage = (touched && error) || displayError
  const required = isOptional ? false : true

  return (
    <TextInput
      data-cy="text-field"
      variant="outlined"
      error={!!errorMessage}
      helperText={errorMessage || helperText}
      InputLabelProps={{ required }}
      inputProps={{
        'data-required': required,
        ...rest.inputProps
      }}
      fullWidth
      {...input}
      {...rest}
    />
  )
}

export default TextField
