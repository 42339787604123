import { makeStyles, Theme } from '@material-ui/core'
import palette from '~/theme/palette'

interface Props {
  isOwner?: boolean
}

export default makeStyles<Theme, Props>({
  wrapper: ({ isOwner }) => ({
    padding: '8px 0',
    flexDirection: isOwner ? 'row-reverse' : 'row'
  }),

  message: ({ isOwner }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: isOwner ? 'flex-end' : 'flex-start'
  }),

  userInfo: ({ isOwner }) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: isOwner ? 'row-reverse' : 'row',
    marginBottom: '4px'
  }),

  root: ({ isOwner }) => ({
    padding: '7px 15px',
    height: '100%',
    maxWidth: '100%',
    boxShadow: '10px 10px 15px rgba(35, 35, 35, 0.15)',
    backgroundColor: isOwner ? palette.secondary.main : palette.common.white
  }),

  label: ({ isOwner }) => ({
    color: isOwner ? palette.common.white : palette.secondary.main,
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap'
  })
})
