import React from 'react'

import ROUTES from '~/constants/routes'

import { ReactComponent as Instagram } from '~/assets/icons/instagram.svg'
import { ReactComponent as Facebook } from '~/assets/icons/facebook.svg'
import { ReactComponent as Twitter } from '~/assets/icons/twitter.svg'

export const navsParent: NavLinkOption[] = [
  {
    label: 'About us',
    to: ROUTES.aboutUs
  },
  {
    label: 'Contact us',
    to: ROUTES.contactUs
  },
  {
    label: 'Resources',
    to: 'https://blog.planmykids.com',
    outwardLink: true
  },
  {
    label: 'FAQs',
    to: ROUTES.faqs
  }
]

export const navsSpecialist: NavLinkOption[] = [
  {
    label: 'Dashboard',
    to: ROUTES.dashboard
  },
  {
    label: 'Explore Camps',
    to: ROUTES.search
  }
]

export const socialLinks: SocialLinkOption[] = [
  {
    to: 'https://www.instagram.com/planmykids',
    icon: <Instagram />
  },
  {
    to: 'https://www.facebook.com/101190331615393',
    icon: <Facebook />
  },
  {
    to: 'https://www.twitter.com/PlanMyKids',
    icon: <Twitter />
  }
]
