import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box
} from '@material-ui/core'

import { BlankLink, ExtendedButton, Text } from '~/components/shared'

import IntlService from '~/services/IntlService'
import { humanizeDate } from '~/utils/functions'

import ROUTES from '~/constants/routes'
import { ActivitiesTableItem } from '../ActivitiesTable'

import { ReactComponent as ArrowDownIcon } from '~/assets/icons/arrow-down.svg'
import { ReactComponent as Done } from '~/assets/icons/done.svg'

import useActivityAccordionStyles from './ActivityAccordion.styles'

const ActivityAccordion: FC<ActivitiesTableItem & Activity> = ({
  id,
  name,
  url,
  price,
  start_date,
  end_date,
  isAdded,

  isHideButtons,
  isAdding,

  onAddActivity
}) => {
  const s = useActivityAccordionStyles()

  return (
    <Accordion key={id}>
      <AccordionSummary
        expandIcon={<ArrowDownIcon />}
        classes={{
          content: s.content
        }}
      >
        <Text color="textSecondary" className={s.nowrapText}>
          {name}
        </Text>
      </AccordionSummary>

      <AccordionDetails>
        <Box mx={1.5}>
          <Text color="textSecondary" mb={1.5} className={s.nowrapText}>
            Start Date: {humanizeDate(start_date, 'MMM D, YYYY')}
          </Text>
          <Text color="textSecondary" mb={1.5} className={s.nowrapText}>
            End Date: {humanizeDate(end_date, 'MMM D, YYYY')}
          </Text>
          <Text color="textSecondary" mb={1.5} className={s.nowrapText}>
            Link: <BlankLink href={url}>Open url</BlankLink>
          </Text>
          <Text color="textSecondary" mb={2}>
            Price: {price ? IntlService.currencyFormat(price) : '-'}
          </Text>
        </Box>

        <Box className={s.btns}>
          <Button
            component={Link}
            to={`${ROUTES.activity}/${id}`}
            size="small"
            variant="contained"
            color="secondary"
            fullWidth
          >
            View activity
          </Button>

          {!isHideButtons &&
            (isAdded ? (
              <Button
                size="small"
                variant="contained"
                color="primary"
                fullWidth
                style={{ cursor: 'default' }}
              >
                <Done style={{ marginRight: 10 }} />
                Added
              </Button>
            ) : (
              <ExtendedButton
                data-cy="activity-card-itineraries-button"
                size="small"
                variant="outlined"
                color="primary"
                fullWidth
                onClick={(): void => {
                  onAddActivity(id)
                }}
                loading={isAdding === id}
                disabled={isAdding === id}
              >
                Add to itinerarie
              </ExtendedButton>
            ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

export default ActivityAccordion
