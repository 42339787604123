import { makeStyles, createStyles } from '@material-ui/core'

export const useActivityAccordionStyles = makeStyles(
  (theme) =>
    createStyles({
      content: {
        paddingRight: theme.spacing(1),
        overflow: 'hidden'
      },

      nowrapText: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      },

      btns: {
        borderTop: `1px solid ${theme.palette.text.disabled}`,
        padding: theme.spacing(2, 1.5),

        '& > *:not(:last-child)': {
          marginBottom: theme.spacing(1.5)
        }
      }
    }),
  {
    name: 'ActivityAccordion'
  }
)

export default useActivityAccordionStyles
