export const INTERESTS: Record<InterestsKeys, InterestsUnion> = {
  academic: 'academic_interests',
  dance: 'dance_interests',
  sport: 'sport_interests',
  art: 'art_interests',
  music: 'music_interests',
  theater: 'theater_interests',
  outdoor: 'outdoor_interests'
} as const

export const INTERESTS_LABEL: Record<InterestsKeys, InterestsLabel> = {
  academic: 'Academic Learning',
  dance: 'Dance',
  sport: 'Sports',
  art: 'Arts and Crafts',
  music: 'Music',
  theater: 'Theater',
  outdoor: 'Outdoor activities'
} as const
