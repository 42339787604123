import React, { FC } from 'react'
import { Chip as ChipMui, ChipProps } from '@material-ui/core'

import useStyles from './Chip.styles'

const Chip: FC<ChipProps> = (props) => {
  const styles = useStyles()

  return <ChipMui className={styles.chip} color="primary" {...props} />
}

export default Chip
