import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { sortByDate } from '~/utils/functions'
import {
  requestAction,
  successAction,
  failureAction
} from '~/utils/reducerFunctions'

export interface QuestionnairesState {
  item: Partial<Questionnaire>
  data: Itinerary[]
  loading: boolean
  loaded: boolean
  error: any
}

const initialState: QuestionnairesState = {
  data: [],
  item: {},
  loading: false,
  loaded: false,
  error: null
}

const questionnariesSlice = createSlice({
  name: 'questionnaires',
  initialState,
  reducers: {
    request: requestAction,

    success: (state, action: PayloadAction<Questionnaire[]>): void => {
      successAction(state, action, 'data', (state) => {
        const { payload } = action

        const data = sortByDate(payload, 'created_at', true)

        state.data = data
      })
    },

    update: (
      state,
      {
        payload: { questionnaireData, itineraryId }
      }: PayloadAction<{
        questionnaireData: Questionnaire
        itineraryId: string
      }>
    ): void => {
      const questionnairy = state.data.find(({ id }) => id === itineraryId)
        ?.questionnaire

      if (questionnairy) {
        questionnairy.fields = questionnaireData.fields
      }
    },

    failure: failureAction
  }
})

export const { request, success, update, failure } = questionnariesSlice.actions

export default questionnariesSlice.reducer
