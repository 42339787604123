import React, { FC } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { Text } from '~/components/shared'

import { useAccordionsStyles } from '../faq.styles'

const MiaQuestions: FC = () => {
  const s = useAccordionsStyles()

  return (
    <>
      <Accordion className={s.accordion}>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
          classes={{ expandIcon: s.expandIcon, expanded: s.expanded }}
          className={s.summeryAccordoin}
        >
          <Text>Q: What is MIA?</Text>
        </AccordionSummary>

        <AccordionDetails className={s.detailsAccordion}>
          <Text mb={2}>
            MIA is your personal itinerary assistant. MIA uses natural language
            processing (your words) to take direction. MIA then uses information
            she knows and from our platform to answer your questions using
            generative artificial intelligence.
          </Text>
        </AccordionDetails>
      </Accordion>

      <Accordion className={s.accordion}>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
          classes={{ expandIcon: s.expandIcon, expanded: s.expanded }}
          className={s.summeryAccordoin}
        >
          <Text>Q: How does MIA work?</Text>
        </AccordionSummary>

        <AccordionDetails className={s.detailsAccordion}>
          <Text mb={2}>
            MIA uses advanced algorithms and data analysis to provide
            personalized activity recommendations based on the child&apos;s
            interests, age, location, and other preferences shared through the
            questionnaire.
          </Text>
        </AccordionDetails>
      </Accordion>

      <Accordion className={s.accordion}>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
          classes={{ expandIcon: s.expandIcon, expanded: s.expanded }}
          className={s.summeryAccordoin}
        >
          <Text>Q: What can MIA help with?</Text>
        </AccordionSummary>

        <AccordionDetails className={s.detailsAccordion}>
          <Text mb={2}>
            MIA helps parents plan and organize their children&apos;s activities
            and camps. She can provide personalized recommendations based on
            your child&apos;s interests, age, and location. She can also assist
            in creating and managing itineraries, keeping track of important
            dates and activities. Whether you&apos;re looking for sports,
            academics, arts and crafts, or music activities for your child, MIA
            is available to help you find the best options and make the planning
            process easier.
          </Text>
        </AccordionDetails>
      </Accordion>

      <Accordion className={s.accordion}>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
          classes={{ expandIcon: s.expandIcon, expanded: s.expanded }}
          className={s.summeryAccordoin}
        >
          <Text>Q: Can MIA help me with budget constraints?</Text>
        </AccordionSummary>

        <AccordionDetails className={s.detailsAccordion}>
          <Text mb={2}>
            Yes, MIA can take your budget preferences into account and suggest
            activities and camps that align with your specified budget range.
          </Text>
        </AccordionDetails>
      </Accordion>

      <Accordion className={s.accordion}>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
          classes={{ expandIcon: s.expandIcon, expanded: s.expanded }}
          className={s.summeryAccordoin}
        >
          <Text>Q: How accurate are MIA&apos;s recommendations?</Text>
        </AccordionSummary>

        <AccordionDetails className={s.detailsAccordion}>
          <Text mb={2}>
            MIA leverages advanced algorithms and data analysis to provide
            accurate and tailored recommendations. However, it is important to
            note that activity availability and details may change, so it&apos;s
            always recommended to double-check the information.
          </Text>
        </AccordionDetails>
      </Accordion>

      <Accordion className={s.accordion}>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
          classes={{ expandIcon: s.expandIcon, expanded: s.expanded }}
          className={s.summeryAccordoin}
        >
          <Text>
            Q: Can I reformat the structure of your responses to make them
            easier to read?
          </Text>
        </AccordionSummary>

        <AccordionDetails className={s.detailsAccordion}>
          <Text mb={2}>
            Yes, you have the flexibility to reformat the structure of the
            responses provided by MIA. You can make adjustments to the layout
            and organization to make them easier to read and understand. For
            example, you can say “Please provide the activity details in a
            simplified format, including the activity ID, title, description,
            interests, location, start date, end date, and price.”
          </Text>
        </AccordionDetails>
      </Accordion>

      <Accordion className={s.accordion}>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
          classes={{ expandIcon: s.expandIcon, expanded: s.expanded }}
          className={s.summeryAccordoin}
        >
          <Text>Q: Can I edit my itinerary?</Text>
        </AccordionSummary>

        <AccordionDetails className={s.detailsAccordion}>
          <Text mb={2}>
            Yes, simply ask MIA to add or remove an activity and your itinerary
            will be updated.
          </Text>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default MiaQuestions
