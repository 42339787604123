import { makeStyles, createStyles, Theme } from '@material-ui/core'

export const useCalendarPopover = makeStyles<
  Theme,
  {
    backgroundColor: string
  }
>(
  (theme) =>
    createStyles({
      content: {
        width: 350,
        padding: theme.spacing(2),

        [theme.breakpoints.down('xs')]: {
          width: 300
        }
      },

      title: {
        marginRight: theme.spacing(2.5)
      },

      name: {
        width: 'max-content',
        padding: '5px 10px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '300px',
        color: theme.palette.text.secondary,
        border: `1px solid ${theme.palette.text.disabled}`,
        borderRadius: 10,

        [theme.breakpoints.down('xs')]: {
          maxWidth: '250px'
        }
      },

      btnClose: {
        position: 'absolute',
        padding: 0,
        top: 20,
        right: 20,
        width: 18,
        minWidth: 18,
        height: 18,
        minHeight: 18,
        color: theme.palette.text.secondary,

        '& svg': {
          fontSize: 28
        },

        '& > span': {
          height: 20
        }
      },

      listContainer: {
        marginTop: theme.spacing(1.5),
        borderTop: `1px solid ${theme.palette.text.disabled}`
      },

      list: {
        color: theme.palette.text.secondary,

        '& li': {
          padding: 0
        }
      },

      listIcon: {
        minWidth: 15
      },

      decorate: {
        width: 7,
        height: 7,
        borderRadius: '50%',
        backgroundColor: ({ backgroundColor }): string => backgroundColor
      }
    }),
  {
    name: 'CalendarPopover'
  }
)
