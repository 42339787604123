import React from 'react'
import { isEmpty } from 'lodash'
import moment from 'moment'

import { Link, BlankLink } from '~/components/shared'

import {
  capitalize,
  dateFormat,
  deleteKey,
  generateDifferenceDates,
  humanizeDate,
  humanizeTime,
  humanizeTimeFromSeconds,
  parentItineraryActivitiesFormating,
  transformZeroToString
} from '~/utils/functions'
import IntlService from '../IntlService'

import ROUTES from '~/constants/routes'
import { ININERARY_ACTIVITIES_STATUSES } from '~/constants/itineraryActivitiesStatuses'
import { ItineraryCardProps } from '~/components/cards/ItineraryCard/ItineraryCard'

import Ages from '~/assets/icons/search-courses/ages.svg'
import Type from '~/assets/icons/search-courses/type.svg'
import Activity from '~/assets/icons/search-courses/activity.svg'
import Grade from '~/assets/icons/search-courses/grade.svg'
import Ratings from '~/assets/icons/search-courses/ratings.svg'
import Activities from '~/assets/icons/search-courses/activities.svg'
import Development from '~/assets/icons/search-courses/development.svg'
import Prices from '~/assets/icons/search-courses/prices.svg'
import Seats from '~/assets/icons/search-courses/seats.svg'

import { toUpperCaseFirstLetterForEveryWord } from '~/utils/functions'

const formatTime = (time: string): string => {
  if (time) {
    if (moment(time, 'HH:mm:ss A').isValid()) {
      return humanizeTime(time)
    } else {
      return humanizeTimeFromSeconds(time)
    }
  } else {
    return ''
  }
}

const generateCampInfo = (
  start_time: string,
  end_time: string,
  camp_type: string
): { camp_info_title: string; camp_info_time: string } => {
  const isOvernight = camp_type && camp_type?.toLowerCase() === 'overnight'
  const symbol = isOvernight ? '/' : '-'
  const camp_info_title = isOvernight ? 'Check-in / Out:' : 'Daily Times:'

  const camp_info_time =
    start_time && end_time
      ? `${formatTime(start_time)} ${symbol} ${formatTime(end_time)}`
      : 'See camp url for details'

  return {
    camp_info_title,
    camp_info_time
  }
}

export const generateDetails = (
  activity: Activity,
  customInterests = ''
): CourseDetail[] => {
  const {
    camp_type,
    age_level,
    grade_level,
    skill_development,
    price,
    interests,
    total_seats,
    available_seats
  } = activity

  const skillDevelopment = Array.isArray(skill_development)
    ? skill_development.join(', ')
    : skill_development

  const availableSeatsYesOrNo =
    available_seats === 'Yes' || available_seats === 'No'

  const availableSeats = availableSeatsYesOrNo
    ? available_seats
    : `${available_seats} / ${total_seats}`

  let interestsValue = null

  if (interests && Array.isArray(interests)) {
    interestsValue = Object.values(interests)
      .map((interes) => interes.name)
      .join(', ')
  }

  return [
    {
      icon: Ages,
      label: 'Ages',
      value: age_level ? `${age_level} years` : undefined
    },
    {
      icon: Type,
      label: 'Camp type',
      value: camp_type
    },
    {
      icon: Activity,
      label: 'Activity proficiency',
      value: undefined
    },
    {
      icon: Grade,
      label: 'Grade',
      value: grade_level
    },
    {
      icon: Ratings,
      label: 'Ratings',
      value: undefined
    },
    {
      icon: Activities,
      label: 'Interests',
      value: customInterests ? customInterests : interestsValue
    },
    {
      icon: Development,
      label: 'Skill developments',
      value: skillDevelopment
    },
    {
      icon: Prices,
      label: 'Prices',
      value: IntlService.currencyFormat(price)
    },
    {
      icon: Seats,
      label: 'Available seats',
      value: availableSeats
    }
  ]
}

/**
 * @T = ActivityCard | Activity
 */
const generateActivities = <T extends {}>(
  activities: Activity[],
  itineraryActivities: Activity[] = [],
  isNotModifiedActivity?: boolean
): T[] => {
  const data = activities.map((activity) => {
    const isAdded = itineraryActivities.find(
      ({ id: itineraryActivityId }) => itineraryActivityId === activity?.id
    )

    if (isNotModifiedActivity) {
      return {
        ...activity,
        isAdded: !!isAdded
      }
    } else {
      const {
        id,
        camp_type,
        name,
        location,
        start_date,
        end_date,
        activity_provider,
        specialist_notes,
        start_time,
        end_time,
        total_seats,
        available_seats,
        reserved_seats
      } = activity

      const duration =
        start_date && end_date && start_date !== end_date
          ? generateDifferenceDates(start_date, end_date)
          : '1 day'

      const { camp_info_time, camp_info_title } = generateCampInfo(
        start_time,
        end_time,
        camp_type
      )

      return {
        id,
        activityProviderId: activity_provider?.id,
        subtitle: `by ${activity_provider?.name}`,
        title: name || '-',
        location: location || '-',
        date: `${start_date ? humanizeDate(start_date) : ''} - ${
          end_date ? humanizeDate(end_date) : ''
        }`,
        camp_info_title,
        camp_info_time,
        duration,
        details: generateDetails(activity),
        isAdded: !!isAdded,
        note: {
          activity: specialist_notes || '',
          activity_provider: activity_provider?.specialist_notes || ''
        },
        total_seats,
        available_seats,
        reserved_seats
      }
    }
  })

  return (data as unknown) as T[]
}

const generateParentActivities = (
  activities: Activity[]
): ItineraryCardProps[] => {
  const data = activities?.map((activity) => {
    const {
      id,
      name,
      start_date,
      end_date,
      age_level,
      grade_level,
      camp_type,
      price,
      description,
      url,
      media_link,
      provider_name,
      provider_logo,
      location,
      start_time,
      end_time,
      payment_status,
      sku,
      waiver_form_url
    } = activity

    const duration =
      start_date && end_date && start_date !== end_date
        ? generateDifferenceDates(start_date, end_date)
        : '1 day'

    const { camp_info_time, camp_info_title } = generateCampInfo(
      start_time,
      end_time,
      camp_type
    )

    return {
      id,
      subtitle: `by ${provider_name}`,
      title: name,
      img: media_link || provider_logo,
      date: `${start_date ? `${humanizeDate(start_date)}` : ''} - ${
        end_date ? `${humanizeDate(end_date)}` : ''
      }`,
      start_time,
      end_time,
      duration,
      camp_info_title,
      camp_info_time,
      age: age_level ? `${age_level} years` : undefined,
      grade: grade_level,
      type: camp_type,
      prices: IntlService.currencyFormat(price),
      location,
      description,
      url,
      payment_status: toUpperCaseFirstLetterForEveryWord(payment_status),
      sku,
      waiver_form_url
    }
  })

  return data
}

const generateParentItineraryActivities = (
  itinerary_activities: ItineraryActivity[] | Activity
): ItineraryCardProps[] => {
  if (Array.isArray(itinerary_activities)) {
    const data = itinerary_activities?.map((itinerary_activity) =>
      parentItineraryActivitiesFormating({
        ...itinerary_activity?.activity,
        payment_status: itinerary_activity?.payment_status
      })
    )

    return data
  } else {
    const data = [parentItineraryActivitiesFormating(itinerary_activities)]

    return data
  }
}

const generateActivitiesSum = (activities: Activity[] = []): string => {
  const result = activities?.reduce((sum, { price }) => {
    return sum + price
  }, 0)

  return IntlService.currencyFormat(result)
}

const generatePaymentActivitiesSum = (
  itinerary_activities: ItineraryActivity[] = []
): string => {
  const pricesList = itinerary_activities
    ?.map((itinerary_activity) => {
      if (
        itinerary_activity?.payment_status ===
          ININERARY_ACTIVITIES_STATUSES?.bookingAvailable ||
        itinerary_activity?.payment_status ===
          ININERARY_ACTIVITIES_STATUSES?.availableForPayment ||
        itinerary_activity?.payment_status ===
          ININERARY_ACTIVITIES_STATUSES?.paid
      ) {
        const { price } = itinerary_activity?.activity
        return price
      }
    })
    ?.filter(Boolean)

  const result = pricesList?.reduce((sum, price) => {
    return sum + price
  }, 0)

  return IntlService.currencyFormat(result)
}

const withoutActivityFields = [
  'name',
  'url',
  'media_link',
  'price',
  'provider_name',
  'id',
  'activity_provider',
  'start_date',
  'end_date',
  'start_time',
  'end_time',
  'latitude',
  'longitude',
  'provider_logo',
  'optional_media_link'
]

const generateActivityData = (
  activity: Partial<Activity>
): PersonInfoOption[] => {
  const data = []

  const {
    start_date,
    end_date,
    start_time,
    end_time,
    price,
    url,
    provider_name,
    media_link,
    activity_provider,
    optional_media_link
  } = activity

  const activityObject = {
    'Provider name': (
      <>
        {provider_name} (
        <Link to={`${ROUTES.activityProvider}/${activity_provider?.id}`}>
          activity provider link
        </Link>
        )
      </>
    ),
    Url: url ? <BlankLink href={url}>Open url</BlankLink> : '',
    'Media link': media_link ? (
      <BlankLink href={media_link}>Open media link</BlankLink>
    ) : (
      ''
    ),
    'Media link 2': optional_media_link ? (
      <BlankLink href={optional_media_link}>Open media link2</BlankLink>
    ) : (
      ''
    ),
    Price: IntlService.currencyFormat(price),
    'Start date': start_date ? humanizeDate(start_date) : '',
    'End date': end_date ? humanizeDate(end_date) : '',
    'Start time': formatTime(start_time),
    'End time': formatTime(end_time)
  }

  Object.entries(activityObject).forEach(([key, value]) => {
    data.push({
      label: key,
      value
    })
  })

  Object.entries(activity).forEach(([key, value]) => {
    let interestsValue = null

    if (!withoutActivityFields.some((field) => field === key)) {
      const renderLabel = capitalize(key.replace(/[_\s]+/g, ' '))
      const renderValue = Array.isArray(value) ? value.join(', ') : value

      if (key === 'interests' && Array.isArray(value)) {
        interestsValue = Object.values(value)
          .map((interes) => interes.name)
          .join(', ')
      }

      data.push({
        label: renderLabel,
        value: interestsValue || renderValue
      })
    }
  })

  return data
}

const withoutActivityProviderFields = [
  'name',
  'website',
  'policy',
  'faq',
  'logo',
  'id',
  'activities'
]

const generateActivityProviderData = (
  activity_provider: Partial<ActivityProvider>
): PersonInfoOption[] => {
  const data = []
  const { website, policy, faq } = activity_provider

  const activityProviderObject = {
    Website: website ? <BlankLink href={website}>Website link</BlankLink> : '',
    Policy: policy ? <BlankLink href={policy}>Policy link</BlankLink> : '',
    Faq: faq ? <BlankLink href={faq}>Faq link</BlankLink> : ''
  }

  Object.entries(activityProviderObject).forEach(([key, value]) => {
    data.push({
      label: key,
      value
    })
  })

  Object.entries(activity_provider).forEach(([key, value]) => {
    if (!withoutActivityProviderFields.some((field) => field === key)) {
      const renderLabel = capitalize(key.replace(/[_\s]+/g, ' '))
      const renderValue = Array.isArray(value) ? value.join(', ') : value

      if (key === 'special_needs') {
        data.push({
          label: renderLabel,
          value: Object.values(renderValue || {})
            .filter(Boolean)
            .join(', ')
        })
      } else if (key === 'interest_ids') {
        return
      } else {
        data.push({
          label: renderLabel,
          value: renderValue
        })
      }
    }
  })

  return data
}

const generateCampDirectorInitialValues = (
  activity: Partial<Activity>,
  isDuplicate?: boolean
): Partial<ActivityFormValues> => {
  const isStartMinus = activity?.grade_level?.startsWith('-')

  // TODO: hotfix grade_level field with "-1--1" value
  const gradeLevelStart = isStartMinus
    ? '-1'
    : activity?.grade_level?.split('-')[0]

  let gradeLevelEnd = activity?.grade_level?.split('-')[isStartMinus ? 2 : 1]

  if (gradeLevelEnd === '') {
    gradeLevelEnd = '-1'
  }

  const values = !isEmpty(activity) && {
    ...activity,
    price: isDuplicate ? '' : activity?.price,
    url: isDuplicate ? '' : activity?.url,
    total_seats: isDuplicate
      ? ''
      : transformZeroToString(activity?.total_seats),
    reserved_seats: isDuplicate
      ? ''
      : transformZeroToString(activity?.reserved_seats),
    date: isDuplicate
      ? ''
      : JSON.stringify([activity?.start_date, activity?.end_date]),
    start_time: isDuplicate
      ? ''
      : moment(`November 23, 2021 ${activity?.start_time}`).format(),
    end_time: isDuplicate
      ? ''
      : moment(`November 23, 2021 ${activity?.end_time}`).format(),
    age_level: activity?.age_level
      ? activity?.age_level.split('-').map((el) => parseInt(el, 10))
      : null,
    grade_level_start: gradeLevelStart,
    grade_level_end: gradeLevelEnd,
    instructor: isDuplicate ? '' : activity?.instructor,
    interest_ids: activity.interests.reduce((acc, item) => {
      acc.push(item.id)

      return acc
    }, []),
    skill_development: activity?.skill_development || []
  }

  return values || {}
}

const generateCampDirectorNewActivityInitialValues = (
  program: Partial<Program>
): Partial<ActivityFormValues> => {
  const values = !isEmpty(program) && {
    location: `${program.address}, ${program.zip_code}` || ''
  }

  return values || {}
}

const generateCampDirectorValuesDTO = (
  values: Partial<ActivityFormValues>
): ActivityFormValues => {
  const [start_date, end_date] = JSON.parse(values.date)

  const valuesDTO = {
    ...deleteKey('date', values),
    start_date: dateFormat(start_date),
    end_date: dateFormat(end_date),
    ...(values.start_time && {
      start_time: humanizeDate(values.start_time, 'LT')
    }),
    ...(values.end_time && {
      end_time: humanizeDate(values.end_time, 'LT')
    }),
    age_level: values.age_level ? values.age_level.join('-') : null,
    grade_level:
      values.grade_level_start && values.grade_level_end
        ? `${values.grade_level_start}-${values.grade_level_end}`
        : null
  }
  return valuesDTO
}

const generateCampDirectorDuplicateValuesDTO = (
  values: Partial<ActivityFormValues>
): ActivityFormDuplicateValues => {
  const [start_date, end_date] = JSON.parse(values.date)
  const valuesDTO = {
    price: values?.price,
    url: values?.url,
    start_date: dateFormat(start_date),
    end_date: dateFormat(end_date),
    ...(values.start_time && {
      start_time: humanizeDate(values.start_time, 'LT')
    }),
    ...(values.end_time && {
      end_time: humanizeDate(values.end_time, 'LT')
    }),
    total_seats: values?.total_seats,
    reserved_seats: values?.reserved_seats,
    instructor: values?.instructor
  }

  return valuesDTO
}

const generateCustomInterests = (interests: Activity['interests']): string => {
  const customInterests = interests
    .filter(Boolean)
    .reduce((acc, item) => {
      acc.push(item.name)

      return acc
    }, [])
    .join(', ')

  return customInterests
}

export default {
  generateActivities,
  generateParentActivities,
  generateParentItineraryActivities,
  generateActivitiesSum,
  generatePaymentActivitiesSum,
  generateActivityData,
  generateActivityProviderData,
  generateCampInfo,
  generateCampDirectorInitialValues,
  generateCampDirectorNewActivityInitialValues,
  generateCampDirectorValuesDTO,
  generateCampDirectorDuplicateValuesDTO,
  generateCustomInterests
}
