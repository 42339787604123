export const onyTimeAdvantages = [
  'Single personalized itinerary',
  'Planning for (1) kid'
]

export const annualAdvantages = [
  'Multiple personalized itineraries',
  'Planning for (1+) kids'
]

export const commonAdvantages = [
  'MIA - Virtual concierge',
  'Friends and Family Collaboration',
  'Calendar Planner',
  'Easy Booking',
  'Buy now, pay later',
  'Cancellation Assistance'
]
