import React, {
  FC,
  Fragment,
  useMemo,
  useState,
  useCallback,
  useEffect
} from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  Box,
  Button,
  Grid,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core'

import Mobile from '~/containers/Mobile'
import Desktop from '~/containers/Desktop'
import { Flexbox, Text, Title } from '~/components/shared'
import ItinerarySearchCard from '../ItinerarySearchCard'

import { removeActivity } from '~/state/modules/dashboard'
import { generateDifferenceDates, humanizeDate } from '~/utils/functions'

import ROUTES from '~/constants/routes'

import { ReactComponent as MapIcon } from '~/assets/icons/map-pin.svg'
import { ReactComponent as ArrowDownIcon } from '~/assets/icons/arrow-down.svg'

import useItineraryBlockStyles from './ItineraryBlock.styles'

type ItineraryBlockProps = {
  activities: ItineraryActivity[]
  itineraryId: string
}

const ItineraryBlockContent: FC<ItineraryBlockProps> = ({
  activities = [],
  itineraryId
}) => {
  const s = useItineraryBlockStyles()
  const dispatch = useDispatch()

  const activitiesData = useMemo(
    () =>
      activities
        ?.filter((item) => !!item?.activity)
        .slice()
        .sort((elementA, elementB) => {
          const dateA = new Date(elementA.activity.start_date)
          const dateB = new Date(elementB.activity.start_date)

          return ((dateA as unknown) as number) - ((dateB as unknown) as number)
        }),
    [activities]
  )

  return (
    <Box className={s.root}>
      <Flexbox mb={2}>
        {activities.length ? (
          <>
            <Flexbox flexDirection="column" alignItems="center" mr={0.5} py={4}>
              {activitiesData.map(({ id }, idx) => (
                <Fragment key={id}>
                  <MapIcon className={s.pointIcon} />
                  {idx !== activitiesData.length - 1 && (
                    <Box className={s.line} />
                  )}
                </Fragment>
              ))}
            </Flexbox>

            <Box flex={1} minWidth={0}>
              <Grid container spacing={1} data-cy="itinerary-content-block">
                {activitiesData.map(
                  ({ id, activity: { name, start_date, end_date } }) => (
                    <Grid item xs={12} key={id}>
                      <ItinerarySearchCard
                        title={name}
                        dates={`${humanizeDate(start_date)} - ${humanizeDate(
                          end_date
                        )}`}
                        duration={
                          start_date && end_date && start_date !== end_date
                            ? generateDifferenceDates(start_date, end_date)
                            : '1 day'
                        }
                        onDelete={(): void => {
                          dispatch(removeActivity(id))
                        }}
                      />
                    </Grid>
                  )
                )}
              </Grid>
            </Box>
          </>
        ) : (
          <Text>No added courses yet.</Text>
        )}
      </Flexbox>

      <Button
        data-cy="itinerary-block-button"
        component={Link}
        to={{
          pathname: `${ROUTES.itinerary}/${itineraryId}`,
          state: {
            approve: true
          }
        }}
        size="large"
        variant="contained"
        color="primary"
        fullWidth
        disabled={!activities.length}
      >
        Prepare itinerary for parent
      </Button>
    </Box>
  )
}

const ItineraryBlock: FC<ItineraryBlockProps> = (props) => {
  const s = useItineraryBlockStyles()
  const [expanded, setExpanded] = useState<boolean>(false)

  useEffect(() => {
    if (!!props?.activities?.length && !expanded) {
      setExpanded(true)
    }
  }, [props?.activities])

  const handleChange = useCallback(
    (_event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded)
    },
    []
  )

  return (
    <>
      <Desktop>
        <Card className={s.card} data-cy="itinerary-desktop-block">
          <CardContent className={s.cardContent}>
            <Title variant="h5" textAlign="start" mb={1}>
              Itinerary
            </Title>

            <ItineraryBlockContent {...props} />
          </CardContent>
        </Card>
      </Desktop>

      <Mobile>
        <Accordion expanded={expanded} onChange={handleChange}>
          <AccordionSummary expandIcon={<ArrowDownIcon />}>
            <Title variant="h5" textAlign="start">
              Itinerary
            </Title>
          </AccordionSummary>
          <AccordionDetails>
            <ItineraryBlockContent {...props} />
          </AccordionDetails>
        </Accordion>
      </Mobile>
    </>
  )
}

export default ItineraryBlock
