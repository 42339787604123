import React, { useCallback } from 'react'
import { useHistory } from 'react-router'
import { useModal } from 'react-modal-hook'

import { WelcomeDialog, ConfirmDialog } from '~/components/dialogs'

import { STORAGE } from '~/constants/storage'
import ROUTES from '~/constants/routes'
import { authtorize } from '~/state/modules/user'
import { useDispatch } from 'react-redux'

const useWelcomeDialog = (): {
  handleWelcomeDialog: () => void
  handleConsultationDialog: () => void
} => {
  const { push } = useHistory()
  const dispatch = useDispatch()

  const isConsultationDialogWasShown = localStorage?.getItem(
    STORAGE.showConsultationDialog
  )
  const isWelcomeDialogWasShown = localStorage?.getItem(
    STORAGE.showWelcomeDialog
  )

  const fetchUserData = async (): Promise<void> => {
    const jwt = localStorage.getItem(STORAGE.authToken)
    if (!jwt) return

    await dispatch<any>(authtorize({ jwt }))
  }

  const [showModal, hideModal] = useModal((props: any) => (
    <WelcomeDialog
      onClose={hideModal}
      onConfirm={async (): Promise<void> => {
        await fetchUserData()

        localStorage.setItem(STORAGE.showWelcomeDialog, 'true')
        push(ROUTES.questionnaire)
      }}
      {...props}
    />
  ))

  const [showConsultationModal, hideConsultationModal] = useModal(
    (props: any) => (
      <ConfirmDialog
        onClose={hideConsultationModal}
        onConfirm={(): void => {
          localStorage.setItem(STORAGE.showConsultationDialog, 'true')
          window.location.assign(ROUTES.consultation)
        }}
        confirmText="Schedule Now"
        isMoreText
        text="Schedule a consultation with a program specialist to plan activities for your family, personalized to your needs."
        secondText=""
        maxWidth="sm"
        {...props}
      />
    )
  )

  const handleWelcomeDialog = useCallback(async (): Promise<void> => {
    if (!isWelcomeDialogWasShown) {
      showModal()
    } else {
      await fetchUserData()

      push(ROUTES.questionnaire)
    }
  }, [showModal, push])

  const handleConsultationDialog = useCallback((): void => {
    if (!isConsultationDialogWasShown) {
      showConsultationModal()
    } else {
      window.location.assign(ROUTES.consultation)
    }
  }, [showConsultationModal])

  return { handleWelcomeDialog, handleConsultationDialog }
}

export default useWelcomeDialog
