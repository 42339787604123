import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { initialize } from 'redux-form'
import { useModal } from 'react-modal-hook'
import { Box, Button, Tooltip } from '@material-ui/core'

import { Link, Text } from '~/components/shared'
import { LeaveNoteDialog } from '~/components/dialogs'

import { useActivityCardDesktopStyles } from './ActivityCard.style'

import ROUTES from '~/constants/routes'
import { ActivityCardProps } from './ActivityCard'

import { ReactComponent as Note } from '~/assets/icons/note.svg'

const ActivityCardLinks: FC<ActivityCardProps & { isMobile?: boolean }> = ({
  subtitle,
  title,
  id: activityId,
  activityProviderId,
  locationState,
  note,
  isNoAvailableSeats
}) => {
  const dispatch = useDispatch()
  const s = useActivityCardDesktopStyles()

  const [leaveNoteDialogProps, setLeaveNoteDialogProps] = useState({})

  const [showModal, hideModal] = useModal(
    (props: any) => (
      <LeaveNoteDialog
        onClose={hideModal}
        {...props}
        isSearch
        {...leaveNoteDialogProps}
      />
    ),
    [leaveNoteDialogProps]
  )

  return (
    <>
      {isNoAvailableSeats ? (
        <>
          <Box mb={1}>
            <Text className={s.providerInfo}>{subtitle}</Text>
            <Text className={s.activitiesInfo}>{title}</Text>
          </Box>
        </>
      ) : (
        <>
          {' '}
          <Box mb={1}>
            <Link
              to={{
                pathname: `${ROUTES.activityProvider}/${activityProviderId}`,
                state: locationState
              }}
              className={s.providerInfo}
            >
              {subtitle}
            </Link>
            <Tooltip title={note?.activity_provider} arrow>
              <Button
                className={s.iconBtn}
                classes={{ startIcon: s.startIcon }}
                startIcon={<Note />}
                color="primary"
                size="small"
                onClick={(): void => {
                  setLeaveNoteDialogProps({
                    id: activityProviderId,
                    isProvider: true,
                    name: subtitle
                  })

                  dispatch(
                    initialize('LeaveNote_Form', {
                      specialist_notes: note?.activity_provider || ''
                    })
                  )

                  showModal()
                }}
              >
                <span className={s.infoNote}>
                  {!!note?.activity_provider && '*'}
                </span>
              </Button>
            </Tooltip>
          </Box>
          <Box mb={1}>
            <Link
              to={`${ROUTES.activity}/${activityId}`}
              className={s.activitiesInfo}
            >
              {title}
            </Link>

            <Tooltip title={note?.activity} arrow>
              <Button
                className={s.iconBtn}
                classes={{ startIcon: s.startIcon }}
                data-cy="search-note-button"
                startIcon={<Note />}
                color="primary"
                size="small"
                onClick={(): void => {
                  setLeaveNoteDialogProps({
                    id: activityId,
                    isProvider: false,
                    name: title
                  })

                  dispatch(
                    initialize('LeaveNote_Form', {
                      specialist_notes: note?.activity || ''
                    })
                  )

                  showModal()
                }}
              >
                <span className={s.infoNote}>{!!note?.activity && '*'}</span>
              </Button>
            </Tooltip>
          </Box>
        </>
      )}
    </>
  )
}

export default ActivityCardLinks
