import React, { useEffect } from 'react'
import { createStyles, makeStyles, Paper } from '@material-ui/core'
import { Flexbox } from '~/components/shared'
import { ZOHO } from '~/constants/zoho'
import { generateIframeDirector } from '../camp-director.utils'
import { useSelector } from 'react-redux'
import { AppState } from '~/state/store'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      container: {
        width: '100%',
        margin: theme.spacing(3, 'auto', 6),
        padding: theme.spacing(5, 12.5),

        [theme.breakpoints.down('lg')]: {
          padding: '50px'
        },

        [theme.breakpoints.down('sm')]: {
          padding: '50px 0'
        },

        [theme.breakpoints.down('xs')]: {
          width: '100vw',
          position: 'relative',
          left: '50%',
          right: '50%',
          margin: '100px -50vw 0',
          borderRadius: 'inherit',
          boxShadow: 'none'
        }
      }
    }),
  {
    name: 'ProviderForm'
  }
)

const ProviderSignup: React.FC = () => {
  const s = useStyles()
  const production = process.env.REACT_APP_CI_ENV === 'production'
  const { email } = useSelector((state: AppState) => state.user)

  useEffect(() => {
    if (email) {
      generateIframeDirector(email)
    }
  }, [email])

  return (
    <Paper className={s.container}>
      <Flexbox
        justifyContent="center"
        id={
          production
            ? ZOHO.campDirector.divIdProduction()
            : ZOHO.campDirector.divIdStaging()
        }
      />
    </Paper>
  )
}

export default ProviderSignup
