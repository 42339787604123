import React, { FC, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Hidden } from '@material-ui/core'

import ActivityCardDesktop from './ActivityCard.desktop'
import ActivityCardMobile from './ActivityCard.mobile'

import { addActivity } from '~/state/modules/dashboard'

type Props = {
  locationState: object
  isAdded?: boolean
  itineraryId?: string
  isHideButtons?: boolean
  isAdding?: boolean
  onAddActivity?: () => void
  isNoAvailableSeats?: boolean
}

export type ActivityCardProps = Partial<ActivityCard> & Props

const ActivityCard: FC<ActivityCardProps> = ({
  itineraryId,
  available_seats,
  ...props
}) => {
  const dispatch = useDispatch()

  const [isAdding, setIsAdding] = useState(false)

  const onAddActivity = useCallback(async (): Promise<void> => {
    setIsAdding(true)

    try {
      await dispatch<any>(
        addActivity({
          activity_id: props?.id,
          itinerary_id: itineraryId
        })
      )
    } catch (error) {
      console.error(error)
    } finally {
      setIsAdding(false)
    }
  }, [itineraryId, props?.id, dispatch])

  const isNoAvailableSeats = available_seats === 'No'

  return (
    <>
      <Hidden xsDown>
        <ActivityCardDesktop
          isNoAvailableSeats={isNoAvailableSeats}
          isHideButtons={!itineraryId}
          onAddActivity={onAddActivity}
          isAdding={isAdding}
          {...props}
        />
      </Hidden>

      <Hidden smUp>
        <ActivityCardMobile
          isNoAvailableSeats={isNoAvailableSeats}
          isHideButtons={!itineraryId}
          onAddActivity={onAddActivity}
          isAdding={isAdding}
          {...props}
        />
      </Hidden>
    </>
  )
}

export default ActivityCard
