import React, { FC, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Tabs, Tab } from '@material-ui/core'

import { changeActiveTab } from '~/state/modules/dashboard/dashboard.slice'

import { DashboardTabsArray } from './DashboardTabs.config'

import useDashboardTabsStyles from './DashboardTabs.styles'

type Props = {
  activeTab: StatusesUnion
  amount: Record<StatusesUnion, number>
}

const DashboardTabs: FC<Props> = ({ activeTab, amount }) => {
  const s = useDashboardTabsStyles()
  const dispatch = useDispatch()

  const handleChangeTab = useCallback(
    (_event: React.ChangeEvent<{}>, newValue: StatusesUnion): void => {
      dispatch(changeActiveTab(newValue))
    },
    [dispatch]
  )

  return (
    <Tabs
      data-cy="dashboard-tabs"
      indicatorColor="primary"
      variant="scrollable"
      scrollButtons="auto"
      value={activeTab}
      onChange={handleChangeTab}
      classes={{
        root: s.tabs,
        flexContainer: s.tabsContainer,
        indicator: s.indicator
      }}
    >
      {DashboardTabsArray.map((tab) => (
        <Tab
          {...tab}
          key={tab.value}
          label={`${tab.label} (${amount[tab.value] ? amount[tab.value] : 0})`}
          classes={{
            root: s.tab,
            selected: s.selected
          }}
        />
      ))}
    </Tabs>
  )
}

export default DashboardTabs
