import React, { useCallback, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  IconButton,
  Button,
  SwipeableDrawer,
  Box,
  List,
  ListItem
} from '@material-ui/core'
import cn from 'classnames'

import Premium from '~/containers/Premium'
import { Flexbox, Logo, NavLink, Text } from '~/components/shared'
import { HeaderProps } from './Header'

import { isCampDirector, isParent, isShare } from '~/state/modules/user'

import ROUTES from '~/constants/routes'
import { campDirectorTabsArray } from '~/pages/camp-director/camp-director.template'
import { AppState } from '~/state/store'

import { ReactComponent as MenuIcon } from '~/assets/icons/menu.svg'
import { ReactComponent as CloseIcon } from '~/assets/icons/close.svg'

import { useMobileStyles } from './Header.styles'
import { ProfileArray } from '~/data/profile/profileArray'

const HeaderMobile: React.FC<HeaderProps> = ({
  navs,
  isGradient,
  logged,
  specialist,
  limitQuestionnaire,

  handleConsultationDialog,
  logout
}) => {
  const s = useMobileStyles()
  const { pathname } = useLocation()

  const [open, setOpen] = useState<boolean>(false)

  const parent = useSelector(isParent)
  const campDirector = useSelector(isCampDirector)
  const withShare = useSelector(isShare)
  const isLowered = useSelector<AppState, boolean>(
    (state) => state.appLayout.header.lowered
  )

  const handleSidebar = useCallback(() => {
    setOpen(!open)
  }, [open])

  useEffect(() => {
    setOpen(false)
  }, [pathname])

  return (
    <>
      <header
        className={cn(s.root, {
          gradient: isGradient,
          isLowered
        })}
      >
        <Flexbox className={s.container}>
          <Logo customClassName={s.logo} isWhite={isGradient} />

          <IconButton data-cy="swipeable-drawer" onClick={handleSidebar}>
            <MenuIcon />
          </IconButton>
        </Flexbox>
      </header>

      <SwipeableDrawer
        anchor="right"
        open={open}
        onOpen={handleSidebar}
        onClose={handleSidebar}
        disableBackdropTransition={true}
        classes={{
          paper: s.paper,
          modal: s.modal
        }}
      >
        <Flexbox className={cn(s.container, s.header)}>
          <Logo customClassName={s.logo} />

          <IconButton onClick={handleSidebar}>
            <CloseIcon />
          </IconButton>
        </Flexbox>

        {parent && (
          <Box className={s.linksWrapper}>
            <List disablePadding data-cy="swipeable-drawer-button">
              {ProfileArray.map(({ label, value }) => {
                if (value === ROUTES.itinerariesFriends && !withShare) {
                  return null
                }

                const ListItemComponent = (
                  <ListItem key={value} className={s.tabItem}>
                    <NavLink to={value}>
                      <Text>{label}</Text>
                    </NavLink>
                  </ListItem>
                )

                if (value === ROUTES.calendar) {
                  return <Premium key={value}>{ListItemComponent}</Premium>
                }

                if (value === ROUTES.questionnaire) {
                  return (
                    <Premium
                      customBlock={{
                        isBlock: limitQuestionnaire,
                        useBlock: true
                      }}
                      key={value}
                    >
                      {ListItemComponent}
                    </Premium>
                  )
                }

                return ListItemComponent
              })}
            </List>
          </Box>
        )}

        {campDirector && (
          <Box className={s.linksWrapper}>
            <List disablePadding data-cy="swipeable-drawer-button">
              {campDirectorTabsArray.map(({ label, value }) => {
                const ListItemComponent = (
                  <ListItem key={value} className={s.tabItem}>
                    <NavLink to={value}>
                      <Text>{label}</Text>
                    </NavLink>
                  </ListItem>
                )

                return ListItemComponent
              })}
            </List>
          </Box>
        )}

        <Box className={s.linksWrapper}>
          <List disablePadding>
            {navs.map(({ label, to, outwardLink }) => (
              <ListItem key={to} className={s.tabItem}>
                {outwardLink ? (
                  <NavLink
                    component={(props): JSX.Element => <a {...props} />}
                    href={to}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Text>{label}</Text>
                  </NavLink>
                ) : (
                  <NavLink to={to} exact>
                    <Text>{label}</Text>
                  </NavLink>
                )}
              </ListItem>
            ))}
          </List>
        </Box>

        <Box className={s.btnsWrapper}>
          {!specialist && !campDirector && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleConsultationDialog}
              fullWidth
            >
              Schedule Consultation
            </Button>
          )}

          {logged ? (
            <Button
              variant="outlined"
              onClick={logout}
              className={s.logOut}
              fullWidth
            >
              Log Out
            </Button>
          ) : (
            <>
              <Button
                component={Link}
                to={ROUTES.signIn}
                variant="outlined"
                color="primary"
                fullWidth
              >
                Sign in Parent
              </Button>
              <Button
                component={Link}
                to={ROUTES.campDirectorSignIn}
                variant="outlined"
                color="primary"
                fullWidth
              >
                Sign in Camp Director
              </Button>
            </>
          )}
        </Box>
      </SwipeableDrawer>
    </>
  )
}

export default HeaderMobile
