import { AppState } from '~/state/store'

export const programsSelector = (
  state: AppState
): { programs: Program[]; loading: boolean; loaded: boolean } => {
  const { data: programs, loading, loaded } = state.programs

  return { programs, loading, loaded }
}

export const getProgramSelector = (
  state: AppState
): { program: Partial<Program>; loading: boolean } => {
  const { item: program, loading } = state.programs

  return { program, loading }
}

export const activeTabSelector = (state: AppState): string => {
  const { activeTab } = state.programs

  return activeTab
}

export const getActivitiesSelector = (
  state: AppState
): { activities: Activity[]; loading: boolean; loaded: boolean } => {
  const { activities = [], loading, loaded } = state.programs

  return { activities, loading, loaded }
}

export const getActivitySelector = (
  state: AppState
): { activity: Partial<Activity>; loading: boolean } => {
  const { activityItem: activity, loading } = state.programs

  return { activity, loading }
}

export const programMetaSelector = (
  state: AppState
): { count: number; pages: number; page: number } => {
  const { count, pages, page } = state.programs.programsMeta

  return { count, pages, page }
}

export const activitiesMetaSelector = (
  state: AppState
): { count: number; pages: number; page: number } => {
  const { count, pages, page } = state.programs.activitiesMeta

  return { count, pages, page }
}
