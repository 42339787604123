import React, { FC } from 'react'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  createStyles,
  makeStyles
} from '@material-ui/core'

import { Flexbox, Text } from '~/components/shared'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      card: {
        maxWidth: 310,
        minHeight: 244
      },

      avatar: {
        width: 60,
        height: 60,
        marginRight: theme.spacing(1.5)
      },

      subtitle: {
        color: theme.palette.text.hint
      }
    }),
  {
    name: 'ParentCard'
  }
)

export type ParentCardProps = {
  image: string
  title: string
  subtitle: string
  description: string
}

const ParentCard: FC<ParentCardProps> = ({
  image,
  title,
  subtitle,
  description
}) => {
  const s = useStyles()

  return (
    <Card className={s.card}>
      <CardContent>
        <Flexbox mb={2} alignItems="center">
          <Avatar src={image} className={s.avatar} />
          <Box>
            <Text fontWeight={600} mb={0.5}>
              {title}
            </Text>
            <Text variant="body2" className={s.subtitle}>
              {subtitle}
            </Text>
          </Box>
        </Flexbox>

        <Text color="textSecondary">{description}</Text>
      </CardContent>
    </Card>
  )
}

export default ParentCard
