import React, { FC, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Pagination } from '@material-ui/lab'

import { changeActivePage } from '~/state/modules/dashboard/dashboard.slice'

type Props = {
  count: number
  activePage: number
}

const DashboardPagination: FC<Props> = ({ count, activePage }) => {
  const dispatch = useDispatch()

  const handleChangePage = useCallback(
    (_event: React.ChangeEvent<unknown>, value: number): void => {
      dispatch(changeActivePage(value))
    },
    [dispatch]
  )

  return (
    <Pagination
      count={count}
      page={activePage}
      color="primary"
      onChange={handleChangePage}
    />
  )
}

export default DashboardPagination
