import React from 'react'

import { Container, makeStyles, Paper, Theme } from '@material-ui/core'

import { Link } from '~/components/shared'
import FullWidthMobile from '~/containers/FullWidthMobile'

import ROUTES from '~/constants/routes'

import { ReactComponent as BackIcon } from '~/assets/icons/back-icon.svg'

const useStyles = makeStyles((theme: Theme) => ({
  backButton: {
    display: 'inline-block',
    color: theme.palette.text.secondary,
    fontWeight: 400,
    marginBottom: theme.spacing(2),

    '& svg': {
      marginRight: theme.spacing(1)
    }
  },
  container: {
    padding: theme.spacing(5, 11),
    margin: theme.spacing(3, 0, 6),
    minHeight: '70vh',

    [theme.breakpoints.down('sm')]: {
      padding: '50px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
      margin: 0,
      borderRadius: 'inherit',
      boxShadow: 'none'
    }
  }
}))

interface ProgramTemplateProps {
  title?: string
  programId?: string
}

const ProgramTemplate: React.FC<ProgramTemplateProps> = ({
  title = 'Back to Programs',
  programId,
  children
}) => {
  const s = useStyles()

  return (
    <FullWidthMobile>
      <Container maxWidth="lg" disableGutters>
        <Paper className={s.container}>
          <Link
            className={s.backButton}
            underline="hover"
            to={
              programId
                ? `${ROUTES.campDirectorProgram}/${programId}`
                : ROUTES.campDirectorPrograms
            }
          >
            <BackIcon />
            {title}
          </Link>

          {children}
        </Paper>
      </Container>
    </FullWidthMobile>
  )
}

export default ProgramTemplate
