import React, { useState, useEffect } from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'

import Confirmation from './Confirmation'

import { STORAGE } from '~/constants/storage'
import ROUTES from '~/constants/routes'

type RouterMatch = {
  token: string
}

const ConfirmationPassword: React.FC<RouteComponentProps<RouterMatch>> = ({
  match: { params },
  location: { search }
}) => {
  const [redirect, setRedirect] = useState<string>('')
  const entity = new URLSearchParams(search).get(STORAGE.entity)

  useEffect(() => {
    if (params?.token) {
      localStorage.setItem(STORAGE.resetToken, params.token)
      localStorage.setItem(STORAGE.entity, entity)

      setRedirect(ROUTES.resetPassword)
    } else setRedirect(ROUTES.home)
  }, [params])

  if (!redirect) return <Confirmation />

  return <Redirect to={redirect} />
}

export default ConfirmationPassword
