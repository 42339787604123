import React, { FC, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Grid, Hidden } from '@material-ui/core'

import ActivitiesTableDesktop from './ActivitiesTableDesktop'
import ActivityAccordion from './ActivityAccordion'

import { addActivity } from '~/state/modules/dashboard'

export type ActivitiesTableItem = {
  isHideButtons?: boolean
  isAdding?: string | null
  onAddActivity?: (id: string) => void
}

type ActivitiesTableProps = {
  data: Activity[]
  itineraryId: string
}

const ActivitiesTable: FC<ActivitiesTableProps> = ({ itineraryId, data }) => {
  const dispatch = useDispatch()

  const [isAdding, setIsAdding] = useState(null)

  const onAddActivity = useCallback(
    async (id: string): Promise<void> => {
      setIsAdding(id)

      try {
        await dispatch<any>(
          addActivity({
            activity_id: id,
            itinerary_id: itineraryId
          })
        )
      } catch (error) {
        console.error(error)
      } finally {
        setIsAdding(null)
      }
    },
    [itineraryId, dispatch]
  )

  return (
    <>
      <Hidden smDown>
        <ActivitiesTableDesktop
          data={data}
          isHideButtons={!itineraryId}
          onAddActivity={onAddActivity}
          isAdding={isAdding}
        />
      </Hidden>

      <Hidden mdUp>
        <Grid container spacing={2}>
          {data?.map((activities) => (
            <Grid key={activities.id} item xs={12}>
              <ActivityAccordion
                isHideButtons={!itineraryId}
                onAddActivity={onAddActivity}
                isAdding={isAdding}
                {...activities}
              />
            </Grid>
          ))}
        </Grid>
      </Hidden>
    </>
  )
}

export default ActivitiesTable
