import { string, object, array } from 'yup'
import { removeCommasFromString } from '~/utils/functions'

const ActivitySchema = object().shape({
  name: string().required('Name is required').trim(),
  url: string().required('Url is required').trim(),
  date: string()
    .test('Date', 'Date fields must be fill', (value) => {
      if (value) {
        const v: string[] = JSON.parse(value)

        return v.every((item) => item)
      }

      return false
    })
    .required('Date is required'),
  price: string()
    .nullable()
    .required('Price is required')
    .test('valid-min-price', 'Price must be more than or equal to 1', (value) =>
      value ? Number(removeCommasFromString(value)) >= 1 : false
    ),
  start_time: string().required('Start time is required').nullable(),
  end_time: string().required('End time is required').nullable(),
  camp_type: string().required('Camp type is required').nullable(),
  location: string().required('Location is required'),
  media_link: string().required('Media link is required').trim(),
  grade_level_start: string()
    .nullable()
    .test('grade_level_start', 'Grade level is required', (value, ctx) => {
      const isEmptyValueWithEnd = !value && ctx?.parent.grade_level_end

      if (isEmptyValueWithEnd) {
        return false
      }
      return true
    }),
  grade_level_end: string()
    .nullable()
    .test('grade_level_end', 'Grade level is required', (value, ctx) => {
      const isEmptyValueWithStart = !value && ctx?.parent.grade_level_start

      if (isEmptyValueWithStart) {
        return false
      }
      return true
    }),
  interest_ids: array()
    .required('Interests is required')
    .min(1, 'Choose at least 1 option!')
    .nullable(),
  skill_development: array()
    .required('Skill development is required')
    .min(1, 'Choose at least 1 option!')
    .nullable(),
  reserved_seats: string()
    .notRequired()
    .test(
      'total_seats',
      "You can't fill reserved seats without total seats",
      (value, ctx) => {
        if (value && !ctx?.parent.total_seats) {
          return false
        }
        return true
      }
    )
    .test(
      'reserved_seats',
      "Reserved seats can't be more than total seats",
      (value, ctx) => {
        const totalSeatsValue = removeCommasFromString(ctx?.parent?.total_seats)
        const reservedSeatsValue = removeCommasFromString(value)

        if (totalSeatsValue < reservedSeatsValue) {
          return false
        }
        return true
      }
    )
    .nullable()
})

export default ActivitySchema
