import React, { FC, useEffect } from 'react'
import { Grid, Paper, Box } from '@material-ui/core'
import cn from 'classnames'

import { Flexbox, Text, Title } from '~/components/shared'

import { generateIframe } from './contact-us.utils'

import { ZOHO } from '~/constants/zoho'

import painting1 from '~/assets/images/home/title-painting-1.png'

import useStaticStyles from '~/styles/static-pages.styles'

const ContactUs: FC = () => {
  const s = useStaticStyles()

  useEffect(() => {
    generateIframe()
  }, [])

  return (
    <Box className={cn(s.container, s.fullWidth)}>
      <Paper className={s.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Title
              variant="h1"
              paintingOptions={{
                image: painting1,
                customStyles: {
                  bottom: 3,
                  height: 6
                }
              }}
              mb={3}
            >
              Contact us
            </Title>
          </Grid>

          <Grid item xs={12}>
            <Text textAlign="center" variant="h4" fontWeight={600}>
              Reach out directly at info@planmykids.com or let us know how we
              can collaborate below.
            </Text>
          </Grid>

          <Grid item xs={12}>
            <Flexbox
              justifyContent="center"
              id={ZOHO.contactUs.divId()}
              mb={4}
            />
          </Grid>

          <Grid item xs={12}>
            <Text textAlign="center" variant="h4" fontWeight={600}>
              Empowering Parents, Enriching Kids
            </Text>
          </Grid>

          <Grid item xs={12}>
            <Text
              variant="subtitle1"
              color="textSecondary"
              textAlign="center"
              mb={5}
            >
              Empowering Parents, Enriching Kids If you have questions or
              inquiries about the opportunities available in our service, feel
              free to send us a message. We will get back to you as soon as
              possible.
            </Text>
          </Grid>

          <Grid item xs={12}>
            <Text
              variant="subtitle1"
              color="textSecondary"
              textAlign="center"
              mb={5}
            >
              PO Box 26131 Raleigh, North Carolina 27611, United States
            </Text>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default ContactUs
