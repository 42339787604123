import React from 'react'
import { Container, Grid, Paper } from '@material-ui/core'

import FullWidthMobile from '~/containers/FullWidthMobile'
import { Title } from '~/components/shared'
import SignInForm from './components/SignInSpecialistForm'

import useAuthStyles from '~/styles/auth.styles'

const SignInSpecialist: React.FC = () => {
  const s = useAuthStyles()

  return (
    <FullWidthMobile>
      <Container maxWidth="sm" className={s.container}>
        <Paper className={s.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Title mb={1}>Sign in Specialist</Title>
            </Grid>

            <Grid item xs={12}>
              <SignInForm />
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </FullWidthMobile>
  )
}

export default SignInSpecialist
