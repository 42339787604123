import { object } from 'yup'

import { fullName, email, zipCode, phone, address } from './fields'

const ParentProfileSchema = object().shape({
  name: fullName,
  email,
  phone_number: phone,
  address,
  zizip_codep: zipCode
})

export default ParentProfileSchema
