import { object } from 'yup'

import { email, password } from './fields'

const SignupDirectorSchema = object().shape({
  email,
  password,
  password_confirmation: password
    .passwordConfirmation('Passwords are not the same!')
    .required('Password confirmation is required!')
})

export default SignupDirectorSchema
