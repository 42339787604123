import React, { useEffect, useState } from 'react'
import { WrappedFieldProps } from 'redux-form'
import {
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  Select,
  SelectProps
} from '@material-ui/core'

import { createOptions } from '~/utils/functions'
type Props = {
  label?: string
  options?: Option[]
  loadOptions?: () => Promise<Option[]>
  displayError: string
  menuItemClass?: string
  isLoading?: boolean
  isOptional?: boolean
}

type AllProps = WrappedFieldProps & Props & SelectProps

const SelectField: React.FC<AllProps> = ({
  input: { value, name, ...input },
  meta: { touched, error },
  label,
  options: customOptions = [],
  displayError,
  menuItemClass,
  isOptional,
  ...rest
}) => {
  const [options, setOptions] = useState(customOptions)

  const errorMessage = (touched && error) || displayError

  useEffect(() => {
    setOptions(createOptions(options))
  }, [customOptions])

  return (
    <FormControl variant="outlined" fullWidth error={!!errorMessage}>
      {label && <InputLabel id={name}>{label}</InputLabel>}

      <Select
        label={label}
        labelId={name}
        value={value}
        inputProps={{ 'data-required': !isOptional }}
        {...input}
        {...rest}
      >
        {options?.map(({ value, label }) => (
          <MenuItem
            key={value}
            value={value}
            className={menuItemClass}
            data-cy="select-field-option"
          >
            {label}
          </MenuItem>
        ))}
      </Select>

      {!!errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  )
}

export default SelectField
