export default `
<!--Zoho Campaigns Web-Optin Form's Header Code Starts Here-->

<style>
  .submit_btn_disabled {
    cursor: default !important;
    pointer-events: none !important;
    color: rgba(0, 0, 0, 0.26) !important;
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
  .quick_form_8_css * {
    -webkit-box-sizing: border-box !important;
    -moz-box-sizing: border-box !important;
    box-sizing: border-box !important;
    overflow-wrap: break-word;
  }
  @media only screen and (max-width: 600px) {
    .quick_form_8_css[name='SIGNUP_BODY'] {
      width: 100% !important;
      min-width: 100% !important;
      margin: 0px auto !important;
      padding: 0px !important;
    }
    .SIGNUP_FLD {
      width: calc(100% - 20px) !important;
    }
    .SIGNUP_FLD input {
      margin-left: 0 !important;
    }
  }
</style>

<!--Zoho Campaigns Web-Optin Form's Header Code Ends Here--><!--Zoho Campaigns Web-Optin Form Starts Here-->

<div
  id="sf934001741e12d7972ae4d02b29c202b122c9a454c9b9cdcf"
  data-type="signupform"
  style="opacity: 1"
>
  <div id="customForm">
    <div
      class="quick_form_8_css"
      style="
        width: 410px;
        z-index: 2;
        font-family: 'Arial';
        overflow: hidden;
      "
      name="SIGNUP_BODY"
    >
      <div>
        <div style="position: relative">
          <div
            id="Zc_SignupSuccess"
            style="
              display: none;
              position: absolute;
              margin-left: 4%;
              width: 90%;
              background-color: white;
              padding: 3px;
              border: 3px solid rgb(194, 225, 154);
              margin-top: 10px;
              margin-bottom: 10px;
              word-break: break-all;
            "
          >
            <table width="100%" cellpadding="0" cellspacing="0" border="0">
              <tbody>
                <tr>
                  <td width="10%">
                    <img
                      class="successicon"
                      src="https://publ.maillist-manage.com/images/challangeiconenable.jpg"
                      align="absmiddle"
                    />
                  </td>
                  <td>
                    <span
                      id="signupSuccessMsg"
                      style="
                        color: rgb(73, 140, 132);
                        font-family: sans-serif;
                        font-size: 14px;
                        word-break: break-word;
                      "
                      >&nbsp;&nbsp;Thank you for Signing Up</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <form
          method="POST"
          id="zcampaignOptinForm"
          style="margin: 0px; width: 100%"
          action="https://maillist-manage.com/weboptin.zc"
          target="_zcSignup"
        >
          <div
            style="
              background-color: rgb(255, 235, 232);
              padding: 10px;
              color: rgb(210, 0, 0);
              font-size: 14px;
              margin: 20px 10px 0px;
              border: 1px solid rgb(255, 217, 211);
              opacity: 1;
              display: none;
              border-radius: 10px;
            "
            id="errorMsgDiv"
          >
            Please correct the marked field(s) below.
          </div>
          <div
            style="
              position: relative;
              margin: 10px 10px 10px;
              width: calc(100% - 170px);
              height: 50px;
              display: inline-block;
            "
            class="SIGNUP_FLD"
          >
            <input
              type="text"
              style="
                font-size: 14px;
                border: 1px solid rgb(221, 221, 221);
                border-radius: 0;
                width: 100%;
                height: 100%;
                z-index: 4;
                outline: none;
                padding: 5px 10px;
                color: rgb(136, 136, 136);
                text-align: left;
                font-family: 'Arial';
                background-color: rgb(255, 255, 255);
                box-sizing: border-box;
                height: 50px;
                border-radius: 10px;
              "
              placeholder="Enter your email"
              changeitem="SIGNUP_FORM_FIELD"
              name="CONTACT_EMAIL"
              id="EMBED_FORM_EMAIL_LABEL"
            />
          </div>
          <div
            style="
              position: relative;
              margin: 10px;
              min-width: 64px;
              height: 50px;
              text-align: left;
              display: inline-block;
            "
            class="SIGNUP_FLD"
          >
            <input
              class="submit_btn_disabled"
              type="button"
              style="
                text-align: center;
                border-radius: 5px;
                width: 100%;
                height: 100%;
                z-index: 5;
                border: 0;
                color: #703950;
                cursor: pointer;
                outline: none;
                font-size: 14px;
                background-color: rgb(108, 187, 195);
                margin: 0;
                padding: 10px 30px;
                min-height: 50px;
                min-width: 64px;
                font-weight: 700;
                line-height: 1.75;
                border-radius: 10px;
                background-color: #F1EBEE;
              "
              name="SIGNUP_SUBMIT_BUTTON"
              id="zcWebOptin"
              value="Join Now"
            />
          </div>
          <input type="hidden" id="fieldBorder" value="" />
          <input
            type="hidden"
            id="submitType"
            name="submitType"
            value="optinCustomView"
          />
          <input
            type="hidden"
            id="emailReportId"
            name="emailReportId"
            value=""
          />
          <input
            type="hidden"
            id="formType"
            name="formType"
            value="QuickForm"
          />
          <input type="hidden" name="zx" id="cmpZuid" value="12a81b6ec" />
          <input type="hidden" name="zcvers" value="3.0" />
          <input
            type="hidden"
            name="oldListIds"
            id="allCheckedListIds"
            value=""
          />
          <input
            type="hidden"
            id="mode"
            name="mode"
            value="OptinCreateView"
          />
          <input
            type="hidden"
            id="zcld"
            name="zcld"
            value="19659b3bc507cf5d"
          />
          <input type="hidden" id="document_domain" value="" />
          <input
            type="hidden"
            id="zc_Url"
            value="publ.maillist-manage.com"
          />
          <input type="hidden" id="new_optin_response_in" value="0" />
          <input type="hidden" id="duplicate_optin_response_in" value="0" />
          <input
            type="hidden"
            name="zc_trackCode"
            id="zc_trackCode"
            value="ZCFORMVIEW"
          />
          <input
            type="hidden"
            id="zc_formIx"
            name="zc_formIx"
            value="934001741e12d7972ae4d02b29c202b122c9a454c9b9cdcf"
          />
          <input type="hidden" id="viewFrom" value="URL_ACTION" />
          <span style="display: none" id="dt_CONTACT_EMAIL"
            >1,true,6,Contact Email,2</span
          >
        </form>
      </div>
    </div>
  </div>
  <img
    src="https://publ.maillist-manage.com/images/spacer.gif"
    id="refImage"
    onload="referenceSetter(this)"
    style="display: none"
  />
</div>
<input type="hidden" id="signupFormType" value="QuickForm_Horizontal" />
<div
  id="zcOptinOverLay"
  oncontextmenu="return false"
  style="
    display: none;
    text-align: center;
    background-color: rgb(0, 0, 0);
    opacity: 0.5;
    z-index: 100;
    position: fixed;
    width: 100%;
    top: 0px;
    left: 0px;
    height: 988px;
  "
></div>
<div
  id="zcOptinSuccessPopup"
  style="
    display: none;
    z-index: 9999;
    width: 800px;
    height: 40%;
    top: 84px;
    position: fixed;
    left: 26%;
    background-color: #ffffff;
    border-color: #e6e6e6;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 1px 10px #424242;
    padding: 35px;
  "
>
  <span
    style="
      position: absolute;
      top: -16px;
      right: -14px;
      z-index: 99999;
      cursor: pointer;
    "
    id="closeSuccess"
  >
    <img src="https://publ.maillist-manage.com/images/videoclose.png" />
  </span>
  <div id="zcOptinSuccessPanel"></div>
</div>

<!--Zoho Campaigns Web-Optin Form Ends Here-->`
