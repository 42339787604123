import React, { FC } from 'react'
import { makeStyles, createStyles, Box } from '@material-ui/core'

import { Flexbox, ShowMoreText, Text, Title } from '../../shared'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      wrapper: {
        flex: 1,
        minHeight: '250px',
        padding: theme.spacing(2),
        border: `1px solid ${theme.palette.text.disabled}`,
        borderRadius: theme.spacing(1),

        [theme.breakpoints.down('sm')]: {
          minHeight: 'auto'
        }
      },

      textRow: {
        '&:last-child p': {
          marginBottom: 0
        },

        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column'
        }
      },

      label: {
        width: '400px',
        minHeight: 25,
        marginRight: theme.spacing(1),

        [theme.breakpoints.down('sm')]: {
          width: '200px'
        },

        [theme.breakpoints.down('xs')]: {
          width: '100%',
          marginRight: 0,
          marginBottom: theme.spacing(0.5)
        }
      },

      value: {
        marginBottom: theme.spacing(1),
        overflow: 'hidden',
        textOverflow: 'ellipsis',

        [theme.breakpoints.down('xs')]: {
          width: '100%'
        }
      }
    }),
  {
    name: 'PersonInfoCard'
  }
)

type PersonInfoCardProps = {
  data: PersonInfoOption[]
  title?: string
}

const PersonInfoCard: FC<PersonInfoCardProps> = ({ title, data }) => {
  const s = useStyles()

  return (
    <Box className={s.wrapper}>
      {title && (
        <Title variant="h5" textAlign="start" mb={1}>
          {title}
        </Title>
      )}

      <ShowMoreText height={400}>
        {data.map(({ label, value, isEmpty }, idx) => (
          <Flexbox key={idx} className={s.textRow} data-cy="persone-info-card">
            {isEmpty ? (
              <Text color="textSecondary" className={s.label} />
            ) : (
              <>
                <Text color="textSecondary" className={s.label}>
                  {label}:
                </Text>
                <Text whiteSpace="pre-wrap" className={s.value}>
                  {value || '-'}
                </Text>
              </>
            )}
          </Flexbox>
        ))}
      </ShowMoreText>
    </Box>
  )
}

export default PersonInfoCard
