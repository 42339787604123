import jwtDecode from 'jwt-decode'
import { login, logout } from './user.slice'

import PMK from '~/services/PMK'
import Deserializer from '~/services/Deserializer'
import ErrorHandler from '~/utils/errorHandler'
import { getQuestionnaires } from '../questionnaires'

import { AppThunk } from '~/state/store'

import { STORAGE } from '~/constants/storage'
import { ROLES } from '~/constants/roles'
import { PACKAGES } from '~/constants/packages'

import Actioncable from '~/services/Acable'

// Actions

/**
 * User logout action
 */
export const logoutUser = (): AppThunk<void> => (dispatch): void => {
  try {
    const showWelcomeDialog = localStorage.getItem(STORAGE.showWelcomeDialog)

    Actioncable.disconnectConsumer()

    PMK.setAuthToken()

    localStorage.clear()

    if (showWelcomeDialog) {
      localStorage.setItem(STORAGE.showWelcomeDialog, 'true')
    }

    dispatch(logout())
  } catch (error) {
    throw new ErrorHandler(error)
  }
}

export const authtorize = ({
  jwt
}: {
  jwt: string
}): AppThunk<
  Promise<{
    invited?: boolean
    expired?: boolean
    premium?: boolean
  }>
> => async (
  dispatch
): Promise<{
  invited?: boolean
  expired?: boolean
  premium?: boolean
}> => {
  try {
    let decodedToken: {
      [key: string]: any
    } = {}

    decodedToken = jwtDecode(jwt)

    const isExpired = new Date(decodedToken?.exp * 1000) < new Date()

    if (isExpired) {
      dispatch(logoutUser())

      return {
        expired: true
      }
    } else {
      PMK.setAuthToken(jwt)
      Actioncable.updateConsumer(jwt)
      localStorage.setItem(STORAGE.authToken, jwt)

      dispatch(
        login({
          ...decodedToken,
          token: jwt
        })
      )

      const id = decodedToken?.id
      const role = decodedToken?.role
      const isParent = role === ROLES.Parent
      let userData = {}

      if (role === ROLES.Specialist) {
        userData = await PMK.getSpecialist(id)
      } else if (isParent) {
        userData = await PMK.getUser(id)
        await dispatch(getQuestionnaires())
      } else {
        userData = await PMK.getDirector()
      }

      const data = await Deserializer.deserialize(userData)

      if (isParent && !data.confirmed) {
        localStorage.setItem(STORAGE.userEmail, data.email)
      }

      dispatch(login(data))

      return {
        invited: data.invited_to_itinerary,
        premium: data.package === PACKAGES.Premium
      }
    }
  } catch (error) {
    throw new ErrorHandler(error)
  }
}

/**
 * User sign up with email
 */
export const signupUser = (
  user: SignUpInput
): AppThunk<Promise<void>> => async (dispatch): Promise<void> => {
  try {
    const resp = await PMK.createUser(user)
    const data = await Deserializer.deserialize(resp)

    localStorage.setItem(STORAGE.userEmail, user.email)

    dispatch(login(data))
  } catch (error) {
    throw new ErrorHandler(error, true)
  }
}

/**
 * User confirm email
 */
export const signupConfirmEmail = (
  token: string
): AppThunk<
  Promise<{
    invited: boolean
    premium: boolean
  }>
> => async (
  dispatch
): Promise<{
  invited: boolean
  premium: boolean
}> => {
  try {
    const resp = await PMK.confirmUser(token)

    const payload = await dispatch<any>(authtorize(resp))

    return payload
  } catch (error) {
    throw new ErrorHandler(error, true)
  }
}

/**
 * Camp-director confirm email
 */
export const signupConfirmDirectorEmail = (
  token: string
): AppThunk<
  Promise<{
    invited: boolean
    premium: boolean
  }>
> => async (
  dispatch
): Promise<{
  invited: boolean
  premium: boolean
}> => {
  try {
    const resp = await PMK.confirmDirector(token)

    const payload = await dispatch<any>(authtorize(resp))

    return payload
  } catch (error) {
    throw new ErrorHandler(error, true)
  }
}

/**
 * User login action
 */
export const loginUser = (
  user: SignInInput
): AppThunk<
  Promise<{
    invited: boolean
    premium: boolean
  }>
> => async (
  dispatch
): Promise<{
  invited: boolean
  premium: boolean
}> => {
  try {
    const resp = await PMK.login(user)

    const payload = await dispatch<any>(authtorize(resp))

    return payload
  } catch (error) {
    throw new ErrorHandler(error)
  }
}

/**
 * Specialist login action
 */
export const loginSpecialist = (
  user: SignInInput
): AppThunk<Promise<void>> => async (dispatch): Promise<void> => {
  try {
    const resp = await PMK.loginSpecialist(user)

    dispatch<any>(authtorize(resp))
  } catch (error) {
    throw new ErrorHandler(error)
  }
}

/**
 * Camp-director login action
 */
export const loginDirector = (
  user: SignInInput
): AppThunk<Promise<void>> => async (dispatch): Promise<void> => {
  try {
    const resp = await PMK.loginDirector(user)

    dispatch<any>(authtorize(resp))
  } catch (error) {
    throw new ErrorHandler(error)
  }
}
/**
 * Camp-director sign-up action
 */
export const signUpDirector = (
  user: SignInInput
): AppThunk<Promise<void>> => async (dispatch): Promise<void> => {
  try {
    const resp = await PMK.createDirector(user)
    const data = await Deserializer.deserialize(resp)

    localStorage.setItem(STORAGE.userEmail, user.email)

    dispatch(login(data))
  } catch (error) {
    throw new ErrorHandler(error, true)
  }
}
/**
 * Camp-director update action
 */
export const updateDirector = (
  user: SignInInput
): AppThunk<Promise<void>> => async (dispatch): Promise<void> => {
  try {
    const resp = await PMK.updateDirector(user)
    const data = await Deserializer.deserialize(resp)

    dispatch(login(data))
  } catch (error) {
    throw new ErrorHandler(error)
  }
}

/**
 * User sign in with google
 */
export const signinWithSocial = (
  values: AuthThirdPartyDTO
): AppThunk<
  Promise<{
    invited: boolean
    premium: boolean
  }>
> => async (
  dispatch
): Promise<{
  invited: boolean
  premium: boolean
}> => {
  try {
    const resp = await PMK.signinWithSocial(values)

    const payload = await dispatch<any>(authtorize(resp))

    return payload
  } catch (error) {
    throw new ErrorHandler(error)
  }
}

/**
 * Camp director sign in with google
 */
export const signinDirectorWithSocial = (
  values: AuthThirdPartyDTO
): AppThunk<
  Promise<{
    invited: boolean
    premium: boolean
  }>
> => async (
  dispatch
): Promise<{
  invited: boolean
  premium: boolean
}> => {
  try {
    const resp = await PMK.signinDirectorWithSocial(values)

    const payload = await dispatch<any>(authtorize(resp))

    return payload
  } catch (error) {
    throw new ErrorHandler(error)
  }
}

/**
 * User send reset link
 */
export const sendResetLink = (values: ForgotPasswordInput): Promise<void> =>
  PMK.sendResetLink(values).catch((error: any) => {
    throw new ErrorHandler(error)
  })

/**
 * Camp-director send reset link
 */
export const sendResetDirectorLink = (
  values: ForgotPasswordInput
): Promise<void> =>
  PMK.sendResetDirectorLink(values).catch((error: any) => {
    throw new ErrorHandler(error)
  })

/**
 * User resend email
 */
export const sendResendEmail = (email: string): Promise<void> =>
  PMK.sendResendEmail(email).catch((error: any) => {
    throw new ErrorHandler(error)
  })

/**
 * Director resend email
 */
export const sendResendDirectorEmail = (email: string): Promise<void> =>
  PMK.sendResendDirectorEmail(email).catch((error: any) => {
    throw new ErrorHandler(error)
  })

/**
 * User reset password action
 */
export const resetPassword = (
  values: ResetPasswordInput
): AppThunk<Promise<void>> => async (dispatch): Promise<void> => {
  try {
    const token = localStorage.getItem(STORAGE.resetToken) || ''

    const resp = await PMK.resetPassword({
      token,
      ...values
    })

    localStorage.removeItem(STORAGE.userEmail)
    localStorage.removeItem(STORAGE.resetToken)

    dispatch(authtorize(resp))
  } catch (error) {
    throw new ErrorHandler(error)
  }
}
/**
 * User reset password action
 */
export const resetDirectorPassword = (
  values: ResetPasswordInput
): AppThunk<Promise<void>> => async (dispatch): Promise<void> => {
  try {
    const token = localStorage.getItem(STORAGE.resetToken) || ''

    const resp = await PMK.resetDirectorPassword({
      token,
      ...values
    })

    localStorage.removeItem(STORAGE.userEmail)
    localStorage.removeItem(STORAGE.resetToken)

    dispatch(authtorize(resp))
  } catch (error) {
    throw new ErrorHandler(error)
  }
}

/**
 * User reset password action
 */
export const changePassword = (
  values: ChangePasswordInput
): AppThunk<Promise<void>> => async (): Promise<void> => {
  try {
    await PMK.changePassword(values)
  } catch (error) {
    throw new ErrorHandler(error)
  }
}

/**
 * User update action
 */
export const updateUser = (
  values: Partial<UpdateUserInput & ChangePasswordInput>
): AppThunk<Promise<void>> => async (dispatch): Promise<void> => {
  try {
    const res = await PMK.updateUser(values)
    const payload = await Deserializer.deserialize(res)

    dispatch(login(payload))
  } catch (error) {
    throw new ErrorHandler(error)
  }
}

/**
 * User invalidate action
 */
export const invalidateUser = (): AppThunk<Promise<void>> => async (
  dispatch,
  getState
): Promise<void> => {
  try {
    const userId = getState().user.id

    const userData = await PMK.getUser(userId)
    const data = await Deserializer.deserialize(userData)

    dispatch(login(data))
  } catch (error) {
    throw new ErrorHandler(error)
  }
}

/**
 * User delete action
 */
export const deleteUser = (): AppThunk<Promise<void>> => async (
  dispatch,
  getState
): Promise<void> => {
  const userId = getState().user.id

  try {
    await PMK.deleteUser(userId)

    dispatch(logoutUser())
  } catch (error) {
    throw new ErrorHandler(error)
  }
}

/**
 * Parent auto login after zoho submit
 */
export const parentAutoSignIn = (
  formId: string
): AppThunk<Promise<void>> => async (dispatch): Promise<void> => {
  try {
    const resp = await PMK.confirmParentAccountFromZoho(formId)

    await dispatch<any>(authtorize(resp))
  } catch (error) {
    throw new ErrorHandler(error, true)
  }
}
