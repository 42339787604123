import React, { FC } from 'react'
import { Link as MuiLink } from '@material-ui/core'

import { Link, Text } from '~/components/shared'

import ROUTES from '~/constants/routes'
import PackagesView from '~/components/views/PackagesView'

const Congratulations: FC = () => {
  return (
    <PackagesView
      title="Congratulations!"
      subtitle={
        <Text mb={5} color="textSecondary">
          Your profile was automatically created and you’re one step closer to
          parenting bliss. Choose a package that suites you best. Then we’ll go
          to work for you. You&apos;ll be notified when your personalized
          itinerary is ready. You can also check your itineraries under your
          profile. If you have questions, you may find answers within our{' '}
          <Link to={ROUTES.faqs} color="primary">
            FAQs
          </Link>{' '}
          or you may contact us at{' '}
          <MuiLink
            color="secondary"
            href="mailto:info@planmykids.com"
            style={{ fontWeight: 700 }}
          >
            info@planmykids.com
          </MuiLink>
        </Text>
      }
    />
  )
}

export default Congratulations
