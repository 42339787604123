import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { useHistory } from 'react-router-dom'
import { useModal } from 'react-modal-hook'
import {
  Button,
  FormControlLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  Switch
} from '@material-ui/core'
import { PersonAdd } from '@material-ui/icons'
import cn from 'classnames'

import withGoogleMaps from '~/containers/withGoogleMaps'
import Premium from '~/containers/Premium'
import CongratulationDialog from '~/components/dialogs/CongratulationDialog'
import { Flexbox, Loader, Text, Title } from '~/components/shared'
import { ItineraryCard } from '~/components/cards'
import { BookingDialog } from '~/components/dialogs'
import InviteFriendDialog from './components/InviteFriendDialog'
import SpecialistCard from './components/SpecialistCard'
import ItineraryMap from '../components/ItineraryMap'
import Comment from './components/Comment'
import ProfileTemplate from '../profile.template'

import {
  acceptItinerary,
  getParentItineraries
} from '~/state/modules/itineraries'
import {
  changeActiveItem,
  changeActiveTab
} from '~/state/modules/itineraries/parents/parentsItineraries.slice'
import ActivitiesService from '~/services/ActivitiesService'
import { humanizeDate, sortByDate } from '~/utils/functions'
import useQueryParams from '~/hooks/useQueryParams'

import { STATUSES, STATUSES_SHARED } from '~/constants/statusesItinerary'
import { ININERARY_ACTIVITIES_STATUSES } from '~/constants/itineraryActivitiesStatuses'
import { AppState } from '~/state/store'

import ROUTES from '~/constants/routes'

import useItinerariesStyles from './itineraries.styles'
import { ChatbotAssistant } from '~/components/forms'
import useGetChatbotMessages from '~/hooks/useGetChatbotMessages'
import useRefetchItineraries from '~/hooks/useRefetchItineraries'

const ItinerariesBtns: {
  label: string
  value: StatusesUnion
}[] = [
  {
    label: 'In progress',
    value: STATUSES.in_progress
  },
  {
    label: 'Approved',
    value: STATUSES.approved
  }
]

type ReduxProps = {
  data: Itinerary[]
  item: Partial<Itinerary>
  loading: boolean
  loaded: boolean
  activeTab: StatusesUnion
}

type dispatchProps = {
  getParentItineraries: () => void
  changeActiveTab: (tab: StatusesUnion) => void
  changeActiveItem: (id: string) => void
  acceptItinerary: (id: string) => Promise<void>
}

type AllProps = ReduxProps & dispatchProps

const Itineraries: FC<AllProps> = ({
  loading,
  loaded,
  data,
  item,
  activeTab,
  getParentItineraries,
  changeActiveTab,
  changeActiveItem,
  acceptItinerary
}) => {
  const s = useItinerariesStyles()
  const [isShowMap, setIsShowMap] = useState(false)
  const history = useHistory()
  const query = useQueryParams()
  const payment = query.get('payment')
  const isApproved = item?.status === STATUSES.approved

  useRefetchItineraries(item?.id)
  useGetChatbotMessages()

  useEffect(() => {
    getParentItineraries()
    changeActiveTab(STATUSES.in_progress)
  }, [])

  const childFirstName = item?.questionnaire?.fields?.child_first_name

  const isBookingAvailable = (item?.itinerary_activities || []).some(
    (activity) =>
      activity?.payment_status === ININERARY_ACTIVITIES_STATUSES.easyBooking
  )

  const isAvailableForPayment = (item?.itinerary_activities || []).some(
    (activity) =>
      activity?.payment_status ===
      ININERARY_ACTIVITIES_STATUSES.availableForPayment
  )

  const [showCongatulationModal, hideCongatulationModal] = useModal(
    (props: any) => (
      <CongratulationDialog
        onClose={(): void => {
          hideCongatulationModal()
          history.push(ROUTES.itineraries)
        }}
        {...props}
      />
    ),
    []
  )

  useEffect(() => {
    if (payment) showCongatulationModal()
  }, [payment])

  const [showApproveModal, hideApproveModal] = useModal(
    (props: any) => (
      <BookingDialog
        isBookingAvailable={isBookingAvailable}
        onClose={hideApproveModal}
        onBooking={async (): Promise<void> => {
          try {
            await acceptItinerary(item?.id)
            history.push(`/profile/payment/${item?.id}`)
          } catch (error) {
            console.error(error)
          }
        }}
        onBookingLater={async (): Promise<void> => {
          try {
            await acceptItinerary(item?.id)
          } catch (error) {
            console.error(error)
          }
        }}
        title={
          isBookingAvailable
            ? 'Congratulation!'
            : 'You have approved this itinerary. A specialist will review it and contact you shortly'
        }
        text={
          isBookingAvailable &&
          "Almost done! For your convenience, the activities marked 'Easy Booking' can be booked now. You can book the remaining activities through the provider's website using the"
        }
        textWithColor={isBookingAvailable && 'Open Camp Page'}
        startBookingText={isBookingAvailable ? 'Start Booking' : 'Accept'}
        laterBookingText={isBookingAvailable ? 'Book Later' : 'Cancel'}
        {...props}
      />
    ),
    [item]
  )

  const [showInviteModal, hideInviteModal] = useModal(
    (props: any) => <InviteFriendDialog onClose={hideInviteModal} {...props} />,
    [item]
  )

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      changeActiveItem((event.target as HTMLInputElement).value)
    },
    []
  )

  const handleChangeMap = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setIsShowMap(event.target.checked)
    },
    []
  )

  const {
    activitiesData,
    activitiesSum,
    isReview,
    isAccepted,
    isShowComment,
    activitiesStripeSum
  } = useMemo(
    () => ({
      activitiesData: ActivitiesService.generateParentItineraryActivities(
        sortByDate(item?.itinerary_activities, 'start_date', false, 'activity')
      ),
      activitiesSum: ActivitiesService.generateActivitiesSum(item?.activities),
      activitiesStripeSum: ActivitiesService.generatePaymentActivitiesSum(
        item?.itinerary_activities
      ),
      isReview: item?.status === STATUSES.review,
      isAccepted: item?.status === STATUSES.accepted,
      isShowComment: [STATUSES.review, STATUSES.alternate_request].some(
        (status) => status === item?.status
      )
    }),
    [item]
  )

  const isDisabledChatField = isAccepted || isApproved

  return (
    <ProfileTemplate>
      <Flexbox mb={3} className={s.btns}>
        {ItinerariesBtns.map(({ value, label }) => (
          <Button
            data-cy="itineraries-status-button"
            key={value}
            color={value === activeTab ? 'primary' : 'default'}
            variant="outlined"
            size="small"
            onClick={(): void => {
              changeActiveTab(value)
            }}
          >
            {label}
          </Button>
        ))}
      </Flexbox>

      {loading ? (
        <Loader noGutters boxProps={{ flex: 1 }} />
      ) : data?.length && loaded ? (
        <>
          <Flexbox className={s.titleWrapper}>
            <Title variant="h4" data-cy="itineraries-title">
              {childFirstName && `${childFirstName}'s`} Itinerary
            </Title>

            <FormControlLabel
              value="map"
              control={
                <Switch
                  data-cy="show-map-switch"
                  checked={isShowMap}
                  color="primary"
                  onChange={handleChangeMap}
                />
              }
              label="Show map"
              labelPlacement="start"
              className={s.labelMap}
            />
          </Flexbox>

          <Flexbox className={s.radiosWrapper}>
            <Text mr={3} whiteSpace="nowrap">
              Choose a child
            </Text>

            <RadioGroup
              row
              value={item?.id}
              onChange={handleChange}
              className={s.labelGroup}
            >
              {data.map(({ id, questionnaire, activity_dates }) => {
                const startDate = humanizeDate(
                  activity_dates?.start,
                  'DD/MM/YY'
                )
                const endDate = humanizeDate(activity_dates?.end, 'DD/MM/YY')
                const childName = questionnaire?.fields?.child_first_name

                return (
                  <FormControlLabel
                    data-cy="itineraries-radio"
                    key={id}
                    value={id}
                    label={
                      <Flexbox>
                        <Text className={s.labelText} mr={0.5}>
                          {childName}
                        </Text>

                        {isApproved && (
                          <Text whiteSpace="nowrap">
                            - {startDate} - {endDate}
                          </Text>
                        )}
                      </Flexbox>
                    }
                    control={<Radio color="primary" />}
                    className={cn(s.label, { checked: id === item?.id })}
                  />
                )
              })}
            </RadioGroup>
          </Flexbox>

          {isShowMap ? (
            <ItineraryMap activities={item?.activities} />
          ) : (
            <>
              {item?.specialist && !!activitiesData?.length && (
                <SpecialistCard message={item?.message} {...item?.specialist} />
              )}
              {activitiesData?.length ? (
                <>
                  {activitiesData.map((activity, idx) => (
                    <ItineraryCard
                      key={`${activity?.id}-${idx}`}
                      isLast={idx === activitiesData.length - 1}
                      isApproved={isApproved}
                      {...activity}
                    />
                  ))}

                  <Flexbox mt={2} width="100%">
                    <ChatbotAssistant
                      itineraryId={item.id}
                      isDisabledField={isDisabledChatField}
                    />
                  </Flexbox>

                  <Flexbox>
                    <Text
                      color="textSecondary"
                      fontSize={18}
                      mt={2}
                      mb={3}
                      mr={2.5}
                    >
                      Itinerary Total :{' '}
                      <Text component="span" className={s.total}>
                        {activitiesSum}
                      </Text>
                    </Text>
                    {activitiesStripeSum && (
                      <Text color="textSecondary" fontSize={18} mt={2} mb={3}>
                        Booking Available Total :{' '}
                        <Text component="span" className={s.total}>
                          {activitiesStripeSum}
                        </Text>
                      </Text>
                    )}
                  </Flexbox>
                </>
              ) : (
                <Text mb={5} flex={1}>
                  No activities yet, but don’t worry, we’re working on it.
                </Text>
              )}
            </>
          )}

          {!!item?.parents_itineraries?.length && (
            <>
              <Title variant="h5" textAlign="start" mb={1}>
                Friends invitation
              </Title>

              <List disablePadding style={{ marginBottom: 30 }}>
                {item?.parents_itineraries.map(
                  ({ id, friend_name, status, comment }) => {
                    let inviteText = ''

                    switch (status) {
                      case STATUSES_SHARED.draft:
                        inviteText = `Your invitation is pending with a ${friend_name}`
                        break
                      case STATUSES_SHARED.accepted:
                        inviteText = `A ${friend_name} accepted your invitation.`
                        break
                      case STATUSES_SHARED.declined:
                        inviteText = `A ${friend_name} declined your invitation.`
                        break
                    }

                    return (
                      <ListItem key={id} alignItems="flex-start">
                        <ListItemIcon>
                          <PersonAdd />
                        </ListItemIcon>
                        <ListItemText
                          primary={inviteText}
                          secondary={
                            !!comment && (
                              <Flexbox>
                                <Text
                                  variant="body2"
                                  color="textPrimary"
                                  mr={0.5}
                                >
                                  Comment:
                                </Text>
                                <Text variant="body2">{comment}</Text>
                              </Flexbox>
                            )
                          }
                        />
                      </ListItem>
                    )
                  }
                )}
              </List>
            </>
          )}

          {isReview && (
            <Flexbox className={cn(s.titleWrapper, 'buttons')}>
              <Button
                data-cy="itineraries-approve-button"
                size="large"
                variant="contained"
                color="primary"
                onClick={showApproveModal}
              >
                Accept Itinerary
              </Button>

              <Premium>
                <Button
                  data-cy="itineraries-invite-button"
                  size="large"
                  variant="outlined"
                  color="primary"
                  onClick={showInviteModal}
                >
                  Invite a friend
                </Button>
              </Premium>
            </Flexbox>
          )}

          {isAccepted && (
            <Flexbox
              className={cn(s.titleWrapper, 'buttons')}
              justifyContent={!isBookingAvailable && 'flex-start !important'}
            >
              {!isBookingAvailable && item?.status === STATUSES.accepted && (
                <Text variant="subtitle1" mr={2}>
                  Your itinerary was accepted
                </Text>
              )}

              {isAvailableForPayment && (
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={(): void => {
                    history.push(`/profile/payment/${item?.id}`)
                  }}
                >
                  Start Booking
                </Button>
              )}
            </Flexbox>
          )}

          {isShowComment && <Comment id={item?.id} />}

          {isApproved && (
            <Text variant="subtitle1">Your itinerary was approved</Text>
          )}
        </>
      ) : (
        <Text>No results</Text>
      )}
    </ProfileTemplate>
  )
}

const mapStateToProps = ({
  itineraries: {
    parents: { data, ...itineraries }
  }
}: AppState): ReduxProps => {
  return {
    data: data[itineraries.activeTab],
    ...itineraries
  }
}

const withConnect = connect(mapStateToProps, {
  getParentItineraries,
  changeActiveTab,
  changeActiveItem,
  acceptItinerary
})

export default compose<React.FC>(withGoogleMaps(), withConnect)(Itineraries)
