import React, { FC } from 'react'
import ReactCarousel from 'react-slick'
import cn from 'classnames'

import { Box, createStyles, makeStyles } from '@material-ui/core'

import { ReactComponent as ArrowLeft } from '~/assets/images/home/arrow-left-carousel.svg'
import { ReactComponent as ArrowRight } from '~/assets/images/home/arrow-right-carousel.svg'

import { Title } from '~/components/shared'
import CampCard from './CampCard'

import programSpotlight from '~/data/home/programSpotlight'

const useStyles = makeStyles(
  ({ spacing, palette, breakpoints }) =>
    createStyles({
      slideItem: {
        padding: spacing(1),

        '& .MuiCard-root': {
          minHeight: '566px',

          [breakpoints.down(894)]: {
            minHeight: '566px'
          },

          [breakpoints.down(721)]: {
            minHeight: '513px'
          },

          [breakpoints.down('xs')]: {
            minHeight: '565px'
          },

          '& .MuiListItem-root': {
            [breakpoints.down(894)]: {
              width: '100%'
            }
          }
        }
      },
      button: {
        display: 'flex !important',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 40,
        width: 30,
        zIndex: 1200,

        '&.slick-prev': {
          left: -6
        },

        '&.slick-disabled svg path': {
          fill: palette.text.hint
        },

        '&:before': {
          display: 'none'
        }
      }
    }),
  {
    name: 'ProgramSpotlightSection'
  }
)

const SampleNextArrow = (props): JSX.Element => {
  const s = useStyles()
  const { className, style, onClick } = props

  return (
    <div className={cn(className, s.button)} style={style} onClick={onClick}>
      <ArrowRight height={19} width={11} />
    </div>
  )
}

const SamplePrevArrow = (props): JSX.Element => {
  const s = useStyles()
  const { className, style, onClick } = props

  return (
    <div className={cn(className, s.button)} style={style} onClick={onClick}>
      <ArrowLeft height={19} width={11} />
    </div>
  )
}

const carouselSettings = {
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  speed: 500,
  swipeToSlide: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 720,
      settings: {
        slidesToShow: 1
      }
    }
  ]
}

const ProgramSpotlightSection: FC = () => {
  const s = useStyles()

  return (
    <>
      <Title variant="h1" mb={5}>
        Program spotlight
      </Title>

      <ReactCarousel {...carouselSettings}>
        {programSpotlight.map((camp, idx) => (
          <Box key={idx} className={s.slideItem}>
            <CampCard {...camp} />
          </Box>
        ))}
      </ReactCarousel>
    </>
  )
}

export default ProgramSpotlightSection
