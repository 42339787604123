import { createStyles, makeStyles } from '@material-ui/core'

const useEditProfileStyles = makeStyles(
  ({ palette, breakpoints, spacing }) =>
    createStyles({
      titleWrapper: {
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: spacing(3),
        width: '100%',

        '& h4': {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'start'
        },

        [breakpoints.down('sm')]: {
          flexDirection: 'column',
          alignItems: 'flex-start',

          '& h4': {
            marginBottom: spacing(1)
          }
        }
      },

      subWrapper: {
        alignItems: 'center',

        [breakpoints.down('xs')]: {
          flexDirection: 'column',
          alignItems: 'flex-start'
        }
      },

      subType: {
        whiteSpace: 'nowrap',
        marginRight: spacing(1),

        [breakpoints.down('xs')]: {
          whiteSpace: 'initial',
          marginBottom: spacing(0.5)
        }
      },

      chip: {
        padding: '5px 15px',
        height: 30,
        fontWeight: 600
      },

      chipPending: {
        padding: '5px 10px',
        marginLeft: 10,
        height: 28,
        fontWeight: 600,
        color: palette.success.dark,
        borderColor: palette.success.dark,

        '& svg': {
          width: 18,
          height: 18,
          margin: '0 0 2px 5px'
        },

        '&.paid': {
          color: palette.success.main,
          borderColor: palette.success.main
        }
      }
    }),
  {
    name: 'EditProfile'
  }
)

export default useEditProfileStyles
