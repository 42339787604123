import React from 'react'
import { CircularProgress, Box } from '@material-ui/core'

import Text from '../shared/Text'

const Confirmation: React.FC = (): JSX.Element => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      flex={1}
    >
      <CircularProgress size={60} />

      <Text variant="h4" mt={4} mb={1}>
        Confirmation in progress
      </Text>

      <Text variant="subtitle1" color="textSecondary">
        Please waiting ...
      </Text>
    </Box>
  )
}

export default Confirmation
