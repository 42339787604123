import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Field } from 'redux-form'
import { Grid } from '@material-ui/core'

import { AutocompleteField } from '~/components/fields'

import { AppState } from '~/state/store'
import { PriceField } from '.'

import ageOptions from '~/data/profile/ageOptions'
import gradeLevelOptions from '~/data/gradeLevelOptions'
import skillDevelopmentOptions from '~/data/camp-director/skillDevelopmentOptions'
import availableSeatsOption from '~/data/camp-director/availableSeatsOptions'

type Props = {
  minPrice: number
  maxPrice: number
  campTypes: string[]
}

const Filters: React.FC<Props> = ({ campTypes, ...props }) => {
  const activities =
    useSelector(
      (state: AppState) => state?.activities?.meta?.activity_providers
    ) || []

  const interests =
    useSelector((state: AppState) => state?.activities?.meta.interests) || []

  const interestsProvider = useMemo(
    () =>
      interests?.map(({ interest }) => {
        return {
          label: `${interest}`,
          value: interest
        }
      }),
    [interests]
  )

  const activitiesProvider = useMemo(
    () =>
      Object.entries(activities).map(([key, value]) => {
        return {
          label: value,
          value: key
        }
      }),
    [activities]
  )

  const campTypesOptions = useMemo(
    () =>
      campTypes.map((type) => ({
        label: type,
        value: type
      })),
    [campTypes]
  )

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Field
          name="activity_provider"
          label="Activity provider"
          component={AutocompleteField}
          options={activitiesProvider}
          onChangeKey="value"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="interests"
          label="Interests"
          component={AutocompleteField}
          options={interestsProvider}
          onChangeKey="value"
          multiple
        />
      </Grid>

      <Grid item xs={12}>
        <Field
          id="skill_development"
          name="skill_development"
          label="Skill developments"
          component={AutocompleteField}
          options={skillDevelopmentOptions || []}
          groupBy={(option: InterestOption): InterestsLabel =>
            option.interestLabel
          }
          multiple
          onChangeKey="value"
        />
      </Grid>

      <Grid item xs={12}>
        <PriceField {...props} />
      </Grid>

      <Grid item xs={12}>
        <Field
          name="age"
          label="Age"
          component={AutocompleteField}
          options={ageOptions}
          onChangeKey="value"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="grade_level"
          label="Grade level"
          component={AutocompleteField}
          options={gradeLevelOptions}
          onChangeKey="value"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="type"
          label="Camp type"
          component={AutocompleteField}
          options={campTypesOptions}
          onChangeKey="value"
        />
      </Grid>

      <Grid item xs={12}>
        <Field
          name="available_seats"
          label="Available seats"
          component={AutocompleteField}
          options={availableSeatsOption}
          onChangeKey="value"
        />
      </Grid>
    </Grid>
  )
}

export default Filters
