import React, { FC, useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import { Box } from '@material-ui/core'

import CalendarPopover from '../Popover/CalendarPopover'

import useCalendarStyles from './Calendar.styles'

type Props = {
  events: ActivityEvent[]
}

const Calendar: FC<Props> = ({ events }) => {
  const s = useCalendarStyles()

  const [anchorEl, setAnchorEl] = useState(null)
  const [event, setEvent] = useState<EventProps>({})

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const handleEventClick = (info: any): void => {
    const {
      extendedProps,
      title,
      ui: { backgroundColor }
    } = info.event._def

    setEvent({
      title,
      location,
      backgroundColor,
      ...extendedProps
    })

    if (info.el.clientHeight === 0 && info.el.clientWidth === 0) {
      setAnchorEl({})
    } else {
      setAnchorEl(info.el)
    }
  }

  return (
    <Box className={s.root} data-cy="calendar">
      <FullCalendar
        plugins={[dayGridPlugin, listPlugin]}
        headerToolbar={{
          left: 'today',
          center: 'title',
          right: 'prev,next dayGridMonth,listMonth'
        }}
        initialView="dayGridMonth"
        events={events}
        dayMaxEventRows={3}
        moreLinkText={(num): string => `See all (${num})`}
        dayPopoverFormat={{
          day: 'numeric'
        }}
        viewClassNames={s.view}
        dayHeaderClassNames={s.dayHeader}
        dayCellClassNames={s.dayCell}
        moreLinkClassNames={s.moreLink}
        eventClassNames={s.event}
        eventClick={handleEventClick}
        eventOrder="-end"
      />
      <CalendarPopover el={anchorEl} event={event} handleClose={handleClose} />
    </Box>
  )
}

export default Calendar
