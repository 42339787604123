import { makeStyles, createStyles } from '@material-ui/core'

import { fonts } from '~/theme/typography'

const useCalendarStyles = makeStyles(
  ({ palette, breakpoints }) =>
    createStyles({
      root: {
        '& h2': {
          fontFamily: fonts.serif,
          fontWeight: 400,
          fontSize: 24
        },

        '& .fc-toolbar': {
          '& button': {
            padding: '2px 7px',
            height: 30,
            backgroundColor: 'transparent',
            borderColor: palette.text.disabled,
            color: palette.text.secondary,
            textTransform: 'capitalize',

            '&:hover': {
              backgroundColor: palette.grey[300],
              color: palette.text.secondary,
              borderColor: palette.text.disabled
            },

            '&:focus': {
              boxShadow: 'none !important'
            },

            '&:disabled': {
              backgroundColor: 'rgba(0, 0, 0, 0.12)',
              color: 'rgba(0, 0, 0, 0.26)',
              borderColor: palette.text.disabled
            },

            '&:not(:disabled):active': {
              backgroundColor: palette.text.hint,
              borderColor: palette.text.disabled
            }
          },

          [breakpoints.down('xs')]: {
            flexDirection: 'column',

            '& .fc-toolbar-chunk': {
              '&:first-child': {
                alignSelf: 'flex-start'
              },

              '&:last-child': {
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                marginTop: 15
              }
            }
          }
        },

        '& .fc-today-button': {
          padding: '2px 20px !important'
        },

        [breakpoints.down('sm')]: {
          '& .fc-view-harness': {
            height: '100% !important',

            '& > .fc-view': {
              position: 'relative'
            }
          },

          '& .fc-scroller-liquid-absolute': {
            position: 'relative',
            overflow: 'visible !important'
          }
        }
      },

      view: {
        '& table.fc-scrollgrid, th, td': {
          border: `1px dashed ${palette.text.hint}`
        },

        '& table.fc-scrollgrid': {
          borderRightWidth: 0,
          borderBottomWidth: 0
        }
      },

      dayHeader: {
        '& a': {
          display: 'block !important',
          textAlign: 'left',
          fontWeight: 400,
          color: palette.text.secondary
        }
      },

      dayCell: {
        '&.fc-day-today': {
          backgroundColor: 'rgba(251, 76, 216, 0.15) !important'
        },

        '& .fc-daygrid-day-top': {
          flexDirection: 'row'
        },

        '&.fc-more-popover': {
          border: 'none',
          borderRadius: 6,
          boxShadow: '0px 6px 24px rgba(0, 0, 0, 0.08)',

          '& .fc-popover-header': {
            padding: '10px 10px 0 15px',
            background: palette.common.white,
            fontWeight: 600,

            '& .fc-popover-close': {
              color: palette.text.hint
            }
          },

          '& .fc-popover-body': {
            padding: '10px 15px 11px',
            backgroundColor: palette.common.white
          }
        }
      },

      event: {
        padding: '3px 9px',
        height: 25,
        marginBottom: 4,
        borderRadius: 4,
        border: 'none',

        '& .fc-event-title': {
          textOverflow: 'ellipsis'
        },

        '& .fc-list-event-time': {
          display: 'none'
        },

        '& .fc-event-title-container': {
          cursor: 'pointer'
        },

        '& .fc-list-event-title': {
          cursor: 'pointer'
        }
      },

      moreLink: {
        color: palette.primary.main,
        fontSize: 12,
        fontWeight: 600
      }
    }),
  {
    name: 'Calendar'
  }
)

export default useCalendarStyles
