import React, { FC, useEffect, useMemo } from 'react'
import { RouteComponentProps } from 'react-router'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form'
import isEqual from 'lodash.isequal'
import debounce from 'lodash.debounce'
import { Box, Grid, useMediaQuery, Theme } from '@material-ui/core'

import withGoogleMaps from '~/containers/withGoogleMaps'
import Desktop from '~/containers/Desktop'
import Mobile from '~/containers/Mobile'
import { Loader, Text, Title } from '~/components/shared'
import { InfoAccordion } from '~/components/accordions'
import SearchFilters from './components/SearchFilters'
import ActivityCard from './components/ActivityCard'
import ItineraryBlock from './components/ItineraryBlock'
import FiltersDrawer from './components/FiltersDrawer'
import ActiveFilters from './components/ActiveFilters'
import { PaginationField } from './components/filters'
import Filters from './components/filters/Filters'
import InfoBlock from './components/InfoBlock'

import { getItineraryById } from '~/state/modules/dashboard'
import { getActivities } from '~/state/modules/activities'
import ItineraryService from '~/services/ItineraryService'
import ActivitiesService from '~/services/ActivitiesService'
import SearchService from '~/services/SearchService'

import ROUTES from '~/constants/routes'
import { AppState } from '~/state/store'

import { ReactComponent as FilterIcon } from '~/assets/icons/filter.svg'

import useSearchStyles from './search.styles'

type ReduxProps = {
  initialValues: Partial<SearchInput>
  formValues: Partial<SearchInput>

  itinerary: Partial<Itinerary>
  loadingItinerary: boolean
  loadedItinerary: boolean

  activities: Activity[]
  loadingActivities: boolean
  loadedActivities: boolean
  meta: ActivitiesMeta
}

type dispatchProps = {
  getItineraryById: (id: string) => void
  fetchData: (params: any) => void
}

type RouterState = {
  itineraryId: string
}

type RouteProps = RouteComponentProps<{}, {}, RouterState>

type AllProps = ReduxProps & dispatchProps & RouteProps & InjectedFormProps

const Search: FC<AllProps> = ({
  location,

  form,
  initialize,

  formValues,
  loadingItinerary,
  loadedItinerary,
  itinerary,

  loadingActivities,
  loadedActivities,
  activities,
  meta,

  fetchData,
  getItineraryById
}) => {
  const s = useSearchStyles()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const itineraryId = location.state?.itineraryId

  useEffect(() => {
    if (itineraryId && !loadedItinerary) {
      getItineraryById(itineraryId)
    }
  }, [itineraryId, loadedItinerary])

  useEffect(() => {
    if (itineraryId ? loadedItinerary : true) {
      if (!loadedActivities) {
        const { initialValues, priceInQuery } = SearchService.createValues(
          location.search,
          meta
        )
        const searchDTO = SearchService.createDTO(
          initialValues,
          priceInQuery,
          itinerary?.location
        )

        fetchData(searchDTO)
      } else {
        const { initialValues } = SearchService.createValues(
          location.search,
          meta
        )

        initialize(initialValues)
      }
    }
  }, [itineraryId, loadedItinerary, loadedActivities])

  const fields = useMemo(() => itinerary?.questionnaire?.fields, [itinerary])

  const activitiesData = useMemo(
    () =>
      ActivitiesService.generateActivities<ActivityCard>(
        activities,
        itinerary?.activities
      ),
    [activities, itinerary?.activities]
  )

  const ItineraryRow = useMemo(
    () =>
      itineraryId ? (
        loadingItinerary ? (
          <Loader />
        ) : (
          loadedItinerary &&
          fields && (
            <>
              {!!itinerary?.change_requests?.length && (
                <Grid item xs={12}>
                  <InfoAccordion
                    isRequestChange
                    title="Change requests"
                    data={ItineraryService.generateCommentsData(
                      itinerary.change_requests
                    )}
                    variantTitle="h5"
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <InfoAccordion
                  title="Parent info"
                  data={ItineraryService.generateParentData(
                    fields,
                    itinerary?.parent
                  )}
                  variantTitle="h5"
                />
              </Grid>

              <Grid item xs={12}>
                <InfoAccordion
                  title="Child info"
                  data={ItineraryService.generateChildData(fields)}
                  variantTitle="h5"
                />
              </Grid>

              <Grid item xs={12}>
                <InfoAccordion
                  title="Questionnaires"
                  data={ItineraryService.generateAnswersData(fields)}
                  variantTitle="h5"
                />
              </Grid>

              <Grid item xs={12}>
                <ItineraryBlock
                  activities={itinerary?.itinerary_activities}
                  itineraryId={itinerary?.id}
                />
              </Grid>
            </>
          )
        )
      ) : null,
    [itineraryId, loadedItinerary, fields, itinerary]
  )

  return (
    <Box mt={{ xs: 5, md: 7 }} mb={6}>
      <Title customClassName={s.title}>Kids&apos; Camps</Title>

      <Desktop>
        <SearchFilters
          minPrice={meta.min_price}
          maxPrice={meta.max_price}
          campTypes={meta.camp_types}
          distanceCenter={itinerary?.location}
        />
      </Desktop>

      <Mobile>
        <Box mb={5}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FiltersDrawer counter={meta.count}>
                <Box mb={2}>
                  <ActiveFilters
                    formValues={formValues}
                    form={form}
                    meta={meta}
                  />
                </Box>

                <Title variant="h4" mb={3} textAlign="start">
                  <FilterIcon className={s.icon} />
                  Filters
                </Title>

                <SearchFilters
                  minPrice={meta.min_price}
                  maxPrice={meta.max_price}
                  campTypes={meta.camp_types}
                  distanceCenter={itinerary?.location}
                />
              </FiltersDrawer>
            </Grid>

            {ItineraryRow}
          </Grid>
        </Box>
      </Mobile>

      <Grid container spacing={2}>
        <Desktop>
          <Grid item xs={12} md={3}>
            <Title
              variant="h4"
              mb={1.5}
              textAlign="start"
              data-cy="search-component-title"
            >
              Filters
            </Title>

            <Box className={s.chipsWrapper}>
              <ActiveFilters formValues={formValues} form={form} meta={meta} />
            </Box>

            <Box className={s.chipsWrapper}>
              <Filters
                minPrice={meta.min_price}
                maxPrice={meta.max_price}
                campTypes={meta.camp_types}
              />
            </Box>
          </Grid>
        </Desktop>

        <Grid item xs={12} md={6}>
          {loadingActivities ? (
            <Loader />
          ) : loadedActivities && !!activitiesData?.length ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Title
                  variant="h4"
                  mb={-0.5}
                  textAlign="start"
                  data-cy="search-component-title"
                >
                  Found {meta.count} courses
                </Title>
              </Grid>

              {activitiesData.map((activity) => (
                <Grid item xs={12} key={activity.id}>
                  <ActivityCard
                    {...activity}
                    itineraryId={itineraryId}
                    locationState={location.state}
                  />
                </Grid>
              ))}

              <Grid item xs={12}>
                <Box mt={1}>
                  <Field
                    name="page"
                    count={meta.pages}
                    siblingCount={isMobile ? 0 : 1}
                    component={PaginationField}
                  />
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Text mt={8} textAlign="center">
              No results found.
            </Text>
          )}
        </Grid>

        <Desktop>
          <Grid item xs={12} md={3} style={{ marginTop: '45px' }}>
            <Grid container spacing={2}>
              {ItineraryRow ? ItineraryRow : <InfoBlock />}
            </Grid>
          </Grid>
        </Desktop>
      </Grid>
    </Box>
  )
}

const mapStateToProps = ({
  dashboard,
  activities,
  ...state
}: AppState): ReduxProps => {
  return {
    initialValues: {
      isInitialize: true
    },
    formValues: getFormValues('Search_Filters_Form')(state) || {},

    loadingItinerary: dashboard.loading,
    loadedItinerary: dashboard.loaded,
    itinerary: dashboard.item,

    loadingActivities: activities.loading,
    loadedActivities: activities.loaded,
    activities: activities.data,
    meta: activities.meta
  }
}

const withConnect = connect(mapStateToProps, {
  getItineraryById,
  fetchData: getActivities
})

const withForm = reduxForm<SearchInput, AllProps>({
  form: 'Search_Filters_Form',
  onChange: debounce(
    (
      values,
      dispatch,
      {
        history,
        location,
        loadedActivities,
        meta,
        fetchData,
        change,
        itinerary
      },
      { isInitialize, page }
    ) => {
      if (loadedActivities && !isInitialize) {
        let currentPage = values.page

        if (page === values.page) {
          currentPage = 1

          dispatch(change('page', 1))
        }

        const currentValues = {
          ...values,
          page: currentPage
        }

        const priceInQuery = {
          min: values.price?.[0] !== meta.min_price,
          max: values.price?.[1] !== meta.max_price
        }

        const query = SearchService.createQuery(currentValues, meta)
        const searchDTO = SearchService.createDTO(
          currentValues,
          priceInQuery,
          itinerary?.location
        )

        fetchData(searchDTO)

        history.push({
          pathname: ROUTES.search,
          search: query,
          state: location.state
        })
      }
    },
    500
  )
})

export default compose<React.FC>(
  withGoogleMaps(true),
  withConnect,
  withForm
)(Search)
