import React, { FC } from 'react'
import { Box, Chip, Grid } from '@material-ui/core'
import { AvatarImage, Text } from '~/components/shared'
import useStyles from './Message.styles'
import { generatePlaceholderForImage } from '~/utils/functions'

interface MessageProps {
  message: string
  isOwner: boolean
  name?: string
  icon?: string
}

const Message: FC<MessageProps> = ({ message, isOwner, name, icon }) => {
  const s = useStyles({ isOwner })
  const placeholder = name ? generatePlaceholderForImage(name) : ''

  return (
    <Grid className={s.wrapper} container>
      <Grid
        container
        item
        xs={10}
        justify={isOwner ? 'flex-end' : 'flex-start'}
      >
        <Chip
          label={
            <Box className={s.message}>
              <Box className={s.userInfo}>
                <AvatarImage placeholder={placeholder} src={icon} />

                {name && (
                  <Text mx={1}>
                    <b>{name}</b>
                  </Text>
                )}
              </Box>

              {message}
            </Box>
          }
          classes={{ root: s.root, label: s.label }}
        />
      </Grid>
      <Grid item xs={2} />
    </Grid>
  )
}

export default Message
