import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles, createStyles, Theme, Button } from '@material-ui/core'

import { Flexbox, Title, Text } from '~/components/shared'

import ROUTES from '~/constants/routes'

import verifySuccess from '~/assets/images/verify-success.svg'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageSuccess: {
      width: 230,
      height: 230,
      marginBottom: theme.spacing(2)
    }
  })
)

const StripePending: React.FC = () => {
  const s = useStyles()

  return (
    <Flexbox
      flexDirection="column"
      justifyContent="spaceBatween"
      alignItems="center"
      mt={7}
      mb={7}
    >
      <img
        src={verifySuccess}
        className={s.imageSuccess}
        alt="image for verification with success"
      />

      <Title mb={1.5}>Verification in-progress</Title>

      <Text color="textPrimary" fontWeight={600} textAlign="center">
        Your business data will be verified with Stripe.
      </Text>

      <Text color="textPrimary" fontWeight={600} textAlign="center" mb={2}>
        The length of time for verification can vary and depends on the
        completeness of your submission.
      </Text>

      <Text color="textPrimary" fontWeight={600} textAlign="center" mb={3}>
        When complete, you’ll be able to edit your program and add activities.
      </Text>

      <Button
        component={Link}
        to={ROUTES.campDirectorPrograms}
        variant="contained"
        size="large"
        color="primary"
      >
        Go to programs
      </Button>
    </Flexbox>
  )
}

export default StripePending
