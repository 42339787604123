import React, { FC } from 'react'

import { Avatar } from '@material-ui/core'

interface AvatarImageProps {
  src?: string
  width?: string
  height?: string
  placeholder?: string
  loading?: boolean
}

const AvatarImage: FC<AvatarImageProps> = ({
  src,
  width = '32px',
  height = '32px',
  placeholder
}) => {
  return (
    <Avatar style={{ width, height }} src={src}>
      {placeholder}
    </Avatar>
  )
}

export default AvatarImage
