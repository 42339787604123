import { makeStyles, createStyles } from '@material-ui/core'

const useProgramStyles = makeStyles(
  (theme) =>
    createStyles({
      stripe: {
        width: '100%',
        padding: theme.spacing(1.5, 2, 2),
        marginBottom: 20,
        backgroundColor: '#F6F8F9'
      },

      table: {
        '& th': {
          padding: theme.spacing(1, 3),
          backgroundColor: '#F6F8F9',
          fontWeight: 600,
          borderBottom: 'none',

          '&:last-child': {
            width: '24%',
            textAlign: 'center'
          },

          [theme.breakpoints.down('lg')]: {
            padding: theme.spacing(1, 2),

            '&:first-child': {
              paddingLeft: theme.spacing(3)
            }
          }
        },

        '& td': {
          padding: theme.spacing(1.8, 3),
          borderColor: theme.palette.text.disabled,
          color: theme.palette.text.secondary,
          fontSize: 16,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',

          '&:last-child': {
            minWidth: 120,
            paddingRight: 0
          }
        }
      },

      actionBtn: {
        minWidth: 120,
        maxHeight: 42,

        '&:first-child': {
          marginRight: 10
        }
      },

      empty: {
        width: '100%',
        justifyContent: 'center',
        backgroundColor: '#F8FAFD',
        padding: 20,
        '& p': {
          fontSize: '18px !Important'
        }
      },

      accordionsContainer: {
        [theme.breakpoints.up('sm')]: {
          padding: theme.spacing(0, 2)
        }
      },

      location: { maxWidth: 525 },

      name: { maxWidth: 300 }
    }),
  {
    name: 'Programs'
  }
)

export default useProgramStyles
