import React from 'react'
import { Button, ButtonProps, CircularProgress } from '@material-ui/core'

type Props = {
  children: string | JSX.Element | JSX.Element[] | React.ReactNode
  loading: boolean
  component?: string
}

type AllProps = ButtonProps & Props

const ExtendedButton: React.FC<AllProps> = ({
  children,
  loading,
  ...props
}) => (
  <Button color="primary" {...props}>
    {loading ? <CircularProgress color="inherit" size={26} /> : children}
  </Button>
)

export default ExtendedButton
