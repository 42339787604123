/**
 * Intl service
 */
class IntlService {
  /**
   * Number formatter
   *
   * @param value formatted value
   * @param options Intl.NumberFormatOptions
   */
  static numberFormat(
    value: string | number,
    options?: Intl.NumberFormatOptions
  ): string {
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
      ...options
    })

    return formatter.format(Number(value))
  }

  /**
   * Currency formatter
   *
   * @param value formatter value
   * @param options Intl.NumberFormatOptions
   */
  static currencyFormat(
    value: string | number,
    options?: Intl.NumberFormatOptions
  ): string {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      ...options
    })

    return value ? formatter.format(Number(value)) : ''
  }
}

export default IntlService
