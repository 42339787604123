import React, { FC } from 'react'

import Desktop from '~/containers/Desktop'
import Mobile from '~/containers/Mobile.tsx'
import ContainerFull from '~/components/layouts/ContainerFull'

import HowItWorksDesktop from './HowItWorksBlock.desktop'
import HowItWorksMobile from './HowItWorksBlock.mobile'

import steps from '~/data/home/steps'

export type HowItWorksProps = {
  data: Step[]
}

const HowItWorksBlock: FC = () => {
  return (
    <>
      <Desktop>
        <HowItWorksDesktop data={steps} />
      </Desktop>

      <Mobile>
        <ContainerFull>
          <HowItWorksMobile data={steps} />
        </ContainerFull>
      </Mobile>
    </>
  )
}

export default HowItWorksBlock
