import React from 'react'
import { Button } from '@material-ui/core'

import { ExtendedButton } from '~/components/shared'

import { ReactComponent as Done } from '~/assets/icons/done.svg'

type Props = {
  onAddActivity: () => void
  isAdding: boolean
  isAdded: boolean
  isHideButtons: boolean
  style?: Partial<React.CSSProperties>
  fullWidth?: boolean
}

const ActivityButtons: React.FC<Props> = ({
  isAdding,
  isAdded,
  isHideButtons,
  onAddActivity,
  ...rest
}) => {
  return (
    <>
      {!isHideButtons &&
        (isAdded ? (
          <Button size="small" variant="contained" color="primary" {...rest}>
            <Done style={{ marginRight: 10 }} />
            Added
          </Button>
        ) : (
          <ExtendedButton
            data-cy="activity-card-itineraries-button"
            size="small"
            variant="outlined"
            color="primary"
            onClick={onAddActivity}
            loading={isAdding}
            disabled={isAdding}
            {...rest}
          >
            Add to itinerarie
          </ExtendedButton>
        ))}
    </>
  )
}

export default ActivityButtons
