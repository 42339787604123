import { TokenResponse, useGoogleLogin } from '@react-oauth/google'
import React from 'react'
import { ExtendedButton } from '~/components/shared'
import { ReactComponent as GoogleSVG } from '~/assets/icons/google.svg'
import { makeStyles, createStyles, lighten } from '@material-ui/core'

interface WebGoogleButtonProps {
  loading: boolean
  handleSuccess: (tokenResponse: TokenResponse) => void
}

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      btn: {
        backgroundColor: theme.palette.info.main,

        '&:hover': {
          backgroundColor: lighten(theme.palette.info.main, 0.1)
        }
      }
    }),
  {
    name: 'GoogleButton'
  }
)

const WebGoogleButton: React.FC<WebGoogleButtonProps> = ({
  children,
  loading,
  handleSuccess
}) => {
  const s = useStyles()

  const login = useGoogleLogin({
    onSuccess: handleSuccess
  })

  return (
    <ExtendedButton
      onClick={() => login()}
      loading={loading}
      startIcon={<GoogleSVG />}
      size="large"
      variant="contained"
      fullWidth
      className={s.btn}
    >
      {children}
    </ExtendedButton>
  )
}

export default WebGoogleButton
