import Parent1 from '~/assets/images/home/parent-1.png'
import Parent2 from '~/assets/images/home/parent-2.png'
import Parent3 from '~/assets/images/home/parent-3.png'

import { ParentCardProps } from '~/pages/home/components/ParentCard'

const parents: ParentCardProps[] = [
  {
    image: Parent3,
    title: 'Lilia M.',
    subtitle: "Chloe's Mom",
    description:
      'Sharing activities with my son’s other caregivers made planning and logistics easier.'
  },
  {
    image: Parent1,
    title: 'James S.',
    subtitle: "Jimmy's Dad",
    description:
      'Finding a program for my son who has special needs is hard. PlanMykids helped me find the best suited programs for him.'
  },
  {
    image: Parent2,
    title: 'Bridgett C.',
    subtitle: 'Nathanael’s mom',
    description:
      "I'm very grateful for your work!  I hadn’t heard of the camps you found and I’m excited to look into them!"
  },
  {
    image: Parent1,
    title: 'Cooper D.',
    subtitle: "David's dad",
    description:
      'It was incredible, my son was very happy that with his camp. Thanks to PLANMYKIDS, I found camp very quickly and easily.'
  },
  {
    image: Parent2,
    title: 'Kaylynn B.',
    subtitle: "Brad's mom",
    description:
      'I love PLANMYKIDS, it saved me time and my kids loved the programs they were matched with.'
  },
  {
    image: Parent3,
    title: 'Kaiya G.',
    subtitle: "Corner's mom",
    description: "PLANMYKIDS it's fantastic service. Very good concierge team."
  }
]

export default parents
