const risingLevelOptions: Option[] = [
  {
    value: '1st',
    label: '1st'
  },
  {
    value: '2nd',
    label: '2nd'
  },
  {
    value: '3rd',
    label: '3rd'
  },
  {
    value: '4th',
    label: '4th'
  },
  {
    value: '5th',
    label: '5th'
  },
  {
    value: '6th',
    label: '6th'
  },
  {
    value: '7th',
    label: '7th'
  },
  {
    value: '8th',
    label: '8th'
  },
  {
    value: '9th',
    label: '9th'
  },
  {
    value: '10th',
    label: '10th'
  },
  {
    value: '11th',
    label: '11th'
  },
  {
    value: '12th',
    label: '12th'
  }
]

export default risingLevelOptions
