import InfoImage1 from '~/assets/images/home/info-card-1.png'
import InfoImage2 from '~/assets/images/home/info-card-2.png'
import InfoImage3 from '~/assets/images/home/info-card-3.png'

import { InfoCardProps } from '~/components/cards/InfoCard'

const helpCards: InfoCardProps[] = [
  {
    image: InfoImage1,
    title: 'Care-Free',
    description: 'Offload tedious, difficult tasks and free your time.'
  },
  {
    image: InfoImage2,
    title: 'Personalized',
    description:
      "Get recommended programs tailored to your family's interests and needs."
  },
  {
    image: InfoImage3,
    title: 'Trusted',
    description:
      'Have confidence in the choices made and that your privacy is kept.'
  }
]

export default helpCards
