import React, { FC, useState, useCallback } from 'react'
import { useAsync } from 'react-async'
import {
  TextField,
  createStyles,
  makeStyles,
  Grid,
  FormHelperText,
  InputAdornment
} from '@material-ui/core'
import { Done, ErrorOutline } from '@material-ui/icons'

import { ExtendedButton, Text } from '~/components/shared'

import { checkCoupon } from '~/state/modules/user/user.async'

const useStyles = makeStyles(
  () =>
    createStyles({
      couponInput: {
        '& input::placeholder': {
          color: '#D4DAE1'
        }
      }
    }),
  {
    name: 'CouponInput'
  }
)

type Props = {
  price: number
  updateCoupon: (coupon: string) => void
  updatePrice: (price: number) => void
}

const CouponInput: FC<Props> = ({ price, updateCoupon, updatePrice }) => {
  const s = useStyles()

  const [value, setValue] = useState<string>('')
  const [coupon, setCoupon] = useState<Coupon>(null)
  const [error, setError] = useState<boolean>(false)

  const { isLoading, run } = useAsync({
    deferFn: checkCoupon,
    onResolve: (data) => {
      setCoupon(data)
      updatePrice(data?.recalculated_price)

      setError(false)
    },
    onReject: () => {
      updatePrice(null)

      setError(true)
      setCoupon(null)
    }
  })

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value

      setValue(value)
      setCoupon(null)
      setError(false)

      updateCoupon(value)
      updatePrice(null)
    },
    []
  )

  const handleSubmit = useCallback(() => {
    run(value, price)
  }, [value, price])

  return (
    <Grid container spacing={2} alignItems="flex-start">
      <Grid item xs={12}>
        <Text variant="body2" mb={-1} fontWeight={600}>
          Coupon Code
        </Text>
      </Grid>

      <Grid item xs={8}>
        <TextField
          className={s.couponInput}
          placeholder="Coupon"
          variant="outlined"
          fullWidth
          value={value}
          onChange={handleChange}
          InputProps={{
            endAdornment: (coupon || error) && (
              <InputAdornment position="end">
                {coupon ? (
                  <Done style={{ color: '#5FB37E' }} />
                ) : (
                  <ErrorOutline style={{ color: '#f44336' }} />
                )}
              </InputAdornment>
            )
          }}
        />

        {error && (
          <FormHelperText error>
            The coupon code you entered is invalid. Please try again.
          </FormHelperText>
        )}

        {coupon && (
          <Text color="textSecondary" mt={1}>
            Coupon:{' '}
            <Text component="span" fontWeight={600}>
              - {coupon?.discount_amount}
              {coupon?.discount_type.toUpperCase()}
            </Text>
          </Text>
        )}
      </Grid>

      <Grid item xs={4}>
        <ExtendedButton
          size="large"
          variant="outlined"
          color="secondary"
          fullWidth
          disabled={!value}
          loading={isLoading}
          onClick={handleSubmit}
        >
          Apply
        </ExtendedButton>
      </Grid>
    </Grid>
  )
}

export default CouponInput
