import { styled } from '@material-ui/core/styles'
import { compose, sizing, spacing, display, flexbox } from '@material-ui/system'

const Flexbox = styled('div')(compose(sizing, spacing, display, flexbox), {
  name: 'Flexbox'
})

Flexbox.defaultProps = {
  display: 'flex'
}

export default Flexbox
