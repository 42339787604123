import { object, string } from 'yup'

const ChatbotAssistantSchema = object().shape({
  message: string()
    .trim()
    .required((value) => {
      if (value?.originalValue?.length && !value?.value?.length) {
        return 'This field should be filled'
      }

      return false
    })
})

export default ChatbotAssistantSchema
