import React, { FC, useMemo } from 'react'
import { compose } from 'redux'
import { RouteComponentProps, withRouter } from 'react-router'
import {
  InjectedFormProps,
  Form,
  Field,
  reduxForm,
  SubmissionError,
  WrappedFieldArrayProps,
  FieldArray
} from 'redux-form'
import { connect, useSelector } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { Grid, Button, Box } from '@material-ui/core'

import {
  TextField,
  SelectField,
  CheckBoxField,
  ErrorField
} from '~/components/fields'
import { ExtendedButton, Flexbox, FormControl, Text } from '~/components/shared'
import { InfoAccordion } from '~/components/accordions'

import { addCommentItinerary } from '~/state/modules/itineraries'
import { sortByDate } from '~/utils/functions'

import { asyncValidate } from '~/utils/asyncValidate'
import ItineraryService from '~/services/ItineraryService'
import RequestActivity from '~/schemas/request-activity'
import ROUTES from '~/constants/routes'
import { AppState } from '~/state/store'

import useItineraryCommentStyles from '../itinerary-comment.styles'

const reasonChange = [
  { lable: 'Date', value: 'Date' },
  { lable: 'Location', value: 'Location' },
  { lable: 'Price', value: 'Price' },
  { lable: 'Program', value: 'Program' },
  { lable: 'Other', value: 'Other' }
]

const renderRequestActivity: React.FC<WrappedFieldArrayProps> = ({
  fields
}) => {
  const s = useItineraryCommentStyles()

  const activities = useSelector<AppState, Activity[]>(
    (state) => state.itineraries.parents.item.activities
  )

  const sortedActivity = useMemo(() => sortByDate(activities, 'start_date'), [
    activities
  ])

  return (
    <>
      <Grid container spacing={2} direction="column">
        {fields.map((activity, index) => (
          <Grid item xs={12} md={8} lg={6} key={index}>
            <Box className={s.content}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl label="Select activity you want to exclude from itinerary?*">
                    <Field
                      id="selectActivity"
                      component={SelectField}
                      variant="outlined"
                      fullWidth
                      options={sortedActivity}
                      name={`${activity}.activity_id`}
                      label="Select activity"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl label="Reason for change:" fullWidth={false}>
                    <Flexbox ml={-1.2} mt={-0.5} flexWrap="wrap">
                      {reasonChange.map((item) => (
                        <Field
                          key={item.lable}
                          name={`${activity}.reason.${item.value}`}
                          component={CheckBoxField}
                          label={item.lable}
                        />
                      ))}
                    </Flexbox>
                    <Field name={`${activity}.reason`} component={ErrorField} />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl label="More information">
                    <Field
                      name={`${activity}.more_information`}
                      placeholder="Share your opinion on the compiled itinerary"
                      component={TextField}
                      rowsMax={10}
                      rows={4}
                      multiline
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        ))}
      </Grid>

      <Box my={1}>
        <Button
          size="small"
          color="default"
          onClick={(): void => fields.push({})}
        >
          + Add a request to another camp
        </Button>
      </Box>
    </>
  )
}

type CommentFormProps = {
  id: string
  initialValues: {
    requestChange: ActivityChangeInput[]
  }
  change_requests: ItineraryChangeRequest[]
}

const RequestActivityForm: FC<CommentFormProps & InjectedFormProps> = ({
  handleSubmit,
  submitting,
  change_requests
}) => {
  return (
    <Box
      component={Form}
      display="flex"
      flexDirection="column"
      onSubmit={handleSubmit}
      mt={2}
    >
      <Grid container spacing={2}>
        {!!change_requests?.length && (
          <Grid item xs={12} md={8} lg={6}>
            <InfoAccordion
              title="Previous requests"
              data={ItineraryService.generateCommentsData(change_requests)}
              variantTitle="h6"
              isRequestChange
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Text variant="subtitle1" mt={1} mb={1.5}>
            Your request{' '}
          </Text>

          <FieldArray<{}>
            name="requestChange"
            component={renderRequestActivity}
          />
        </Grid>

        <Grid item xs={12}>
          <Flexbox width="100%" justifyContent="center">
            <ExtendedButton
              data-cy="request-activity-form-button"
              size="large"
              variant="contained"
              color="primary"
              type="submit"
              loading={submitting}
            >
              Send feedback to a specialist
            </ExtendedButton>
          </Flexbox>
        </Grid>
      </Grid>
    </Box>
  )
}

const mapStateToProps = ({
  itineraries: {
    parents: { item }
  }
}: AppState): CommentFormProps => {
  return {
    initialValues: {
      requestChange: [
        {
          more_information: '',
          activity_id: '',
          reason: {}
        }
      ]
    },
    change_requests: item.change_requests,
    id: item?.id
  }
}

const withConnect = connect(mapStateToProps)

const withForm = reduxForm<
  ActivitiesItineraryInput,
  CommentFormProps & RouteComponentProps
>({
  form: 'Comment_Itinerary_Update_Form',
  enableReinitialize: true,
  shouldAsyncValidate: () => true,
  asyncValidate: asyncValidate(RequestActivity),
  onSubmit: async (values, dispatch, { id }) => {
    try {
      const { requestChange } = values
      await dispatch<any>(addCommentItinerary(id, requestChange))
    } catch (error) {
      throw new SubmissionError(error.errors)
    }
  },
  onSubmitSuccess: (_values, _dispatch, { reset, history }) => {
    reset()

    toastr.success(
      'Itinerary comment',
      'The comment(s) was successfully added!'
    )

    history.push(ROUTES.itineraries)
  }
})

export default compose<React.FC>(
  withRouter,
  withConnect,
  withForm
)(RequestActivityForm)
