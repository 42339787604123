import React, { FC } from 'react'
import cn from 'classnames'
import { Box, Card, Hidden } from '@material-ui/core'

import { DetailsList, Flexbox, ShowMoreText, Text } from '~/components/shared'
import ActivityButtons from '../ActivityButtons'
import ActivityCardLinks from './ActivityCardLinks'

import { useActivityCardDesktopStyles } from './ActivityCard.style'

import { ActivityCardProps } from './ActivityCard'

const ActivityCardDesktop: FC<ActivityCardProps> = ({
  location,
  date,
  camp_info_time,
  camp_info_title,
  details,
  isAdded,
  isAdding,
  duration,
  isHideButtons,
  onAddActivity,
  isNoAvailableSeats,
  ...rest
}) => {
  const s = useActivityCardDesktopStyles()

  return (
    <Card className={s.card} data-cy="activity-card-desktop">
      <Box
        className={cn({
          [s.titleBlock]: true,
          [s.opacity]: isNoAvailableSeats
        })}
      >
        <ActivityCardLinks isNoAvailableSeats={isNoAvailableSeats} {...rest} />

        <Flexbox mb={1}>
          <Text color="textSecondary" mr={1}>
            Location:
          </Text>
          <Text color="textPrimary">{location}</Text>
        </Flexbox>
        <Flexbox mb={1}>
          <Text color="textSecondary" mr={1}>
            Date:
          </Text>
          <Text color="textPrimary" mr={0.5}>
            {date}{' '}
            <Text component="span" className={s.duration}>
              ({duration})
            </Text>
          </Text>
        </Flexbox>
        <Flexbox mb={1}>
          <Text color="textSecondary" mr={1} className={s.campInfoTitle}>
            {camp_info_title}
          </Text>
          <Text
            color="textPrimary"
            className={cn({
              [s.campInfoTime]: camp_info_time.includes('See camp url')
            })}
          >
            {camp_info_time}
          </Text>
        </Flexbox>
      </Box>

      <Flexbox
        className={cn({
          [s.optionsBlock]: true,
          [s.opacity]: isNoAvailableSeats
        })}
      >
        <Hidden mdDown>
          <Flexbox>
            <Box className={s.firstRowDetails}>
              <DetailsList items={details} />
            </Box>
          </Flexbox>
          {!isNoAvailableSeats && (
            <ActivityButtons
              isAdded={isAdded}
              isAdding={isAdding}
              onAddActivity={onAddActivity}
              isHideButtons={isHideButtons}
              style={{
                marginTop: 20,
                width: '260px',
                ...(isAdded && { cursor: 'default' })
              }}
            />
          )}
        </Hidden>

        <Hidden lgUp>
          <ShowMoreText height={210}>
            <Box className={s.firstRowDetails}>
              <DetailsList items={details} />
            </Box>

            <ActivityButtons
              isAdded={isAdded}
              isAdding={isAdding}
              onAddActivity={onAddActivity}
              isHideButtons={isHideButtons}
              style={{ marginTop: 20, ...(isAdded && { cursor: 'default' }) }}
            />
          </ShowMoreText>
        </Hidden>
      </Flexbox>
    </Card>
  )
}

export default ActivityCardDesktop
