import palette from '~/theme/palette'
import { fonts } from '~/theme/typography'

const cardStyles = {
  base: {
    color: palette.text.primary,
    fontFamily: fonts.nunito,
    fontSmoothing: 'antialiased',
    fontSize: '16px',

    '::placeholder': {
      color: '#D4DAE1'
    }
  },

  invalid: {
    color: '#f44336',
    iconColor: '#f44336'
  }
}

export const numberElementOptions = {
  style: cardStyles,
  placeholder: '0000-0000-0000-0000',
  showIcon: true
}

export const expiryElementOptions = {
  placeholder: 'MM/YY',
  style: cardStyles
}

export const cvcElementOptions = {
  placeholder: 'CVV',
  style: cardStyles
}
