import { makeStyles, createStyles } from '@material-ui/core'

const useVideoBlockStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        position: 'relative',
        paddingTop: '48%',

        [theme.breakpoints.down('xs')]: {
          paddingTop: '58%'
        }
      },

      container: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: 20,
        boxShadow: '5px 5px 15px rgba(35, 35, 35, 0.15)',
        border: '15px solid',
        borderColor: theme.palette.text.primary,
        overflow: 'hidden',
        backgroundColor: theme.palette.text.primary,

        [theme.breakpoints.down('sm')]: {
          borderWidth: 6
        }
      },

      player: {
        width: '100% !important',
        height: '101% !important'
      }
    }),
  {
    name: 'VideoBlock'
  }
)

export default useVideoBlockStyles
