import { makeStyles, createStyles } from '@material-ui/core'

const useAuthStyles = makeStyles(
  (theme) =>
    createStyles({
      container: {
        margin: '30px auto 70px',

        [theme.breakpoints.up('sm')]: {
          maxWidth: 700
        },

        [theme.breakpoints.down('xs')]: {
          padding: 0,
          margin: '100px 0 0'
        }
      },

      paper: {
        padding: '50px 125px',

        [theme.breakpoints.down('sm')]: {
          padding: '50px'
        },

        [theme.breakpoints.down('xs')]: {
          padding: '50px 20px',
          borderRadius: 'inherit',
          boxShadow: 'none'
        }
      }
    }),
  {
    name: 'Auth'
  }
)

export default useAuthStyles
