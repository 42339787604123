import React, { FC, useCallback } from 'react'
import { WrappedFieldProps } from 'redux-form'
import cn from 'classnames'
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  createStyles,
  makeStyles
} from '@material-ui/core'

export const useCheckboxFieldStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        display: 'none',
        '& label': {
          margin: 0
        },

        '&$checked ~ span': {
          color: theme.palette.common.white,
          backgroundColor: theme.palette.primary.main,
          borderRadius: '50%'
        }
      },

      checked: {},

      label: {
        width: 30,
        height: 30,
        marginRight: 2,
        marginLeft: 2,
        lineHeight: '30px',
        textAlign: 'center'
      }
    }),
  {
    name: 'CheckboxField'
  }
)

type CheckboxFieldProps = {
  label?: string
  isTeeth?: boolean
}

type AllProps = WrappedFieldProps & CheckboxFieldProps

const CheckboxField: FC<AllProps> = ({
  input: { onChange, onBlur, value, ...input },
  meta: { touched, error },
  label,
  isTeeth,
  ...rest
}) => {
  const s = useCheckboxFieldStyles()
  const errorMessage = touched && error

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const v = event.target.checked

      onChange(v)
      onBlur(v)
    },
    []
  )

  return (
    <>
      <FormControlLabel
        style={{ margin: 0 }}
        classes={{
          label: cn({ [s.label]: isTeeth })
        }}
        control={
          <Checkbox
            classes={{
              root: cn({ [s.root]: isTeeth }),
              checked: cn({ [s.checked]: isTeeth })
            }}
            color="primary"
            onChange={handleChange}
            checked={!!value}
            {...input}
            {...rest}
          />
        }
        label={label}
      />

      {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
    </>
  )
}

export default CheckboxField
