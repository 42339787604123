import React, { FC } from 'react'
import AnimatedShowMore from 'react-animated-show-more'
import { Box, makeStyles } from '@material-ui/core'

import Flexbox from './Flexbox'

import { ReactComponent as ArrowDownIcon } from '~/assets/icons/arrow-down.svg'

const useStyles = makeStyles(
  {
    root: {
      '& button': {
        width: '100%'
      }
    },

    arrowUp: {
      transform: 'rotate(180deg)'
    }
  },
  {
    name: 'ShowMoreText'
  }
)

const ArrowDownBtn: FC = () => {
  return (
    <Flexbox justifyContent="center" mt={1} mb={0.5}>
      <ArrowDownIcon />
    </Flexbox>
  )
}

const ArrowUpBtn: FC = () => {
  const s = useStyles()

  return (
    <Flexbox justifyContent="center" mt={1} mb={0.5}>
      <ArrowDownIcon className={s.arrowUp} />
    </Flexbox>
  )
}

type Props = {
  height?: number
}

const ShowMoreText: FC<Props> = ({ children, height = 105 }) => {
  const s = useStyles()

  return (
    <Box className={s.root}>
      <AnimatedShowMore
        height={height}
        toggle={({ isOpen }): JSX.Element =>
          isOpen ? <ArrowUpBtn /> : <ArrowDownBtn />
        }
      >
        {children}
      </AnimatedShowMore>
    </Box>
  )
}

export default ShowMoreText
