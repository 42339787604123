import React, { FC, useEffect, useState, useCallback } from 'react'
import { change } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'

import ActiveChips from './ActiveChips'

import IntlService from '~/services/IntlService'
import { humanizeDate } from '~/utils/functions'
import gradeLevelOptions from '~/data/gradeLevelOptions'

import { AppState } from '~/state/store'

type ActiveFiltersProps = {
  form: string
  formValues: Partial<SearchInput>
  meta: ActivitiesMeta
}

const ActiveFilters: FC<ActiveFiltersProps> = ({
  form,
  formValues,
  meta: { min_price, max_price }
}) => {
  const dispatch = useDispatch()
  const activities = useSelector(
    (state: AppState) => state?.activities?.meta?.activity_providers
  )

  const [chips, setChips] = useState<ChipOption[]>([])

  useEffect(() => {
    const valuesChips = Object.entries(formValues).reduce(
      (acc: ChipOption[], [key, value]: [string, any]) => {
        if (key === 'search' && value) {
          return [
            ...acc,
            {
              field: key,
              value: `🔎 ${value}`
            }
          ]
        }

        if (key === 'date' && value) {
          const date = JSON.parse(value)

          date.forEach((date, idx) => {
            if (date)
              acc.push({
                field: `date[${idx}]`,
                value: `${idx === 0 ? 'Start' : 'End'} - ${humanizeDate(date)}`
              })
          })
        }

        if (key === 'price' && value.some((v) => !!v)) {
          value.forEach((price, idx) => {
            if (price && !(price === min_price || price === max_price))
              acc.push({
                field: `price[${idx}]`,
                value: `${
                  idx === 0 ? 'Min' : 'Max'
                } - ${IntlService.currencyFormat(price)}`
              })
          })
        }

        if (key === 'age' && value) {
          return [
            ...acc,
            {
              field: key,
              value: `Age - ${value}`
            }
          ]
        }

        if (key === 'grade_level' && value) {
          const currentValue = gradeLevelOptions.find(
            ({ value: optionValue }) => optionValue === value
          )?.label

          return [
            ...acc,
            {
              field: key,
              value: `Grade level - ${currentValue}`
            }
          ]
        }

        if (['type'].some((field) => field === key) && value) {
          return [
            ...acc,
            {
              field: key,
              value
            }
          ]
        }

        if (key === 'radius' && value && value !== 500) {
          return [
            ...acc,
            {
              field: key,
              value: `Distance - ${value} mi`
            }
          ]
        }

        if (key === 'activity_provider' && value) {
          return [
            ...acc,
            {
              field: key,
              value: activities?.[value]
            }
          ]
        }

        if (key === 'activities' && value) {
          return [
            ...acc,
            {
              field: key,
              value
            }
          ]
        }

        if (key === 'interests' && value) {
          value.forEach((item) => {
            acc.push({
              field: `interest.${item}`,
              value: item
            })
          })
        }

        if (key === 'skill_development' && value) {
          value.forEach((item) => {
            acc.push({
              field: `skill_development.${item}`,
              value: item
            })
          })
        }

        if (key === 'available_seats' && value) {
          return [
            ...acc,
            {
              field: key,
              value: `Available seats - ${value}`
            }
          ]
        }

        return acc
      },
      []
    )

    setChips(valuesChips)
  }, [formValues])

  const handleDelete = useCallback(
    (fields: ChipOption[], clearAll?: boolean) => {
      fields.forEach(({ field }) => {
        let currentValue = null
        let currentField = field

        const dateData =
          !clearAll && formValues?.date
            ? JSON.parse(formValues?.date)
            : [null, null]

        const interests =
          !clearAll && formValues?.interests ? formValues?.interests : null

        const skill_development =
          !clearAll && formValues?.skill_development
            ? formValues?.skill_development
            : null

        switch (field) {
          case 'radius':
            currentValue = 500
            break
          case 'price[0]':
            currentValue = min_price
            break
          case 'price[1]':
            currentValue = max_price
            break
          case 'date[0]':
            dateData[0] = null
            currentField = 'date'
            currentValue = JSON.stringify(dateData)
            break
          case 'date[1]':
            dateData[1] = null
            currentField = 'date'
            currentValue = JSON.stringify(dateData)
            break
        }

        if (field.includes('interest') && interests) {
          dispatch(
            change(
              form,
              'interests',
              interests?.filter((interest) => interest !== field.split('.')[1])
            )
          )
        }

        clearAll && dispatch(change(form, 'interests', null))

        if (field.includes('skill_development') && skill_development) {
          dispatch(
            change(
              form,
              'skill_development',
              skill_development?.filter(
                (skill) => skill !== field.split('.')[1]
              )
            )
          )
        }

        clearAll && dispatch(change(form, 'skill_development', []))

        dispatch(change(form, currentField, currentValue))
      })
    },
    [formValues, min_price, max_price]
  )

  return <ActiveChips data={chips} onDelete={handleDelete} />
}

export default ActiveFilters
