import React, { FC } from 'react'
import { Grid, Paper, Box } from '@material-ui/core'

import FullWidthMobile from '~/containers/FullWidthMobile'
import { Text, Title } from '~/components/shared'

import painting1 from '~/assets/images/home/title-painting-1.png'
import aboutUs from '~/assets/images/about-us.png'

import useStaticStyles from '~/styles/static-pages.styles'

const AboutUs: FC = () => {
  const s = useStaticStyles()

  return (
    <FullWidthMobile>
      <Box className={s.container}>
        <Paper className={s.paper}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Title
                variant="h1"
                paintingOptions={{
                  image: painting1,
                  customStyles: {
                    bottom: 3,
                    height: 6
                  }
                }}
                mb={2}
              >
                About us
              </Title>
            </Grid>
            <Grid item xs={12} md={3}>
              <img src={aboutUs} className={s.aboutUsImg} alt="about-us" />
            </Grid>

            <Grid item xs={12} md={9}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Title textAlign="start" variant="h4">
                    Empowering Parents, Enriching Kids
                  </Title>
                </Grid>

                <Grid item xs={12}>
                  <Text color="textSecondary">
                    Every year busy parents feel waves of anxiety crash over
                    them as they fret over what to do with their kids when
                    they&apos;re not in school or how to keep them occupied with
                    something constructive. As parents, we want to provide the
                    best experiences for our kids, and we want the world to know
                    we&apos;re on top of it. But we often don&apos;t know what
                    options are available, or we feel too overwhelmed with the
                    process of planning them. At the same time, our kids can
                    feel isolated and disconnected from the communities they
                    live in, while the skills they need to succeed in
                    today&apos;s world atrophy without stimulation.
                  </Text>
                </Grid>

                <Grid item xs={12}>
                  <Text color="textSecondary">
                    Beyond these waves is a sea of opportunities to explore and
                    help kids blossom. PlanMyKids is about making it easy and
                    fun for parents to plan and discover them with confidence.
                    We got this, so you got this, and, most importantly, your
                    kids know this.
                  </Text>
                </Grid>

                <Grid item xs={12}>
                  <Title textAlign="start" variant="h4">
                    Vision
                  </Title>
                </Grid>

                <Grid item xs={12}>
                  <Text color="textSecondary">
                    PlanMyKids&apos;<span className={s.underline}>vision</span>{' '}
                    is about increasing children’s activity, learning and
                    participation in the communities they live in and beyond.
                  </Text>
                </Grid>

                <Grid item xs={12}>
                  <Title textAlign="start" variant="h4">
                    Mission
                  </Title>
                </Grid>

                <Grid item xs={12}>
                  <Text color="textSecondary">
                    PlanMyKids&apos;<span className={s.underline}>mission</span>{' '}
                    is about empowering parents with convenient and personalized
                    services to facilitate relationships and experiences with
                    enrichment programs for their children.
                  </Text>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </FullWidthMobile>
  )
}

export default AboutUs
