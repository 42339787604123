import React from 'react'
import { Field } from 'redux-form'
import { Grid } from '@material-ui/core'

import PasswordField from '~/components/fields/PasswordField'
import { FormControl } from '../shared'

type Props = {
  confirm?: boolean
  isRow?: boolean
}

const PasswordFragment: React.FC<Props> = ({ confirm, isRow }) => (
  <Grid container spacing={2}>
    <Grid item xs={12} sm={isRow ? 6 : 12}>
      <FormControl label="Password">
        <Field
          id="password"
          name="password"
          placeholder="Password"
          autoComplete="current-password"
          component={PasswordField}
        />
      </FormControl>
    </Grid>

    {confirm && (
      <Grid item xs={12} sm={isRow ? 6 : 12}>
        <FormControl label="Confirm password">
          <Field
            id="password_confirmation"
            name="password_confirmation"
            placeholder="Confirm password"
            autoComplete="current-password"
            component={PasswordField}
            hideHelper
          />
        </FormControl>
      </Grid>
    )}
  </Grid>
)

export default PasswordFragment
