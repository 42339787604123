import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'

import {
  requestAction,
  successAction,
  failureAction
} from '~/utils/reducerFunctions'
import { humanizeDate } from '~/utils/functions'
import ActivitiesService from '~/services/ActivitiesService'
import {
  generateItinerariesWithColor,
  humanizeCalendarDate
} from './calendar.utils'

import { STATUSES, STATUSES_SHARED } from '~/constants/statusesItinerary'

export interface CalendarState {
  data: {
    parents: Itinerary[]
    friends: Itinerary[]
  }
  events: ActivityEvent[]
  loading: boolean
  loaded: boolean
  checkboxes: {
    children: {
      [key: string]: {
        value: boolean
        field: 'parents' | 'friends'
      }
    }
  }
}

const initialState: CalendarState = {
  data: {
    parents: [],
    friends: []
  },
  events: [],
  loading: false,
  loaded: false,
  checkboxes: {
    children: {}
  }
}

const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    request: requestAction,

    success: (
      state,
      action: PayloadAction<{
        parents: Itinerary[]
        friends: Itinerary[]
        userId: string
      }>
    ): void => {
      successAction(state, action, 'data', (state) => {
        const {
          payload: { parents, friends, userId }
        } = action

        const parentsData = parents?.filter(
          ({ status }) => status === STATUSES.approved
        )

        const friendsData = friends
          ?.filter(
            ({ status, parents_itineraries }) =>
              status === STATUSES.approved &&
              parents_itineraries?.find(
                ({ parent_id }) => parent_id === Number(userId)
              )?.status === STATUSES_SHARED.accepted
          )
          .map((itinery) => ({ ...itinery, isFriend: true }))

        const itinerariesWithColors = generateItinerariesWithColor([
          ...parentsData,
          ...friendsData
        ])

        const data = itinerariesWithColors.reduce(
          (
            acc: {
              parents: Itinerary[]
              friends: Itinerary[]
            },
            itinerary: Itinerary
          ) => {
            if (itinerary.isFriend) {
              acc.friends.push(itinerary)
            } else {
              acc.parents.push(itinerary)
            }

            return acc
          },
          {
            parents: [],
            friends: []
          }
        )

        state.data = data
      })
    },

    changeCheckbox: (
      state,
      {
        payload
      }: PayloadAction<{
        [key: string]: {
          value: boolean
          field: 'parents' | 'friends'
        }
      }>
    ): CalendarState => {
      const checkboxes = {
        ...state.checkboxes.children,
        ...payload
      }

      const events = Object.entries(checkboxes).reduce(
        (eventsArray, [itineraryId, { field, value }]) => {
          if (value) {
            const invited_parents = []
            let invited_friend_name = ''

            const child_name = state.data[field].find(
              ({ id }) => id === itineraryId
            ).questionnaire.fields.child_first_name

            if (field === 'parents') {
              state.data[field]
                .find(({ id }) => id === itineraryId)
                .parents_itineraries?.forEach(
                  ({ itinerary_id, friend_name }) => {
                    if (itinerary_id === +itineraryId) {
                      invited_parents.push(friend_name)
                    }
                  }
                )
            }

            if (field === 'friends') {
              invited_friend_name = state.data[field].find(
                ({ id }) => id === itineraryId
              )?.parent_name
            }

            const itinerary = state.data[field].find(
              ({ id }) => id === itineraryId
            )

            const activitiesEvents = itinerary.activities.map(
              ({
                id,
                name,
                start_date,
                end_date,
                location,
                start_time,
                end_time,
                camp_type
              }) => {
                const {
                  camp_info_time,
                  camp_info_title
                } = ActivitiesService.generateCampInfo(
                  start_time,
                  end_time,
                  camp_type
                )

                const duration =
                  start_date && end_date && start_date !== end_date
                    ? `${moment(end_date).diff(start_date, 'days') + 1} days`
                    : '1 day'

                const startDate = humanizeDate(start_date, 'LL')
                const endDate = humanizeDate(end_date, 'LL')

                return {
                  id,
                  title: name,
                  start: humanizeDate(start_date, 'YYYY-MM-DD'),
                  end: humanizeCalendarDate(end_date),
                  backgroundColor: itinerary.color,
                  extendedProps: {
                    date: `${startDate} - ${endDate}`,
                    location,
                    duration,
                    child_name,
                    invited_parents,
                    invited_friend_name,
                    camp_info_time,
                    camp_info_title
                  }
                }
              }
            )

            return [...eventsArray, ...activitiesEvents]
          }

          return eventsArray
        },
        []
      )

      return {
        ...state,
        checkboxes: {
          ...state.checkboxes,
          children: checkboxes
        },
        events
      }
    },

    failure: failureAction
  }
})

export const {
  request,
  success,
  changeCheckbox,
  failure
} = calendarSlice.actions

export default calendarSlice.reducer
