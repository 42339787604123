import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button
} from '@material-ui/core'

import {
  BlankLink,
  CroppedTextTooltip,
  ExtendedButton,
  Flexbox,
  Text
} from '~/components/shared'

import IntlService from '~/services/IntlService'
import { humanizeDate } from '~/utils/functions'

import ROUTES from '~/constants/routes'
import { ActivitiesTableItem } from '../ActivitiesTable'

import { ReactComponent as Done } from '~/assets/icons/done.svg'

import useActivitiesTableStyles from './ActivitiesTableDesktop.styles'

type Props = ActivitiesTableItem & {
  data: Activity[]
}

const ActivitiesTableDesktop: FC<Props> = ({
  data,

  isHideButtons,
  isAdding,

  onAddActivity
}) => {
  const s = useActivitiesTableStyles()

  return (
    <TableContainer>
      <Table size="small" className={s.table}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>End Date</TableCell>
            <TableCell>Url</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data.map(
            ({ id, name, url, price, start_date, end_date, isAdded }) => (
              <TableRow key={id} data-cy="activity-table-row">
                <TableCell>
                  <CroppedTextTooltip title={name}>
                    <Text>{name}</Text>
                  </CroppedTextTooltip>
                </TableCell>
                <TableCell>{humanizeDate(start_date, 'MMM D, YYYY')}</TableCell>
                <TableCell>{humanizeDate(end_date, 'MMM D, YYYY')}</TableCell>
                <TableCell>
                  <BlankLink href={url}>Open url</BlankLink>
                </TableCell>
                <TableCell>
                  {price ? IntlService.currencyFormat(price) : '-'}
                </TableCell>
                <TableCell>
                  <Flexbox className={s.btns}>
                    <Button
                      data-cy="activity-table-button"
                      component={Link}
                      to={`${ROUTES.activity}/${id}`}
                      size="small"
                      variant="contained"
                      color="secondary"
                      fullWidth
                    >
                      View activity
                    </Button>

                    {!isHideButtons &&
                      (isAdded ? (
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          fullWidth
                          style={{ cursor: 'default' }}
                        >
                          <Done style={{ marginRight: 10 }} />
                          Added
                        </Button>
                      ) : (
                        <ExtendedButton
                          data-cy="activity-card-itineraries-button"
                          size="small"
                          variant="outlined"
                          color="primary"
                          fullWidth
                          onClick={(): void => {
                            onAddActivity(id)
                          }}
                          loading={isAdding === id}
                          disabled={isAdding === id}
                        >
                          Add to itinerarie
                        </ExtendedButton>
                      ))}
                  </Flexbox>
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ActivitiesTableDesktop
