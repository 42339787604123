import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import * as Sentry from '@sentry/react'
import { Container, createStyles, makeStyles } from '@material-ui/core'
import cn from 'classnames'

import Error from '~/components/layouts/Error'
import { ScrollTop } from '../shared'

import Header from './Header'
import Footer from './Footer'
import { CookieNotificationCard } from '../cards'

import { isSpecialist } from '~/state/modules/user'

import { navsParent, navsSpecialist } from '~/components/layouts/links.config'

import PatternBg from '~/assets/images/pattern.png'
import PatternMobileBg from '~/assets/images/pattern-mobile.png'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      page: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        minHeight: '100vh',
        position: 'relative'
      },

      container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flex: 1,
        minWidth: 0,
        marginTop: 84,
        overflowX: 'hidden',

        backgroundImage: `url(${PatternBg})`,
        backgroundRepeat: 'repeat-y',
        backgroundSize: '100% auto',
        backgroundPosition: 'top',

        '&.gradientHeader': {
          marginTop: 0
        },

        [theme.breakpoints.down('sm')]: {
          marginTop: 64,
          backgroundImage: `url(${PatternMobileBg})`
        }
      }
    }),
  { name: 'AppLayout' }
)

export type LayoutProps = {
  isGradientHeader?: boolean
  background?: string
  isFullHeight?: boolean
}

const AppLayout: FC<LayoutProps> = ({
  children,
  isGradientHeader,
  background,
  isFullHeight
}) => {
  const s = useStyles()

  const specialist = useSelector(isSpecialist)

  const navs = useMemo(() => (specialist ? navsSpecialist : navsParent), [
    specialist
  ])

  return (
    <div className={s.page}>
      <Header isGradient={isGradientHeader} navs={navs} />

      <main
        className={cn(s.container, {
          gradientHeader: isGradientHeader
        })}
        style={{
          ...(background && {
            background
          })
        }}
      >
        <Container
          maxWidth="xl"
          style={{ flex: isFullHeight ? '1' : 'initial' }}
        >
          <Sentry.ErrorBoundary fallback={<Error />}>
            {children}
          </Sentry.ErrorBoundary>
        </Container>
      </main>

      <Footer navs={navs} />

      <ScrollTop />

      <CookieNotificationCard />
    </div>
  )
}

export default AppLayout
