import React from 'react'
import {
  ClickAwayListener,
  Tooltip as MuiTooltip,
  TooltipProps,
  createStyles,
  makeStyles
} from '@material-ui/core'
import { useTooltip } from './useTooltip'

interface Props extends TooltipProps {
  wrapperProps?: {
    style?: React.CSSProperties
  }
  enableWordBreak?: boolean
}

const useStyle = makeStyles(
  () =>
    createStyles({
      childrenWrapper: {
        display: 'inline-block',
        verticalAlign: 'bottom',
        overflow: 'hidden',
        maxWidth: '100%',
        textOverflow: 'ellipsis',
        '& > *': {
          display: 'inline !important',
          whiteSpace: 'nowrap'
        }
      }
    }),
  {
    name: 'CustomTooltip'
  }
)

const CroppedTextTooltip: React.FC<Props> = ({
  children,
  title,
  enableWordBreak = true,
  placement = 'top',
  wrapperProps = {},
  ...rest
}) => {
  const s = useStyle()
  const { tooltipProps, closeTooltip } = useTooltip()

  return (
    <ClickAwayListener onClickAway={closeTooltip}>
      <MuiTooltip
        title={title}
        interactive
        PopperProps={{
          style: {
            ...(enableWordBreak && {
              wordBreak: 'break-word'
            })
          }
        }}
        placement={placement}
        {...tooltipProps}
        {...rest}
      >
        <span className={s.childrenWrapper} {...wrapperProps}>
          {children}
        </span>
      </MuiTooltip>
    </ClickAwayListener>
  )
}

export default CroppedTextTooltip
{
}
