import React, { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  FormControlLabel,
  Checkbox,
  List,
  ListItem,
  Grid,
  FormControl
} from '@material-ui/core'

import { Text } from '~/components/shared'

import { isShare } from '~/state/modules/user'
import { changeCheckbox } from '~/state/modules/calendar/calendar.slice'

import useCalendarSidebarStyles from './CalendarSidebar.styles'
import palette from '~/theme/palette'
import { humanizeDate } from '~/utils/functions'

type Props = {
  childrenData: {
    parents: Itinerary[]
    friends: Itinerary[]
  }
  checkboxes: {
    children: {
      [key: string]: {
        value: boolean
        field: 'parents' | 'friends'
      }
    }
  }
}

const CalendarSidebar: FC<Props> = ({
  childrenData: { parents, friends },
  checkboxes: { children }
}) => {
  const s = useCalendarSidebarStyles()
  const dispatch = useDispatch()

  const withShare = useSelector(isShare)

  const handleChangeChildren = useCallback(
    (field: 'parents' | 'friends') => (event: React.ChangeEvent<{}>): void => {
      const currentTarget = event.target as HTMLInputElement

      dispatch(
        changeCheckbox({
          [currentTarget.name]: {
            value: currentTarget.checked,
            field
          }
        })
      )
    },
    []
  )

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Text fontWeight={600} mb={1}>
          Children
        </Text>

        <FormControl fullWidth>
          <List disablePadding>
            {!!parents.length ? (
              parents.map(({ id, questionnaire, color, activity_dates }) => {
                const childName = `${questionnaire?.fields?.child_first_name} -`

                const startDate = humanizeDate(
                  activity_dates?.start,
                  'DD/MM/YY'
                )
                const endDate = humanizeDate(activity_dates?.end, 'DD/MM/YY')

                const calendarDate = `${startDate} - ${endDate}`

                const checkboxColor = !!children[id]?.value
                  ? color
                  : palette.text.disabled

                return (
                  <ListItem disableGutters className={s.listItem} key={id}>
                    <FormControlLabel
                      onChange={handleChangeChildren('parents')}
                      label={
                        <>
                          <Text className={s.label}>{childName}</Text>
                          <Text className={s.label}>{calendarDate}</Text>
                        </>
                      }
                      className={s.labelRoot}
                      control={
                        <Checkbox
                          data-cy="calendar-children-checkbox"
                          name={id}
                          checked={!!children[id]?.value}
                          style={{
                            color: checkboxColor
                          }}
                        />
                      }
                    />
                  </ListItem>
                )
              })
            ) : (
              <Text>No approved itineraries yet.</Text>
            )}
          </List>
        </FormControl>
      </Grid>

      {withShare && (
        <Grid item xs={12}>
          <Text fontWeight={600} mb={1}>
            Friends
          </Text>

          <FormControl fullWidth>
            <List disablePadding>
              {!!friends.length ? (
                friends.map(({ id, parent_name, color }) => (
                  <ListItem disableGutters className={s.listItem} key={id}>
                    <FormControlLabel
                      onChange={handleChangeChildren('friends')}
                      label={`${parent_name}'s itinerary`}
                      className={s.label}
                      control={
                        <Checkbox
                          name={id}
                          checked={!!children[id]?.value}
                          style={{
                            color: !!children[id]?.value
                              ? color
                              : palette.text.disabled
                          }}
                        />
                      }
                    />
                  </ListItem>
                ))
              ) : (
                <Text>No approved itineraries yet.</Text>
              )}
            </List>
          </FormControl>
        </Grid>
      )}
    </Grid>
  )
}

export default CalendarSidebar
