import { makeStyles, createStyles } from '@material-ui/core'

export const useStyles = makeStyles(
  (theme) =>
    createStyles({
      label: {
        WebkitTapHighlightColor: 'transparent',

        '& > .flag-dropdown': {
          '& ul.country-list': {
            [theme.breakpoints.down(360)]: {
              width: '100% !important',
              minWidth: '200px !important',
              maxWidth: '301px !important'
            }
          }
        },

        '& .special-label': {
          display: 'none'
        }
      },

      input: {
        width: '100% !important',
        maxHeight: 50,
        borderRadius: '10px !important',
        color: theme.palette.text.primary,
        borderColor: theme.palette.text.disabled,

        '&:focus': {
          border: `2px solid ${theme.palette.primary.main} !important`,
          boxShadow: `none !important`
        }
      },

      error: {
        '& .form-control': {
          border: `1px solid #f44336 !important`,

          '&:focus': {
            border: `2px solid #f44336 !important`
          },

          '&::placeholder': {
            color: `#f44336 !important`
          }
        }
      },

      errorLabel: {
        margin: '3px 14px 0'
      }
    }),
  {
    name: 'PhoneField'
  }
)
