import React, { FC, useMemo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useScrollTrigger } from '@material-ui/core'

import Desktop from '~/containers/Desktop'
import Mobile from '~/containers/Mobile'

import HeaderDesktop from './Header.desktop'
import HeaderMobile from './Header.mobile'

import {
  enoughtQuestionnaire,
  isLogged,
  isSpecialist,
  logoutUser
} from '~/state/modules/user'
import useWelcomeDialog from '~/hooks/useWelcomeDialog'

import ROUTES from '~/constants/routes'

type Props = {
  navs: NavLinkOption[]
  isGradient?: boolean
}

export type HeaderProps = Props & {
  logged: boolean
  specialist: boolean
  limitQuestionnaire: boolean
  logout: () => void
  handleConsultationDialog: () => void
}

const Header: FC<Props> = ({ isGradient, ...props }): JSX.Element => {
  const h = useHistory()
  const dispatch = useDispatch()
  const { handleConsultationDialog } = useWelcomeDialog()

  const logged = useSelector(isLogged)
  const specialist = useSelector(isSpecialist)
  const limitQuestionnaire = useSelector(enoughtQuestionnaire)

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 85
  })

  const gradientHeader = useMemo(() => isGradient && !trigger, [
    isGradient,
    trigger
  ])

  const logout = useCallback((): void => {
    dispatch(logoutUser())
    h.push(ROUTES.home)
  }, [h, dispatch])

  return (
    <>
      <Desktop>
        <HeaderDesktop
          {...props}
          isGradient={gradientHeader}
          logged={logged}
          specialist={specialist}
          limitQuestionnaire={limitQuestionnaire}
          handleConsultationDialog={handleConsultationDialog}
          logout={logout}
        />
      </Desktop>

      <Mobile>
        <HeaderMobile
          {...props}
          isGradient={gradientHeader}
          logged={logged}
          specialist={specialist}
          limitQuestionnaire={limitQuestionnaire}
          handleConsultationDialog={handleConsultationDialog}
          logout={logout}
        />
      </Mobile>
    </>
  )
}

export default Header
