import { createMuiTheme } from '@material-ui/core'
import { TypographyOptions } from '@material-ui/core/styles/createTypography'

const defaultTheme = createMuiTheme()

export const fonts = {
  nunito: 'Nunito, sans-serif',
  serif: 'DM Serif Display, serif'
}

const typography: TypographyOptions = {
  fontFamily: fonts.nunito,
  h1: {
    fontSize: 48,

    [defaultTheme.breakpoints.down('sm')]: {
      fontSize: 36
    }
  },
  h2: {
    fontSize: 36
  },
  h3: {
    fontSize: 30,

    [defaultTheme.breakpoints.down('xs')]: {
      fontSize: 24
    }
  },
  h4: {
    fontSize: 24
  },
  h5: {
    fontSize: 20
  },
  subtitle1: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 1.7
  },
  body1: {
    fontSize: 16,
    lineHeight: 1.65
  },
  body2: {
    fontSize: 14
  }
}

export default typography
