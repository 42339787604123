import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  successAction,
  failureAction,
  successItemAction
} from '~/utils/reducerFunctions'

import { STATUSES } from '~/constants/statusesItinerary'

export interface DashboardState {
  item: Partial<Itinerary>
  data: Itinerary[]
  loading: boolean
  loaded: boolean
  activeTab: StatusesUnion
  activePage: number
  meta: ItinerariesMeta
  error: any
}

const initialState: DashboardState = {
  data: [],
  item: {},
  activeTab: STATUSES.draft,
  activePage: 1,
  loading: false,
  loaded: false,
  meta: {
    pages: 1,
    amount: Object.values(STATUSES).reduce(
      (acc, key) => ({
        ...acc,
        [key]: 0
      }),
      {}
    ) as Record<StatusesUnion, number>
  },
  error: null
}

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    request: (state): void => {
      state.loading = true
      state.meta.pages = 1
      state.error = null
    },

    success: (
      state,
      action: PayloadAction<{
        data: Itinerary[]
        meta: ItinerariesMeta
      }>
    ): void => {
      successAction(state, action, 'data', (state) => {
        const {
          payload: { data, meta }
        } = action

        state.data = data
        state.meta = meta
      })
    },

    changeActiveTab: (
      state,
      { payload }: PayloadAction<StatusesUnion>
    ): void => {
      state.activeTab = payload
      state.activePage = 1
    },

    changeActivePage: (state, { payload }: PayloadAction<number>): void => {
      state.activePage = payload
    },

    changeItineraryStatus: (
      state,
      {
        payload: { itineraryId, fromStatus, toStatus }
      }: PayloadAction<{
        itineraryId: string
        fromStatus: StatusesUnion
        toStatus: StatusesUnion
      }>
    ): void => {
      const data = state.data.filter(({ id }) => id !== itineraryId)

      if (!!data.length) {
        state.meta.amount[fromStatus] = --state.meta.amount[fromStatus]
        state.meta.amount[toStatus] = ++state.meta.amount[toStatus]
      } else if (state.meta.pages > 1) {
        state.activePage = --state.activePage
      }

      state.data = data
    },

    addActivityToItinerary: (
      state,
      {
        payload: { itineraryActivity, itineraryId }
      }: PayloadAction<AddItineraryActivityDTO>
    ): void => {
      const itinerary = state.data.find(({ id }) => id === itineraryId)

      state.item.activities.push(itineraryActivity.activity)
      state.item.itinerary_activities.push(itineraryActivity)

      if (itinerary) {
        itinerary.activities.push(itineraryActivity.activity)
        itinerary.itinerary_activities.push(itineraryActivity)
      }
    },

    removeActivityFromItinerary: (
      state,
      { payload }: PayloadAction<string>
    ): DashboardState => {
      const itineraryActivity = state.item.itinerary_activities.find(
        ({ id }) => id === payload
      )

      return {
        ...state,
        item: {
          ...state.item,
          activities: state.item.activities.filter(
            ({ id }) => id !== itineraryActivity.activity.id
          ),
          itinerary_activities: state.item.itinerary_activities.filter(
            ({ id }) => id !== itineraryActivity.id
          )
        }
      }
    },

    successItem: successItemAction,

    updateItemMessage: (state, { payload }: PayloadAction<string>): void => {
      state.item.message = payload
    },

    failure: failureAction
  }
})

export const {
  request,
  success,
  changeActiveTab,
  changeActivePage,
  changeItineraryStatus,
  addActivityToItinerary,
  removeActivityFromItinerary,
  successItem,
  updateItemMessage,
  failure
} = dashboardSlice.actions

export default dashboardSlice.reducer
