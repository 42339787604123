import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { Box, Button, createStyles, makeStyles } from '@material-ui/core'

import Premium from '~/containers/Premium'
import { Text } from '~/components/shared'

import ROUTES from '~/constants/routes'

const useStyles = makeStyles(
  ({ spacing, palette }) =>
    createStyles({
      root: {
        padding: spacing(0, 2),
        borderLeft: `4px solid ${palette.primary.main}`
      },

      label: {
        height: 20,

        '& svg': {
          width: 16,
          height: 16
        }
      }
    }),
  {
    name: 'Itineraries'
  }
)

type Props = {
  id: string
}

const Comment: FC<Props> = ({ id }) => {
  const s = useStyles()

  return (
    <Box className={s.root}>
      <Text mb={2} color="textSecondary">
        If you have any change requests or comments to the itinerary, please
        leave them and a program specialist will respond.
      </Text>

      <Premium>
        <Button
          data-cy="comment-button"
          component={Link}
          to={`${ROUTES.itineraries}/${id}`}
          variant="outlined"
          color="primary"
        >
          Leave requests / comments
        </Button>
      </Premium>
    </Box>
  )
}

export default Comment
