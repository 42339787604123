import React, { FC } from 'react'
import { compose } from 'redux'
import { connect, useSelector } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import {
  Field,
  Form,
  InjectedFormProps,
  reduxForm,
  SubmissionError
} from 'redux-form'
import { Button, Card, CardContent, Grid } from '@material-ui/core'

import FullWidthMobile from '~/containers/FullWidthMobile'
import { ExtendedButton, Flexbox, FormControl } from '~/components/shared'
import { AutocompleteField, TextField } from '~/components/fields'

import { updateQuestionaire } from '~/state/modules/questionnaires'
import QuestionnaireService from '~/services/QuestionnaireService'
import ChildrenProfileSchema from '~/schemas/children'
import { asyncValidate } from '~/utils/asyncValidate'

import ageOptions from '~/data/profile/ageOptions'
import risingLevelOptions from '~/data/profile/risingLevelOptions'
import interestsOptions from '~/data/profile/interestsOptions'
import personalOptions from '~/data/profile/personalOptions'
import needsOptions from '~/data/profile/needsOptions'

import useProfileStyles from '../../profile.styles'
import { Link } from 'react-router-dom'
import ROUTES from '~/constants/routes'
import Premium from '~/containers/Premium'
import { isPremiumPaid } from '~/state/modules/user'

type Props = {
  id: string
  itineraryId: string
  fields: Fields
  disabled: boolean
}

type DispatchProps = {
  updateQuestionaire: (
    id: string,
    itineraryId: string,
    fields: Fields
  ) => Promise<void>
}

type AllProps = Props & DispatchProps & InjectedFormProps

const ChildrenProfileForm: FC<AllProps> = ({
  disabled,
  itineraryId,
  dirty,
  submitting,
  handleSubmit
}) => {
  const s = useProfileStyles()
  const isPremium = useSelector(isPremiumPaid)

  return (
    <FullWidthMobile>
      <Card variant="outlined" className={s.formCard}>
        <CardContent>
          <Form onSubmit={handleSubmit} data-cy="children-profile-form">
            <Grid container spacing={2}>
              {disabled && (
                <>
                  <Grid item xs={12} className={s.similarBtnGrid}>
                    <Premium
                      customBlock={{
                        isBlock: !isPremium,
                        useBlock: true
                      }}
                    >
                      <Button
                        component={Link}
                        to={{
                          pathname: ROUTES.questionnaire,
                          state: { itineraryId }
                        }}
                        variant="outlined"
                        size="small"
                      >
                        Start plan for this child
                      </Button>
                    </Premium>
                  </Grid>
                </>
              )}

              <Grid item xs={12} sm={6}>
                <FormControl label="Name">
                  <Field
                    name="name"
                    placeholder="Name"
                    component={TextField}
                    disabled={disabled}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl label="Age">
                  <Field
                    name="age"
                    placeholder="Age"
                    component={AutocompleteField}
                    options={ageOptions}
                    onChangeKey="value"
                    disabled={disabled}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl label="Rising grade level">
                  <Field
                    name="level"
                    placeholder="Rising grade level"
                    component={AutocompleteField}
                    options={risingLevelOptions}
                    onChangeKey="value"
                    disabled={disabled}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl label="Interests">
                  <Field
                    name="interests"
                    placeholder="Interests"
                    component={AutocompleteField}
                    options={interestsOptions}
                    groupBy={(option: InterestOption): InterestsLabel =>
                      option.interestLabel
                    }
                    limitTags={3}
                    multiple
                    disabled={disabled}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl label="Personal skills">
                  <Field
                    name="skills"
                    placeholder="Personal skills"
                    component={AutocompleteField}
                    options={personalOptions}
                    onChangeKey="value"
                    limitTags={3}
                    multiple
                    disabled={disabled}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl label="Special needs">
                  <Field
                    name="needs"
                    placeholder="Special needs"
                    component={AutocompleteField}
                    options={needsOptions}
                    onChangeKey="value"
                    multiple
                    disabled={disabled}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Flexbox justifyContent="flex-end">
                  <ExtendedButton
                    data-cy="children-form-button"
                    type="submit"
                    loading={submitting}
                    disabled={disabled || !dirty}
                  >
                    Save changes
                  </ExtendedButton>
                </Flexbox>
              </Grid>
            </Grid>
          </Form>
        </CardContent>
      </Card>
    </FullWidthMobile>
  )
}

const mapStateToProps = (_state, { id, fields }: Props): any => {
  const initialValues = QuestionnaireService.createValues(fields)

  return {
    form: `Childrens_Profile_Form_${id}`,
    initialValues
  }
}

const withConnect = connect(mapStateToProps, { updateQuestionaire })

const withForm = reduxForm<ChildrenInput, Props & DispatchProps>({
  form: 'Childrens_Profile_Form',
  enableReinitialize: true,
  shouldAsyncValidate: () => true,
  asyncValidate: asyncValidate(ChildrenProfileSchema),
  onSubmit: async (
    values,
    _dispatch,
    { id, fields, itineraryId, updateQuestionaire }
  ) => {
    try {
      const fieldsDTO = QuestionnaireService.createDTO(values, fields)

      await updateQuestionaire(id, itineraryId, fieldsDTO)

      return values.name
    } catch (error) {
      throw new SubmissionError(error.errors)
    }
  },
  onSubmitSuccess: (name) => {
    toastr.success('Success', `${name} was successfully updated!`)
  }
})

export default compose<React.FC<Props>>(
  withConnect,
  withForm
)(ChildrenProfileForm)
