import React, { FC, useCallback, useState } from 'react'
import { Field } from 'redux-form'
import { Box, ClickAwayListener } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { ExpandMore } from '@material-ui/icons'
import cn from 'classnames'

import Desktop from '~/containers/Desktop'
import Mobile from '~/containers/Mobile'
import { Flexbox, FormControl, Text } from '~/components/shared'
import { SliderField } from '~/components/fields'

import IntlService from '~/services/IntlService'

import usePriceFieldStyles from './PriceField.styles'

const CustomSlider = withStyles(({ palette }) => ({
  root: {
    color: palette.secondary.main,
    margin: '5px 10px 0',
    width: 'calc(100% - 25px)'
  },

  thumb: {
    height: 18,
    width: 18,
    border: '1px solid currentColor',
    marginTop: -9,
    marginLeft: -9,
    '&:focus, &:hover, &$active': {
      boxShadow: 'none'
    }
  },

  active: {},

  valueLabel: {
    left: 'calc(-50% - 7px)',
    top: -48,

    '& > span': {
      width: 47,
      height: 47,
      borderRadius: '35% 80% 35% 0',

      '& > span': {
        fontSize: 11
      }
    }
  }
}))(SliderField)

type Props = {
  minPrice: number
  maxPrice: number
}

const PriceField: FC<Props> = ({ minPrice, maxPrice }) => {
  const s = usePriceFieldStyles()

  const [open, setOpen] = useState<boolean>(false)

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen)
  }, [])

  const PriceCurrentField = (
    <Field
      name="price"
      component={CustomSlider}
      min={minPrice}
      max={maxPrice}
      step={10}
      valueLabelFormat={(v: number): string =>
        IntlService.currencyFormat(v) || '$0'
      }
      valueLabelDisplay="auto"
    />
  )

  return (
    <>
      <Desktop>
        <ClickAwayListener
          onClickAway={(): void => {
            setOpen(false)
          }}
        >
          <Box className={s.wrapper}>
            <Flexbox
              className={cn(s.btn, {
                active: open
              })}
              onClick={handleToggle}
            >
              <Text className={s.text}>Price</Text>
              <ExpandMore className="arrow" />
            </Flexbox>

            {open && (
              <Box className={s.container}>
                <Box className={s.dropdown}>{PriceCurrentField}</Box>
              </Box>
            )}
          </Box>
        </ClickAwayListener>
      </Desktop>

      <Mobile>
        <FormControl label="Price">{PriceCurrentField}</FormControl>
      </Mobile>
    </>
  )
}

export default PriceField
