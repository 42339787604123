import React from 'react'
import isEmpty from 'lodash.isempty'
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core'
import { Popover } from '@material-ui/core'
import { Close } from '@material-ui/icons'

import { Text } from '~/components/shared'

import { useCalendarPopover } from './CalendarPopover.style'

type Props = {
  el: HTMLElement
  event: EventProps
  handleClose?: () => void
}

const CalendarPopover: React.FC<Props> = ({ el, event, handleClose }) => {
  const s = useCalendarPopover({ backgroundColor: event?.backgroundColor })

  const open = Boolean(el)
  const id = open ? 'simple-popover' : undefined

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={el}
      onClose={handleClose}
      anchorOrigin={{
        vertical: isEmpty(el) ? 'center' : 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <Box className={s.content}>
        <IconButton className={s.btnClose} onClick={handleClose}>
          <Close />
        </IconButton>

        <Text variant="subtitle1" className={s.title}>
          {event.title}
        </Text>
        <Text color="textSecondary">Date: {event?.date}</Text>
        <Text color="textSecondary">Duration: {event?.duration}</Text>
        <Text color="textSecondary">
          {event?.camp_info_title} {event?.camp_info_time}
        </Text>
        <Text mb={1} color="textSecondary">
          Location: {event?.location}
        </Text>
        <Text className={s.name}>{event?.child_name}</Text>

        {!!event?.invited_parents?.length && (
          <Box className={s.listContainer}>
            <Text pt={1}>Accepted invitations:</Text>

            <List className={s.list}>
              {event?.invited_parents?.map((item) => (
                <ListItem key={item}>
                  <ListItemIcon className={s.listIcon}>
                    <Box className={s.decorate} />
                  </ListItemIcon>
                  <ListItemText>{item}</ListItemText>
                </ListItem>
              ))}
            </List>
          </Box>
        )}

        {!!event.invited_friend_name && (
          <Box className={s.listContainer}>
            <Text pt={1}>Invitation from:</Text>

            <List className={s.list}>
              <ListItem>
                <ListItemIcon className={s.listIcon}>
                  <Box className={s.decorate} />
                </ListItemIcon>
                <ListItemText>{event.invited_friend_name}</ListItemText>
              </ListItem>
            </List>
          </Box>
        )}
      </Box>
    </Popover>
  )
}

export default CalendarPopover
