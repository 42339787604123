import React, { FC, useEffect } from 'react'
import { connect } from 'react-redux'
import { Paper, Box, Container, Grid } from '@material-ui/core'

import Desktop from '~/containers/Desktop'
import Mobile from '~/containers/Mobile'
import FullWidthMobile from '~/containers/FullWidthMobile'
import { Flexbox, Loader, Text, Title } from '~/components/shared'
import DashboardTabs from './components/DashboardTabs'
import DashboardTable from './components/DashboardTable'
import DashboardAccordion from './components/DashboardAccordion'
import DashboardPagination from './components/DashboardPagination'

import { getItineraries } from '~/state/modules/dashboard'
import { AppState } from '~/state/store'

import useDashboardStyles from './dashboard.style'

type ReduxProps = {
  data: Itinerary[]
  activeTab: StatusesUnion
  activePage: number
  loading: boolean
  loaded: boolean
  meta: ItinerariesMeta
}

type dispatchProps = {
  getItineraries: (params: ItinerariesDTO) => void
}

type AllProps = ReduxProps & dispatchProps

const Dashboard: FC<AllProps> = ({
  loading,
  loaded,
  data,
  activeTab,
  activePage,
  meta,

  getItineraries
}) => {
  const s = useDashboardStyles()

  useEffect(() => {
    getItineraries({
      by_status: activeTab,
      page: activePage
    })
  }, [activeTab, activePage])

  return (
    <FullWidthMobile>
      <Container maxWidth="lg" disableGutters>
        <Paper className={s.container}>
          <Box className={s.headerWrapper}>
            <Flexbox className={s.header}>
              <Title variant="h4" textAlign="left">
                Dashboard
              </Title>

              {/* <DashboardControls /> */}
            </Flexbox>

            <DashboardTabs activeTab={activeTab} amount={meta.amount} />
          </Box>

          {loading ? (
            <Loader />
          ) : data?.length && loaded ? (
            <>
              <Desktop>
                <DashboardTable data={data} activeTab={activeTab} />
              </Desktop>

              <Mobile>
                <Grid container spacing={2} className={s.accordionsContainer}>
                  {data.map((itinerary) => (
                    <Grid
                      key={itinerary.id}
                      item
                      xs={12}
                      data-cy="dashboard-mobile-active-tabs"
                    >
                      <DashboardAccordion
                        activeTab={activeTab}
                        {...itinerary}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Mobile>

              <Flexbox justifyContent="center" mt={3}>
                <DashboardPagination
                  count={meta.pages}
                  activePage={activePage}
                />
              </Flexbox>
            </>
          ) : (
            <Text px={3}>No results</Text>
          )}
        </Paper>
      </Container>
    </FullWidthMobile>
  )
}

const mapStateToProps = ({ dashboard }: AppState): ReduxProps => {
  return dashboard
}

export default connect(mapStateToProps, {
  getItineraries
})(Dashboard)
