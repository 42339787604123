import React, { FC } from 'react'
import { compose } from 'redux'
import { toastr } from 'react-redux-toastr'
import { RouteComponentProps, withRouter } from 'react-router'
import { InjectedFormProps, Form, reduxForm, SubmissionError } from 'redux-form'
import { Grid, Button, Box } from '@material-ui/core'

import { Text } from '~/components/shared'
import { PasswordFragment } from '~/components/forms'

import { resetPassword, resetDirectorPassword } from '~/state/modules/user'
import { asyncValidate } from '~/utils/asyncValidate'
import ResetPasswordSchema from '~/schemas/forgot-password/reset-password'

import ROUTES from '~/constants/routes'
import { STORAGE } from '~/constants/storage'
import { HELPER_ROLES } from '~/constants'

const ResetPasswordForm: FC<InjectedFormProps> = ({ handleSubmit }) => {
  return (
    <Box
      component={Form}
      display="flex"
      flexDirection="column"
      onSubmit={handleSubmit}
      data-cy="reset-password-form"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Text variant="h5" color="textSecondary" align="center">
            Create new password
          </Text>
        </Grid>
        <Grid item xs={12}>
          <PasswordFragment confirm />
        </Grid>

        <Grid item xs={12}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
          >
            Reset
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

const withForm = reduxForm<ResetPasswordInput, RouteComponentProps>({
  form: 'Reset_Password_Form',
  shouldAsyncValidate: () => true,
  asyncValidate: asyncValidate(ResetPasswordSchema),
  onSubmit: async (values, dispatch) => {
    try {
      const entity = localStorage.getItem(STORAGE.entity)

      if (entity === HELPER_ROLES.director)
        await dispatch<any>(resetDirectorPassword(values))

      if (entity === HELPER_ROLES.parent)
        await dispatch<any>(resetPassword(values))

      return { entity }
    } catch (error) {
      throw new SubmissionError(error.errors)
    }
  },
  onSubmitSuccess: ({ entity }, _dispatch, { history }) => {
    toastr.success('Success', 'Password was reseted successfully!')

    if (entity === HELPER_ROLES.director)
      history.push(ROUTES.campDirectorSignIn)
    if (entity === HELPER_ROLES.parent) history.push(ROUTES.signIn)

    localStorage.removeItem(STORAGE.entity)
  }
})

export default compose<React.FC>(withRouter, withForm)(ResetPasswordForm)
