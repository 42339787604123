import queryString from 'query-string'

import { ROLES } from '~/constants/roles'
import {
  QuestionnaireFormStatus,
  getQuestionnaireFormConfig
} from './questionnaire.config'

/**
 * Create query string from userData for Zoho form
 *
 * @param user User data
 */
const createUserQuery = (user: Partial<User>, fields?: Fields): string => {
  const { name, email, role, address, zip_code, phone_number } = user

  let queryDTO = {}

  const [first_name, ...last_name] = name?.split(' ')

  queryDTO = {
    name_title: !!role && role === ROLES.Parent ? 'Parent' : undefined,
    first_name: first_name || undefined,
    last_name: last_name ? last_name?.join(' ') : undefined,
    email: email || undefined,
    address_street: address || undefined,
    zip_code: zip_code || undefined,
    phone_number: phone_number || undefined
  }

  if (fields) {
    const {
      distance_preferences_overnight_camps,
      distance_preferences_day_camps,
      day_camp_duration_preferences,
      earliest_time_day_camp_drop_off,
      firtst_date_camp_preference,
      second_date_camp_preference,
      third_date_camp_preference,
      fourth_date_camp_preference,
      academic_proficiency,
      cooking_baking_proficiency,
      specify_accessibility,
      ...fieldsValues
    } = fields

    queryDTO = {
      ...fieldsValues,
      distance_overnight: distance_preferences_overnight_camps,
      distance_day: distance_preferences_day_camps,
      day_camp: day_camp_duration_preferences,
      earliest_time: earliest_time_day_camp_drop_off || undefined,
      firtst_date_camp: firtst_date_camp_preference,
      second_date_camp: second_date_camp_preference,
      third_date_camp: third_date_camp_preference,
      fourth_date_camp: fourth_date_camp_preference,
      academic: academic_proficiency,
      cooking_baking: cooking_baking_proficiency,
      specify_access: specify_accessibility
    }
  }

  return queryString.stringify(queryDTO)
}

/**
 * Generate iframe for Zoho form
 *
 * @param user User data
 */
export const generateIframe = (
  user: Partial<User>,
  fields?: Fields | boolean
): void => {
  const userQuery = createUserQuery(user, fields as Fields)

  const f = document.createElement('iframe')

  const { has_not_completed_questionnaire: hasNotCompletedQuestionnaire } = user

  const userProgressStatus = hasNotCompletedQuestionnaire
    ? QuestionnaireFormStatus.COMPLETED
    : QuestionnaireFormStatus.UNCOMPLETED

  const { formName, zohoForm } = getQuestionnaireFormConfig({
    isProduction: process.env.REACT_APP_CI_ENV === 'production',
    status: userProgressStatus
  })

  f.src = `https://forms.planmykids.com/know/form/${formName}/formperma/${zohoForm.key}?zf_rszfm=1&${userQuery}`
  f.style.border = 'none'
  f.style.height = '912px'
  f.style.width = '90%'
  f.style.transition = 'all 0.5s ease' // No I18N

  const d = document.getElementById(zohoForm.divId())

  d?.appendChild(f)

  window.addEventListener('message', function (event) {
    if (event.origin === 'https://forms.planmykids.com' && event.data) {
      const zf_ifrm_data = event.data?.split('|')
      const zf_perma = zf_ifrm_data[0]
      const zf_ifrm_ht_nw = parseInt(zf_ifrm_data[1], 10) + 15 + 'px'
      const iframe = document
        .getElementById(zohoForm.divId())
        ?.getElementsByTagName('iframe')[0]

      if (
        iframe?.src.indexOf('formperma') > 0 &&
        iframe?.src.indexOf(zf_perma) > 0
      ) {
        const prevIframeHeight = iframe.style.height

        if (prevIframeHeight !== zf_ifrm_ht_nw) {
          iframe.style.height = zf_ifrm_ht_nw
        }
      }
    }
  })
}
