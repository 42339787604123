import React, { FC, useCallback, useState, useEffect } from 'react'
import { WrappedFieldProps } from 'redux-form'
import debounce from 'lodash.debounce'
import { Slider, SliderProps } from '@material-ui/core'

const SliderField: FC<WrappedFieldProps & SliderProps> = ({
  input: { onChange, value, ...input },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  meta,
  defaultValue,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState(defaultValue ?? [0, 1])

  useEffect(() => {
    if (value) setInputValue(value)
  }, [value])

  const changeDebounce = useCallback(
    debounce((_e: any, newValue: number | number[]): void => {
      onChange(newValue)
    }, 300),
    [onChange]
  )

  const handleChange = useCallback(
    (_e: any, newValue: number | number[]) =>
      setInputValue(newValue as number[]),
    []
  )

  return (
    <Slider
      onChange={handleChange}
      onChangeCommitted={changeDebounce}
      value={inputValue}
      {...input}
      {...rest}
    />
  )
}

export default SliderField
