import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { getParentItineraries } from '~/state/modules/itineraries'
import Actioncable from '~/services/Acable'
import { toastr } from 'react-redux-toastr'

const useRefetchItineraries = (itinerary_id?: string) => {
  const dispatch = useDispatch()

  useEffect(() => {
    let channel: any = null

    if (itinerary_id && !channel) {
      channel = Actioncable?.cable.subscriptions.create(
        { channel: `ItinerariesChannel`, itinerary_id },
        {
          received: ({ refetch }) => {
            if (refetch) {
              dispatch(getParentItineraries())

              toastr.success(
                'Itineraries',
                'Your Itineraries have been updated'
              )
            }
          }
        }
      )
    }

    return (): void => {
      if (channel?.unsubscribe) channel?.unsubscribe()
    }
  }, [itinerary_id])
}

export default useRefetchItineraries
