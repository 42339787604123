import React, { FC, useState } from 'react'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import { Button } from '@material-ui/core'

import { Flexbox, Text, Title } from '~/components/shared'
import DirectorProfileForm from './components/DirectorProfileForm'

import PMK from '~/services/PMK'
import CampDirectorTemplate from '../camp-director.template'
import { logoutUser } from '~/state/modules/user'
import ROUTES from '~/constants/routes'

import { ReactComponent as StripeIcon } from '~/assets/icons/icon-stripe.svg'

const Profile: FC = () => {
  const h = useHistory()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState<boolean>(false)

  const logout = (): void => {
    dispatch(logoutUser())
    h.push(ROUTES.home)
  }

  const handleLoginStripe = async (): Promise<void> => {
    setLoading(true)

    const { url } = await PMK.getStripeLinkAccount()

    if (url) {
      window.location = url
    } else {
      setLoading(false)
    }
  }

  return (
    <CampDirectorTemplate>
      <Flexbox
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Title variant="h4" textAlign="start">
          Profile
        </Title>

        <Button
          size="small"
          variant="outlined"
          onClick={handleLoginStripe}
          disabled={loading}
        >
          <StripeIcon />
          <Text fontSize={15} fontWeight={600} ml={1} color="textPrimary">
            Stripe
          </Text>
        </Button>
      </Flexbox>

      <DirectorProfileForm />

      <Flexbox justifyContent="space-between" mt={5} width="100%">
        <Button color="primary" onClick={logout}>
          Log out
        </Button>
      </Flexbox>
    </CampDirectorTemplate>
  )
}

export default Profile
