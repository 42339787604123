import React from 'react'
import { Redirect } from 'react-router-dom'
import { DecoratedComponentClass } from 'redux-form'

import { STORAGE } from '~/constants/storage'
import { ROLES } from '~/constants/roles'
import ROUTES from '~/constants/routes'

import store from '~/state/store'

type ComponentType =
  | React.ComponentType
  | React.FC<any>
  | DecoratedComponentClass<any, any>

/**
 * HOC for authorized pages, only for logged users
 *
 * @param allowedRoles allowed roles list
 */
export const Authorization = (allowedRole: RolesUnion) => (
  WrappedComponent: ComponentType,
  verify?: (user: Partial<User>) => boolean,
  isCheckout?: boolean
): React.ComponentClass =>
  class WithAuthorization extends React.Component {
    state = {
      user: store.getState().user
    }

    render(): JSX.Element {
      const {
        user: { role, token, confirmed, email, package_type: packageType }
      } = this.state

      if (verify) {
        if (verify(this.state.user)) return <Redirect to={ROUTES.signIn} />
      }

      if (!!token && !confirmed) {
        localStorage.setItem(STORAGE.userEmail, email)
        const isDirector = ROLES.CampDirector === allowedRole
        const isParent = allowedRole === ROLES.Parent

        if (isParent && !packageType) {
          if (isCheckout) return <WrappedComponent {...this.props} />

          return <Redirect to={{ pathname: ROUTES.congratulations }} />
        }

        return (
          <Redirect
            to={{
              pathname: ROUTES.signUpVerify,
              state: { director: isDirector }
            }}
          />
        )
      }

      if (allowedRole === role && token && confirmed) {
        return <WrappedComponent {...this.props} />
      }

      return <Redirect to={ROUTES.signIn} />
    }
  }

/**
 * HOC for authentication pages, only for unloged users
 *
 * @param WrappedComponent wrapped component
 * @param verify callback
 */
export const Unauthorized = (
  WrappedComponent: ComponentType,
  verify?: (user: Partial<User>) => boolean
): React.ComponentClass =>
  class WithAuthorization extends React.Component {
    state = {
      user: store.getState().user
    }

    render(): JSX.Element {
      const {
        user: { token, confirmed }
      } = this.state

      if (verify) {
        if (verify(this.state.user)) return <Redirect to={ROUTES.home} />
      } else {
        if (!!token && confirmed) return <Redirect to={ROUTES.home} />
      }

      return <WrappedComponent {...this.props} />
    }
  }

export const Parent = Authorization(ROLES.Parent)

export const Specialist = Authorization(ROLES.Specialist)

export const CampDirector = Authorization(ROLES.CampDirector)
