import React, { useEffect, useState } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import {
  RouteProps,
  RouteComponentProps,
  withRouter,
  useLocation
} from 'react-router'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { actions as toastrActions } from 'react-redux-toastr'

import { Loader } from './components/shared'
import AppLayout, { LayoutProps } from './components/layouts/AppLayout'

import useNetwork from './hooks/useNetwork'
import { authtorize } from './state/modules/user'
import store from './state/store'

import { STORAGE } from './constants/storage'
import ROUTES from './constants/routes'
import { routes } from './routes'

import palette from './theme/palette'

interface AppRouteProps extends RouteProps {
  layoutProps?: LayoutProps
}

const AppRoute: React.FC<AppRouteProps> = ({
  component: Component,
  layoutProps,
  ...rest
}) => {
  const { pathname } = useLocation()

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  if (pathname === ROUTES.advertising) {
    return <Redirect to={ROUTES.home} />
  }

  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps): JSX.Element => (
        <AppLayout
          background={
            pathname === ROUTES.calendar ||
            pathname === ROUTES.campDirectorCongratulations ||
            pathname === ROUTES.campDirectorStripe
              ? palette.common.white
              : ''
          }
          {...layoutProps}
        >
          <Component {...props} />
        </AppLayout>
      )}
    />
  )
}

interface AppState {
  loading: boolean
}

interface Props {
  showToastr: typeof toastrActions.add
  closeToastr: typeof toastrActions.remove
}

type AppProps = RouteComponentProps & Props

const App: React.FC<AppProps> = ({ history, showToastr, closeToastr }) => {
  const [loading, setLoading] = useState(true)
  const isOffline = useNetwork()

  useEffect(() => {
    async function fetchData(): Promise<void> {
      const jwt = localStorage.getItem(STORAGE.authToken)

      try {
        if (jwt) {
          const res = await store.dispatch(authtorize({ jwt }))

          if (res?.expired) {
            history.push(ROUTES.signIn)

            showToastr({
              type: 'warning',
              title: 'Warning',
              message: 'Session has expired. Please sign-in to account',
              position: 'bottom-right',
              options: {
                showCloseButton: true
              }
            })
          }
        }
      } catch (error) {
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (isOffline) {
      showToastr({
        id: 'offline-toastr',
        type: 'warning',
        title: 'Whoops! No Internet Connection',
        message:
          'Some parts of this app may be unavailable until you come back online.',
        position: 'bottom-center',
        options: {
          timeOut: 0
        }
      })
    } else {
      closeToastr('offline-toastr')
    }
  }, [isOffline])

  if (loading) return <Loader height="100vh" noGutters />

  return (
    <Switch>
      {routes.map((routeProps) => (
        <AppRoute key={routeProps.path} {...routeProps} />
      ))}
    </Switch>
  )
}

const withConnect = connect(null, {
  showToastr: toastrActions.add,
  closeToastr: toastrActions.remove
})

export default compose<React.ComponentClass>(withRouter, withConnect)(App)
