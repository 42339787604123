import { object, string } from 'yup'

import { email, phone } from './fields'

const CampDirctorProfileSchema = object().shape({
  first_name: string().required('First name is required').max(255).trim(),
  last_name: string().required('Last name is required').max(255).trim(),
  email,
  contact_phone: phone
})

export default CampDirctorProfileSchema
