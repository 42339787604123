import React, { CSSProperties } from 'react'
import cx from 'classnames'
import { makeStyles, Container } from '@material-ui/core'

const useStyles = makeStyles(
  {
    root: {
      width: '100vw',
      position: 'relative',
      left: '50%',
      right: '50%',
      marginLeft: '-50vw',
      marginRight: '-50vw'
    }
  },
  { name: 'ContainerFull' }
)

type ContainerFullProps = {
  className?: string
  style?: CSSProperties
  withContainer?: boolean | 'xs' | 'sm' | 'lg' | 'xl'
  enableGutters?: boolean
}

const ContainerFull: React.FC<
  ContainerFullProps & React.HTMLProps<HTMLDivElement>
> = ({
  children,
  className,
  style,
  withContainer,
  enableGutters,
  ...rest
}): JSX.Element => {
  const classes = useStyles()

  return (
    <section
      className={cx(classes.root, { [className]: !!className })}
      style={style}
      {...rest}
    >
      {withContainer ? (
        <Container
          maxWidth={typeof withContainer === 'string' ? withContainer : 'md'}
          disableGutters={!enableGutters}
        >
          {children}
        </Container>
      ) : (
        children
      )}
    </section>
  )
}

export default ContainerFull
