import React, { FC } from 'react'
import { Container, Grid, Paper } from '@material-ui/core'

import FullWidthMobile from '~/containers/FullWidthMobile'

import { Text, Title } from '~/components/shared'

import useStaticStyles from '~/styles/static-pages.styles'

const Confirm: FC = () => {
  const s = useStaticStyles()

  return (
    <FullWidthMobile>
      <Container maxWidth="md" className={s.container}>
        <Paper className={s.paper}>
          <Grid container spacing={3} direction="column" alignItems="center">
            <Grid item xs={12}>
              <Title>Verification Email Sent</Title>
            </Grid>
            <Grid item xs={12}>
              <Text
                textAlign="center"
                color="textSecondary"
                className={s.overflowText}
              >
                Check your inbox for a message instructing you to confirm your
                email address. After confirmation you&apos;ll be logged in and
                can proceed. <br />
                If you received a temporary password, please update under your
                profile.
              </Text>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </FullWidthMobile>
  )
}

export default Confirm
