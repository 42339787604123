import { useState } from 'react'

const useScreenOrientation = (): boolean => {
  const orientationLandscape = window.matchMedia('(orientation: landscape)')

  const [isOrientationLandscape, setIsOrientationLandscape] = useState(
    orientationLandscape.matches
  )

  const updateOrientation = (e): void => {
    if (e.matches) {
      // Landscape mode
      setIsOrientationLandscape(true)
    } else {
      //  Portrait mode
      setIsOrientationLandscape(false)
    }
  }

  orientationLandscape.addEventListener('change', updateOrientation)

  return isOrientationLandscape
}

export default useScreenOrientation
