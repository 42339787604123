import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface UserReducer extends Partial<User> {
  token: string
  exp: string
  confirmed: boolean
  first_name: string
  last_name: string
}

// TODO: after approval User interface remove Partial in all project
type UserReducerState = Partial<UserReducer>

export const initialState: UserReducerState = {
  token: '',
  exp: '',
  confirmed: false,
  first_name: '',
  last_name: '',
  name: '',
  phone_number: '',
  address: '',
  zip_code: ''
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (
      state,
      { payload }: PayloadAction<Partial<User>>
    ): UserReducerState => ({
      ...state,
      ...payload
    }),

    logout: (): UserReducerState => initialState
  }
})

export const { login, logout } = userSlice.actions

export default userSlice.reducer
