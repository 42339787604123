import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button, makeStyles, Theme } from '@material-ui/core'

import { Flexbox, Title } from '~/components/shared'
import ProgramForm from '../../components/ProgramForm'
import ProgramActivities from './components/ProgramActivities'
import ProgramTemplate from '../program.template'

import {
  activeTabSelector,
  getProgramById,
  getProgramSelector
} from '~/state/modules/programs'
import {
  deleteProgramItem,
  updateActiveTab
} from '~/state/modules/programs/programs.slice'
import ProgramService from '~/services/ProgramService'

import { PROGRAM_STATUS } from '~/constants'

interface ProgramProps {
  activeTab?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  btns: {
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1)
    }
  }
}))

const ProgramBtns: {
  label: string
  value: string
}[] = [
  {
    label: 'Information',
    value: PROGRAM_STATUS.information
  },
  {
    label: 'Activities',
    value: PROGRAM_STATUS.activities
  }
]

const Program: React.FC<ProgramProps> = () => {
  const s = useStyles()
  const { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()

  const { program, loading } = useSelector(getProgramSelector)
  const activeTab = useSelector(activeTabSelector)

  useEffect(() => {
    if (id) {
      dispatch(getProgramById(id))
    }
    return (): void => {
      dispatch(deleteProgramItem())
    }
  }, [id])

  return (
    <ProgramTemplate>
      <Flexbox mb={3} className={s.btns}>
        {ProgramBtns.map(({ value, label }) => (
          <Button
            data-cy="itineraries-status-button"
            key={value}
            color={value === activeTab ? 'primary' : 'default'}
            variant="outlined"
            size="small"
            onClick={(): void => {
              dispatch(updateActiveTab(value))
            }}
          >
            {label}
          </Button>
        ))}
      </Flexbox>

      {activeTab === PROGRAM_STATUS.information ? (
        <>
          <Title variant="h4" textAlign="start" mb={2}>
            {program.name}
          </Title>
          <ProgramForm
            isEdit
            initialValues={ProgramService.generateInitialValues(program) || {}}
            loading={loading}
            id={id}
          />
        </>
      ) : (
        <ProgramActivities />
      )}
    </ProgramTemplate>
  )
}

export default Program
