import StepImage1 from '~/assets/images/home/step-1-1.png'
import StepImage2 from '~/assets/images/home/step-2-1.png'
import StepImage3 from '~/assets/images/home/step-3-1.png'
import StepImage4 from '~/assets/images/home/step-4.png'
import StepDraw1 from '~/assets/images/home/step-draw-1.png'
import StepDraw2 from '~/assets/images/home/step-draw-2.png'
import StepDraw3 from '~/assets/images/home/step-draw-3.png'
import StepDraw4 from '~/assets/images/home/step-draw-4.png'

const steps: Step[] = [
  {
    value: 0,
    label: 'Step 1',
    image: StepImage1,
    title: 'Share your Family Needs',
    description:
      'Through a short self-guided questionnaire tell us your family preferences and child’s interests.',
    painting: StepDraw1,
    paintingStyles: {
      desktop: {
        bottom: -85,
        left: -36,
        width: 135
      },
      mobile: {
        bottom: -34,
        left: -13,
        width: 60
      }
    }
  },
  {
    value: 1,
    label: 'Step 2',
    image: StepImage2,
    title: 'Choose a package that suites you',
    description:
      'Get all the benefits designed to save you time and make life easier.',
    painting: StepDraw2,
    paintingStyles: {
      desktop: {
        top: -50,
        right: -40,
        width: 100
      },
      mobile: {
        top: -22,
        right: -15,
        width: 50
      }
    }
  },
  {
    value: 2,
    label: 'Step 3',
    image: StepImage3,
    title: 'Receive a personalized itinerary',
    description:
      'A program specialist will find and deliver the programs that are suitable to your needs. You can edit them with the help of MIA, your virtual concierge.',
    painting: StepDraw3,
    paintingStyles: {
      desktop: {
        bottom: -57,
        left: -41,
        width: 120
      },
      mobile: {
        bottom: -20,
        left: -15,
        width: 50
      }
    }
  },
  {
    value: 3,
    label: 'Step 4',
    image: StepImage4,
    title: 'Manage your plans',
    description:
      'Collaborate with friends and family, get information at your finger tips, view on your calendar, start new plans.  Everyone will be star struck by how organized you are!',
    painting: StepDraw4,
    paintingStyles: {
      desktop: {
        top: -32,
        right: -25,
        width: 70
      },
      mobile: {
        top: -17,
        right: -11,
        width: 35
      }
    }
  }
]

export default steps
