const needsOptions: Option[] = [
  {
    value: 'Accessibility',
    label: 'Accessibility'
  },
  {
    value: 'Food Allergy',
    label: 'Food Allergy'
  },
  {
    value: 'Other',
    label: 'Other'
  }
]

export default needsOptions
