import { makeStyles, createStyles } from '@material-ui/core'

const useSignInPopoverStyles = makeStyles(
  ({ palette }) =>
    createStyles({
      btn: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        minWidth: 0,
        border: `1px solid ${palette.primary.main}`,
        padding: '8px 14px 8px 14px',
        borderRadius: 10,
        maxWidth: 200,
        color: palette.primary.main,
        backgroundColor: 'rgba(112, 57, 101, 0.04)'
      },

      text: {
        padding: '0 16px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      },

      container: {
        position: 'absolute',
        top: 43,
        right: 0,
        zIndex: 101,
        paddingTop: 10
      },

      dropdown: {
        background: palette.common.white,
        padding: '7.5px 0',
        boxShadow: '0px 6px 24px rgba(0, 0, 0, 0.08)',
        borderRadius: 6,
        minWidth: 250
      },

      tabItem: {
        position: 'relative',
        padding: 0,
        cursor: 'pointer',

        '& > *': {
          padding: '7.5px 15px',
          width: '100%',
          color: palette.text.secondary,

          '&.active p': {
            fontWeight: 600
          }
        },

        '&:hover': {
          backgroundColor: palette.grey[100],

          '& > *': {
            color: palette.text.primary
          }
        }
      },

      logOut: {
        color: palette.text.hint
      }
    }),
  {
    name: 'SignInPopoverPopover'
  }
)

export default useSignInPopoverStyles
