import { makeStyles, createStyles } from '@material-ui/core'

import { fonts } from '~/theme/typography'

const useStripeFormStyles = makeStyles(
  (theme) =>
    createStyles({
      container: {
        [theme.breakpoints.up('sm')]: {
          maxWidth: 500,
          margin: '0 auto'
        }
      },

      form: {
        '& input, .StripeElement': {
          display: 'block',
          fontFamily: fonts.nunito,
          border: `1px solid ${theme.palette.text.disabled}`,
          outline: 0,
          background: theme.palette.common.white,
          fontSize: 16,
          fontWeight: 700,
          borderRadius: 10,
          minHeight: 42,
          padding: 15
        },

        '& input:focus, .StripeElement--focus': {
          boxShadow:
            'rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px',
          transition: 'all 150ms ease'
        }
      },

      errMsg: {
        textAlign: 'center',
        marginBottom: 15
      }
    }),
  {
    name: 'StripeForm'
  }
)

export default useStripeFormStyles
