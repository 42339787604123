import React, { FC, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import cn from 'classnames'

import ROUTES from '~/constants/routes'

import logo from '~/assets/images/logo.svg'
import whiteLogo from '~/assets/images/logo-white.svg'
import mobileLogo from '~/assets/images/logo-mobile.svg'

const useStyles = makeStyles(
  {
    logo: {
      display: 'inline-block',
      height: 54
    }
  },
  {
    name: 'Logo'
  }
)

type Props = {
  isWhite?: boolean
  isMobile?: boolean
  customClassName?: string
}

const Logo: FC<Props> = ({ customClassName, isWhite, isMobile }) => {
  const s = useStyles()

  const imgSrc = useMemo((): string => {
    if (isMobile) {
      return mobileLogo
    }

    if (isWhite) {
      return whiteLogo
    }

    return logo
  }, [isMobile, isWhite])

  return (
    <Link to={ROUTES.home} className={cn(s.logo, customClassName)}>
      <img src={imgSrc} alt="Logo" />
    </Link>
  )
}

export default Logo
