import { configureStore, combineReducers, Action } from '@reduxjs/toolkit'
import { ThunkAction } from 'redux-thunk'

import * as reducers from './modules'
import { logout } from './modules/user/user.slice'

const combinedReducer = combineReducers(reducers)

const rootReducer = (state: any, action: Action): any => {
  if (action.type === logout.type) {
    state = undefined
  }

  return combinedReducer(state, action)
}

const store = configureStore({
  reducer: rootReducer
})

export type AppState = ReturnType<typeof combinedReducer>
export type AppDispatch = typeof store.dispatch
export type AppThunk<R> = ThunkAction<R, AppState, unknown, Action<string>>

export default store
