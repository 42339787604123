import React, { useCallback, useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import {
  Box,
  Button,
  createStyles,
  Grid,
  InputAdornment,
  makeStyles,
  Theme,
  useMediaQuery
} from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import debounce from 'lodash.debounce'

import { TextField } from '~/components/fields'
import { Flexbox, Loader, Title } from '~/components/shared'
import ProgramActivitiesCard from '../ProgramActivitiesCard'

import ROUTES from '~/constants/routes'

import {
  activitiesMetaSelector,
  fetchActivities,
  getActivitiesSelector,
  getProgramSelector
} from '~/state/modules/programs'
import {
  changeActivitiesPage,
  deleteProgramActivities
} from '~/state/modules/programs/programs.slice'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import { DateRangeField } from '~/pages/search/components/filters'
import { ReactComponent as Search } from '~/assets/icons/search.svg'

import { humanizeDate } from '~/utils/functions'
import { AppState } from '~/state/store'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      nameWrapper: {
        paddingTop: '3.5px !important'
      },

      searchWrapper: {
        minWidth: 200,
        width: 200,

        '& .MuiOutlinedInput-input': {
          padding: '12.5px 14px'
        },

        [theme.breakpoints.down('xs')]: {
          marginBottom: 15,
          width: '100%'
        }
      },

      activitiesBtn: {
        height: '100%'
      },

      infoText: {
        padding: '20px 0',
        backgroundColor: '#F8FAFD'
      },

      chipRoot: {
        minWidth: 0,
        marginTop: 10
      }
    }),
  {
    name: 'SearchProgramActivities'
  }
)

interface DispatchProps {
  fetchData: (params: ActivitySearchDto) => void
}

interface ReduxProps {
  programsId: string
  programsLoaded: boolean
  initialValues: { page: number; search: string }
}

type AllProps = ReduxProps & DispatchProps & InjectedFormProps

const ProgramActivities: React.FC<AllProps> = ({ change }) => {
  const s = useStyles()
  const history = useHistory()
  const { id } = useParams<{ id: string }>()

  const dispatch = useDispatch()
  const { activities, loading, loaded } = useSelector(getActivitiesSelector)
  const { program } = useSelector(getProgramSelector)
  const { page, pages } = useSelector(activitiesMetaSelector)

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))

  useEffect(() => {
    dispatch(fetchActivities({ id, params: { page: page } }))

    return (): void => {
      dispatch(deleteProgramActivities())
    }
  }, [page])

  const handleChangePage = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ): void => {
    dispatch(changeActivitiesPage(value))
  }

  const handleDelete = useCallback((): void => {
    change('search', '')
    change('date', '[null,null]')
  }, [])

  const createActivityLink = `${ROUTES.campDirectorProgram}/${id}/create-activity`

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} className={s.nameWrapper}>
          <Flexbox
            width="100%"
            justifyContent="space-between"
            alignItems={!isMobile ? 'center' : 'flex-start'}
            flexDirection={isMobile && 'column'}
          >
            <Title
              variant="h4"
              textAlign="start"
              mt={isMobile && 2}
              mb={isMobile && 2}
            >
              {program.name}
            </Title>

            <Button
              className={s.activitiesBtn}
              variant="contained"
              color="primary"
              onClick={(): void => {
                history.push(createActivityLink)
              }}
              fullWidth={isMobile}
            >
              Create activity
            </Button>
          </Flexbox>
        </Grid>

        <Grid item xs={12} md={5}>
          <Field
            name="search"
            placeholder="Search by Sku"
            component={TextField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
          />
        </Grid>

        <Grid item xs={12} md={5}>
          <Field name="date" component={DateRangeField} />
        </Grid>

        <Grid item xs={12} md={2}>
          <Button
            size="small"
            className={s.chipRoot}
            color="primary"
            onClick={handleDelete}
          >
            Clear all
          </Button>
        </Grid>

        {loading || !loaded ? (
          <Loader />
        ) : (
          <>
            {activities.length > 0 ? (
              <>
                <Grid item xs={12}>
                  {activities.map((props, index) => (
                    <ProgramActivitiesCard key={index} page={page} {...props} />
                  ))}
                </Grid>

                <Box mt={1.5} width="100%">
                  <Pagination
                    count={pages}
                    page={page}
                    color="primary"
                    onChange={handleChangePage}
                  />
                </Box>
              </>
            ) : (
              <Grid item xs={12}>
                <Title variant="h6" textAlign="center" className={s.infoText}>
                  There are no activities yet!
                </Title>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </>
  )
}

const mapStateToProps = ({ programs }: AppState): ReduxProps => {
  return {
    initialValues: {
      page: 1,
      search: ''
    },
    programsId: programs.item.id,
    programsLoaded: programs.loaded
  }
}

const withConnect = connect(mapStateToProps, {
  fetchData: fetchActivities
})

const withForm = reduxForm<ActivitySearchDto, AllProps>({
  form: 'Search_Activities_Form',
  onChange: debounce(
    (
      values,
      dispatch,
      { fetchData, change, programsId, programsLoaded },
      { page, isInitialize }
    ) => {
      if (programsLoaded && !isInitialize) {
        let currentPage = values.page

        if (page === values.page) {
          currentPage = 1

          dispatch(change('page', 1))
        }

        const dateData = values.date ? JSON.parse(values.date) : [null, null]
        const searchDTO = {
          by_sku_search: values.search || undefined,
          by_start_date: dateData?.[0]
            ? humanizeDate(dateData?.[0], 'YYYY-MM-DD')
            : undefined,
          by_end_date: dateData?.[1]
            ? humanizeDate(dateData?.[1], 'YYYY-MM-DD')
            : undefined
        }

        fetchData({ id: programsId, params: searchDTO })
      }
    },
    500
  )
})

export default compose<React.FC>(withConnect, withForm)(ProgramActivities)
