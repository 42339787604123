import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { Text } from '~/components/shared'

import { useAccordionsStyles } from '../faq.styles'

const ItineraryCalendar: React.FC = () => {
  const s = useAccordionsStyles()

  return (
    <>
      <Accordion className={s.accordion}>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
          classes={{ expandIcon: s.expandIcon, expanded: s.expanded }}
          className={s.summeryAccordoin}
        >
          <Text>
            Q: What&apos;s the difference between in progress and approved
            itineraries?
          </Text>
        </AccordionSummary>

        <AccordionDetails className={s.detailsAccordion}>
          <Text mb={2}>
            Itineraries are displayed under <strong>in progress</strong> once
            your program specialist sends you one to review. The itinerary will
            remain under In Progress while you collaborate with friends, request
            alternate requests and after you accept the itinerary plan.
          </Text>

          <Text mb={2}>
            Itineraries are displayed under <strong>Approved</strong> after
            you’ve accepted your itinerary plan and after your specialist has
            released it to start the registration process.
          </Text>
        </AccordionDetails>
      </Accordion>

      <Accordion className={s.accordion}>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
          classes={{ expandIcon: s.expandIcon, expanded: s.expanded }}
          className={s.summeryAccordoin}
        >
          <Text>
            Q: Why don’t I see any itineraries listed under my itinerary page?
          </Text>
        </AccordionSummary>

        <AccordionDetails className={s.detailsAccordion}>
          <Text>
            Your itineraries are displayed on the <strong>Itineraries</strong>{' '}
            page under <strong>In Progress</strong> as soon as a program
            specialist finishes creating your itinerary and it is ready for your
            review. You will receive an email notification from your program
            specialist indicating your itinerary is ready for review.
          </Text>
        </AccordionDetails>
      </Accordion>

      <Accordion className={s.accordion}>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
          classes={{ expandIcon: s.expandIcon, expanded: s.expanded }}
          className={s.summeryAccordoin}
        >
          <Text>Q: What does it mean to accept my itinerary?</Text>
        </AccordionSummary>

        <AccordionDetails className={s.detailsAccordion}>
          <Text>
            When you <strong>Accept</strong> your itinerary plan you indicate to
            your program specialist that you are <b>a)</b> satisfied with the
            itinerary, <b>b)</b> have no additional change requests and{' '}
            <b>c)</b> are finished collaborating with your friends. Your program
            specialist will then provide one last review before you can use the
            itinerary plan to start registering the activities on your child’s
            itinerary.
          </Text>
        </AccordionDetails>
      </Accordion>

      <Accordion className={s.accordion}>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
          classes={{ expandIcon: s.expandIcon, expanded: s.expanded }}
          className={s.summeryAccordoin}
        >
          <Text>
            Q: How will I know when an itinerary is available for review?
          </Text>
        </AccordionSummary>

        <AccordionDetails className={s.detailsAccordion}>
          <Text mb={2}>
            When an itinerary is available for review, you will receive an email
            notification from your Program specialist. Additionally, your
            itinerary will appear under the in progress section of your
            itineraries page. To access in progress itineraries follow these
            steps:
          </Text>

          <List className={s.listItem}>
            <ListItem>
              <ListItemText>
                1. After signing in, navigate to your itinerary page by choosing{' '}
                <strong>Itineraries</strong> from the menu in the top right hand
                corner OR clicking <strong>Itineraries</strong> at the top of
                the page.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                2. Click the <strong>In Progress</strong> button.
              </ListItemText>
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion className={s.accordion}>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
          classes={{ expandIcon: s.expandIcon, expanded: s.expanded }}
          className={s.summeryAccordoin}
        >
          <Text>Q: How long will it take to receive an itinerary?</Text>
        </AccordionSummary>

        <AccordionDetails className={s.detailsAccordion}>
          <Text mb={2}>
            A program specialist will review your questionnaire and introduce
            themselves within 8 business hours after submitting your family
            questionnaire. The length of time to complete your initial itinerary
            will vary depending on your family’s needs and preferences, but we
            strive to provide your first itinerary within 2 business days or
            sooner.
          </Text>
        </AccordionDetails>
      </Accordion>

      <Accordion className={s.accordion}>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
          classes={{ expandIcon: s.expandIcon, expanded: s.expanded }}
          className={s.summeryAccordoin}
        >
          <Text>Q: How do I request changes to my itinerary?</Text>
        </AccordionSummary>

        <AccordionDetails className={s.detailsAccordion}>
          <Text mb={2}>
            You can leave comments or request change requests to an in progress
            itinerary by following these steps:
          </Text>

          <List className={s.listItem}>
            <ListItem>
              <ListItemText>
                1. After signing in, navigate to your itinerary page by choosing{' '}
                <strong>Itineraries</strong> from the menu in the top right hand
                corner OR clicking <strong>Itineraries</strong> at the top of
                the page.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                2. Find the itinerary you want to request a change for under{' '}
                <strong>In Progress.</strong>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                3. Scroll down to the bottom of the itinerary page.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                4. Click the <strong>Leave requests / comments</strong> button.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                5. Enter sufficient information about your request or comment
                for the program specialist.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                6. If you would like to leave additional requests or comments
                regarding an itinerary activity click{' '}
                <strong>+ Add a comment to another camp</strong> and repeat the
                previous step.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                7. Once you’ve completed your request or comments, click the{' '}
                <strong>Send feedback to specialist</strong> button.
              </ListItemText>
            </ListItem>
          </List>

          <Text mb={2}>
            After you leave a request or comment your itinerary will be worked
            on by your program specialist. During this time you won’t be able to{' '}
            <strong>Accept</strong> it or <strong>Invite friends</strong>. Once
            the program specialist makes any changes or replies, you will be
            notified that your itinerary is once again ready for your review at
            which point you can continue to <strong>Accept</strong> it or{' '}
            <strong>Invite friends.</strong>
          </Text>
        </AccordionDetails>
      </Accordion>

      <Accordion className={s.accordion}>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
          classes={{ expandIcon: s.expandIcon, expanded: s.expanded }}
          className={s.summeryAccordoin}
        >
          <Text>
            Q: Am I automatically registered for approved activities on my
            itinerary?
          </Text>
        </AccordionSummary>

        <AccordionDetails className={s.detailsAccordion}>
          <Text mb={2}>
            Instant registration is not available yet. With{' '}
            <strong>Booking Assistance</strong>, your program specialist will
            determine which activities can be registered on your behalf and
            which will require your attention.
          </Text>
        </AccordionDetails>
      </Accordion>

      <Accordion className={s.accordion}>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
          classes={{ expandIcon: s.expandIcon, expanded: s.expanded }}
          className={s.summeryAccordoin}
        >
          <Text>Q: How do I view my calendar?</Text>
        </AccordionSummary>

        <AccordionDetails className={s.detailsAccordion}>
          <Text mb={2}>
            To access your itinerary in a calendar view, follow these step:
          </Text>

          <List className={s.listItem}>
            <ListItem>
              <ListItemText>
                1. After signing in, navigate to your calendar page by choosing{' '}
                <strong>Calendar</strong> from the menu in the top right hand
                corner OR clicking <strong>Calendar</strong> at the top of the
                page.
              </ListItemText>
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion className={s.accordion}>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
          classes={{ expandIcon: s.expandIcon, expanded: s.expanded }}
          className={s.summeryAccordoin}
        >
          <Text>Q: Why don’t I see anything on my calendar page?</Text>
        </AccordionSummary>

        <AccordionDetails className={s.detailsAccordion}>
          <Text mb={2}>
            Activities associated with approved itineraries will show up on your
            calendar page. Once on the calendar page, follow these steps:
          </Text>

          <List className={s.listItem}>
            <ListItem>
              <ListItemText>
                1. On the left side of the calendar select at least one child’s
                name under <strong>Children.</strong>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                2. If your itinerary activities have start and end dates other
                than the current month, navigate to the corresponding month by
                clicking on the <strong>left arrow</strong> or{' '}
                <strong>right arrow</strong> buttons located in the top corner
                of the calendar.
              </ListItemText>
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default ItineraryCalendar
