import { object } from 'yup'

import { fullName, email, password } from './fields'

const SignupSchema = object().shape({
  name: fullName,
  email,
  password,
  password_confirmation: password
    .passwordConfirmation('Passwords are not the same!')
    .required('Password confirmation is required!')
})

export default SignupSchema
