import { FormHelperText } from '@material-ui/core'
import React, { FC } from 'react'
import { WrappedFieldProps } from 'redux-form'

type ErrorFieldProps = {
  withoutMargins?: boolean
}

const ErrorField: FC<ErrorFieldProps & WrappedFieldProps> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  input,
  meta: { error, touched },
  withoutMargins,
  ...rest
}) => {
  const displayError = touched && error

  return displayError ? (
    <FormHelperText
      variant={withoutMargins ? 'standard' : 'filled'}
      error
      {...rest}
    >
      {error}
    </FormHelperText>
  ) : null
}

export default ErrorField
