import React, { useState, useEffect } from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { toastr } from 'react-redux-toastr'

import Confirmation from './Confirmation'

import {
  signupConfirmEmail,
  signupConfirmDirectorEmail
} from '~/state/modules/user/user.actions'

import { STORAGE } from '~/constants/storage'
import ROUTES from '~/constants/routes'
import { HELPER_ROLES } from '~/constants'

type RouterMatch = {
  token: string
}

const ConfirmationEmail: React.FC<RouteComponentProps<RouterMatch>> = ({
  match: { params },
  location: { search }
}) => {
  const dispatch = useDispatch()
  const [redirect, setRedirect] = useState<string>('')
  const query = new URLSearchParams(search).get(STORAGE.entity)

  useEffect(() => {
    async function confirmUser(): Promise<void> {
      try {
        let redirect = null
        if (query === HELPER_ROLES.director) {
          await dispatch<any>(signupConfirmDirectorEmail(params.token))

          redirect = ROUTES.home
        }

        if (query === HELPER_ROLES.parent) {
          const { invited, premium } = await dispatch<any>(
            signupConfirmEmail(params.token)
          )

          redirect = invited
            ? ROUTES.itinerariesFriends
            : premium
            ? ROUTES.profile
            : localStorage.getItem(STORAGE.redirect_from) || ROUTES.profile
        }

        toastr.success('Email', 'Your have confirmed your email address.')

        localStorage.removeItem(STORAGE.redirect_from)

        setRedirect(redirect)
      } catch (error) {
        toastr.warning('Email', error._message)

        setRedirect(ROUTES.home)
      } finally {
        localStorage.removeItem(STORAGE.userEmail)
      }
    }

    confirmUser()
  }, [dispatch, params.token, query])

  if (!redirect) return <Confirmation />

  return <Redirect to={redirect} />
}

export default ConfirmationEmail
