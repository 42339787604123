import React, { FC } from 'react'

import { Box } from '@material-ui/core'
import useScrollChat from '~/hooks/useScrollChat'

import useStyles from './MessageList.styles'
import Message from '../Message'
import { SENDER_ROLE } from '~/constants/statusesItinerary'

import AssistantIcon from '~/assets/images/assistant.png'

interface MessageListProps {
  messages: ChatbotMessageModel[]
  ownerName?: string
  currentName?: string
}

const MessageList: FC<MessageListProps> = ({
  messages,
  ownerName,
  currentName
}) => {
  const s = useStyles()

  const { scrollRef } = useScrollChat([messages.length])

  return (
    <Box className={s.wrapper}>
      <div ref={scrollRef} className={s.scroll}>
        {messages.map(({ text, sender_role }, index) => {
          const isOwner = sender_role === SENDER_ROLE.user
          const name = isOwner ? ownerName : currentName
          const icon = isOwner ? '' : AssistantIcon

          return (
            <Message
              key={index}
              isOwner={isOwner}
              message={text}
              name={name}
              icon={icon}
            />
          )
        })}
      </div>
    </Box>
  )
}

export default MessageList
