import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Dialog, DialogContent } from '@material-ui/core'

import { Flexbox, Text } from '../shared'
import DialogTitle from './DialogTitle'

import ROUTES from '~/constants/routes'

type Props = {
  in?: boolean
  onClose: () => void
}

const PremiumDialog: React.FC<Props> = ({ in: open, onClose, ...rest }) => {
  return (
    <Dialog {...rest} fullWidth open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>Get more personal assistance!</DialogTitle>

      <DialogContent>
        <Text mb={3} variant="h5" textAlign="center">
          Update your package and start delegating!
        </Text>

        <Flexbox justifyContent="center">
          <Button
            component={Link}
            to={ROUTES.packages}
            size="large"
            variant="contained"
            color="primary"
          >
            Show more
          </Button>
        </Flexbox>
      </DialogContent>
    </Dialog>
  )
}

export default PremiumDialog
