import React, { FC, useEffect } from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'
import { initialize } from 'redux-form'
import { useModal } from 'react-modal-hook'
import { Button, Container, Paper } from '@material-ui/core'
import { Notes } from '@material-ui/icons'

import FullWidthMobile from '~/containers/FullWidthMobile'
import { PersonInfoCard } from '~/components/cards'
import { Title, Link, Text, Loader, Flexbox } from '~/components/shared'
import { LeaveNoteDialog } from '~/components/dialogs'

import { getActivityById } from '~/state/modules/activities'
import ActivitiesService from '~/services/ActivitiesService'

import ROUTES from '~/constants/routes'
import { AppState } from '~/state/store'

import { ReactComponent as BackIcon } from '~/assets/icons/back-icon.svg'

import useItineraryStyles from '../itinerary/itinerary.styles'

type ReduxProps = {
  data: Partial<Activity>
  loading: boolean
  loaded: boolean
  error: null | boolean
}

type dispatchProps = {
  getActivityById: (id: string) => void
  initialize: (form: string, data: any) => void
}

type AllProps = ReduxProps &
  dispatchProps &
  RouteComponentProps<{
    id: string
  }>

const Activity: FC<AllProps> = ({
  match: { params },
  history,

  loading,
  loaded,
  data,
  error,

  getActivityById,
  initialize
}) => {
  const s = useItineraryStyles()

  const [showModal, hideModal] = useModal(
    (props: any) => (
      <LeaveNoteDialog
        {...props}
        onClose={hideModal}
        id={data?.id}
        name={data?.name}
      />
    ),
    [data]
  )

  useEffect(() => {
    if (params?.id) {
      getActivityById(params?.id)
    }
  }, [])

  if (error) {
    return <Redirect to={ROUTES.notFound} />
  }

  return (
    <FullWidthMobile>
      <Container maxWidth="lg" disableGutters>
        <Paper className={s.wrapper}>
          <Link
            to="#"
            underline="hover"
            className={s.backButton}
            onClick={(e: React.MouseEvent<HTMLElement>): void => {
              e.preventDefault()

              // @ts-ignore
              history.goBack()
            }}
          >
            <BackIcon />
            Back
          </Link>

          {loading ? (
            <Loader />
          ) : loaded ? (
            <>
              <Flexbox className={s.titleWrapper}>
                <Title variant="h4" textAlign="start">
                  {data?.name}
                </Title>

                <Button
                  startIcon={<Notes />}
                  color="primary"
                  variant="outlined"
                  onClick={(): void => {
                    initialize('LeaveNote_Form', {
                      specialist_notes: data?.specialist_notes || ''
                    })

                    showModal()
                  }}
                >
                  Note{!!data?.specialist_notes && '*'}
                </Button>
              </Flexbox>

              <PersonInfoCard
                data={ActivitiesService.generateActivityData(data)}
              />
            </>
          ) : (
            <Text px={3}>No results</Text>
          )}
        </Paper>
      </Container>
    </FullWidthMobile>
  )
}

const mapStateToProps = ({
  activities: { loading, loaded, item, error }
}: AppState): ReduxProps => {
  return {
    loading,
    loaded,
    data: item,
    error
  }
}

export default connect(mapStateToProps, {
  getActivityById,
  initialize
})(Activity)
