import React, { FC } from 'react'
import { Field } from 'redux-form'
import {
  Box,
  createStyles,
  Grid,
  InputAdornment,
  makeStyles
} from '@material-ui/core'

import { TextField } from '~/components/fields'
import Mobile from '~/containers/Mobile'
import Filters from './filters/Filters'
import { DateRangeField, DistanceFilter } from './filters'

import { ReactComponent as Search } from '~/assets/icons/search.svg'

const useStyles = makeStyles(
  ({ spacing, breakpoints }) =>
    createStyles({
      container: {
        '& > *': {
          flex: 1,
          minWidth: 160,

          [breakpoints.down('sm')]: {
            flex: '0 1 100%',
            maxWidth: '100%'
          }
        },

        [breakpoints.up('md')]: {
          marginBottom: spacing(2)
        }
      },

      search: {
        [breakpoints.up('md')]: {
          paddingRight: '0 !important'
        }
      },

      date: {
        minWidth: 270,
        [breakpoints.up('md')]: {
          paddingRight: '0 !important'
        }
      }
    }),
  {
    name: 'SearchFilters'
  }
)

type Props = {
  minPrice: number
  maxPrice: number
  campTypes: string[]
  distanceCenter: Coords | null
}

const SearchFilters: FC<Props> = ({ campTypes, distanceCenter, ...props }) => {
  const s = useStyles()

  return (
    <Box>
      <Grid container spacing={2} className={s.container}>
        <Grid item xs={12} md={3} className={s.search}>
          <Field
            name="search"
            placeholder="Search"
            component={TextField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
          />
        </Grid>

        <Grid item xs={12} md={4} className={s.date}>
          <Field name="date" component={DateRangeField} />
        </Grid>

        <Mobile>
          <Grid item xs={12} md={4}>
            <Filters campTypes={campTypes} {...props} />
          </Grid>
        </Mobile>

        {distanceCenter && (
          <Grid item xs={12} md={3}>
            <DistanceFilter center={distanceCenter} {...props} />
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default SearchFilters
