import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { hydrate, render } from 'react-dom'
import ReduxToastr from 'react-redux-toastr'
import { ModalProvider } from 'react-modal-hook'
import { TransitionGroup } from 'react-transition-group'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider, Theme } from '@material-ui/core/styles'
import { LocalizationProvider } from '@material-ui/pickers'
import MomentUtils from '@material-ui/pickers/adapter/moment'
import { isIOS, isSafari } from 'react-device-detect'
import PWAPrompt from 'react-ios-pwa-prompt'
import { CookiesProvider } from 'react-cookie'
import TagManager from 'react-gtm-module'

import App from './App'
import ServiceWorkerWrapper from './components/layouts/ServiceWorkerWrapper'

import store from './state/store'
import theme from './theme'

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import 'react-phone-input-2/lib/material.css'
import '~/styles/style.css'

/**
 * Initialize GTM
 */

if (process.env.NODE_ENV !== 'development') {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID
  }

  TagManager.initialize(tagManagerArgs)
}

if (process.env.REACT_APP_CI_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_KEY,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0
  })
}

const Index: React.FC = () => (
  <React.StrictMode>
    <Provider store={store}>
      <CookiesProvider>
        <Router>
          <LocalizationProvider dateAdapter={MomentUtils}>
            <ThemeProvider<Theme> theme={theme}>
              <ModalProvider rootComponent={TransitionGroup}>
                <App />

                <ReduxToastr
                  timeOut={4000}
                  preventDuplicates
                  transitionIn="fadeIn"
                  transitionOut="fadeOut"
                  progressBar
                />
              </ModalProvider>

              <CssBaseline />

              <ServiceWorkerWrapper />

              {isIOS && isSafari && (
                <PWAPrompt permanentlyHideOnDismiss={false} />
              )}
            </ThemeProvider>
          </LocalizationProvider>
        </Router>
      </CookiesProvider>
    </Provider>
  </React.StrictMode>
)

const rootElement = document.getElementById('root')

if (rootElement.hasChildNodes()) {
  hydrate(<Index />, rootElement)
} else {
  render(<Index />, rootElement)
}
