import React, { FC, useCallback, useState } from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { useDispatch } from 'react-redux'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { makeStyles, createStyles, lighten } from '@material-ui/core'

import { ExtendedButton } from '../shared'

import { signinWithSocial } from '~/state/modules/user/user.actions'

import ROUTES from '~/constants/routes'
import { STORAGE } from '~/constants/storage'

import { ReactComponent as FacebookSVG } from '~/assets/icons/facebook.svg'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      btn: {
        backgroundColor: theme.palette.info.dark,

        '& svg path': {
          fill: theme.palette.common.white
        },

        '&:hover': {
          backgroundColor: lighten(theme.palette.info.dark, 0.1)
        }
      }
    }),
  {
    name: 'FacebookButton'
  }
)

const FacebookButton: FC<RouteComponentProps> = ({ children, history }) => {
  const s = useStyles()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(false)

  const handleSuccess = useCallback(
    async (rest: any) => {
      try {
        setLoading(true)

        const { invited, premium } = await dispatch<any>(
          signinWithSocial({
            access_token: rest?.accessToken,
            provider_name: 'facebook'
          })
        )

        const redirect = invited
          ? ROUTES.itinerariesFriends
          : premium
          ? ROUTES.home
          : localStorage.getItem(STORAGE.redirect_from) || ROUTES.home

        localStorage.removeItem(STORAGE.redirect_from)

        history.push(redirect)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    },
    [dispatch, history]
  )

  return (
    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_APP_ID}
      callback={handleSuccess}
      fields="name,email"
      disableMobileRedirect
      render={(renderProps): JSX.Element => (
        <ExtendedButton
          onClick={renderProps.onClick}
          disabled={renderProps.isDisabled || !renderProps.isSdkLoaded}
          loading={renderProps.isProcessing || loading}
          startIcon={<FacebookSVG />}
          size="large"
          variant="contained"
          color="secondary"
          fullWidth
          className={s.btn}
        >
          {children}
        </ExtendedButton>
      )}
    />
  )
}

export default withRouter(FacebookButton)
