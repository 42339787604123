import React, { FC } from 'react'
import { toastr } from 'react-redux-toastr'
import { InjectedFormProps, Form, reduxForm, SubmissionError } from 'redux-form'
import { Grid, Card, CardContent } from '@material-ui/core'

import FullWidthMobile from '~/containers/FullWidthMobile'
import { PasswordFragment } from '~/components/forms'
import { Flexbox, ExtendedButton } from '~/components/shared'

import { updateUser } from '~/state/modules/user'
import { asyncValidate } from '~/utils/asyncValidate'
import ResetPasswordSchema from '~/schemas/forgot-password/reset-password'

import useProfileStyles from '../../profile.styles'

const ChangePasswordForm: FC<InjectedFormProps<ChangePasswordInput>> = ({
  handleSubmit,
  dirty,
  submitting
}) => {
  const s = useProfileStyles()

  return (
    <FullWidthMobile>
      <Card variant="outlined" className={s.formCard}>
        <CardContent>
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PasswordFragment confirm isRow />
              </Grid>

              <Grid item xs={12}>
                <Flexbox justifyContent="flex-end">
                  <ExtendedButton
                    data-cy="change-password-button"
                    type="submit"
                    loading={submitting}
                    disabled={!dirty}
                  >
                    Save changes
                  </ExtendedButton>
                </Flexbox>
              </Grid>
            </Grid>
          </Form>
        </CardContent>
      </Card>
    </FullWidthMobile>
  )
}

const withForm = reduxForm<ChangePasswordInput>({
  form: 'Change_Password_Form',
  enableReinitialize: true,
  shouldAsyncValidate: () => true,
  asyncValidate: asyncValidate(ResetPasswordSchema),
  onSubmit: async (values, dispatch) => {
    try {
      await dispatch<any>(updateUser(values))
    } catch (error) {
      throw new SubmissionError(error.errors)
    }
  },
  onSubmitSuccess: (_values, _dispatch, { reset }) => {
    toastr.success('Success', 'Password was changed successfully!')
    reset()
  }
})

export default withForm(ChangePasswordForm)
