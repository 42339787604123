export const STATUSES: Record<StatusesUnion, StatusesUnion> = {
  draft: 'draft',
  in_progress: 'in_progress',
  review: 'review',
  alternate_request: 'alternate_request',
  accepted: 'accepted',
  approved: 'approved',
  canceled: 'canceled'
} as const

export const STATUSES_SHARED: Record<
  StatusesSharedUnion,
  StatusesSharedUnion
> = {
  draft: 'draft',
  accepted: 'accepted',
  declined: 'declined'
} as const

export enum StatusesEnum {
  draft = 'draft',
  in_progress = 'in_progress',
  review = 'review',
  alternate_request = 'alternate_request',
  accepted = 'accepted',
  approved = 'approved',
  canceled = 'canceled'
}

export enum SENDER_ROLE {
  user = 'user',
  assistant = 'assistant'
}
