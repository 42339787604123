import React, { FC } from 'react'
import ReactCarousel from 'react-slick'
import cn from 'classnames'

import StepCard from '../StepCard'

import { HowItWorksProps } from './HowItWorksBlock'

import { ReactComponent as ArrowLeft } from '~/assets/images/home/arrow-left-carousel.svg'
import { ReactComponent as ArrowRight } from '~/assets/images/home/arrow-right-carousel.svg'

import { useMobileStyles } from './HowItWorksBlock.styles'

const SampleNextArrow = (props): JSX.Element => {
  const s = useMobileStyles()
  const { className, style, onClick } = props

  return (
    <div className={cn(className, s.button)} style={style} onClick={onClick}>
      <ArrowRight height={19} width={11} />
    </div>
  )
}

const SamplePrevArrow = (props): JSX.Element => {
  const s = useMobileStyles()
  const { className, style, onClick } = props

  return (
    <div className={cn(className, s.button)} style={style} onClick={onClick}>
      <ArrowLeft height={19} width={11} />
    </div>
  )
}

const carouselSettings = {
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: '0',
  speed: 1000,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />
}

const HowItWorksMobile: FC<HowItWorksProps> = ({ data }) => {
  const s = useMobileStyles()

  return (
    <ReactCarousel {...carouselSettings}>
      {data.map((step) => (
        <div key={step.value} className={s.imageWrapper}>
          <StepCard {...step} />
        </div>
      ))}
    </ReactCarousel>
  )
}

export default HowItWorksMobile
