import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import isEmpty from 'lodash.isempty'

import { sortByDate } from '~/utils/functions'
import {
  requestAction,
  successAction,
  failureAction,
  successItemAction
} from '~/utils/reducerFunctions'

import { STATUSES, StatusesEnum } from '~/constants/statusesItinerary'

export interface ItinerariesData {
  [StatusesEnum.in_progress]: Itinerary[]
  [StatusesEnum.approved]: Itinerary[]
}

export interface FriendsItinerariesState {
  item: Partial<Itinerary>
  data: ItinerariesData
  loading: boolean
  loaded: boolean
  activeTab: StatusesUnion
  error: any
}

const initialState: FriendsItinerariesState = {
  data: Object.values(STATUSES).reduce(
    (acc, key) => ({
      ...acc,
      [key]: []
    }),
    {}
  ) as ItinerariesData,
  item: {},
  activeTab: STATUSES.in_progress,
  loading: false,
  loaded: false,
  error: null
}

const inProgressStatuses = [
  STATUSES.draft,
  STATUSES.in_progress,
  STATUSES.review,
  STATUSES.alternate_request,
  STATUSES.accepted
]

const friendsItinerariesSlice = createSlice({
  name: 'friends-itineraries',
  initialState,
  reducers: {
    request: requestAction,

    success: (state, action: PayloadAction<Itinerary[]>): void => {
      successAction(state, action, 'data', (state) => {
        const { payload } = action

        const data = {
          [STATUSES.in_progress]: payload?.filter(({ status }) =>
            inProgressStatuses.some((parentStatus) => parentStatus === status)
          ),
          [STATUSES.approved]: payload?.filter(
            ({ status }) => status === STATUSES.approved
          )
        }

        Object.entries(data).forEach(([key, value]) => {
          data[key] = sortByDate(value, 'created_at', true)
        })

        if (isEmpty(state.item)) {
          state.item = data[STATUSES.in_progress]?.[0]
        }

        state.data = data
        state.activeTab = STATUSES.in_progress
      })
    },

    changeActiveTab: (
      state,
      { payload }: PayloadAction<StatusesUnion>
    ): void => {
      state.activeTab = payload
      state.item = state.data[payload]?.[0]
    },

    changeActiveItem: (state, { payload }: PayloadAction<string>): void => {
      const item = state.data[state.activeTab].find(({ id }) => id === payload)

      state.item = item
    },

    rejectItinerary: (
      state,
      { payload }: PayloadAction<string>
    ): FriendsItinerariesState => {
      const itineraries = state.data[STATUSES.in_progress].filter(
        ({ id }) => id !== payload
      )

      const itineraryItem = itineraries?.[0] || {}

      return {
        ...state,
        data: {
          ...state.data,
          [STATUSES.in_progress]: itineraries
        },
        item: itineraryItem
      }
    },

    successItem: successItemAction,

    failure: failureAction
  }
})

export const {
  request,
  success,
  changeActiveTab,
  changeActiveItem,
  rejectItinerary,
  successItem,
  failure
} = friendsItinerariesSlice.actions

export default friendsItinerariesSlice.reducer
