import React, { FC, useMemo } from 'react'

import { Title } from '~/components/shared'
import { PersonInfoCard } from '~/components/cards'
import { InfoAccordion } from '~/components/accordions'

import { STATUSES } from '~/constants/statusesItinerary'

type Props = {
  title: string
  data: PersonInfoOption[]
  status: StatusesUnion
  forParentPayment?: boolean
}

const showAccordionArray = [
  STATUSES.review,
  STATUSES.alternate_request,
  STATUSES.accepted,
  STATUSES.approved,
  STATUSES.canceled
]

const InfoDataBlock: FC<Props> = ({
  title = '',
  data = [],
  status,
  forParentPayment
}) => {
  const isAccordion = useMemo(
    () =>
      showAccordionArray.some((itineraryStatus) => itineraryStatus === status),
    [status]
  )

  return isAccordion && !forParentPayment ? (
    <InfoAccordion title={title} data={data} />
  ) : (
    <>
      <Title variant="h4" p={'20px'} textAlign="start">
        {title}
      </Title>

      <PersonInfoCard data={data} />
    </>
  )
}

export default InfoDataBlock
