import React from 'react'
import {
  DialogTitle as MuiDialogTitle,
  makeStyles,
  IconButton,
  createStyles
} from '@material-ui/core'
import { Close } from '@material-ui/icons'

import { TextComponentProps } from '../shared/Text'
import { Title } from '../shared'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      btnClose: {
        position: 'absolute',
        padding: 0,
        top: 20,
        right: 20,
        width: 18,
        minWidth: 18,
        height: 18,
        minHeight: 18,
        color: theme.palette.text.secondary,

        '& svg': {
          fontSize: 28
        },

        '& > span': {
          height: 20
        }
      }
    }),
  {
    name: 'DialogTitle'
  }
)

type Props = {
  onClose: () => void
  component?: TextComponentProps['component']
}

const DialogTitle: React.FC<Props> = ({
  onClose,
  children,
  component,
  ...rest
}) => {
  const s = useStyles()

  return (
    <MuiDialogTitle disableTypography {...rest}>
      <Title variant="h3" component={component}>
        {children}
      </Title>

      <IconButton className={s.btnClose} onClick={onClose}>
        <Close />
      </IconButton>
    </MuiDialogTitle>
  )
}

export default DialogTitle
