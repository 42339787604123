import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { Text } from '~/components/shared'

import { useAccordionsStyles } from '../faq.styles'

const PlanFriends: React.FC = () => {
  const s = useAccordionsStyles()

  return (
    <>
      <Accordion className={s.accordion}>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
          classes={{ expandIcon: s.expandIcon, expanded: s.expanded }}
          className={s.summeryAccordoin}
        >
          <Text>Q: How many friends can I invite?</Text>
        </AccordionSummary>

        <AccordionDetails className={s.detailsAccordion}>
          <Text mb={2}>
            You can invite as many friends to collaborate on an itinerary as you
            like.
          </Text>
        </AccordionDetails>
      </Accordion>

      <Accordion className={s.accordion}>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
          classes={{ expandIcon: s.expandIcon, expanded: s.expanded }}
          className={s.summeryAccordoin}
        >
          <Text>Q: How do I know the results of a friend invitation?</Text>
        </AccordionSummary>

        <AccordionDetails className={s.detailsAccordion}>
          <Text>
            When a friend accepts or declines your invitation you will receive
            an email notification indicating their action along with any
            supporting comments. Additionally their name and response will
            appear at the bottom of your itinerary page under the area titled{' '}
            <strong>Friends Invitation.</strong>
          </Text>
        </AccordionDetails>
      </Accordion>

      <Accordion className={s.accordion}>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
          classes={{ expandIcon: s.expandIcon, expanded: s.expanded }}
          className={s.summeryAccordoin}
        >
          <Text>
            Q: Where can I find activities that were shared with me from
            friends?
          </Text>
        </AccordionSummary>

        <AccordionDetails className={s.detailsAccordion}>
          <Text>
            When a friend invites you to collaborate on an activity together,
            the itinerary associated with that activity will show up under Share
            with me. To access shared itineraries follow these steps:
          </Text>

          <List className={s.listItem}>
            <ListItem>
              <ListItemText>
                1. Navigate to your share with me page by choosing{' '}
                <strong>Share with me</strong> from the menu in the top right
                hand corner OR clicking the <strong>Share with me</strong> tab
                at the top of the page.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                2. Depending on the state of your friends itinerary it will
                appear under either the <strong>In Progress</strong> or{' '}
                <strong>Approved</strong> area.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                3. If you have more than one friend invitation, locate{' '}
                <strong>Choose a child</strong> at the top of the page and
                choose the name of your friend’s child whose activity you are
                looking for.
              </ListItemText>
            </ListItem>
          </List>

          <Text>
            You can also find activities on approved itineraries shared with you
            from your calendar page. To find activities shared with you on your
            calendar page follow these steps:
          </Text>

          <List className={s.listItem}>
            <ListItem>
              <ListItemText>
                1. Navigate to your calendar page by choosing{' '}
                <strong>Calendar</strong> from the menu in the top right hand
                corner OR clicking the <strong>Calendar</strong> tab at the top
                of the page.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                2. On the left side of your calendar locate the{' '}
                <strong>Friends</strong> area.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                3. If you’ve been invited to join activities by more than one
                friend, choose the name of the child associated with your friend
                you’re interested in by choosing the name of the child
                associated with your friend.
              </ListItemText>
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion className={s.accordion}>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
          classes={{ expandIcon: s.expandIcon, expanded: s.expanded }}
          className={s.summeryAccordoin}
        >
          <Text>
            Q: How do I accept or decline my friend’s activity invitation?
          </Text>
        </AccordionSummary>

        <AccordionDetails className={s.detailsAccordion}>
          <Text mb={2}>
            When a friend invites you to join them and their child for an
            activity you can accept or decline the invitation after reviewing
            it. To accept or decline your friend’s invitation for an activity
            follow these steps:
          </Text>

          <List className={s.listItem}>
            <ListItem>
              <ListItemText>
                1. Navigate to the share with me page by choosing{' '}
                <strong>Share with me</strong> from the menu in the top right
                hand corner OR clicking the <strong>Share with me</strong> tab
                at the top of the page.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                2. After reviewing the activity, scroll down to the bottom of
                the itinerary.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                3. To accept an invitation to join your friend at this activity
                click the <strong>Accept</strong> button. OR
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                4. To decline an invitation to join your friend at this activity
                click the <strong>Decline</strong> button.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                5. After clicking the button you’ll be prompted to leave a
                comment.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                6. Leave a <strong>Comment</strong> in the text area.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                7. Click the <strong>Accept</strong> or <strong>Decline</strong>{' '}
                button to send your response.
              </ListItemText>
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default PlanFriends
