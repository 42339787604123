import { DecoratedComponentClass } from 'redux-form'

import ROUTES from './constants/routes'

// pages
import Index from '~/pages/home'
import AboutUs from '~/pages/about-us'
import ContactUs from '~/pages/contact-us'
import Terms from '~/pages/terms'
import BusinessTerms from '~/pages/business-terms'
import Privacy from '~/pages/privacy-policy'
import Faq from '~/pages/faq'

import SignIn from '~/pages/sign-in'
import SignUp from '~/pages/sign-up'
import Verify from '~/pages/verify'
import ForgotPassword from '~/pages/forgot-password'
import ResetPassword from '~/pages/reset-password'

import Questionnaire from '~/pages/questionnaire'
import Congratulations from '~/pages/congratulations'
import Confirm from '~/pages/confirm'
import Checkout from '~/pages/checkout'
import Packages from '~/pages/packages'
import Profile from '~/pages/profile'

import SignInSpecialist from '~/pages/sign-in-specialist'
import SignInDirector from '~/pages/camp-director/sign-in'
import SignUpDirector from '~/pages/camp-director/sign-up'
import ProviderProfile from '~/pages/camp-director/provider-profile'
import Dashboard from '~/pages/dashboard'
import Itinerary from '~/pages/itinerary'
import Activity from '~/pages/activity'
import ActivityProvider from '~/pages/activity-provider'
import Search from '~/pages/search'
import CampDirectorRoutes from '~/pages/camp-director'

import NotFound from '~/pages/404'

import {
  ConfirmationEmail,
  ConfirmationPassword,
  ConfirmationParent
} from './components/confirmation'

import {
  Unauthorized,
  Parent,
  Specialist,
  CampDirector
} from './containers/Authorization'
import { ROLES } from './constants/roles'

import { LayoutProps } from './components/layouts/AppLayout'

import palette from './theme/palette'

type Route = {
  path: string
  component:
    | React.FunctionComponent<any>
    | React.ComponentClass<any, any>
    | DecoratedComponentClass<any, any>
  exact?: boolean
  layoutProps?: LayoutProps
}

export const routes: Route[] = [
  {
    path: ROUTES.home,
    exact: true,
    component: Index,
    layoutProps: {
      isGradientHeader: true,
      background: palette.common.white
    }
  },
  {
    path: ROUTES.signIn,
    component: Unauthorized(SignIn)
  },
  {
    path: ROUTES.signUp,
    exact: true,
    component: Unauthorized(SignUp)
  },
  {
    path: ROUTES.signUpVerify,
    component: Unauthorized(Verify, (user) => !!user.confirmed)
  },
  {
    path: ROUTES.confirmationEmail,
    component: Unauthorized(ConfirmationEmail)
  },
  {
    path: ROUTES.confirmationParent,
    component: Unauthorized(ConfirmationParent)
  },
  {
    path: ROUTES.forgotPassword,
    exact: true,
    component: Unauthorized(ForgotPassword)
  },
  {
    path: ROUTES.forgotPasswordVerify,
    component: Unauthorized(Verify)
  },
  {
    path: ROUTES.resetPassword,
    exact: true,
    component: Unauthorized(ResetPassword)
  },
  {
    path: ROUTES.confirmationPassword,
    component: Unauthorized(ConfirmationPassword)
  },
  {
    path: ROUTES.profile,
    component: Parent(Profile)
  },
  {
    path: `${ROUTES.checkout}/:plan`,
    component: Parent(Checkout, undefined, true)
  },
  {
    path: ROUTES.packages,
    component: Parent(Packages),
    layoutProps: {
      background: palette.common.white
    }
  },
  {
    path: ROUTES.questionnaire,
    component: Unauthorized(
      Questionnaire,
      (user) => user.role === ROLES.Specialist
    )
  },
  {
    path: ROUTES.congratulations,
    exact: true,
    component: Unauthorized(
      Congratulations,
      (user) => user.role === ROLES.Specialist
    ),
    layoutProps: {
      background: palette.common.white
    }
  },
  {
    path: ROUTES.confirm,
    component: Unauthorized(Confirm)
  },
  {
    path: ROUTES.signInSpecialist,
    component: Unauthorized(SignInSpecialist)
  },
  {
    path: ROUTES.campDirectorSignIn,
    component: Unauthorized(SignInDirector)
  },
  {
    path: ROUTES.campDirectorSignUp,
    component: Unauthorized(SignUpDirector)
  },
  {
    path: ROUTES.campDirectorResetPassword,
    component: Unauthorized(ResetPassword)
  },
  {
    path: ROUTES.campDirectorForgotPassword,
    component: Unauthorized(ForgotPassword)
  },
  {
    path: ROUTES.campDirectorProvider,
    component: CampDirector(ProviderProfile)
  },
  {
    path: ROUTES.dashboard,
    component: Specialist(Dashboard),
    layoutProps: {
      background: palette.background.default
    }
  },
  {
    path: `${ROUTES.itinerary}/:id`,
    component: Specialist(Itinerary),
    layoutProps: {
      background: palette.background.default
    }
  },
  {
    path: `${ROUTES.activity}/:id`,
    component: Specialist(Activity),
    layoutProps: {
      background: palette.background.default
    }
  },
  {
    path: `${ROUTES.activityProvider}/:id`,
    component: Specialist(ActivityProvider),
    layoutProps: {
      background: palette.background.default
    }
  },
  {
    path: ROUTES.search,
    component: Specialist(Search),
    layoutProps: {
      background: palette.common.white,
      isFullHeight: true
    }
  },
  {
    path: ROUTES.campDirector,
    component: CampDirector(CampDirectorRoutes)
  },
  {
    path: ROUTES.aboutUs,
    component: AboutUs
  },
  {
    path: ROUTES.contactUs,
    component: ContactUs
  },
  {
    path: ROUTES.terms,
    component: Terms
  },
  {
    path: ROUTES.businessTerms,
    component: BusinessTerms
  },
  {
    path: ROUTES.privacy,
    component: Privacy
  },
  {
    path: ROUTES.faqs,
    component: Faq
  },
  {
    path: '*',
    component: NotFound
  }
]
