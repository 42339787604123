/* eslint-disable react/no-unescaped-entities */
import React, { FC } from 'react'
import { Grid, Paper, Box, Link as MuiLink } from '@material-ui/core'
import cn from 'classnames'

import FullWidthMobile from '~/containers/FullWidthMobile'
import { Text, Title, BlankLink } from '~/components/shared'

import painting1 from '~/assets/images/home/title-painting-1.png'

import useStaticStyles from '~/styles/static-pages.styles'

const BusinessTerms: FC = () => {
  const s = useStaticStyles()

  return (
    <FullWidthMobile>
      <Box className={cn(s.container, 'withWidth')}>
        <Paper className={s.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Title
                variant="h1"
                paintingOptions={{
                  image: painting1,
                  customStyles: {
                    bottom: 3,
                    height: 6,
                    width: '100%'
                  }
                }}
                mb={2}
              >
                Business Terms of Service
              </Title>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary" fontStyle="italic">
                Last Updated: January 13, 2022
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary" pb={3}>
                These Business Terms of Service (“Business Terms”) create a
                legally binding agreement made between PlanMyKids, Inc., located
                in Raleigh, North Carolina ("PlanMyKids," "we", "us" or “our''),
                and your organization (“you”, “your”), concerning your access to
                and use of the online services, website, and software provided
                in connection with our services (collectively the "Service" or
                “Services”). By clicking “I agree”, you are accepting these
                Business Terms of Service (“Business Terms”). These Business
                Terms apply to all providers and their employees who access the
                Service to provide programs (“Program Providers”), post the
                activities that their Program Providers offer (“Program
                Activities”), upload content about their Program Activities
                (“Provider Content”), enroll kids, and receive payments as
                described below. We have tried to make the Business Terms simple
                and fair, but if you do not agree with them, please feel free to
                make a request which we will review for future adoption to our
                Terms. Until such time, we kindly ask you to accept our terms or
                not use our service.
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Title textAlign="start" variant="h4">
                Registration
              </Title>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary" pb={3}>
                In order to use the Service to post Program Activities, upload
                Provider Content, enroll kids, and receive payments, you must
                register as a Program Provider. You agree that all information
                that you provide to Us and/or via the Services is accurate,
                true, and complete to the best of your knowledge. We will
                evaluate your registration in good faith and will, at our sole
                discretion, accept or reject your registration and notify you of
                such outcomes. As long as you are accurate, true, and complete,
                and your program does not (i) promote Program Activities
                unrelated to our Service; (ii) promote illegal, harmful, or
                offensive Program Activities; or (iii) disagree with the
                technical specification of our Service, all systems should be a
                go! The acceptance of your program constitutes a completed
                registration (“Registered”).
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Title textAlign="start" variant="h4">
                Provider Content
              </Title>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary" pb={3}>
                You are solely responsible for Provider Content that you submit
                or upload while using the Service. By using the Service to
                provide Provider Content you represent and warrant that: (i) you
                have all the necessary rights to your content; (ii) you are not
                infringing or violating any third party&apos;s rights, including
                intellectual property rights and privacy rights; (iii) the
                content complies with applicable laws; and (iv) and does not
                contain any confidential, proprietary, or personal information
                that should not be shared with the public. We reserve the right
                to remove any Provider Content that, in our sole discretion, we
                determine is abusive, threatening, defamatory, obscene, vulgar,
                false, misleading, fraudulent, deceptive, or in violation of any
                other part of these Business Terms. So be good!
              </Text>

              <Text color="textSecondary" pb={3}>
                Now that we know you own your content, we want to inform you
                that by using the Service to provide Provider Content we&apos;re
                going to put it to work. Break out the sweat bands. As such,
                grant us an irrevocable, world-wide, perpetual, non-exclusive,
                non-exclusive, royalty-free, assignable, sublicensable,
                transferable right to use your Provider Content for the purposes
                of, but not limited to, store, copy, publicly display,
                reproduce, distribute, edit, modify, analyze, remove,
                commercialize, and prepare derivative works of Provider Content.
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Title textAlign="start" variant="h4">
                Enrollment
              </Title>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary" pb={3}>
                As a Registered Program Provider, you are allowing us to enroll
                users to your Program Activities on your behalf. To celebrate
                with you, when a user enrolls through the Service, we will
                notify you with relevant user information (such as name, email
                address, home address, child name and age) for your internal use
                to register the user for the correlating Program Activities
                only.
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Title textAlign="start" variant="h4">
                Payments and Fees
              </Title>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary" pb={3}>
                Payments for Program Activities (“Payments”) paid to Program
                Providers are facilitated by our Payment Provider, Stripe, Inc.
                (“Stripe”), and are subject to the{' '}
                <BlankLink href="https://stripe.com/connect-account/legal">
                  Stripe Connected Account Agreement
                </BlankLink>{' '}
                , which includes the{' '}
                <BlankLink href="https://stripe.com/legal">
                  Stripe Terms of Service
                </BlankLink>{' '}
                (collectively, the “Stripe Services Agreement”) for enabling a
                Stripe Connect account (“Stripe Account”). By agreeing to these
                Terms of Business, you agree to be bound by the Stripe Services
                Agreement, as the same may be modified by Stripe from time to
                time, for the duration of this Agreement. As a condition of us
                enabling Payments to Program Providers with Stripe, you agree to
                provide us accurate and complete information about you and your
                business, and you authorize us to share such information and
                transaction information related to your use of these Payments
                with Stripe.
              </Text>

              <Text color="textSecondary" pb={3}>
                As a Registered Program Provider with an enabled Stripe Account,
                you agree to the fees and charges associated with processing
                Payments (“Service Fees” or “Fees”). We may add new services for
                for additional Fees or charges, add or amend Fees or charges for
                existing Services at any time, and, in our sole discretion, upon
                at least thirty (30) days notice prior to any Fees or charges
                becoming effective.
              </Text>

              <Text color="textSecondary" pb={3}>
                In consideration for the use of our Services, PlanMyKids will
                retain a percentage(%) of all enrollments to Program Activities
                booked via the Service at a rate of 10% plus any applicable
                Stripe Payment Fees and sales tax.
              </Text>

              <Text color="textSecondary" pb={3}>
                Registered Program Providers with an enabled Stripe Account will
                receive payments for their enrolled Provider Activities via
                direct deposit through the Stripe Account, minus the Service
                Fee. Direct deposits will occur in accordance with the Stripe
                Account policies which, by default, are on a daily rolling
                basis.
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Title textAlign="start" variant="h4">
                Cancellations and Refunds of Provider Activities
              </Title>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary" pb={3}>
                Program Providers must have in place an appropriate policy and
                procedure regarding cancellations and refunds which must be
                available to users prior to their enrollment in your Provider
                Activities. We understand that things happen, but any refunds
                resulting in a cancellation is between the Program Provider and
                the enrolled user.
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Title textAlign="start" variant="h4">
                Feedback
              </Title>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary" pb={3}>
                We want to hear from you. By sending us any ideas, suggestions,
                documents or proposals (“Feedback”), you agree that (i) your
                Feedback does not contain the confidential or proprietary
                information of third parties, (ii) we are under no obligation of
                confidentiality, express or implied, with respect to the
                Feedback, (iii) we may have something similar to the Feedback
                already under consideration or in development, and (iv) you
                grant us an irrevocable, non-exclusive, royalty-free, perpetual,
                worldwide license to use, modify, prepare derivative works,
                publish, distribute and sublicense the Feedback, and you
                irrevocably waive, and cause to be waived, against PlanMyKids
                and its users any claims and assertions of any moral rights
                contained in such Feedback.
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Title textAlign="start" variant="h4">
                Term and Termination
              </Title>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary" pb={3}>
                The term of these Business Terms commences upon your
                registration for the Services and remains in effect for the term
                of your use of the Services. Upon termination, your access to
                the Service and any information stored by the Service will also
                terminate. PlanMyKids may terminate this Agreement at any time
                upon 30 days&apos;prior written notice for its convenience.
              </Text>

              <Text color="textSecondary" pb={3}>
                We may immediately, without notice, suspend or terminate your
                access to the Services and terminate these Business Terms for
                any of the following reasons: (a) you breach any provision of
                these Business Terms; (b) you seek to hack the security
                mechanisms of the Services or we otherwise determine that your
                use of the Services poses a security risk to us or to another
                user of the Service; (c) you introduce a malicious program into
                the network or a virtual machine instance; (d) you cause network
                interference that affects Services performance for other
                customers; (e) you use the Services in a way that we determine,
                in our sole discretion, is abusive or disrupts or threatens the
                performance or availability of the Service; or (e) we receive
                notice or we otherwise determine, in our sole discretion, that
                you may be using the Services for an illegal purpose or in a way
                that violates the law or violates, infringes, or misappropriates
                the rights of any third party.
              </Text>

              <Text color="textSecondary" pb={3}>
                If these Business Terms are terminated for any reason: (i) you
                will pay to PlanMyKids any fees or other amounts that have
                accrued prior to the effective date of the termination; (ii) any
                and all liabilities accrued prior to the effective date of the
                termination will survive; and (iii) the Sections of these
                Business Terms that are understood to extend beyond termination
                will survive. Following termination and upon your request and
                subject to any specific restrictions applicable to you or your
                data, PlanMyKids will make reasonable efforts to export and
                provide to you in electronic format the information you have
                uploaded to the Service (service fees may apply), but PlanMyKids
                is not obligated to retain that information following any
                termination and makes no representation to the integrity,
                completeness or timeliness of any data so exported.
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Title textAlign="start" variant="h4">
                Indemnification
              </Title>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary" pb={3}>
                YOU AGREE TO INDEMNIFY, DEFEND, AND HOLD PLANMYKIDS INC., ITS
                PARENTS, SUBSIDIARIES, AFFILIATES, ANY RELATED COMPANIES,
                SUPPLIERS, LICENSORS AND PARTNERS, AND THE OFFICERS, DIRECTORS,
                EMPLOYEES, AGENTS AND REPRESENTATIVES OF EACH OF THEM HARMLESS,
                INCLUDING COSTS, LIABILITIES AND LEGAL FEES, FROM ANY CLAIM OR
                DEMAND MADE BY ANY THIRD PARTY ARISING OUT OF OR RELATING TO (I)
                YOUR ACCESS TO OR USE OF THE SERVICE, (II) YOUR VIOLATION OF THE
                TERMS, (III) ANY PRODUCTS OR SERVICES PURCHASED OR OBTAINED BY
                YOU IN CONNECTION WITH THE SERVICE, OR (IV) THE INFRINGEMENT BY
                YOU, OR ANY THIRD PARTY USING YOUR ACCOUNT, OF ANY INTELLECTUAL
                PROPERTY OR OTHER RIGHT OF ANY PERSON OR ENTITY. PLANMYKIDS WILL
                USE REASONABLE EFFORTS TO NOTIFY YOU OF ANY SUCH CLAIM, ACTION
                OR PROCEEDING UPON BECOMING AWARE OF IT.
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Title textAlign="start" variant="h4">
                Third party links
              </Title>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary" pb={3}>
                Certain content, products and services available via our Service
                may include materials from third-parties. Third-party links on
                this site may direct you to third-party websites that are not
                affiliated with us. We provide these third-party links for your
                convenience but are not responsible for examining or evaluating
                the content or accuracy and we do not warrant and will not have
                any liability or responsibility for any third-party materials or
                website, or for any other materials, products, or services of
                third-parties. We are not liable for any harm or damages
                purchase or use of goods, services, resources, content, content,
                content, or any other transactions made in connection with any
                third-party websites. Please review carefully the
                third-party&apos;s policies and practices and make sure you
                understand them before you engage in any transaction.
                Complaints, claims, concerns, or questions regarding third-party
                products should be direct to the applicable third-party.
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Title textAlign="start" variant="h4">
                Warranty Disclaimer
              </Title>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary" pb={3}>
                THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS.
                USE OF THE SERVICE IS AT YOUR OWN RISK. TO THE MAXIMUM EXTENT
                PERMITTED BY APPLICABLE LAW, THE SERVICE IS PROVIDED WITHOUT
                WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING,
                BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY,
                FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. WITHOUT
                LIMITING THE FOREGOING, PLANMYKIDS, ITS SUBSIDIARIES, ITS
                AFFILIATES, AND ITS LICENSORS DO NOT WARRANT ANY OF THE
                FOLLOWING: (i) THAT THE CONTENT IS ACCURATE, RELIABLE OR
                CORRECT; (ii) THAT THE SERVICE WILL MEET YOUR REQUIREMENTS;
                (iii) THAT THE SERVICE WILL BE AVAILABLE AT ANY PARTICULAR TIME
                OR LOCATION, UNINTERRUPTED OR SECURE; (iv) THAT ANY DEFECTS OR
                ERRORS WILL BE CORRECTED; OR (v) THAT THE SERVICE IS FREE OF
                VIRUSES OR OTHER HARMFUL COMPONENTS.
              </Text>

              <Text color="textSecondary" pb={3}>
                ANY CONTENT DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF
                THE SERVICE IS DOWNLOADED AT YOUR OWN RISK AND YOU WILL BE
                SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR
                MOBILE DEVICE OR LOSS OF DATA THAT RESULTS FROM SUCH DOWNLOAD OR
                YOUR USE OF THE SERVICE. PLANMYKIDS DOES NOT WARRANT, ENDORSE,
                GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE
                ADVERTISED OR OFFERED BY A THIRD-PARTY THROUGH THE SERVICE OR
                ANY HYPERLINKED WEBSITE OR SERVICE, AND PLANMYKIDS WILL NOT BE A
                PARTY TO OR IN ANY WAY MONITOR ANY TRANSACTION BETWEEN YOU AND
                THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. FEDERAL LAW, SOME
                SOME STATES, PROVINCES AND OTHER JURISDICTIONS DO NOT ALLOW
                EXCLUSIONS AND LIMITATIONS OF CERTAIN IMPLIED WARRANTIES, SO
                SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Title textAlign="start" variant="h4">
                Limitation of Liability
              </Title>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary" pb={3}>
                IN NO CASE SHALL PLANMYKIDS, INC., OUR DIRECTORS, OFFICERS,
                EMPLOYEES, AFFILIATES, AGENTS, CONTRACTORS, INTERNS, SUPPLIERS,
                SERVICE PROVIDERS OR LICENSORS BE LIABLE FOR ANY INJURY, LOSS,
                CLAIM, OR ANY DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, SPECIAL,
                OR CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING, WITHOUT
                LIMITATION LOST PROFITS, LOST REVENUE, LOST SAVINGS, LOSS OF
                DATA, REPLACEMENT COSTS, OR ANY SIMILAR DAMAGES, WHETHER BASED
                IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR
                OTHERWISE, ARISING FROM YOUR USE OF ANY OF THE SERVICE OR
                PRODUCTS PROCURED USING THE SERVICE, OR FOR ANY OTHER CLAIM
                RELATED TO IN ANY WAY TO YOUR USE OF THE SERVICE OR ANY PRODUCT,
                INCLUDING, BUT NOT LIMITED TO, ANY ERRORS OR OMISSIONS IN
                CONTENT, OR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT
                OF THE USE OF THE SERVICE OR ANY CONTENT (OR PRODUCT) POSTED,
                TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICE, EVEN
                IF ADVISED OF THEIR POSSIBILITY. FURTHERMORE YOU AGREE TO
                RELEASE AND HOLD HARMLESS PLANMYKIDS FROM ANY CLAIM OR
                CONTROVERSY THAT MAY ARISE BETWEEN THE ACTIONS OR INACTIONS OF,
                OR THE RELATIONSHIP BETWEEN YOU AND ANY USER. IN NO EVENT SHALL
                PLANMYKID&apos;S AGGREGATE LIABILITY ARISING OUT OF OR RELATED
                TO TO THIS AGREEMENT, WHETHER ARISING OUT OF OR RELATED TO
                CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, EXCEED THE
                AGGREGATE AMOUNTS PAID TO PLANMYKIDS IN THE SIX MONTH PERIOD
                PRECEDING THE EVENT GIVING RISE TO THE CLAIM. BECAUSE SOME
                STATES OR JURISDICTIONS DO NOT ALL THE EXCLUSION OF THE
                LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES,
                IN SUCH STATES OR JURISDICTIONS, OUR LIABILITY SHALL BE LIMITED
                TO THE MAXIMUM EXTENT PERMITTED BY LAW.
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Title textAlign="start" variant="h4">
                Governing Law
              </Title>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary" pb={3}>
                These terms, and all claims related to or arising out of this
                contract, or the breach thereof, whether sounding in contract,
                tort, or otherwise, shall be governed by the laws of the State
                of Delaware, including Delaware&apos;s statute of limitations
                governing your claim, without giving effect to its principles of
                conflicts of law.
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Title textAlign="start" variant="h4">
                Questions
              </Title>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary">
                If you have any questions about the Terms, please contact us via
                email at{' '}
                <MuiLink
                  color="secondary"
                  href="mailto:help@planmykids.com"
                  style={{ fontWeight: 700 }}
                >
                  help@planmykids.com
                </MuiLink>{' '}
                or via postal mail at: P.O. Box 26131, Raleigh NC, 27611.
              </Text>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </FullWidthMobile>
  )
}

export default BusinessTerms
