import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { List, ListItem } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import cn from 'classnames'

import Premium from '~/containers/Premium'
import { NavLink, Text } from '~/components/shared'

import { isBlockQuestionnaire, isParent, isShare } from '~/state/modules/user'
import ROUTES from '~/constants/routes'
import { AppState } from '~/state/store'
import { campDirectorTabsArray } from '~/pages/camp-director/camp-director.template'

import useProfilePopoverStyles from './ProfilePopover.styles'
import './css/transitions.css'
import { ProfileArray } from '~/data/profile/profileArray'

type Props = {
  logout: () => void
  isGradient?: boolean
  limitQuestionnaire?: boolean
}

const ProfilePopover: FC<Props> = ({ isGradient, logout }) => {
  const s = useProfilePopoverStyles()

  const [open, setOpen] = useState<boolean>(false)

  const userName = useSelector<AppState, string>((state) => state.user.name)
  const parent = useSelector<AppState, boolean>((state) => isParent(state))
  const withShare = useSelector(isShare)
  const isQuestionnaireBlock = useSelector(isBlockQuestionnaire)

  return (
    <div
      className={cn(s.btn, { active: open, isGradient })}
      onMouseEnter={(): void => {
        setOpen(true)
      }}
      onMouseLeave={(): void => {
        setOpen(false)
      }}
      onTouchEnd={(): void => {
        setOpen(!open)
      }}
      data-cy="profile-popover"
    >
      {parent ? (
        <Text className={s.text}>Hi, {userName}</Text>
      ) : (
        <Text className={s.text}>Camp Director</Text>
      )}

      <ExpandMore className="arrow" />

      <CSSTransition in={open} timeout={200} classNames="fade" mountOnEnter>
        <div className={s.container}>
          <div className={s.dropdown}>
            <List disablePadding data-cy="profile-popover-button">
              {parent ? (
                <>
                  {ProfileArray.map(({ label, value }) => {
                    if (value === ROUTES.itinerariesFriends && !withShare) {
                      return null
                    }

                    const ListItemComponent = (
                      <ListItem key={value} className={s.tabItem}>
                        <NavLink
                          to={value}
                          color="primary"
                          exact
                          underline="none"
                        >
                          <Text>{label}</Text>
                        </NavLink>
                      </ListItem>
                    )

                    if (value === ROUTES.calendar) {
                      return <Premium key={value}>{ListItemComponent}</Premium>
                    }

                    if (value === ROUTES.questionnaire) {
                      return (
                        <Premium
                          customBlock={{
                            isBlock: isQuestionnaireBlock,
                            useBlock: true
                          }}
                          key={value}
                        >
                          {ListItemComponent}
                        </Premium>
                      )
                    }

                    return ListItemComponent
                  })}
                </>
              ) : (
                <>
                  {campDirectorTabsArray.map(({ label, value }) => {
                    const ListItemComponent = (
                      <ListItem key={value} className={s.tabItem}>
                        <NavLink
                          to={value}
                          color="primary"
                          exact
                          underline="none"
                        >
                          <Text>{label}</Text>
                        </NavLink>
                      </ListItem>
                    )

                    return ListItemComponent
                  })}
                </>
              )}

              <ListItem onClick={logout} className={s.tabItem}>
                <Text className={s.logOut}>Log out</Text>
              </ListItem>
            </List>
          </div>
        </div>
      </CSSTransition>
    </div>
  )
}

export default ProfilePopover
