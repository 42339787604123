import { createStyles, makeStyles } from '@material-ui/core'

import WorksBg from '~/assets/images/home/works-background.png'
import ParentsBg from '~/assets/images/home/parents-background.png'
import AdvantagesBg from '~/assets/images/home/advantages-background.png'
import ConnectedBg from '~/assets/images/home/connected-background.png'

const useHomeStyles = makeStyles(
  (theme) =>
    createStyles({
      container: {
        position: 'relative',
        padding: theme.spacing(9, 0)
      },

      worksContainer: {
        backgroundImage: `url(${WorksBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        backgroundPosition: 'top',

        [theme.breakpoints.down('sm')]: {
          backgroundSize: '130% 100%'
        }
      },

      helpTitle: {
        margin: theme.spacing(0, 'auto', 5),
        maxWidth: 620
      },

      titleDecoration: {
        position: 'absolute',
        right: '0%',
        top: '40%'
      },

      parentsContainer: {
        backgroundImage: `url(${ParentsBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        backgroundPosition: 'bottom',

        [theme.breakpoints.down('sm')]: {
          backgroundSize: '135% 100%'
        },

        [theme.breakpoints.down('xs')]: {
          backgroundSize: '235% 100%',
          backgroundPositionX: '30%'
        }
      },

      spotlightContainer: {
        paddingBottom: 60
      },

      advantagesContainer: {
        backgroundImage: `url(${AdvantagesBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        backgroundPosition: 'bottom',

        [theme.breakpoints.down('sm')]: {
          backgroundSize: '135% 100%'
        },

        [theme.breakpoints.down('xs')]: {
          backgroundSize: '235% 100%',
          backgroundPositionX: '30%'
        }
      },

      videoPainting: {
        position: 'absolute',
        height: 'auto',
        width: 160,
        objectFit: 'cover',

        [theme.breakpoints.down('sm')]: {
          width: 100
        }
      },

      videoPainting1: {
        bottom: 25,
        left: 0,

        [theme.breakpoints.down('sm')]: {
          left: -30,

          '&.camp': {
            left: 0,
            bottom: 90
          }
        }
      },

      videoPainting2: {
        top: 10,
        right: 0,

        [theme.breakpoints.down('sm')]: {
          top: 10,
          right: -30,

          '&.camp': {
            right: 0,
            top: -10
          }
        }
      },

      spotlightPainting: {
        zIndex: 1,
        bottom: -80,
        left: 0,

        [theme.breakpoints.down('sm')]: {
          left: -30,

          '&.camp': {
            left: 0,
            bottom: -40
          }
        }
      },

      campGridItem: {
        [theme.breakpoints.up('md')]: {
          position: 'relative'
        }
      },

      connectedContainer: {
        padding: theme.spacing(8, 6),
        backgroundImage: `url(${ConnectedBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'top',

        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(6, 0),
          backgroundSize: '120% 100%',
          backgroundPosition: '25% 0%'
        },

        [theme.breakpoints.down('xs')]: {
          backgroundSize: 'cover'
        }
      },

      connectedWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: theme.palette.common.white,

        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column'
        },

        [theme.breakpoints.down('xs')]: {
          alignItems: 'initial'
        }
      }
    }),
  {
    name: 'Home'
  }
)

export default useHomeStyles
