import React, { FC, useEffect, useMemo, useState } from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { useAsync } from 'react-async'
import { Container, Grid, Paper } from '@material-ui/core'

import withStripe from '~/containers/withStripe'
import FullWidthMobile from '~/containers/FullWidthMobile'
import { Loader, Title } from '~/components/shared'
import StripeForm from '~/components/forms/StripeForm'

import { loadPackages } from '~/state/modules/user/user.async'

import ROUTES from '~/constants/routes'
import { PACKAGES_TYPE } from '~/constants/packages'

import useStaticStyles from '~/styles/static-pages.styles'

type RouterMatch = {
  plan: string
}

const Checkout: FC<RouteComponentProps<RouterMatch>> = ({
  match: { params }
}) => {
  const s = useStaticStyles()

  const packageUrl = params?.plan

  const [currentPackage, setCurrentPackage] = useState<Package>(null)

  let isValidPackageUrl = useMemo(
    (): boolean =>
      !!packageUrl &&
      Object.values(PACKAGES_TYPE).some(
        (packageType) => packageType === packageUrl
      ),
    [packageUrl]
  )

  const { isLoading, run } = useAsync({
    deferFn: loadPackages,
    onResolve: (data) => {
      const actualPackage = data?.find(({ name }) => name === packageUrl)

      if (!actualPackage) {
        isValidPackageUrl = false
      }

      setCurrentPackage(actualPackage)
    }
  })

  useEffect(() => {
    if (isValidPackageUrl) {
      run()
    }
  }, [isValidPackageUrl])

  if (!isValidPackageUrl) {
    return <Redirect to={ROUTES.packages} />
  }

  return (
    <FullWidthMobile>
      <Container maxWidth="md" className={s.container}>
        <Paper className={s.paper}>
          <Grid container spacing={3} direction="column" alignItems="center">
            <Grid item xs={12}>
              <Title>Checkout</Title>
            </Grid>

            <Grid item xs={12}>
              {isLoading ? (
                <Loader />
              ) : (
                <StripeForm currentPackage={currentPackage} />
              )}
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </FullWidthMobile>
  )
}

export default withStripe(Checkout)
