import { array, object, string } from 'yup'

import { zipCode, phone } from './fields'

const ProgramSchema = object().shape({
  name: string().required('Name required').trim(),
  email: string().email('Email invalid').required('Email required'),
  website: string().required('Website required').trim(),
  address: string().required('Address required').trim(),
  city: string().required('City required').trim(),
  state: string().required('State required').trim(),
  zip_code: zipCode.required('Zip Code required').trim(),
  phone_number: phone,
  interest_ids: array()
    .required('Interest is required')
    .min(1, 'Choose at least 1 option!')
    .nullable(),
  tax_id: string().required('TaxID is required').nullable().trim(),
  cancellation_policy: string()
    .required('Cancellation policy is required')
    .nullable()
    .trim()
})

export default ProgramSchema
