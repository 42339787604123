import { ZOHO } from '~/constants/zoho'

export const generateIframeDirector = (email?: string): void => {
  const f = document.createElement('iframe')

  let formName = 'ProviderSignup'
  let key = ZOHO.campDirector.staging

  if (process.env.REACT_APP_CI_ENV === 'production') {
    formName = 'ProgramSignup'
    key = ZOHO.campDirector.production
  }

  f.src = `https://forms.planmykids.com/know/form/${formName}/formperma/${key}?zf_rszfm=1&email=${email}`
  f.style.border = 'none'
  f.style.height = '912px'
  f.style.width = '90%'
  f.style.transition = 'all 0.5s ease' // No I18N

  const d = document.getElementById(
    process.env.REACT_APP_CI_ENV === 'production'
      ? ZOHO.campDirector.divIdProduction()
      : ZOHO.campDirector.divIdStaging()
  )

  d?.appendChild(f)
}
