import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { STORAGE } from '~/constants/storage'

export interface AppLayoutState {
  header: {
    lowered: boolean
  }
  counter: number
}

const initialState: AppLayoutState = {
  header: {
    lowered: false
  },
  counter: Number(sessionStorage.getItem(STORAGE.counter))
}

const appLayoutSlice = createSlice({
  name: 'appLayout',
  initialState,
  reducers: {
    changeHeaderLevel: (state, { payload }: PayloadAction<boolean>): void => {
      state.header.lowered = payload
    },
    changeCounter: (state, { payload }: PayloadAction<number>): void => {
      state.counter = payload
    }
  }
})

export const { changeHeaderLevel, changeCounter } = appLayoutSlice.actions

export default appLayoutSlice.reducer
