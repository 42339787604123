export const initialAnswersFields = {
  start_date: '-',
  end_date: '-',
  camp_type: '-',
  start_date_2: '-',
  end_date_2: '-',
  second_date_camp_preference: '-',
  start_date_3: '-',
  end_date_3: '-',
  third_date_camp_preference: '-',
  start_date_4: '-',
  end_date_4: '-',
  fourth_date_camp_preference: '-',
  distance_preferences_day_camps: '-',
  day_camp_duration_preferences: '-',
  earliest_time_day_camp_drop_off: '-',
  early_drop_fee: '-',
  distance_preferences_overnight_camps: '-',
  favorite_camps: '-',
  camp_name: '-',
  name_of_second_camp: '-',
  name_of_third_camp: '-',
  name_of_fourth_camp: '-',
  amount_money: '-',
  highest_priority: '-',
  academic_interests: '-',
  academic_proficiency: '-',
  art_interests: '-',
  art_proficiency: '-',
  cooking_baking_proficiency: '-',
  dance_interests: '-',
  dance_proficiency: '-',
  music_interests: '-',
  music_proficiency: '-',
  outdoor_interests: '-',
  outdoor_proficiency: '-',
  sport_interests: '-',
  sport_proficiency: '-',
  theater_interests: '-',
  theater_proficiency: '-',
  other_interests: '-',
  specify_accessibility: '-',
  last_comments: '-'
}

export const filteredFields = [
  'name_title',
  'first_name',
  'last_name',
  'email',
  'address_street',
  'zip_code',
  'child_first_name',
  'age',
  'rising_grade_level',
  'interests',
  'personal_skills',
  'special_needs',
  'questionnaire',
  'action',
  'controller'
]
