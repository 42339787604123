import React from 'react'
import { Container, Grid, Paper, Divider, Box } from '@material-ui/core'

import FullWidthMobile from '~/containers/FullWidthMobile'
import { Flexbox, Link, Text, Title } from '~/components/shared'
import { GoogleButton } from '~/components/forms'
import SignInForm from './components/SignInForm'

import ROUTES from '~/constants/routes'

import useAuthStyles from '~/styles/auth.styles'

const SignIn: React.FC = () => {
  const s = useAuthStyles()

  return (
    <FullWidthMobile>
      <Container maxWidth="sm" className={s.container}>
        <Paper className={s.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Title mb={1} fontSize="34px">
                Sign in Parent / Guardian
              </Title>
            </Grid>

            <Grid item xs={12}>
              <SignInForm />
            </Grid>

            <Grid item xs={12}>
              <Flexbox justifyContent="center" alignItems="center">
                <Text variant="body2" mr={1}>
                  Don&apos;t have an account?
                </Text>
                <Link to={ROUTES.signUp} color="primary">
                  Sign up
                </Link>
              </Flexbox>
            </Grid>

            <Grid item xs={12}>
              <Box mt={2}>
                <Divider />
              </Box>
            </Grid>

            {/* <Grid item xs={12}>
              <FacebookButton>Sign in with Facebook</FacebookButton>
            </Grid> */}

            <Grid item xs={12}>
              <GoogleButton>Sign in with Google</GoogleButton>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </FullWidthMobile>
  )
}

export default SignIn
