import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useModal } from 'react-modal-hook'
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch
} from '@material-ui/core'
import cn from 'classnames'

import withGoogleMaps from '~/containers/withGoogleMaps'
import { Flexbox, Loader, Text, Title } from '~/components/shared'
import { ItineraryCard } from '~/components/cards'
import ItineraryFriendDialog from './components/ItineraryFriendDialog'
import ItineraryMap from '../components/ItineraryMap'
import ProfileTemplate from '../profile.template'

import { getFriendsItineraries } from '~/state/modules/itineraries'
import {
  changeActiveTab,
  changeActiveItem
} from '~/state/modules/itineraries/friends/friendsItineraries.slice'
import { updateUser } from '~/state/modules/user'
import ActivitiesService from '~/services/ActivitiesService'
import { sortByDate } from '~/utils/functions'

import { STATUSES, STATUSES_SHARED } from '~/constants/statusesItinerary'
import { AppState } from '~/state/store'

import useItinerariesStyles from '../itineraries/itineraries.styles'

const ItinerariesBtns: {
  label: string
  value: StatusesUnion
}[] = [
  {
    label: 'In progress',
    value: STATUSES.in_progress
  },
  {
    label: 'Approved',
    value: STATUSES.approved
  }
]

const showedStatuses = [
  STATUSES.review,
  STATUSES.alternate_request,
  STATUSES.accepted,
  STATUSES.approved
]

type ReduxProps = {
  data: Itinerary[]
  item: Partial<Itinerary>
  loading: boolean
  loaded: boolean
  activeTab: StatusesUnion
  invited: boolean
  userId: string
}

type dispatchProps = {
  getFriendsItineraries: () => void
  changeActiveTab: (tab: StatusesUnion) => void
  changeActiveItem: (id: string) => void
  updateUser: (values: Partial<UpdateUserInput>) => void
}

type AllProps = ReduxProps & dispatchProps

const ItinerariesFriends: FC<AllProps> = ({
  loading,
  loaded,
  data,
  item,
  activeTab,
  invited,
  userId,

  getFriendsItineraries,
  changeActiveTab,
  changeActiveItem,
  updateUser
}) => {
  const s = useItinerariesStyles()
  const [isShowMap, setIsShowMap] = useState(false)

  useEffect(() => {
    getFriendsItineraries()
  }, [])

  useEffect(() => {
    if (invited) {
      updateUser({ invited_to_itinerary: false })
    }
  }, [invited])

  const [showModal, hideModal] = useModal(
    (props: any) => (
      <ItineraryFriendDialog
        onClose={hideModal}
        itinerary={{
          id: item?.id,
          name: `${item?.questionnaire?.fields?.child_first_name}'s Itinerary`
        }}
        isAccept
        {...props}
      />
    ),
    [item]
  )

  const [showRejectModal, hideRejectModal] = useModal(
    (props: any) => (
      <ItineraryFriendDialog
        onClose={hideRejectModal}
        itinerary={{
          id: item?.id,
          name: `${item?.questionnaire?.fields?.child_first_name}'s Itinerary`
        }}
        {...props}
      />
    ),
    [item]
  )

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      changeActiveItem((event.target as HTMLInputElement).value)
    },
    []
  )

  const handleChangeMap = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setIsShowMap(event.target.checked)
    },
    []
  )

  const { friendsActivities, activitiesSum, isDraft } = useMemo(() => {
    const activitiesData = item?.activities

    const friendsActivities = ActivitiesService.generateParentActivities(
      sortByDate(activitiesData, 'start_date')
    )

    return {
      friendsActivities,
      activitiesSum: ActivitiesService.generateActivitiesSum(activitiesData),
      isDraft:
        showedStatuses.some((status) => status === item?.status) &&
        item?.parents_itineraries?.find(
          ({ parent_id }) => parent_id === Number(userId)
        )?.status === STATUSES_SHARED.draft
    }
  }, [item, userId])

  return (
    <ProfileTemplate>
      <Flexbox mb={3} className={s.btns}>
        {ItinerariesBtns.map(({ value, label }) => (
          <Button
            data-cy="itineraries-friends-status-button"
            key={value}
            color={value === activeTab ? 'primary' : 'default'}
            variant="outlined"
            size="small"
            onClick={(): void => {
              changeActiveTab(value)
            }}
          >
            {label}
          </Button>
        ))}
      </Flexbox>

      {loading ? (
        <Loader noGutters boxProps={{ flex: 1 }} />
      ) : data?.length && loaded ? (
        <>
          <Flexbox className={s.titleWrapper}>
            <Title variant="h4" data-cy="itineraries-friends-title">
              {item?.questionnaire?.fields?.child_first_name}&apos;s Itinerary
            </Title>

            <FormControlLabel
              value="map"
              control={
                <Switch
                  checked={isShowMap}
                  color="primary"
                  onChange={handleChangeMap}
                />
              }
              label="Show map"
              labelPlacement="start"
              className={s.labelMap}
            />
          </Flexbox>

          <Flexbox className={s.radiosWrapper}>
            <Text mr={3} whiteSpace="nowrap">
              Choose a child
            </Text>

            <RadioGroup
              row
              value={item?.id}
              onChange={handleChange}
              className={s.labelGroup}
            >
              {data.map(({ id, questionnaire }) => (
                <FormControlLabel
                  data-cy="itineraries-friends-radio-button"
                  key={id}
                  value={id}
                  label={questionnaire?.fields?.child_first_name}
                  control={<Radio color="primary" />}
                  className={cn(s.label, { checked: id === item?.id })}
                />
              ))}
            </RadioGroup>
          </Flexbox>

          {isShowMap ? (
            <ItineraryMap activities={item?.activities} />
          ) : friendsActivities?.length ? (
            <>
              {friendsActivities?.map((activity, idx) => (
                <ItineraryCard
                  key={activity?.id}
                  isLast={idx === friendsActivities?.length - 1}
                  {...activity}
                />
              ))}

              <Text color="textSecondary" fontSize={18} mt={2} mb={3}>
                Total:{' '}
                <Text component="span" className={s.total}>
                  {activitiesSum}
                </Text>
              </Text>
            </>
          ) : (
            <Text mb={5} flex={1}>
              No shared activities with you.
            </Text>
          )}

          {isDraft && (
            <Flexbox className={cn(s.titleWrapper, 'buttons')}>
              <Button
                data-cy="itineraries-friends-accept-button"
                size="large"
                variant="contained"
                color="primary"
                onClick={showModal}
              >
                Accept
              </Button>

              <Button
                data-cy="itineraries-friends-reject-button"
                size="large"
                variant="outlined"
                color="primary"
                onClick={showRejectModal}
              >
                Decline
              </Button>
            </Flexbox>
          )}
        </>
      ) : (
        <Text>No results</Text>
      )}
    </ProfileTemplate>
  )
}

const mapStateToProps = ({
  itineraries: {
    friends: { data, ...itineraries }
  },
  user: { invited_to_itinerary, id }
}: AppState): ReduxProps => {
  return {
    invited: invited_to_itinerary,
    data: data[itineraries.activeTab],
    userId: id,
    ...itineraries
  }
}

const withConnect = connect(mapStateToProps, {
  getFriendsItineraries,
  changeActiveTab,
  changeActiveItem,
  updateUser
})

export default compose<React.FC>(
  withGoogleMaps(),
  withConnect
)(ItinerariesFriends)
