import { createAsyncThunk } from '@reduxjs/toolkit'
import { toastr } from 'react-redux-toastr'

import Deserializer from '~/services/Deserializer'
import PMK from '~/services/PMK'
import ErrorHandler from '~/utils/errorHandler'

export const getPrograms = createAsyncThunk<
  { data: Program[]; meta: Meta },
  { page: number }
>('programs/fetchPrograms', async ({ page }) => {
  try {
    const { activity_providers, pagy } = await PMK.getPrograms(page)

    const payload = await Deserializer.deserialize(activity_providers)

    return {
      data: payload,
      meta: {
        count: pagy.count,
        pages: pagy.pages,
        page: pagy.page
      }
    }
  } catch (error) {
    throw new ErrorHandler(error)
  }
})

export const getCampDirectorInterests = createAsyncThunk(
  'programs/getCampDirectorInterests',
  async () => {
    try {
      const resp = await PMK.getCampDirectorInterests()

      const payload = await Deserializer.deserialize(resp)

      return payload
    } catch (error) {
      throw new ErrorHandler(error)
    }
  }
)

export const getProgramById = createAsyncThunk<Program, string>(
  'program/fetchProgramById',
  async (id) => {
    try {
      const resp = await PMK.getProgramById(id)

      const payload = await Deserializer.deserialize(resp)

      return payload
    } catch (error) {
      throw new ErrorHandler(error)
    }
  }
)

export const createProgram = createAsyncThunk<any, ProgramsFormValues>(
  'programs/createProgram',
  async (data) => {
    try {
      const resp = await PMK.createProgram(data)

      const payload = await Deserializer.deserialize(resp)

      return payload
    } catch (error) {
      throw new ErrorHandler(error)
    }
  }
)

export const updateProgramById = createAsyncThunk<
  Program,
  { id: string; values: ProgramsFormValues }
>('programs/updateProgramById', async ({ id, values }) => {
  try {
    const resp = await PMK.updateProgramById(id, values)

    const payload = await Deserializer.deserialize(resp)

    return payload
  } catch (error) {
    throw new ErrorHandler(error)
  }
})

export const deleteProgramById = createAsyncThunk<string, string>(
  'program/deleteProgramById',
  async (id) => {
    try {
      await PMK.deleteProgram(id)

      toastr.success('Success', 'The program was deleted successfully')

      return id
    } catch (error) {
      throw new ErrorHandler(error)
    }
  }
)

export const fetchActivities = createAsyncThunk<
  { data: Activity[]; meta: Meta },
  { id: string; params: ActivitySearchDto }
>('activities/fetchActivities', async ({ id, params }) => {
  try {
    const { activities, pagy } = await PMK.getCampDirectorActivities(id, params)

    const payload = await Deserializer.deserialize(activities)

    return {
      data: payload,
      meta: {
        count: pagy.count,
        pages: pagy.pages,
        page: pagy.page
      }
    }
  } catch (error) {
    throw new ErrorHandler(error)
  }
})

export const createActivity = createAsyncThunk<
  Activity,
  { id: string; values: ActivityFormValues }
>('activity/createActivity', async ({ id, values }) => {
  try {
    const resp = await PMK.createCampDirectorActivity(id, values)

    const payload = await Deserializer.deserialize(resp)

    return payload
  } catch (error) {
    throw new ErrorHandler(error)
  }
})

export const duplicateActivity = createAsyncThunk<
  Activity,
  Partial<ActivityDuplicate>
>(
  'activity/duplicateActivity',
  // @ts-ignore
  async (values) => {
    try {
      const resp = await PMK.duplicateCampDirectorActivity(values)

      const payload = await Deserializer.deserialize(resp)
      return payload
    } catch (error) {
      throw new ErrorHandler(error)
    }
  }
)

export const updateCampDirectorActivity = createAsyncThunk<
  Activity,
  {
    id: string
    activityId: string
    values: ActivityFormValues | ActivityFormDuplicateValues
  }
>('activity/updateCampDirectorActivity', async ({ id, activityId, values }) => {
  try {
    const resp = await PMK.updateCampDirectorActivity(id, activityId, values)

    const payload = await Deserializer.deserialize(resp)

    return payload
  } catch (error) {
    throw new ErrorHandler(error)
  }
})

export const fetchingCampDirectorActivity = createAsyncThunk<
  Activity,
  { id: string; activityId: string }
>('activity/fetchingCampDirectorActivity', async ({ id, activityId }) => {
  try {
    const resp = await PMK.getCampDirectorActivity(id, activityId)

    const payload = await Deserializer.deserialize(resp)

    return payload
  } catch (error) {
    throw new ErrorHandler(error)
  }
})

export const deleteActivity = createAsyncThunk<
  string,
  { id: string; activityId: string }
>('activity/deleteActivity', async ({ id, activityId }) => {
  try {
    await PMK.deleteActivity(id, activityId)

    toastr.success('Success', 'The activity was successfully deleted')

    return activityId
  } catch (error) {
    throw new ErrorHandler(error)
  }
})
