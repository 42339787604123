import React from 'react'
import { Link } from 'react-router-dom'
import { Button, makeStyles, createStyles, Theme } from '@material-ui/core'

import { Flexbox, Title, Text } from '~/components/shared'

import ROUTES from '~/constants/routes'

import verifyFailure from '~/assets/images/verify-failure.svg'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageFailure: {
      width: 170,
      height: 170,
      marginBottom: theme.spacing(3)
    }
  })
)

const StipeFailure: React.FC = () => {
  const s = useStyles()

  return (
    <Flexbox
      flexDirection="column"
      justifyContent="spaceBatween"
      alignItems="center"
      mt={7}
      mb={7}
    >
      <img
        src={verifyFailure}
        className={s.imageFailure}
        alt="image for verification with failure"
      />

      <Title mb={1.5}>Verification Failed</Title>

      <Text color="textSecondary" mb={3}>
        Please proceed with Stripe account set up
      </Text>

      <Button
        component={Link}
        to={ROUTES.campDirectorStripe}
        variant="contained"
        size="large"
        color="primary"
      >
        Go to stripe
      </Button>
    </Flexbox>
  )
}

export default StipeFailure
