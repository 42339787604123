const personalGrowthOptions: Option[] = [
  {
    value: 'Self-esteem',
    label: 'Self-esteem'
  },
  {
    value: 'Leadership',
    label: 'Leadership'
  },
  {
    value: 'Social Comfort',
    label: 'Social Comfort'
  },
  {
    value: 'Adventure and Exploration',
    label: 'Adventure and Exploration'
  },
  {
    value: 'Values and Decisions',
    label: 'Values and Decisions'
  },
  {
    value: 'Independence',
    label: 'Independence'
  },
  {
    value: 'Friendship Skills',
    label: 'Friendship Skills'
  },
  {
    value: 'Peer Relationships',
    label: 'Peer Relationships'
  },
  {
    value: 'Environmental Awareness',
    label: 'Environmental Awareness'
  },
  {
    value: 'Spirtuality/Faith',
    label: 'Spirtuality/Faith'
  }
]

export default personalGrowthOptions
