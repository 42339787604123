import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Box, Button, makeStyles, createStyles } from '@material-ui/core'

import { BlankLink, Flexbox, Text, Title } from '~/components/shared'

import { campDirectorStatuses } from '~/state/modules/user'
import { CAMP_DIRECTOR_STATUSES } from '~/constants'
import ROUTES from '~/constants/routes'

import StepDraw3 from '~/assets/images/home/step-draw-3.png'
import StepDraw1 from '~/assets/images/home/step-draw-1.png'

const useStyles = makeStyles(
  () =>
    createStyles({
      firstImage: {
        position: 'absolute',
        transform: 'rotate(90deg)',
        top: '-60px',
        left: '-70px',
        height: '85px'
      },
      secondImage: {
        position: 'absolute',
        transform: 'rotateX(180deg) rotate(67deg)',
        top: '76px',
        right: '-42px',
        height: '85px'
      }
    }),
  {
    name: 'CampDirectorCongratulation'
  }
)

const CongratulationsDirector: React.FC = () => {
  const s = useStyles()
  const history = useHistory()

  const { zoho_profile } = useSelector(campDirectorStatuses)

  useEffect(() => {
    if (zoho_profile === CAMP_DIRECTOR_STATUSES.show_form) {
      history.push(ROUTES.campDirectorProvider)
    }
  }, [zoho_profile])

  return (
    <Flexbox flexDirection="column" alignItems="center" pt={7}>
      <Box position="relative">
        <img src={StepDraw3} className={s.firstImage} />
        <Title mb={2}>Thank you for registering!</Title>
      </Box>
      <Flexbox flexDirection="column" width="50%" alignItems="start">
        <Text color="textSecondary" textAlign="start">
          Thank you for registering your program!
        </Text>
        <Text color="textSecondary" textAlign="start">
          You are on your way to connecting with a community of parents
          interested in enriching their kids in programs like yours.
        </Text>
        <Text color="textSecondary" textAlign="start" mb={2}>
          After we complete a short validation process, we will contact you by
          email with instructions on how to add your content. We look forward to
          partnering with you.
        </Text>

        <Box position="relative">
          <Text color="textSecondary" textAlign="justify">
            How would you like your program to be featured on our{' '}
            <BlankLink href="https://blog.planmykids.com/?blogcategory=Program+Spotlight">
              blog?
            </BlankLink>
          </Text>
          <Text color="textSecondary" textAlign="justify">
            You&apos;ll be able to create an intimate story about your program
            to connect with parents.
          </Text>
          <Text color="textSecondary" textAlign="justify">
            We&apos;ll share your story in our newsletter and post it on social
            media.
          </Text>
          <Text color="textSecondary" textAlign="justify">
            Plus, the extra backlink to your website will improve your google
            search ranking.
          </Text>
          <Text color="textSecondary" textAlign="justify">
            There is no cost for your request to be featured in our program
            spotlight.
          </Text>
          <img src={StepDraw1} className={s.secondImage} />
        </Box>
      </Flexbox>
      <Box maxWidth={500} mt={3} mb={5}>
        <Button
          component="a"
          href="https://forms.planmykids.com/know/form/ProgramSpotlight/formperma/chsWza9l4EkIqB99QCn3ag-9E424ZhU0WmWWCY0mPqg"
          variant="contained"
          color="secondary"
          target="_blank"
          fullWidth
        >
          Request Program Spotlight
        </Button>
      </Box>
    </Flexbox>
  )
}

export default CongratulationsDirector
