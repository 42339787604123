import React, { FC, useEffect, useCallback, useState } from 'react'
import { useHistory } from 'react-router'
import { Paper, Box, Tab, Tabs } from '@material-ui/core'
import cn from 'classnames'

import ROUTES from '~/constants/routes'

import useCampDirectorStyles from './camp-director.styles'

export const campDirectorTabsArray: Option[] = [
  {
    label: 'Programs',
    value: ROUTES.campDirectorPrograms
  },
  {
    label: 'Orders',
    value: ROUTES.campDirectorOrders
  },
  {
    label: 'Profile',
    value: ROUTES.campDirectorProfile
  }
]
const CampDirectorTemplate: React.FC = ({ children }) => {
  const s = useCampDirectorStyles()
  const h = useHistory()

  const [activeTab, setActiveTab] = useState<string>(h.location.pathname)

  useEffect(() => {
    window.dispatchEvent(new CustomEvent('resize'))
  }, [])

  const handleChangeTab = useCallback(
    (_event: React.ChangeEvent<{}>, newValue: string): void => {
      setActiveTab(newValue)

      h.push(newValue)
    },
    [h]
  )

  return (
    <Paper className={s.container}>
      <Tabs
        textColor="primary"
        indicatorColor="primary"
        variant="scrollable"
        scrollButtons="on"
        value={activeTab}
        onChange={handleChangeTab}
        classes={{
          root: s.tabs,
          flexContainer: cn(s.tabsContainer),
          indicator: s.indicator,
          scrollable: s.scrollable
        }}
      >
        {campDirectorTabsArray.map((tab) => {
          return (
            <Tab
              data-cy="profile-tabs"
              key={tab.value}
              {...tab}
              selected
              classes={{
                root: s.tab,
                selected: s.selected
              }}
            />
          )
        })}
      </Tabs>

      <Box className={s.wrapper}>{children}</Box>
    </Paper>
  )
}

export default CampDirectorTemplate
