import createPalette from '@material-ui/core/styles/createPalette'

const palette = createPalette({
  primary: {
    main: '#703965'
  },
  secondary: {
    main: '#703950',
    light: '#fcacc4'
  },
  text: {
    primary: '#1B1B1B',
    secondary: '#575757',
    disabled: '#D4DAE1',
    hint: '#A9AFBF'
  },
  info: {
    main: '#5383EC',
    dark: '#3D5A96'
  },
  success: {
    main: '#5FB37E',
    dark: '#4351AF'
  },
  grey: {
    '100': '#F6F8F9',
    '200': '#E2E7EA',
    '300': '#E3E2E1'
  },
  common: {
    white: '#FFF',
    black: '#000'
  },
  background: {
    default: '#F6F8F9'
  }
})

export default palette
