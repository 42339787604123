import React, { FC, ReactNode } from 'react'
import { Box, Button, Container, Grid } from '@material-ui/core'

import ContainerFull from '~/components/layouts/ContainerFull'
import { Flexbox, Title } from '~/components/shared'
import { InfoCard, PaymentPlanCard } from '~/components/cards'

import advantagesCards from '~/data/congratulations/advantagesCards'

import useCongratulationsStyles from '~/styles/congratulations.styles'
import { Redirect } from 'react-router-dom'
import ROUTES from '~/constants/routes'
import usePackages from '~/hooks/usePackages'

interface PackagesViewProps {
  title: string
  subtitle?: ReactNode
}

const PackagesView: FC<PackagesViewProps> = ({ title, subtitle }) => {
  const styles = useCongratulationsStyles()

  const {
    currentAdvantages,
    handleClickPremium,
    handleChangeSwitch,
    isPremiumAccount
  } = usePackages()

  if (isPremiumAccount) {
    return <Redirect to={ROUTES.profile} />
  }

  return (
    <>
      <Container className={styles.wrapper}>
        <Title mb={subtitle ? 3 : 5}>{title}</Title>

        {subtitle}

        <Grid container justify="center">
          <Grid item xs={12} sm={6}>
            <PaymentPlanCard
              title="Payment"
              currentAdvantages={currentAdvantages}
              buttonText="Choose Package"
              isPremium
              onChangePackage={handleChangeSwitch}
              onClickBtn={handleClickPremium}
            />
          </Grid>
        </Grid>
      </Container>

      <ContainerFull
        className={styles.container}
        withContainer="xl"
        enableGutters
      >
        <Box className={styles.advantagesTitle}>
          <Title variant="h2">Package benefits</Title>
        </Box>

        <Grid container spacing={5} justify="space-around">
          {advantagesCards.map((advantage, idx) => (
            <Grid key={idx} item className={styles.advantageGrid}>
              <InfoCard {...advantage} />
            </Grid>
          ))}
        </Grid>

        <Flexbox justifyContent="center" flexWrap="wrap" mt={{ xs: 4, sm: 5 }}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            className={styles.button}
            onClick={handleClickPremium}
          >
            Choose Package
          </Button>
        </Flexbox>
      </ContainerFull>
    </>
  )
}

export default PackagesView
