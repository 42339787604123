import React from 'react'
import { Box, IconButton } from '@material-ui/core'

import { Flexbox, NavLink, Text, Logo } from '~/components/shared'

import ROUTES from '~/constants/routes'
import { socialLinks } from '../links.config'
import { FooterProps } from './Footer'

import { useMobileStyles } from './Footer.styles'

const FooterMobile: React.FC<FooterProps> = ({ navs }) => {
  const s = useMobileStyles()

  return (
    <footer className={s.root}>
      <Logo />

      <Flexbox className={s.linksWrapper}>
        <nav className={s.links}>
          {navs.map(({ label, to, outwardLink }) =>
            outwardLink ? (
              <NavLink
                key={to}
                component={(props): JSX.Element => <a {...props} />}
                href={to}
                target="_blank"
                rel="noopener noreferrer"
              >
                {label}
              </NavLink>
            ) : (
              <NavLink key={to} to={to}>
                {label}
              </NavLink>
            )
          )}
        </nav>

        <Flexbox className={s.btns}>
          {socialLinks.map(({ to, icon }, idx) => (
            <IconButton
              key={idx}
              component={(props): JSX.Element => <a {...props} />}
              href={to}
              target="_blank"
              rel="noopener"
            >
              {icon}
            </IconButton>
          ))}
        </Flexbox>
      </Flexbox>

      <Box className={s.copyrightWrapper}>
        <Flexbox justifyContent="center" mb={1.5}>
          <NavLink to={ROUTES.terms}>Terms of use</NavLink>
          <NavLink to={ROUTES.businessTerms}>Business Terms</NavLink>
          <NavLink to={ROUTES.privacy}>Privacy Policy</NavLink>
        </Flexbox>

        <Text textAlign="center">
          &copy; PlanMyKids {new Date().getFullYear()}. All rights are reserved
        </Text>
      </Box>
    </footer>
  )
}

export default FooterMobile
