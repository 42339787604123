/* eslint-disable react/no-unescaped-entities */
import React, { FC } from 'react'
import { Grid, Paper, Box, Link as MuiLink } from '@material-ui/core'
import cn from 'classnames'

import FullWidthMobile from '~/containers/FullWidthMobile'
import { Text, Title, Link } from '~/components/shared'

import ROUTES from '~/constants/routes'

import painting1 from '~/assets/images/home/title-painting-1.png'

import useStaticStyles from '~/styles/static-pages.styles'

const Terms: FC = () => {
  const s = useStaticStyles()

  return (
    <FullWidthMobile>
      <Box className={cn(s.container, 'withWidth')}>
        <Paper className={s.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Title
                variant="h1"
                paintingOptions={{
                  image: painting1,
                  customStyles: {
                    bottom: 3,
                    height: 6,
                    width: '100%'
                  }
                }}
                mb={2}
              >
                Terms of Service
              </Title>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary" fontStyle="italic">
                Last Updated: January 13, 2022
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary">
                Welcome to PlanMyKids, the website and online service of
                PlanMyKids, Inc. ( "PlanMyKids," "we", "us" or “our'' ). To
                start, we&apos;d like to let you know that this page explains
                and governs the terms by which you may use our online services,
                website, and software provided on or in connection with the
                service (collectively the "Service" or “Services”). Please read
                these terms carefully before accessing our Service as they
                constitute a legally binding contract between you and
                PlanMyKids. By accessing and using our Service, you are
                accepting these Terms of Service (“Terms”) as a customer (“you”,
                “your”) of the Service and agree to a legally binding agreement
                between you and PlanMyKids. We&apos;ve tried to make Terms
                simple and fair, but if you don&apos;t agree with them, we
                kindly ask that you please do not use our Service. We will miss
                you!
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Title textAlign="start" variant="h4">
                Changes to our Service
              </Title>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary">
                Our Service is grounded in our mission and guided by your needs.
                How we accomplish our mission may change over time. We realize
                that your needs are going to evolve as well and, therefore, so
                will our Service. Our Terms may need to be modified to reflect
                these changes and we reserve the right to do so. You will find
                any material modifications here and we&apos;ll indicate by date
                at the top of the page here when these changes occurred. By
                continuing to access or use our Service after the effective date
                of the modifications, you understand and agree to the changes.
                Onwards and upwards!
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Title textAlign="start" variant="h4">
                Privacy
              </Title>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary">
                We want to earn your trust and confidence which is why we value
                your privacy. In order to provide our Service in a meaningful
                way you will be asked to provide some personal information about
                yourself and your family. This information will be governed by
                our privacy policy which can be found{' '}
                <Link to={ROUTES.privacy}>here</Link>.
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Title textAlign="start" variant="h4">
                Use of Service
              </Title>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary" mb={1.5}>
                The Service allows you to receive a tailored itinerary of
                activities including, but not limited to, residential, day and
                virtual camps, afterschool programs, teams, groups, events and
                other child enrichment programs (''Program Activities”) offered
                by independent program providers (“Program Providers”) based on
                preferences requested and submitted by you. We would like to
                make clear that PlanMyKids is not an operator of Program
                Activities.
              </Text>
              <Text color="textSecondary" mb={1.5}>
                While we make every effort to ensure the information provided to
                you is accurate and up to date, you are ultimately responsible
                for checking your itinerary to make sure that suggested Program
                Activities and the Program Provider information (“Provider
                Content”) associated with them is accurate.
              </Text>
              <Text color="textSecondary" mb={1.5}>
                The information you provide to us when using the Service is
                considered your content (“Customer Content”). We make no claim
                of ownership rights over Customer Content. However, by providing
                this Customer Content you agree to allow us to use this Customer
                Content in order to provide the Service.
              </Text>
              <Text color="textSecondary" mb={1.5}>
                The Service allows, at your direction, the ability to share your
                itinerary and other CustomerContent; such as comments, reviews,
                activity interests, dates of interest, name and email address
                with others for planning purposes. This makes the Service more
                awesome, but if we determine that you're using the Service in a
                manner that we consider objectionable, in violation of these
                Terms or harmful to the Service, we reserve the right to put our
                foot down at any time and, without prior notice, to remove
                Customer Content. So be good.
              </Text>
              <Text color="textSecondary">
                Since there is Provider Content and Customer Content, we thought
                it only fair that PlanMyKids have content (“PlanMyKids
                Content”). This includes any information created solely by us
                for use of the Service on our website, resource blog,
                newsletters and other communications.
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Title textAlign="start" variant="h4">
                Eligibility
              </Title>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary">
                To use our Service you represent that you are at least 18 years
                old or of legal age in your jurisdiction (if different than 18)
                to form a binding contract or you have received permission from
                a parent or legal guardian who agrees to these Terms. Any use of
                our Service by anyone under 13 years of age is strictly
                prohibited and in violation of these Terms. If you&apos;re under
                13 years of age, stop now and go tell your parents about us.{' '}
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Title textAlign="start" variant="h4">
                Account Creation
              </Title>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary" mb={1.5}>
                In order to use certain features of our Service, you will be
                asked to create a free account (“Free Account”) or paid account
                (“Paid Account”). While giving you access to some awesome
                features, it comes with great responsibilities. For instance,
                you are responsible for maintaining the confidentiality of your
                account password. Don&apos;t get duped; we recommend using
                “strong” passwords. You are also responsible for all activities
                that occur in connection with your account. If you&apos;re
                suspicious someone is using your account, you agree to notify us
                immediately of this unauthorized use of your account. We hope we
                never have to, but, if we determine that you're using the
                Service in a manner that we consider objectionable, in violation
                of these Terms or harmful to the Service in our sole discretion,
                we reserve the right to close your account at any time and
                without notice. Your account is for your personal,
                non-commercial use only. In creating it, we ask that you provide
                complete and accurate information about yourself to bolster your
                credibility as a contributor of Customer Content. You may not be
                a poser or impersonate someone else (e.g., adopt the identity of
                a celebrity or your next-door neighbor), create or use an
                account for anyone other than yourself, provide an email address
                other than your own, or create multiple accounts. In other
                words; just be you. If you create an account using a third-party
                service, you give us permission to access and use your
                information from that service as permitted by that service, and
                to store your log-in credentials for that service.
              </Text>
              <Text color="textSecondary">
                By providing your email address you consent to our using your
                email address to send you Service-related notices, content and
                offers. You may choose to opt out of these communications. But
                be aware, opting out may prevent you from receiving important
                communications about Service-related notices, content and offers
                which could be sad.
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Title textAlign="start" variant="h4">
                Payment
              </Title>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary" variant="subtitle1">
                Service Fees
              </Text>
              <Text color="textSecondary">
                If you choose to sign-up or upgrade to a Paid Account, you
                understand that will be charged an applicable fee (minus any
                discounts) (the “Fee” or “Fees”) described on our pricing{' '}
                <Link to={ROUTES.packages}>packages</Link> page and based on the
                particular subscription you choose. The subscription period for
                Paid Accounts can be month-to-month or yearly. All Fees,
                including fees incurred for renewal, are non-refundable, except
                at our sole discretion and in accordance with the Terms.
                PlanMyKids reserves the right to establish, remove and/or revise
                Fees for any or all services obtained through the use of the
                Services at any time in PlanMyKid&apos;s sole discretion.
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary" variant="subtitle1">
                Renewal and Cancellation
              </Text>
              <Text color="textSecondary">
                Paid Accounts will automatically renew for the same subscription
                period unless you cancel the account by the end of the
                then-current subscription period. You can cancel your account at
                any time by contacting us at{' '}
                <MuiLink
                  color="secondary"
                  href="mailto:help@planmykids.com"
                  style={{ fontWeight: 700 }}
                >
                  help@planmykids.com
                </MuiLink>
                . Cancellation will be effective immediately. Please note that
                after you cancel your account, you may not be able to use or
                access the Service any longer.
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary" variant="subtitle1">
                Payment Provider
              </Text>
              <Text color="textSecondary">
                We use a third-party payment provider to process payments
                (“Payment Provider”). By submitting your payment information to
                or through a Payment Provider, you authorize us to charge the
                applicable Payment Method for your payment and any related fees
                or charges. You represent that you will not use any credit card
                or other form of payment unless you have all necessary
                authorization to do so. Your financial institution agreement
                governs your use of the designated Payment Method.
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary" variant="subtitle1">
                Payment Methods
              </Text>
              <Text color="textSecondary">
                When you provide a payment method or add a payment method to
                your Paid Account, you will be asked to provide billing
                information such as name, billing address, and financial
                institution information either to PlanMyKids or our Payment
                Provider. You authorize PlanMyKids and/or our Payment Provider
                to collect and store your Payment Method information and you
                agree to keep it current. You agree to pay all charges incurred
                by users of your credit card or other form of payment method
                used in connection with a purchase or transaction or other
                monetary transaction interaction with the Service at the prices
                in effect when such charges are incurred. You agree that you
                will pay any applicable taxes, if any, relating to any such
                purchases, transactions or other monetary transaction
                interactions.
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary" variant="subtitle1">
                Payments to Program Providers
              </Text>
              <Text color="textSecondary">
                Your interactions with Program Providers found through our
                Service, including payment and delivery of goods or services,
                and any other terms, condition, warranties or representation
                associated with such dealings, are solely between you and the
                Program Providers; even when those interactions are performed
                with your authorization by PlanMyKids on your behalf. You agree
                that PlanMyKids shall not be responsible or liable for any loss
                or damage of any sort incurred as a result of any such dealings.
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Title textAlign="start" variant="h4">
                Suggestions and Improvements
              </Title>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary">
                We want to hear from you. By sending us any ideas, suggestions,
                documents or proposals (“Feedback”), you agree that (i) your
                Feedback does not contain the confidential or proprietary
                information of third parties, (ii) we are under no obligation of
                confidentiality, express or implied, with respect to the
                Feedback, (iii) we may have something similar to the Feedback
                already under consideration or in development, and (iv) you
                grant us an irrevocable, non-exclusive, royalty-free, perpetual,
                worldwide license to use, modify, prepare derivative works,
                publish, sell, distribute and sublicense the Feedback, and you
                irrevocably waive, and cause to be waived, against PlanMyKids
                and its users any claims and assertions of any moral rights
                contained in such Feedback.
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Title textAlign="start" variant="h4">
                Things Your Parents Would Say:
              </Title>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary">
                You are prohibited from contributing any Customer Content, using
                the Service or interacting with the Service for any unlawful
                purpose or to solicit others to perform or participate in any
                unlawful acts. You agree NOT TO do or assist anyone else in
                doing the following:
              </Text>
              <Text color="textSecondary" pl={2}>
                I. Violate any international, federal, provincial or state
                regulations, rules, laws or local ordinances;
              </Text>
              <Text color="textSecondary" pl={2}>
                II. Violate the Terms
              </Text>
              <Text color="textSecondary" pl={2}>
                III. Infringe upon or violate our intellectual property rights
                or the intellectual property rights of others;
              </Text>
              <Text color="textSecondary" pl={2}>
                IV. Harass, abuse insult, harm, defame, slander, disparage,
                intimidate, or discriminate based on gender, sexual orientation,
                religion, ethnicity, race, age, national origin or disability;
              </Text>
              <Text color="textSecondary" pl={2}>
                V. Submit false or misleading information
              </Text>
              <Text color="textSecondary" pl={2}>
                VI. Upload or transmit viruses or other type of malicious code
                that will or may be used in any way that will affect the
                functionality or operation of the Service or of any related
                website, other websites, or the Internet;
              </Text>
              <Text color="textSecondary" pl={2}>
                VII. Use any device, software or routine that interferes with
                the proper working of the Service, or otherwise attempt to
                interfere with the proper working of the Service;
              </Text>
              <Text color="textSecondary" pl={2}>
                VIII. Reverse engineer any portion of the Service;
              </Text>
              <Text color="textSecondary" pl={2}>
                IX. Collect or track the personal information of others;
              </Text>
              <Text color="textSecondary" pl={2}>
                X. Spam, phish, pharm, pretext, spider, crawl or scrape the
                Service;
              </Text>
              <Text color="textSecondary" pl={2}>
                XI. Use for any obscene or immoral purpose;
              </Text>
              <Text color="textSecondary" pl={2}>
                XII. Interfere with or circumvent the security features of the
                Service or any related website, other websites, or the Internet;
              </Text>
              <Text color="textSecondary" pl={2} mb={1.5}>
                XIII. Use the service for any commercial solicitation purposes
              </Text>

              <Text color="textSecondary">
                These actions deserve a time out and we reserve the right to
                terminate your use of the Service for violating any of the
                prohibited uses in our sole and complete discretion.
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Title textAlign="start" variant="h4">
                Things Our Lawyers Would Yell:
              </Title>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary" variant="subtitle1">
                Warranty Disclaimer
              </Text>
              <Text color="textSecondary" mb={1.5}>
                THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS.
                USE OF THE SERVICE IS AT YOUR OWN RISK. TO THE MAXIMUM EXTENT
                PERMITTED BY APPLICABLE LAW, THE SERVICE IS PROVIDED WITHOUT
                WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING,
                BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY,
                FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. WITHOUT
                LIMITING THE FOREGOING, PLANMYKIDS, ITS SUBSIDIARIES, ITS
                AFFILIATES, AND ITS LICENSORS DO NOT WARRANT ANY OF THE
                FOLLOWING: (i) THAT THE CONTENT IS ACCURATE, RELIABLE OR
                CORRECT; (ii) THAT THE SERVICE WILL MEET YOUR REQUIREMENTS;
                (iii) THAT THE SERVICE WILL BE AVAILABLE AT ANY PARTICULAR TIME
                OR LOCATION, UNINTERRUPTED OR SECURE; (iv) THAT ANY DEFECTS OR
                ERRORS WILL BE CORRECTED; OR (v) THAT THE SERVICE IS FREE OF
                VIRUSES OR OTHER HARMFUL COMPONENTS.{' '}
              </Text>
              <Text color="textSecondary" mb={1.5}>
                ANY CONTENT DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF
                THE SERVICE IS DOWNLOADED AT YOUR OWN RISK AND YOU WILL BE
                SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR
                MOBILE DEVICE OR LOSS OF DATA THAT RESULTS FROM SUCH DOWNLOAD OR
                YOUR USE OF THE SERVICE. PLANMYKIDS DOES NOT WARRANT, ENDORSE,
                GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE
                ADVERTISED OR OFFERED BY A THIRD-PARTY THROUGH THE SERVICE OR
                ANY HYPERLINKED WEBSITE OR SERVICE, AND PLANMYKIDS WILL NOT BE A
                PARTY TO OR IN ANY WAY MONITOR ANY TRANSACTION BETWEEN YOU AND
                THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. FEDERAL LAW, SOME
                STATES, PROVINCES AND OTHER JURISDICTIONS DO NOT ALLOW
                EXCLUSIONS AND LIMITATIONS OF CERTAIN IMPLIED WARRANTIES, SO
                SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
              </Text>

              <Text color="textSecondary" variant="subtitle1">
                Limitation of Liability
              </Text>
              <Text color="textSecondary" mb={1.5}>
                IN NO CASE SHALL PLANMYKIDS, INC., OUR DIRECTORS, OFFICERS,
                EMPLOYEES, AFFILIATES, AGENTS, CONTRACTORS, INTERNS, SUPPLIERS,
                SERVICE PROVIDERS OR LICENSORS BE LIABLE FOR ANY INJURY, LOSS,
                CLAIM, OR ANY DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, SPECIAL,
                OR CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING, WITHOUT
                LIMITATION LOST PROFITS, LOST REVENUE, LOST SAVINGS, LOSS OF
                DATA, REPLACEMENT COSTS, OR ANY SIMILAR DAMAGES, WHETHER BASED
                IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR
                OTHERWISE, ARISING FROM YOUR USE OF ANY OF THE SERVICE OR
                PRODUCTS PROCURED USING THE SERVICE, OR FOR ANY OTHER CLAIM
                RELATED TO IN ANY WAY TO YOUR USE OF THE SERVICE OR ANY PRODUCT,
                INCLUDING, BUT NOT LIMITED TO, ANY ERRORS OR OMISSIONS IN
                CONTENT, OR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT
                OF THE USE OF THE SERVICE OR ANY CONTENT (OR PRODUCT) POSTED,
                TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICE, EVEN
                IF ADVISED OF THEIR POSSIBILITY. FURTHERMORE YOU AGREE TO
                RELEASE AND HOLD HARMLESS PLANMYKIDS FROM ANY CLAIM OR
                CONTROVERSY THAT MAY ARISE BETWEEN THE ACTIONS OR INACTIONS OF,
                OR THE RELATIONSHIP BETWEEN YOU AND ANY ACTIVITY PROVIDERS. IN
                NO EVENT SHALL PLANMYKID&apos;S AGGREGATE LIABILITY ARISING OUT
                OF OR RELATED TO THIS AGREEMENT, WHETHER ARISING OUT OF OR
                RELATED TO BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR
                OTHERWISE, EXCEED THE AGGREGATE AMOUNTS PAID TO COMPANY IN THE
                SIX MONTH PERIOD PRECEDING THE EVENT GIVING RISE TO THE CLAIM.
                BECAUSE SOME STATES OR JURISDICTIONS DO NOT ALL THE EXCLUSION OF
                THE LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL
                DAMAGES, IN SUCH STATES OR JURISDICTIONS, OUR LIABILITY SHALL BE
                LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW.
              </Text>

              <Text color="textSecondary" variant="subtitle1">
                Third party links
              </Text>
              <Text color="textSecondary" mb={1.5}>
                Certain content, products and services available via our Service
                may include materials from third-parties. Third-party links on
                this site may direct you to third-party websites that are not
                affiliated with us. We provide these third-party links for your
                convenience but are not responsible for examining or evaluating
                the content or accuracy and we do not warrant and will not have
                any liability or responsibility for any third-party materials or
                website, or for any other materials, products, or services of
                third-parties. We are not liable for any harm or damages related
                to the purchase or use of goods, services, resources, content,
                or any other transactions made in connection with any
                third-party websites. Please review carefully the
                third-party&apos;s policies and practices and make sure you
                understand them before you engage in any transaction.
                Complaints, claims, concerns, or questions regarding third-party
                products should be direct to the applicable third-party.{' '}
              </Text>

              <Text color="textSecondary" variant="subtitle1">
                Indemnification
              </Text>
              <Text color="textSecondary" mb={1.5}>
                YOU AGREE TO INDEMNIFY, DEFEND, AND HOLD PLANMYKIDS INC., ITS
                PARENTS, SUBSIDIARIES, AFFILIATES, ANY RELATED COMPANIES,
                SUPPLIERS, LICENSORS AND PARTNERS, AND THE OFFICERS, DIRECTORS,
                EMPLOYEES, AGENTS AND REPRESENTATIVES OF EACH OF THEM HARMLESS,
                INCLUDING COSTS, LIABILITIES AND LEGAL FEES, FROM ANY CLAIM OR
                DEMAND MADE BY ANY THIRD PARTY ARISING OUT OF OR RELATING TO (I)
                YOUR ACCESS TO OR USE OF THE SERVICE, (II) YOUR VIOLATION OF THE
                TERMS, (III) ANY PRODUCTS OR SERVICES PURCHASED OR OBTAINED BY
                YOU IN CONNECTION WITH THE SERVICE, OR (IV) THE INFRINGEMENT BY
                YOU, OR ANY THIRD PARTY USING YOUR ACCOUNT, OF ANY INTELLECTUAL
                PROPERTY OR OTHER RIGHT OF ANY PERSON OR ENTITY. PLANMYKIDS WILL
                USE REASONABLE EFFORTS TO NOTIFY YOU OF ANY SUCH CLAIM, ACTION
                OR PROCEEDING UPON BECOMING AWARE OF IT.
              </Text>

              <Text color="textSecondary">Are your ears ringing?</Text>
            </Grid>

            <Grid item xs={12}>
              <Title textAlign="start" variant="h4">
                Governing Law
              </Title>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary">
                These terms, and all claims related to or arising out of this
                contract, or the breach thereof, whether sounding in contract,
                tort, or otherwise, shall be governed by the laws of the State
                of Delaware, including Delaware&apos;s statute of limitations
                governing your claim, without giving effect to its principles of
                conflicts of law.
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Title textAlign="start" variant="h4">
                Contact Us
              </Title>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary">
                Please contact us as{' '}
                <MuiLink
                  color="secondary"
                  href="mailto:help@planmykids.com"
                  style={{ fontWeight: 700 }}
                >
                  help@planmykids.com
                </MuiLink>{' '}
                with any questions regarding these Terms, our privacy policy, or
                for weather updates.
              </Text>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </FullWidthMobile>
  )
}

export default Terms
