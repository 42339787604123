import moment from 'moment'

import palette from '~/theme/palette'

export const generateItinerariesWithColor = (
  data: Itinerary[]
): Itinerary[] => {
  return data?.reduce((acc: Itinerary[], itinerary) => {
    if (!acc.some(({ color }) => color === palette.success.main)) {
      return [
        ...acc,
        {
          ...itinerary,
          color: palette.success.main
        }
      ]
    } else if (!acc.some(({ color }) => color === palette.success.dark)) {
      return [
        ...acc,
        {
          ...itinerary,
          color: palette.success.dark
        }
      ]
    } else {
      return [
        ...acc,
        {
          ...itinerary,
          color: '#' + Math.floor(Math.random() * 16777215).toString(16)
        }
      ]
    }
  }, [])
}

export const humanizeCalendarDate = (date: string): string => {
  return moment(date).add(1, 'd').format('YYYY-MM-DD')
}
