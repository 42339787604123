const campTypesOptions: Option[] = [
  {
    value: 'Day',
    label: 'Day'
  },
  {
    value: 'Overnight',
    label: 'Overnight'
  },
  {
    value: 'Track-Out',
    label: 'Track-Out'
  },
  {
    value: 'Afterschool',
    label: 'Afterschool'
  },
  {
    value: 'Classes',
    label: 'Classes'
  },
  {
    value: 'Virtual',
    label: 'Virtual'
  },
  {
    value: 'Night-Out',
    label: 'Night-Out'
  }
]

export default campTypesOptions
