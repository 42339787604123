import { makeStyles, createStyles } from '@material-ui/core'

const useDashboardTabsStyles = makeStyles(
  (theme) =>
    createStyles({
      tabs: {
        position: 'relative',

        '&:after': {
          content: '""',
          position: 'absolute',
          background: theme.palette.text.disabled,
          bottom: '0',
          left: '0',
          height: 1,
          width: '100%'
        }
      },

      tabsContainer: {
        justifyContent: 'space-between'
      },

      indicator: {
        zIndex: 1
      },

      tab: {
        minWidth: 'auto',
        padding: theme.spacing(1),
        fontSize: 16,
        fontWeight: 600,
        textTransform: 'initial',

        '&:not(:last-child)': {
          marginRight: theme.spacing(3)
        }
      },

      selected: {
        color: theme.palette.text.primary
      }
    }),
  {
    name: 'DashboardTabs'
  }
)

export default useDashboardTabsStyles
