import React, { FC, useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Button, Grid } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { Flexbox, Loader, Text } from '~/components/shared'
import ChildrensProfileForm from './ChildrenProfileForm'

import { enoughtQuestionnaire, isPremiumPaid } from '~/state/modules/user'

import { AppState } from '~/state/store'

import { STATUSES } from '~/constants/statusesItinerary'
import Premium from '~/containers/Premium'
import ROUTES from '~/constants/routes'
import { getQuestionnaires } from '~/state/modules/questionnaires'

type ReduxProps = {
  data: Itinerary[]
  loading: boolean
  loaded: boolean
  limitQuestionnaire: boolean
}

type AllProps = ReduxProps

const ChildrensProfile: FC<AllProps> = ({
  loading,
  loaded,
  data,
  limitQuestionnaire
}) => {
  const dispatch = useDispatch()
  const isPremium = useSelector(isPremiumPaid)
  const isBlockNewChild = !isPremium || limitQuestionnaire

  useEffect(() => {
    dispatch(getQuestionnaires())
  }, [])

  return (
    <>
      <Flexbox
        alignItems="center"
        justifyContent="space-between"
        mt={5}
        mb={2}
        width="100%"
      >
        <Text fontWeight={600}>Children</Text>

        <Premium
          customBlock={{
            isBlock: isBlockNewChild,
            useBlock: true
          }}
        >
          <Button
            component={Link}
            to={ROUTES.questionnaire}
            color="primary"
            variant="outlined"
            size="small"
          >
            Start new child plan
          </Button>
        </Premium>
      </Flexbox>

      {loading ? (
        <Loader />
      ) : data?.length && loaded ? (
        <Grid container spacing={2}>
          {data.map(({ questionnaire: { id, fields }, ...itinarary }) => {
            const isDisabled = [STATUSES.approved, STATUSES.canceled].some(
              (status) => status === itinarary?.status
            )

            return (
              <Grid item xs={12} key={id}>
                <ChildrensProfileForm
                  id={id}
                  fields={fields}
                  disabled={isDisabled}
                  itineraryId={itinarary?.id}
                />
              </Grid>
            )
          })}
        </Grid>
      ) : (
        <Text textAlign="center" py={3}>
          No added children&apos;s yet
        </Text>
      )}
    </>
  )
}

const mapStateToProps = (state: AppState): ReduxProps => {
  const limitQuestionnaire = enoughtQuestionnaire(state)

  const { loading, loaded, data } = state.questionnaires

  return {
    loading,
    loaded,
    data,
    limitQuestionnaire
  }
}

export default connect(mapStateToProps)(ChildrensProfile)
