import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getChatbotMessages } from '.'

const initialState: ChatbotItinerariesState = {
  messages: [],
  loading: false,
  loaded: false,
  error: null
}

const chatbotItinerariesSlice = createSlice({
  name: 'chatbotMessages',
  initialState,
  reducers: {
    mergeMessages: (
      state,
      { payload }: PayloadAction<ChatbotMessageModel>
    ): void => {
      const oldData = state.messages

      state.messages = oldData.length ? [...oldData, payload] : [payload]
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getChatbotMessages.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getChatbotMessages.fulfilled, (state, { payload }) => {
      state.loading = false
      state.loaded = true
      state.messages = payload
    })
    builder.addCase(getChatbotMessages.rejected, (state) => {
      state.loading = false
      state.error = true
    })
  }
})

export const { mergeMessages } = chatbotItinerariesSlice.actions

export default chatbotItinerariesSlice.reducer
