import PMK from '~/services/PMK'
import Deserializer from '~/services/Deserializer'
import ErrorHandler from '~/utils/errorHandler'

import { AppThunk } from '~/state/store'

import { request, success } from './calendar.slice'
import { isShare } from '../user'

// Actions

/**
 * Get parent itineraries action
 */
export const getCalendarItineraries = (): AppThunk<Promise<void>> => async (
  dispatch,
  getState
): Promise<void> => {
  const withFriends = isShare(getState())
  const userId = getState().user.id

  let friends = []

  dispatch(request())

  try {
    const res = await PMK.getParentItineraries()
    const parents = await Deserializer.deserialize(res)

    if (withFriends) {
      const res = await PMK.getFriendsItineraries()
      const payload = await Deserializer.deserialize(res)

      const promises = payload.map(async (itinerary: Itinerary) => {
        const parentItinerary = itinerary?.parents_itineraries?.find(
          ({ parent_id }) => parent_id === Number(userId)
        )

        const parentActivities = await Deserializer.deserialize(
          parentItinerary?.activities
        )

        return {
          ...itinerary,
          activities: parentActivities
        }
      })

      const itinerariesData: Itinerary[] = await Promise.all(promises)

      friends = itinerariesData
    }

    dispatch(
      success({
        parents,
        friends,
        userId
      })
    )
  } catch (error) {
    throw new ErrorHandler(error)
  }
}
