/* eslint-disable react/no-unescaped-entities */
import React, { FC } from 'react'
import { Grid, Paper, Box, Link as MuiLink } from '@material-ui/core'
import cn from 'classnames'

import FullWidthMobile from '~/containers/FullWidthMobile'
import { Text, Title, BlankLink } from '~/components/shared'

import painting1 from '~/assets/images/home/title-painting-1.png'

import useStaticStyles from '~/styles/static-pages.styles'

const PrivacyPolicy: FC = () => {
  const s = useStaticStyles()

  return (
    <FullWidthMobile>
      <Box className={cn(s.container, 'withWidth')}>
        <Paper className={s.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Title
                variant="h1"
                paintingOptions={{
                  image: painting1,
                  customStyles: {
                    bottom: 3,
                    height: 6,
                    width: '100%'
                  }
                }}
                mb={2}
              >
                PlanMyKids Privacy Policy
              </Title>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary" fontStyle="italic">
                Last Updated: January 13, 2022
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary">
                PlanMyKids, Inc. (the “Company”) is committed to maintaining
                robust privacy protections for its users. Our Privacy Policy
                (“Privacy Policy”) is designed to help you understand how we
                collect, use and safeguard the information you provide to us and
                to assist you in making informed decisions when using our
                Service. For purposes of this Agreement, “Site” refers to the
                Company&apos;s website, which can be accessed at
                www.planmykids.com. “Service” refers to the Company&apos;s
                services accessed via the Site. The terms “we,” “us,” and “our”
                refer to the Company. “You” refers to you, as a user of our Site
                or our Service. By accessing our Site or our Service, you accept
                our Privacy Policy and you consent to our collection, storage,
                use and disclosure of your Personal Information as described in
                this Privacy Policy.
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Title textAlign="start" variant="h4">
                The Skinny
              </Title>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary" mb={1.5}>
                Privacy policies can be long in the tooth. This skinny section
                provides you with an overview of our privacy policy in
                layman&apos;s terms to understand our intent. In order for us to
                interact with you to provide some form of value and to improve
                on how we provide that value, we collect information that helps
                us identify who you are in the form of personal information,
                such as your name and email. With some technical wizardry, we
                also collect non-personal information, such as geographical
                location or what browser you use, that identifies you as a user
                but indistinguishable from other users when interacting with us.
                We store this information scrambled up so that it is unreadable
                in a digital drawer that is locked. Only authorized people with
                the keys can unlock and unscramble this information. To help
                know when you interact with us on multiple occasions we may use
                a commonly used method to store a small crumb of data on your
                computer known as a cookie. We sometimes have to share
                information with other companies whose services we use to help
                serve you. In these cases we sometimes share your personal
                information and vice versa with them, for example in the case of
                creating an account using a social media account. We respect
                your privacy and you are in control of it. If you want us to
                delete your information you can contact us and we will fulfill
                your request. Please continue to read the remainder of this
                policy for the details.
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Title textAlign="start" variant="h4">
                I. HOW WE COLLECT INFORMATION
              </Title>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary" mb={1.5}>
                The primary ways in which we collect information are as follows:
              </Text>

              <Text color="textSecondary" mb={1.5}>
                (i.) User Submitted - Information you submit to us is collected
                when you complete a form, a survey, a questionnaire, or
                otherwise enter information in fields while using our Service.{' '}
              </Text>

              <Text color="textSecondary" mb={1.5}>
                (ii.) Cookies - Cookies do not harm your access device and do
                not contain viruses. Cookies serve to make our Service more
                user-friendly, effective and secure. Cookies are small text
                files that are stored on your access device and stored by your
                browser. The Site and Service use both persistent and session
                cookies; persistent cookies remain on your computer after you
                close your session and until you delete them, while session
                cookies expire when you close your browser. For more information
                about cookies, and how to disable cookies, visit{' '}
                <BlankLink href="http://www.allaboutcookies.org">
                  allaboutcookies.org
                </BlankLink>
              </Text>

              <Text color="textSecondary" mb={1.5}>
                (iii.) Log files - The service automatically collects and stores
                information in so-called server log files, which your browser
                automatically transmits to us.{' '}
              </Text>

              <Text color="textSecondary" mb={1.5}>
                (iv.) Web beacons, tags, pixels - Web beacons, tags and pixels
                are text strings included in the information served to your web
                browser that communicate with services to track user navigation.{' '}
              </Text>

              <Text color="textSecondary" mb={1.5}>
                (v) 3rd Party Login Services - Instead of signing up directly on
                our Site, you may be able to sign up with a preferred 3rd Party
                Login Service. Our Service utilizes Facebook Connect provided by
                Facebook Inc. and Google Connect provided by Google LLC. If you
                decide to sign-up using a 3rd Party Login Service by clicking on
                the "Login with" button, you will automatically be redirected to
                the 3rd Party Login Service. There you can log in with your
                usage data. This links your 3rd Party Login Service profile to
                our Service. This gives us access to certain Personal
                Information from the 3rd Party Login Service including contact
                information(e.g. Name, email address). If you do not wish to
                have this information shared, please don&apos;t use the 3rd
                Party Login Services.{' '}
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Title textAlign="start" variant="h4" mb={1.5}>
                II. INFORMATION WE COLLECT
              </Title>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary" mb={1.5}>
                We collect “Non-Personal Information” and “Personal
                Information.”
              </Text>

              <Text color="textSecondary" variant="subtitle1">
                Personal Information:
              </Text>

              <Text color="textSecondary" mb={1.5}>
                Personal Information includes your email, name, address, phone
                number, which you submit to us when signing up for our
                newsletter or to create an account/register with our Service.
                Except as otherwise stated in this Privacy Policy, we do not
                sell, trade, rent or otherwise share for marketing purposes your
                Personal Information with third parties without your consent. We
                do share Personal Information with vendors who are performing
                services for us as described herein. We may share Personal
                Information with outside parties if we have a good-faith belief
                that access, use, preservation or disclosure of the information
                is reasonably necessary to meet any applicable legal process or
                enforceable governmental request; to enforce applicable Terms of
                Service, including investigation of potential violations;
                address fraud, security or technical concerns; or to protect
                against harm to the rights, property, or safety of our users or
                the public as required or permitted by law. In the event we
                undergo a business transaction such as a merger, acquisition by
                another company, or sale of all or a portion of our assets, your
                Personal Information may be among the assets transferred. You
                acknowledge and consent that such transfers may occur and are
                permitted by this Privacy Policy, and that any acquirer of our
                assets may continue to process your Personal Information as set
                forth in this Privacy Policy.
              </Text>

              <Text color="textSecondary" variant="subtitle1">
                Non-Personal Information
              </Text>
              <Text color="textSecondary" mb={1.5}>
                Non-Personal Information includes information that cannot be
                used to personally identify you, such as anonymous usage data,
                general demographic information we may collect, referring/exit
                pages and URLs, platform types, preferences you submit and
                preferences that are generated based on the data you submit and
                number of clicks. In general, we use Non-Personal Information to
                help us improve the Service and customize the user experience.
                We also aggregate Non-Personal Information in order to track
                trends and analyze use patterns on the Site. This Privacy Policy
                does not limit in any way our use or disclosure of Non-Personal
                Information and we reserve the right to use and disclose such
                Non-Personal Information to our partners, advertisers and other
                third parties at our discretion.
              </Text>

              <Text color="textSecondary" variant="subtitle1">
                Children&apos;s privacy
              </Text>
              <Text color="textSecondary" mb={1.5}>
                The Site and the Service are not directed to anyone under the
                age of 13. The Site does not knowingly collect or solicit
                information from anyone under the age of 13, or allow anyone
                under the age of 13 to sign up for the Service. In the event
                that we learn that we have gathered personal information from
                anyone under the age of 13 without the consent of a parent or
                guardian, we will delete that information as soon as possible.
                If you believe we have collected such information, please
                contact us at{' '}
                <MuiLink
                  color="secondary"
                  href="mailto:help@planmykids.com"
                  style={{ fontWeight: 700 }}
                >
                  help@planmykids.com
                </MuiLink>
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Title textAlign="start" variant="h4">
                III. HOW WE USE INFORMATION
              </Title>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary" mb={1.5}>
                Facebook analytics - Our Site uses Facebook pixel by Facebook,
                Inc. for conversion measurement of Facebook Ads. Site visitors
                can be aggregated together anonymously after they have been
                redirected from a Facebook ad.
              </Text>
              <Text color="textSecondary" mb={1.5}>
                HotJar - Hotjar is an analytics service provided by Hotjar Ltd
                used by us to anonymously record interactions with our Service.
                Hotjar honours generic Do Not Track headers. This means your
                browser can tell its script not to collect any of your data.
                This is a setting that is available in all major browsers. Find
                Hotjar&apos;s opt-out information{' '}
                <BlankLink href="https://www.hotjar.com/policies/do-not-track/">
                  here
                </BlankLink>
              </Text>
              <Text color="textSecondary" mb={1.5}>
                Google Ads - Our Site uses Google Ads by Google LLC for online
                advertising. We use conversion tracking through use of a cookie
                to measure the performance of this advertising. Site visitors
                are aggregated together anonymously after they have been
                redirected from a Google Ad.
              </Text>
              <Text color="textSecondary" mb={1.5}>
                Google Analytics - Our site uses functions of the web analytics
                service Google Analytics by Google LLC for the purpose of
                needs-based design and continuous optimization. Google Analytics
                uses cookies to allow an analysis of the use of the Service by
                you. If you wish to opt-out of Google Analytics you can install
                a{' '}
                <BlankLink href="https://tools.google.com/dlpage/gaoptout?hl=en">
                  browser add-on
                </BlankLink>{' '}
                to disable it.
              </Text>
              <Text color="textSecondary" mb={1.5}>
                Stripe - When you make payments for our services, no credit or
                debit card information is stored on our servers. This
                information is stored by our third party PCI-compliant payment
                processing company, Stripe Inc. Information you submit is sent
                to Stripe in order to complete your transactions. We are
                provided a unique identifier of that transaction or “token” that
                allows us to make future transactions using the information you
                submitted and stored with them.
              </Text>
              <Text color="textSecondary" mb={1.5}>
                Zoho Campaign - We use Zoho Campaign by Zoho Corporation to send
                newsletters, service announcements and marketing information to
                you. If you sign up for our newsletter or our Service, user
                submitted information is stored with Zoho Campaign. Zoho
                Campaign uses web beacons to assist with measuring the
                performance of the service. You can opt-out of receiving these
                using the unsubscribe link included in these communications.
              </Text>
              <Text color="textSecondary" mb={1.5}>
                Zoho Survey - We use Zoho Survey by Zoho Corporation to collect
                user thoughts, opinions and feedback. Zoho Survey uses cookies
                and sometimes User submitted Information, such as email address,
                when it collects responses.
              </Text>
              <Text color="textSecondary" mb={1.5}>
                Zoho CRM - We use Zoho CRM by Zoho Corporation for sales
                automation and maintain a relationship with our users. When you
                sign up for a newsletter or our Service, information submitted
                by you is stored in Zoho CRM. If you would like information you
                submitted to be removed, please send an email to{' '}
                <MuiLink
                  color="secondary"
                  href="mailto:help@planmykids.com"
                  style={{ fontWeight: 700 }}
                >
                  help@planmykids.com
                </MuiLink>
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Title textAlign="start" variant="h4">
                IV. HOW WE PROTECT INFORMATION
              </Title>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary">
                We implement security measures designed to protect your
                information from unauthorized access. We further protect your
                information from potential security breaches by implementing
                certain technological security measures including encryption,
                firewalls and secure socket layer technology. However, these
                measures do not guarantee that your information will not be
                accessed, disclosed, altered or destroyed by breach of such
                firewalls and secure server software. By using our Service, you
                acknowledge that you understand and agree to assume these risks.
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Title textAlign="start" variant="h4">
                V. YOUR RIGHTS REGARDING THE USE OF PERSONAL INFORMATION
              </Title>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary">
                You have the right at any time to prevent us from contacting you
                for marketing purposes. When we send a promotional communication
                to a user, the user can opt out of further promotional
                communications by following the unsubscribe instructions
                provided in each promotional e-mail. Please note that
                notwithstanding the promotional preferences you indicate by
                unsubscribing, we may continue to send you administrative emails
                including, for example, periodic updates to our Privacy Policy.
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Title textAlign="start" variant="h4">
                VI. LINKS TO OTHER WEBSITES
              </Title>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary">
                As part of the Service, we may provide links to or compatibility
                with other websites or applications. However, we are not
                responsible for the privacy practices employed by those websites
                or the information or content they contain. This Privacy Policy
                applies solely to information collected by us through the Site
                and the Service. Therefore, this Privacy Policy does not apply
                to your use of a third party website accessed by selecting a
                link on our Site or via our Service. To the extent that you
                access or use the Service through or on another website or
                application, then the privacy policy of that other website or
                application will apply to your access or use of that site or
                application. We encourage our users to read the privacy
                statements of other websites before proceeding to use them.
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Title textAlign="start" variant="h4">
                VII. CHANGES TO OUR PRIVACY POLICY
              </Title>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary">
                We reserve the right to change this policy at any time. We will
                notify you of significant changes to our Privacy Policy by
                sending a notice to the primary email address specified in your
                account or by placing a prominent notice on our site.
                Significant changes will go into effect 30 days following such
                notification. Non-material changes or clarifications will take
                effect immediately. You should periodically check the Site and
                this privacy page for updates.
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Title textAlign="start" variant="h4">
                VIII. CONTACT US
              </Title>
            </Grid>

            <Grid item xs={12}>
              <Text color="textSecondary">
                If you have any questions regarding this Privacy Policy or the
                practices of this Site, please contact us by sending an email to{' '}
                <MuiLink
                  color="secondary"
                  href="mailto:help@planmykids.com"
                  style={{ fontWeight: 700 }}
                >
                  help@planmykids.com
                </MuiLink>{' '}
                .
              </Text>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </FullWidthMobile>
  )
}

export default PrivacyPolicy
