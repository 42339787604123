export const STORAGE = {
  authToken: 'jwt_token',
  userEmail: 'user_email',
  confirmationToken: 'confirmation_token',
  resetToken: 'reset_password_token',
  showWelcomeDialog: 'show_welcome_dialog',
  showConsultationDialog: 'show_consultation_dialog',
  redirect_from: 'redirect_from',
  counter: 'counter',
  entity: 'entity',
  showStripe: 'show_stripe'
}

export const COOKIE = {
  PMK: 'PMK-cookie'
}
