import React from 'react'
import { Link as RouterLink, LinkProps } from 'react-router-dom'
import {
  Link as MuiLink,
  LinkProps as MuiLinkProps,
  makeStyles
} from '@material-ui/core'

const useStyles = makeStyles(
  {
    link: {
      fontWeight: 700
    }
  },
  {
    name: 'Link'
  }
)

type Props = MuiLinkProps & LinkProps

const Link: React.FC<Props> = ({ children, ...props }) => {
  const s = useStyles()

  return (
    <MuiLink
      component={RouterLink}
      color="secondary"
      className={s.link}
      {...props}
    >
      {children}
    </MuiLink>
  )
}

export default Link
