import React, { FC } from 'react'
import {
  FormControl as MuiFormControl,
  FormLabel,
  FormControlProps,
  FormHelperText,
  makeStyles,
  createStyles
} from '@material-ui/core'

import Text from '../Text'

const useStyle = makeStyles(
  (theme) =>
    createStyles({
      label: {
        color: theme.palette.text.primary,
        marginBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'flex-start',

        '& p': {
          fontWeight: 600
        },

        '& .MuiFormLabel-asterisk': {
          color: 'red'
        }
      }
    }),
  {
    name: 'FormControl'
  }
)

interface Props extends FormControlProps {
  label?: string | React.ReactNode
  errorMessage?: string
}

const FormControl: FC<Props> = ({
  children,
  label,
  errorMessage,
  ...rest
}): JSX.Element => {
  const s = useStyle()
  const isStringLabel = typeof label === 'string'

  return (
    <MuiFormControl fullWidth {...rest}>
      {!!label && isStringLabel && (
        <FormLabel focused={false} filled className={s.label}>
          <Text variant="body2">{label}</Text>
        </FormLabel>
      )}

      {!!label && !isStringLabel && label}

      {children}

      {!!errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </MuiFormControl>
  )
}

export default FormControl
