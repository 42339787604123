import { combineReducers } from '@reduxjs/toolkit'
import chatbot from './chatbot'
import parents from './parents'
import friends from './friends'

export * from './chatbot/chatbotItineraries.actions'
export * from './parents/parentsItineraries.actions'
export * from './friends/friendsItineraries.actions'

export default combineReducers({
  chatbot,
  parents,
  friends
})
