import React, { FC, useEffect, useState } from 'react'
import isEqual from 'lodash.isequal'
import { Box } from '@material-ui/core'

import { GoogleMap, Loader, Text } from '~/components/shared'

import generateCoords from '~/services/MapService'

type Props = {
  activities: Activity[]
}

const ItineraryMap: FC<Props> = ({ activities }) => {
  const [loading, setLoading] = useState(false)
  const [center, setCenter] = useState(null)
  const [markers, setMarkers] = useState([])

  useEffect(() => {
    const promises = activities
      ?.map(async (activity) => {
        const request = await generateCoords(activity)

        return request
      })
      ?.filter(Boolean)

    async function fetchData(): Promise<void> {
      setLoading(true)

      try {
        const markersData = await Promise.all(promises)

        const coordX = []
        const coordY = []

        markersData.forEach(({ position }) => {
          if (position) {
            coordX.push(position.lng)
            coordY.push(position.lat)
          }
        })

        const sortedMarkers = markersData.reduce(
          (acc: Partial<Marker>[], item: Partial<Marker>) => {
            const duplicateMarker = acc.find(({ position }) =>
              isEqual(position, item.position)
            )

            if (duplicateMarker) {
              duplicateMarker.info.push(item.info[0])

              return acc
            } else {
              return [...acc, item]
            }
          },
          []
        )

        const centerX =
          Math.min(...coordX) + (Math.max(...coordX) - Math.min(...coordX)) / 2
        const centerY =
          Math.min(...coordY) + (Math.max(...coordY) - Math.min(...coordY)) / 2

        setCenter({
          lng: centerX,
          lat: centerY
        })

        setMarkers(sortedMarkers)
      } catch (error) {
      } finally {
        setLoading(false)
      }
    }

    if (!!activities?.length) {
      fetchData()
    } else {
      setMarkers([])
    }
  }, [activities])

  return (
    <Box mb={3} width="100%">
      {loading ? (
        <Loader noGutters boxProps={{ flex: 1 }} />
      ) : !!markers.length ? (
        <GoogleMap
          height={600}
          center={center}
          markers={markers}
          mapStyles={{
            borderRadius: 4
          }}
        />
      ) : (
        <Text mb={5} flex={1}>
          No activities yet, but don’t worry, we’re working on it.
        </Text>
      )}
    </Box>
  )
}

export default ItineraryMap
