import React, { FC } from 'react'
import { makeStyles, createStyles, Chip, Button } from '@material-ui/core'
import cn from 'classnames'

import { Flexbox, Text } from '~/components/shared'

import { ReactComponent as Close } from '~/assets/icons/cross.svg'

const useStyles = makeStyles(
  ({ palette }) =>
    createStyles({
      chips: {
        margin: '0px -5px -10px -5px',
        flexWrap: 'wrap'
      },

      chipRoot: {
        margin: '0 5px 10px',
        minWidth: 0
      },

      chipActive: {
        color: palette.text.secondary,
        backgroundColor: palette.grey[100],
        borderColor: palette.text.disabled
      },

      chipDeleteIcon: {
        width: 8,
        minWidth: 8,
        color: palette.text.hint
      },

      hintText: {
        color: palette.text.hint
      }
    }),
  { name: 'ActiveChips' }
)

type ActiveChipProps = {
  label: string
  onDelete: (value: ChipOption[]) => void
}

const ActiveChip: FC<ActiveChipProps> = ({ label, onDelete }) => {
  const s = useStyles()

  return (
    <Chip
      variant="outlined"
      label={label}
      className={cn(s.chipRoot, s.chipActive)}
      classes={{ deleteIcon: s.chipDeleteIcon }}
      onDelete={onDelete}
      deleteIcon={<Close />}
    />
  )
}

type ActiveChipsProps = {
  data: ChipOption[]
  onDelete: (value: ChipOption[], clearAll?: boolean) => void
}

const ActiveChips: FC<ActiveChipsProps> = ({ data, onDelete }) => {
  const s = useStyles()

  return (
    <Flexbox className={s.chips}>
      {!!data?.length ? (
        <>
          {data?.map((chip, idx) => {
            if (chip?.value) {
              return (
                <ActiveChip
                  key={idx}
                  label={chip.value}
                  onDelete={(): void => {
                    onDelete([chip])
                  }}
                />
              )
            }
          })}

          <Button
            size="small"
            color="primary"
            className={s.chipRoot}
            onClick={(): void => {
              onDelete(data, true)
            }}
          >
            Clear all
          </Button>
        </>
      ) : (
        <Text className={cn(s.chipRoot, s.hintText)}>
          You have not selected any filter
        </Text>
      )}
    </Flexbox>
  )
}

export default ActiveChips
