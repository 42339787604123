import React, { FC, useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { makeStyles, createStyles, Box, Grid } from '@material-ui/core'

import withPremium from '~/containers/withPremium'
import { Title, Link, Text, Loader } from '~/components/shared'
import CalendarSidebar from './components/CalendarSidebar'
import Calendar from './components/Calendar'

import { getCalendarItineraries } from '~/state/modules/calendar'

import ROUTES from '~/constants/routes'
import { AppState } from '~/state/store'

import { ReactComponent as BackIcon } from '~/assets/icons/back-icon.svg'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      backButton: {
        display: 'inline-block',
        color: theme.palette.text.secondary,
        fontWeight: 400,
        marginBottom: theme.spacing(3),

        '& svg': {
          marginRight: theme.spacing(1)
        }
      }
    }),
  {
    name: 'CalendarPage'
  }
)

type ReduxProps = {
  data: {
    parents: Itinerary[]
    friends: Itinerary[]
  }
  events: ActivityEvent[]
  loading: boolean
  loaded: boolean
  checkboxes: {
    children: {
      [key: string]: {
        value: boolean
        field: 'parents' | 'friends'
      }
    }
  }
}

type dispatchProps = {
  getCalendarItineraries: () => void
}

type AllProps = ReduxProps & dispatchProps

const CalendarPage: FC<AllProps> = ({
  loading,
  loaded,
  data,
  checkboxes,
  events,

  getCalendarItineraries
}) => {
  const s = useStyles()

  useEffect(() => {
    if (!loaded) {
      getCalendarItineraries()
    }
  }, [loaded])

  return (
    <>
      <Box pt={2} pb={6}>
        <Link to={ROUTES.profile} underline="hover" className={s.backButton}>
          <BackIcon />
          Back to Profile
        </Link>

        <Title mb={3} textAlign="start">
          Calendar
        </Title>

        {loading ? (
          <Loader />
        ) : loaded ? (
          <Grid container spacing={2}>
            <Grid item xs={12} md={3} lg={2}>
              <CalendarSidebar childrenData={data} checkboxes={checkboxes} />
            </Grid>
            <Grid item xs={12} md={9} lg={10}>
              <Calendar events={events} />
            </Grid>
          </Grid>
        ) : (
          <Text>No results</Text>
        )}
      </Box>
    </>
  )
}

const mapStateToProps = ({ calendar }: AppState): ReduxProps => {
  return calendar
}

const withConnect = connect(mapStateToProps, {
  getCalendarItineraries
})

export default compose<FC>(withPremium(), withConnect)(CalendarPage)
