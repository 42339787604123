import React, { FC } from 'react'

import useStyles from './MainAdvantagesSection.styles'
import { Flexbox, Text, Title } from '~/components/shared'
import mainAdvantages from '~/data/home/mainAdvantages'
import { Grid } from '@material-ui/core'

const MainAdvantagesSection: FC = () => {
  const s = useStyles()

  return (
    <>
      <Title variant="h1" mb={5}>
        Parent Time Savers
      </Title>

      <Grid container spacing={2} justify="center">
        {mainAdvantages.map(({ label, icon }, idx) => (
          <Grid key={idx} item className={s.advantageGrid}>
            <Flexbox className={s.advantage}>
              <Flexbox className={s.icon}>{icon}</Flexbox>

              <Text variant="h5" fontWeight="700" mt="16px">
                {label}
              </Text>
            </Flexbox>
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default MainAdvantagesSection
