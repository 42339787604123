import React, { FC, KeyboardEvent } from 'react'
import { AppState } from '~/state/store'
import { connect } from 'react-redux'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import { compose } from 'redux'
import { Box, InputAdornment, Theme, useMediaQuery } from '@material-ui/core'
import { TextField } from '~/components/fields'
import { Send } from '@material-ui/icons'
import MessageList from './components/MessageList'
import { Chip, Flexbox, Title, TooltipInfo } from '~/components/shared'
import PMK from '~/services/PMK'
import ErrorHandler from '~/utils/errorHandler'
import { asyncValidate } from '~/utils/asyncValidate'
import ChatbotAssistantSchema from '~/schemas/forgot-password/chatbot-assistant'

interface ChatbotAssistantProps {
  itineraryId: string
  isDisabledField?: boolean
}

const ChatbotAssistant: FC<
  InjectedFormProps<ChatbotItineraryFormValues> & ChatbotAssistantProps
> = ({
  initialValues: { name, messages },
  itineraryId,
  isDisabledField,
  handleSubmit,
  submitting,
  valid,
  dirty
}) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))

  const isDisabledSend = !dirty || !valid

  const handleSend = handleSubmit(
    async ({ message }, _dispatch, { change }) => {
      try {
        await PMK.createChatbotMessage({
          text: message,
          itinerary_id: itineraryId
        })

        change('message', '')
      } catch (error) {
        throw new ErrorHandler(error)
      }
    }
  )

  const onKeyDown = (
    event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const isEnterPressed = event.key === 'Enter' && !event.shiftKey

    if (isEnterPressed && !submitting && dirty && !isMobile) {
      handleSend()
      event.preventDefault()
    }
  }

  return (
    <Box width="100%" px="32px">
      <Title variant="h4" mb="24px">
        <Flexbox justifyContent="center" alignItems="center">
          MIA
          <TooltipInfo
            title={
              <>
                <b>Who is MIA?</b>
                <br />
                MIA is your personalized itinerary assistant for your child and
                the acronym stands for &quot;My Itinerary Assistant&quot;.
                <br />
                <br />
                <b>List of actions available:</b>
                <br />
                1) To add activity: Please add [Activity name] activity to my
                itinerary.
                <br />
                2) To remove activity: Please remove [Activity name] activity
                from my itinerary.
                <br />
                3) To ask for extra activities: Please send me other activities
                with Music interest, preferred dates are between September 30th
                and October 5th.
              </>
            }
          />
          <Chip size="small" label="BETA" />
        </Flexbox>
      </Title>

      <MessageList messages={messages} currentName="MIA" ownerName={name} />

      <Field
        name="message"
        placeholder="Enter your question"
        component={TextField}
        onKeyDown={onKeyDown}
        disabled={isDisabledField}
        InputProps={{
          rowsMax: 3,
          multiline: true,
          endAdornment: (
            <InputAdornment position="end">
              <Send
                onClick={handleSend}
                color={isDisabledSend ? 'disabled' : 'primary'}
                cursor={isDisabledSend ? 'inherit' : 'pointer'}
              />
            </InputAdornment>
          )
        }}
      />
    </Box>
  )
}

const mapStateToProps = ({
  user: { name },
  itineraries: { chatbot }
}: AppState): any => ({
  initialValues: {
    name,
    messages: chatbot.messages
  }
})

const withConnect = connect(mapStateToProps)

const withForm = reduxForm<ChatbotItineraryFormValues>({
  form: 'Chatbot_Assistant',
  enableReinitialize: true,
  shouldAsyncValidate: () => true,
  asyncValidate: asyncValidate(ChatbotAssistantSchema)
})

export default compose<FC<ChatbotAssistantProps>>(
  withConnect,
  withForm
)(ChatbotAssistant)
