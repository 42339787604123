import { useState } from 'react'

interface UseModalReturn {
  open: boolean
  showModal: () => void
  hideModal: () => void
}

const useModal = (): UseModalReturn => {
  const [open, setOpen] = useState(false)

  const showModal = () => setOpen(true)
  const hideModal = () => setOpen(false)

  return { open, showModal, hideModal }
}

export default useModal
