import React, { FC } from 'react'
import {
  FormControl as MuiFormControl,
  FormLabel,
  FormControlProps,
  FormHelperText,
  makeStyles,
  createStyles,
  Tooltip
} from '@material-ui/core'

import { Flexbox } from '~/components/shared'

import Text from '../Text'

import { ReactComponent as InfoIcon } from '~/assets/icons/info-icon.svg'

const useStyle = makeStyles(
  (theme) =>
    createStyles({
      label: {
        color: theme.palette.text.primary,
        marginBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',

        '& p': {
          fontWeight: 600
        },

        '& .MuiFormLabel-asterisk': {
          color: 'red'
        }
      }
    }),
  {
    name: 'FormControlWithTooltip'
  }
)

interface Props extends FormControlProps {
  label?: string
  errorMessage?: string
}

const FormControlWithTooltip: FC<Props> = ({
  children,
  label,
  errorMessage,
  ...rest
}): JSX.Element => {
  const s = useStyle()

  return (
    <MuiFormControl fullWidth {...rest}>
      {!!label && (
        <Flexbox>
          <FormLabel focused={false} filled className={s.label}>
            <Text variant="body2">{label}</Text>
          </FormLabel>
          <Tooltip
            title="Activity Location is inherited from the Program address"
            arrow
            enterTouchDelay={0}
            leaveTouchDelay={3000}
          >
            <Text px={1.5} variant="body2" component="span">
              <InfoIcon width={20} height={20} />
            </Text>
          </Tooltip>
        </Flexbox>
      )}
      {children}
      {!!errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </MuiFormControl>
  )
}

export default FormControlWithTooltip
