import { makeStyles } from '@material-ui/core'

export default makeStyles({
  chip: {
    marginLeft: '4px',
    height: '100%',
    padding: '0 10px',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '26px'
  }
})
