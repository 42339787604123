export const HELPER_ROLES = {
  parent: 'parent',
  director: 'camp_director'
}

export const CAMP_DIRECTOR_STATUSES = {
  show_form: 'show_form',
  show_congratulations: 'show_congratulations',
  form_completed: 'form_completed'
}

export const STRIPE_ACCOUNT_STATUS = {
  waiting_for_details: 'waiting_for_details',
  waiting_for_stripe_connect_completion:
    'waiting_for_stripe_connect_completion',
  connect_completed: 'connect_completed'
}

export const PROGRAM_STATUS = {
  activities: 'activities',
  information: 'information'
}

export const DESCRIPTION_GENERATOR_LINK =
  'https://app.formwise.ai/form_single/1684168427202x739887814954451000'
