import { createAsyncThunk } from '@reduxjs/toolkit'
import Deserializer from '~/services/Deserializer'
import PMK from '~/services/PMK'
import ErrorHandler from '~/utils/errorHandler'

export const getOrders = createAsyncThunk<
  { data: Orders[]; meta: PagyModel },
  { page: number }
>('orders/fetchorders', async ({ page }) => {
  try {
    const { orders, pagy } = await PMK.getCampDirectorOrders(page)

    const payload = await Deserializer.deserialize(orders)

    return {
      data: payload,
      meta: {
        count: pagy.count,
        pages: pagy.pages,
        page: pagy.page
      }
    }
  } catch (error) {
    throw new ErrorHandler(error)
  }
})

export const markOrderAsProcessed = createAsyncThunk<
  Orders,
  { id: string; processed: boolean }
>('orders/handleorder', async ({ id, processed }) => {
  try {
    const resp = await PMK.markAsProcessedCampDirectorOrder(id, processed)

    const payload = await Deserializer.deserialize(resp)

    return payload
  } catch (error) {
    throw new ErrorHandler(error)
  }
})
