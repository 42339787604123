import { createSelector } from '@reduxjs/toolkit'

import { ORDERS, PACKAGES } from '~/constants/packages'
import { ROLES } from '~/constants/roles'
import { AppState } from '~/state/store'

export const isLogged = (state: AppState): boolean => {
  const {
    user: { token }
  } = state

  return !!token
}

export const isAuthorized = (state: AppState): boolean => {
  const {
    user: { confirmed, token }
  } = state

  return !!token && confirmed
}

export const isParent = (state: AppState): boolean => {
  const {
    user: { role }
  } = state

  return role === ROLES.Parent
}

export const isSpecialist = (state: AppState): boolean => {
  const {
    user: { role }
  } = state

  return role === ROLES.Specialist
}

export const isCampDirector = (state: AppState): boolean => {
  const {
    user: { role }
  } = state

  return role === ROLES.CampDirector
}

export const campDirectorStatuses = (
  state: AppState
): Pick<User, 'zoho_profile' | 'stripe_account_status'> => {
  const {
    user: { zoho_profile, stripe_account_status }
  } = state

  return { zoho_profile, stripe_account_status }
}

export const isShare = (state: AppState): boolean => {
  return state.user.friends_itineraries
}

export const isGoogleUser = (state: AppState): boolean => {
  return state.user.from_google
}

export const userPackageSelector = (state: AppState): PackagesUnion =>
  state.user.package

export const isPremium = createSelector(
  userPackageSelector,
  (userPackage) => userPackage === PACKAGES.Premium
)

export const userOrderStates = (state: AppState): OrdersUnion =>
  state.user.order_status

export const userPackagesType = (state: AppState): PackagesTypeUnion =>
  state.user.package_type

export const isPaid = createSelector(
  userOrderStates,
  (userOrder) => userOrder === ORDERS.paid
)

export const isPremiumPaid = createSelector(
  userPackageSelector,
  userOrderStates,
  (userPackage, userOrder) =>
    userPackage === PACKAGES.Premium && userOrder === ORDERS.paid
)

const moreQuestionnaire = (state: AppState): boolean => {
  return state.questionnaires.data.length >= 1
}

export const enoughtQuestionnaire = createSelector(
  isPremiumPaid,
  moreQuestionnaire,
  (premium, questionnairesLength) => !premium && questionnairesLength
)

export const isBlockQuestionnaire = (state: AppState): boolean => {
  const {
    user: { confirmed, package_type, package: userPackage }
  } = state

  const isPremium = userPackage === PACKAGES.Premium
  const isBlock = confirmed ? !isPremium : !package_type

  return isBlock
}
