import React from 'react'

import { withWidth } from '@material-ui/core'

import ContainerFull from '~/components/layouts/ContainerFull'

const FullWidthMobile = ({ width, children }): JSX.Element | null => {
  return width === 'xs' ? <ContainerFull>{children}</ContainerFull> : children
}

export default withWidth()(FullWidthMobile)
