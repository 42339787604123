import { makeStyles, createStyles } from '@material-ui/core'

export const useTableStyles = makeStyles(
  (theme) =>
    createStyles({
      table: {
        '& th': {
          padding: theme.spacing(1, 3),
          backgroundColor: '#F6F8F9',
          fontWeight: 600,
          borderBottom: 'none',

          [theme.breakpoints.down('lg')]: {
            padding: theme.spacing(1, 2),

            '&:first-child': {
              paddingLeft: theme.spacing(3)
            },

            '&:last-child': {
              paddingRight: theme.spacing(3)
            }
          }
        },

        '& td': {
          padding: theme.spacing(1.8, 3),
          borderColor: theme.palette.text.disabled,
          color: theme.palette.text.secondary,
          fontSize: 16,

          '&:nth-child(1), &:nth-child(2), &:nth-child(4)': {
            maxWidth: 220,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          },

          '&:last-child': {
            width: 1,
            minWidth: 300,
            whiteSpace: 'nowrap',
            paddingRight: theme.spacing(3)
          },

          [theme.breakpoints.down('lg')]: {
            padding: theme.spacing(1.8, 2),

            '&:first-child': {
              paddingLeft: theme.spacing(3),
              maxWidth: 200
            },

            '&:nth-child(2)': {
              maxWidth: 200
            },

            '&:nth-child(3)': {
              maxWidth: 150
            },

            '&:nth-child(4)': {
              maxWidth: 150
            },

            '&:last-child': {
              paddingRight: theme.spacing(3),
              minWidth: 190
            }
          },

          '@media(max-width: 1020px)': {
            '&:first-child': {
              maxWidth: 200
            },

            '&:nth-child(2)': {
              maxWidth: 180
            }
          }
        }
      },

      btns: {
        width: 'fit-content',

        '& > *:not(:last-child)': {
          marginRight: theme.spacing(1)
        }
      }
    }),
  {
    name: 'DashboardTable'
  }
)
