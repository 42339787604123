import React, { CSSProperties, useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import cn from 'classnames'

import Text, { TextProps } from '../Text'

import { fonts } from '~/theme/typography'

const useStyles = makeStyles(
  {
    title: {
      position: 'relative',
      fontFamily: fonts.serif
    },

    lastWord: {
      position: 'relative',
      zIndex: 1,

      '& img': {
        position: 'absolute',
        bottom: -20,
        right: 0,
        width: '95%',
        zIndex: -1,
        height: 'auto'
      }
    }
  },
  {
    name: 'Title'
  }
)

type Props = {
  customClassName?: string
  paintingOptions?: {
    image: string
    customStyles?: CSSProperties
  }
}

const Title: React.FC<Props & TextProps> = ({
  customClassName,
  paintingOptions,
  children,
  ...rest
}) => {
  const s = useStyles()

  const renderChildren = useMemo(() => {
    let render = children

    if (paintingOptions?.image && typeof children === 'string') {
      const words = children?.split(' ')
      const lastWord = words.splice(-1)[0]

      const LastWordItem: React.FC = () => (
        <span className={s.lastWord}>
          {lastWord}
          <img
            src={paintingOptions.image}
            style={paintingOptions.customStyles}
          />
        </span>
      )

      render = (
        <>
          {words.join(' ')} <LastWordItem />
        </>
      )
    }

    return render
  }, [children, paintingOptions])

  return (
    <Text
      variant="h2"
      textAlign="center"
      className={cn(s.title, customClassName)}
      {...rest}
    >
      {renderChildren}
    </Text>
  )
}

export default Title
