import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { WrappedFieldProps } from 'redux-form'
import { useAsync } from 'react-async'
import {
  TextField,
  CircularProgress,
  InputProps,
  makeStyles,
  createStyles
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { ExpandMore } from '@material-ui/icons'

import ErrorHandler from '~/utils/errorHandler'

import { ReactComponent as Close } from '~/assets/icons/cross.svg'

const useStyles = makeStyles(
  () =>
    createStyles({
      popper: {
        zIndex: 1302
      }
    }),
  {
    name: 'AutocompleteField'
  }
)

type Props = {
  label: string
  displayError?: string
  placeholder?: string
  options?: Option[]
  loading?: boolean
  InputProps?: InputProps
  loadOptions?: () => Promise<Option[]>
  required?: boolean
  multiple?: boolean
  isBigZIndex?: boolean
  filterSelectedOptions?: boolean
  limitTags?: number
  customOnChange?: (v: Option) => void
  defaultValueKey?: 'label' | 'value'
  onChangeKey?: 'label' | 'value'
}

const AutocompleteField: React.FC<Props & WrappedFieldProps> = ({
  input: { onChange, value, ...input },
  meta: { touched, error },
  label,
  displayError,
  options: customOptions,
  loadOptions,
  loading = false,
  InputProps,
  required,
  placeholder,
  isBigZIndex,
  customOnChange,
  defaultValueKey = 'value',
  onChangeKey,
  ...rest
}) => {
  const s = useStyles()

  const [options, setOptions] = useState([])
  const errorMessage = (touched && error) || displayError

  const { isLoading, run } = useAsync({
    deferFn: loadOptions,
    onResolve: setOptions,
    onReject: (error) => {
      throw new ErrorHandler(error)
    }
  })

  useEffect(() => {
    if (!!loadOptions) run()
  }, [])

  useEffect(() => {
    if (
      (options.length > 0 && customOptions?.length === 0) ||
      !!customOptions?.length
    ) {
      setOptions(customOptions)
    }
  }, [customOptions])

  const handleChange = useCallback(
    (_e, v) => {
      if (onChangeKey) {
        onChange(
          rest.multiple && Array.isArray(v)
            ? v.map((e) => e?.[onChangeKey] ?? null)
            : v?.[onChangeKey] ?? null
        )
      } else {
        onChange(v ?? null)
      }

      if (customOnChange) customOnChange(v ?? null)
    },
    [customOnChange]
  )

  const defaultValue = useMemo(() => {
    if (
      (typeof value === 'string' || typeof value === 'number') &&
      !!options &&
      !rest?.multiple
    )
      return options.find(
        (v) => v[defaultValueKey].toString() === value.toString()
      )
    if (Array.isArray(value))
      return value
        .map((v) => {
          if (typeof v === 'string' || typeof v === 'number') {
            return options.find(
              (opt) => String(opt[defaultValueKey]) === String(v)
            )
          }

          return v
        })
        ?.filter(Boolean)
    else return value
  }, [value, defaultValueKey, options])

  return (
    <Autocomplete
      onChange={handleChange}
      options={options}
      getOptionSelected={(option, optionValue): boolean => {
        if (rest?.multiple) {
          return option.value === optionValue.value
        }

        if (onChangeKey) {
          return option.value === value
        }

        return option.value === value.value
      }}
      getOptionLabel={(option): string => option.label}
      renderOption={(option): string => option.label}
      value={defaultValue || (rest?.multiple ? [] : null)}
      loading={loading || isLoading}
      ChipProps={{
        deleteIcon: <Close />
      }}
      classes={{
        popper: isBigZIndex ? s.popper : ''
      }}
      popupIcon={<ExpandMore />}
      renderInput={(params): JSX.Element => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          placeholder={placeholder}
          error={!!errorMessage}
          helperText={errorMessage}
          InputLabelProps={{ required }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {InputProps?.endAdornment}
                {(loading || isLoading) && (
                  <CircularProgress color="inherit" size={20} />
                )}
                {params.InputProps.endAdornment}
              </>
            )
          }}
          inputProps={{
            ...input,
            ...params.inputProps
          }}
          fullWidth
        />
      )}
      {...rest}
    />
  )
}
export default AutocompleteField
