import { createStyles, makeStyles } from '@material-ui/core'

import AdvantagesBg from '~/assets/images/congratulations/advantages-background.png'

const useCongratulationsStyles = makeStyles(
  (theme) =>
    createStyles({
      wrapper: {
        padding: theme.spacing(7, 0, 9),
        maxWidth: '922px'
      },

      container: {
        padding: theme.spacing(9, 0),
        marginBottom: theme.spacing(6),
        backgroundImage: `url(${AdvantagesBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',

        [theme.breakpoints.down('sm')]: {
          backgroundSize: '300% 100%',
          backgroundPosition: '45% 100%'
        },

        [theme.breakpoints.down('xs')]: {
          backgroundSize: '350% 100%',
          backgroundPosition: '50% 100%',
          padding: theme.spacing(9, 0, 8)
        }
      },

      advantagesTitle: {
        margin: theme.spacing(0, 'auto', 5)
      },

      advantageGrid: {
        display: 'flex',
        justifyContent: 'center'
      },

      button: {
        margin: theme.spacing(0, 1),

        [theme.breakpoints.down('xs')]: {
          width: '100%',
          margin: theme.spacing(1, 0)
        }
      }
    }),
  {
    name: 'Congratulations'
  }
)

export default useCongratulationsStyles
