import React, { FC, useEffect, useState } from 'react'
import { compose } from 'redux'
import { useSelector } from 'react-redux'
import {
  InjectedFormProps,
  Form,
  Field,
  reduxForm,
  SubmissionError
} from 'redux-form'
import { RouteComponentProps, withRouter } from 'react-router'
import ReCAPTCHA from 'react-google-recaptcha'
import { Grid, Box } from '@material-ui/core'

import { TextField } from '~/components/fields'
import { ExtendedButton, Flexbox, FormControl, Link } from '~/components/shared'
import { PasswordFragment } from '~/components/forms'

import { loginUser } from '~/state/modules/user/user.actions'
import { asyncValidate } from '~/utils/asyncValidate'
import SignInSchema from '~/schemas/sign-in'
import { changeCounter } from '~/state/modules/appLayout/appLayout.slice'

import { STORAGE } from '~/constants/storage'
import ROUTES from '~/constants/routes'
import { AppState } from '~/state/store'

const SignInForm: FC<InjectedFormProps> = ({ handleSubmit, submitting }) => {
  const [isVerify, setVerify] = useState<boolean>(false)
  const counter = useSelector((state: AppState) => state.appLayout.counter)

  const isCounter = counter >= 5

  useEffect(() => {
    if (isCounter) setVerify(false)
  }, [counter])

  const verifyCallback = (): void => {
    setVerify(true)
  }

  return (
    <Box
      component={Form}
      display="flex"
      flexDirection="column"
      onSubmit={handleSubmit}
      data-cy="sign-in-form"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl label="Email">
            <Field
              id="email"
              name="email"
              placeholder="Email"
              autoComplete="email"
              component={TextField}
              normalize={(value): string => value?.toLowerCase()}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <PasswordFragment />

          <Flexbox justifyContent="flex-end" mb={2}>
            <Link
              to={ROUTES.forgotPassword}
              style={{ fontSize: 12, fontWeight: 400 }}
              color="inherit"
            >
              Forgot your password?
            </Link>
          </Flexbox>
        </Grid>

        {isCounter && (
          <Grid item xs={12}>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={verifyCallback}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <ExtendedButton
            size="large"
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            loading={submitting}
            disabled={isCounter && !isVerify}
          >
            Sign in
          </ExtendedButton>
        </Grid>
      </Grid>
    </Box>
  )
}

const withForm = reduxForm<SignInInput, RouteComponentProps>({
  form: 'SignIn_Form',
  shouldAsyncValidate: () => true,
  asyncValidate: asyncValidate(SignInSchema),
  onSubmit: async (values, dispatch) => {
    try {
      const res = await dispatch<any>(loginUser(values))

      return res
    } catch (error) {
      throw new SubmissionError(error.errors)
    }
  },
  onSubmitSuccess: ({ invited, premium }, dispatch, { history }) => {
    const redirect = invited
      ? ROUTES.itinerariesFriends
      : premium
      ? ROUTES.home
      : localStorage.getItem(STORAGE.redirect_from) || ROUTES.home

    localStorage.removeItem(STORAGE.redirect_from)
    sessionStorage.removeItem(STORAGE.counter)

    dispatch(changeCounter(0))

    history.push(redirect)
  },
  onSubmitFail: (_values, dispatch) => {
    const counter = sessionStorage.getItem(STORAGE.counter) || 0
    const increment = Number(counter) + 1

    // @ts-ignore
    const grecaptcha = window?.grecaptcha

    sessionStorage.setItem(STORAGE.counter, String(increment))

    dispatch(changeCounter(increment))

    if (!!grecaptcha && counter >= 5) {
      grecaptcha.reset()
    }
  }
})

export default compose<React.FC>(withRouter, withForm)(SignInForm)
