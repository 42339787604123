import React, { FC } from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
  createStyles,
  Box
} from '@material-ui/core'

import cn from 'classnames'

import { CroppedTextTooltip, Text } from '~/components/shared'
import ItineraryButtons from './ItineraryButtons'

import { getDashboardSubscriptionName, humanizeDate } from '~/utils/functions'

import { ReactComponent as ArrowDownIcon } from '~/assets/icons/arrow-down.svg'
import { ORDERS } from '~/constants/packages'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      content: {
        paddingRight: theme.spacing(1),
        overflow: 'hidden'
      },

      nowrapText: {
        whiteSpace: 'nowrap',
        maxWidth: '100%'
      },

      btns: {
        borderTop: `1px solid ${theme.palette.text.disabled}`,
        padding: theme.spacing(2, 1.5),

        '& > *:not(:last-child)': {
          marginBottom: theme.spacing(1.5)
        }
      },

      flexText: {
        display: 'flex',
        gap: `${theme.spacing(0.5)}px`
      }
    }),
  {
    name: 'DashboardAccordion'
  }
)

type Props = {
  activeTab: StatusesUnion
}

const DashboardAccordion: FC<Props & Itinerary> = ({
  id,
  created_at,
  questionnaire: {
    parent: {
      name,
      email,
      package_type: packageType,
      order_status: orderStatus
    },
    fields: { child_first_name }
  },

  activeTab
}) => {
  const s = useStyles()

  const subscription = getDashboardSubscriptionName(packageType)
  const isPending = orderStatus === ORDERS.pending
  const isPaymentFailed = orderStatus === ORDERS.payment_failed
  const hasPaid = !isPending && !isPaymentFailed && !!packageType

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ArrowDownIcon />}
        classes={{
          content: s.content
        }}
      >
        <Text color="textSecondary" className={cn(s.nowrapText, s.flexText)}>
          Parent:{' '}
          <CroppedTextTooltip title={name}>
            <Text>{name}</Text>
          </CroppedTextTooltip>
        </Text>
      </AccordionSummary>

      <AccordionDetails>
        <Box mx={1.5}>
          <Text
            color="textSecondary"
            mb={1.5}
            className={cn(s.nowrapText, s.flexText)}
          >
            Email:{' '}
            <CroppedTextTooltip title={email}>
              <Text>{email}</Text>
            </CroppedTextTooltip>
          </Text>

          <Text
            color="textSecondary"
            mb={1.5}
            className={cn(s.nowrapText, s.flexText)}
          >
            Subscriptions:{' '}
            <CroppedTextTooltip title={subscription}>
              <Text>{subscription}</Text>
            </CroppedTextTooltip>
          </Text>

          <Text
            color="textSecondary"
            mb={1.5}
            className={cn(s.nowrapText, s.flexText)}
          >
            Child:{' '}
            <CroppedTextTooltip title={child_first_name}>
              <Text>{child_first_name}</Text>
            </CroppedTextTooltip>
          </Text>

          <Text color="textSecondary" mb={2}>
            Date: {humanizeDate(created_at, 'MMM D, YYYY, H:mm A')}
          </Text>
        </Box>
        <Box className={s.btns}>
          <ItineraryButtons
            id={id}
            activeTab={activeTab}
            hasChild={!!child_first_name}
            hasPaid={hasPaid}
            isMobile
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

export default DashboardAccordion
