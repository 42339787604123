import React, { FC, useCallback, useState } from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { useDispatch } from 'react-redux'

import {
  signinWithSocial,
  signinDirectorWithSocial
} from '~/state/modules/user/user.actions'

import ROUTES from '~/constants/routes'
import { STORAGE } from '~/constants/storage'
import { GoogleOAuthProvider, TokenResponse } from '@react-oauth/google'
import WebGoogleButton from './WebGoogleButton'

interface GoogleButtonProps {
  isDirector?: boolean
}

const GoogleButton: FC<RouteComponentProps & GoogleButtonProps> = ({
  children,
  history,
  isDirector
}) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(false)

  const handleSuccess = useCallback(
    async ({ access_token }: TokenResponse) => {
      try {
        let redirect = null

        setLoading(true)

        if (isDirector) {
          await dispatch<any>(
            signinDirectorWithSocial({
              access_token: access_token,
              provider_name: 'google'
            })
          )

          redirect = ROUTES.home
        } else {
          const { invited, premium } = await dispatch<any>(
            signinWithSocial({
              access_token: access_token,
              provider_name: 'google'
            })
          )

          redirect = invited
            ? ROUTES.itinerariesFriends
            : premium
            ? ROUTES.home
            : localStorage.getItem(STORAGE.redirect_from) ||
              ROUTES.congratulations
        }

        localStorage.removeItem(STORAGE.redirect_from)

        history.push(redirect)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    },
    [dispatch, history]
  )

  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
    >
      <WebGoogleButton loading={loading} handleSuccess={handleSuccess}>
        {children}
      </WebGoogleButton>
    </GoogleOAuthProvider>
  )
}

export default withRouter(GoogleButton)
