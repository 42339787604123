import { makeStyles, createStyles } from '@material-ui/core'

import { fonts } from '~/theme/typography'

export const useDesktopStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        backgroundColor: theme.palette.common.white,
        padding: '30px 0',
        borderTop: `1px solid ${theme.palette.grey['300']}`
      },

      logo: {
        height: 54
      },

      links: {
        display: 'flex',
        justifyContent: 'center',
        flex: 1,
        padding: '0 47px',

        '& > a:not(:last-child)': {
          marginRight: theme.spacing(4),

          '@media(max-width: 1060px)': {
            marginRight: theme.spacing(2.5)
          }
        }
      },

      btns: {
        '& > *': {
          width: 45
        }
      },

      copyrightWrapper: {
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(1),

        '& > *': {
          color: theme.palette.text.hint,
          fontSize: 14
        },

        '& > a': {
          fontFamily: fonts.nunito,
          position: 'relative',
          marginLeft: theme.spacing(2),
          paddingLeft: theme.spacing(2),

          '&::before': {
            content: '"|"',
            position: 'absolute',
            left: 0,
            bottom: 1,
            color: theme.palette.text.hint
          }
        }
      }
    }),
  {
    name: 'FooterDesktop'
  }
)

export const useMobileStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(3, 2),

        [theme.breakpoints.down('xs')]: {
          borderTop: `1px solid ${theme.palette.grey[300]}`
        }
      },

      logo: {
        height: 54
      },

      linksWrapper: {
        justifyContent: 'space-between',
        margin: theme.spacing(1.8, 0, 4, 0)
      },

      links: {
        marginRight: theme.spacing(1.5),

        '& > a': {
          display: 'block',
          fontSize: 20,
          padding: theme.spacing(1, 0)
        }
      },

      btns: {
        alignItems: 'flex-start',
        marginRight: '-12px',

        '& > *': {
          width: 45,
          height: 45
        }
      },

      copyrightWrapper: {
        '& *': {
          color: theme.palette.text.hint,
          fontSize: 14
        },

        '& a': {
          fontFamily: fonts.nunito,
          position: 'relative',
          marginLeft: theme.spacing(1),
          paddingLeft: theme.spacing(1),

          '&:not(:first-child)::before': {
            content: '"|"',
            position: 'absolute',
            left: 0,
            bottom: 1,
            color: theme.palette.text.hint
          }
        }
      }
    }),
  {
    name: 'FooterMobile'
  }
)
