import React from 'react'
import { Container, IconButton } from '@material-ui/core'

import { Flexbox, NavLink, Text, Logo } from '~/components/shared'

import ROUTES from '~/constants/routes'
import { socialLinks } from '../links.config'
import { FooterProps } from './Footer'

import { useDesktopStyles } from './Footer.styles'

const FooterDesktop: React.FC<FooterProps> = ({ navs }) => {
  const s = useDesktopStyles()

  return (
    <footer className={s.root} id="footer">
      <Container maxWidth="xl">
        <Flexbox alignItems="center">
          <Logo />

          <nav className={s.links}>
            {navs.map(({ label, to, outwardLink }) =>
              outwardLink ? (
                <NavLink
                  key={to}
                  component={(props): JSX.Element => <a {...props} />}
                  href={to}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {label}
                </NavLink>
              ) : (
                <NavLink key={to} to={to}>
                  {label}
                </NavLink>
              )
            )}
          </nav>

          <Flexbox className={s.btns}>
            {socialLinks.map(({ to, icon }, idx) => (
              <IconButton
                key={idx}
                component={(props): JSX.Element => <a {...props} />}
                href={to}
                target="_blank"
                rel="noopener"
              >
                {icon}
              </IconButton>
            ))}
          </Flexbox>
        </Flexbox>

        <Flexbox className={s.copyrightWrapper}>
          <Text>
            &copy; PlanMyKids {new Date().getFullYear()}. All rights are
            reserved
          </Text>
          <NavLink to={ROUTES.terms}>Terms of use</NavLink>
          <NavLink to={ROUTES.businessTerms}>Business Terms</NavLink>
          <NavLink to={ROUTES.privacy}>Privacy Policy</NavLink>
        </Flexbox>
      </Container>
    </footer>
  )
}

export default FooterDesktop
