import { PayloadAction } from '@reduxjs/toolkit'

export const requestAction = (state): void => {
  state.loading = true
  state.error = null
}

export const successAction = <Payload = {}>(
  state,
  { payload }: PayloadAction<Payload>,
  field = 'data',
  customChanges?: (state) => void
): void => {
  state[field] = payload
  state.loading = false
  state.loaded = true
  state.error = null

  if (customChanges) {
    customChanges(state)
  }
}

export const successItemAction = <Payload = {}>(state, action): void => {
  successAction<Payload>(state, action, 'item')
}

export const failureAction = <Error = null>(
  state,
  { payload = null }: { payload: Error }
): void => {
  state.loading = false
  state.error = payload
}
