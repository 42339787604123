import React, { FC } from 'react'
import { compose } from 'redux'
import {
  InjectedFormProps,
  Form,
  Field,
  reduxForm,
  SubmissionError
} from 'redux-form'
import { RouteComponentProps, withRouter } from 'react-router'
import { Grid, Box } from '@material-ui/core'

import { TextField } from '~/components/fields'
import { ExtendedButton, FormControl } from '~/components/shared'
import { PasswordFragment } from '~/components/forms'

import { loginUser, signupUser } from '~/state/modules/user/user.actions'
import { asyncValidate } from '~/utils/asyncValidate'
import SignupSchema from '~/schemas/sign-up'

import ROUTES from '~/constants/routes'

const SignUpForm: FC<InjectedFormProps> = ({ handleSubmit, submitting }) => {
  return (
    <Box
      component={Form}
      display="flex"
      flexDirection="column"
      onSubmit={handleSubmit}
      data-cy="sign-up-form"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl label="Full name">
            <Field
              id="name"
              name="name"
              placeholder="Full name"
              component={TextField}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl label="Email">
            <Field
              id="email"
              name="email"
              placeholder="Email"
              autoComplete="email"
              component={TextField}
              normalize={(value): string => value?.toLowerCase()}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <PasswordFragment confirm />
        </Grid>

        <Grid item xs={12}>
          <ExtendedButton
            size="large"
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            loading={submitting}
            style={{ whiteSpace: 'nowrap' }}
          >
            Sign up
          </ExtendedButton>
        </Grid>
      </Grid>
    </Box>
  )
}

const withForm = reduxForm<SignUpInput, RouteComponentProps>({
  form: 'SignUp_Form',
  shouldAsyncValidate: () => true,
  asyncValidate: asyncValidate(SignupSchema),
  onSubmit: async (values, dispatch) => {
    try {
      await dispatch<any>(signupUser(values))

      await dispatch<unknown>(loginUser(values))
    } catch (error) {
      throw new SubmissionError(error.errors)
    }
  },
  onSubmitSuccess: (_values, _dispatch, { history }) => {
    history.push(ROUTES.congratulations)
  }
})

export default compose<React.FC>(withRouter, withForm)(SignUpForm)
