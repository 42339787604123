import React, { useState } from 'react'
import {
  Dialog,
  DialogProps,
  Button,
  Box,
  makeStyles,
  createStyles,
  useMediaQuery
} from '@material-ui/core'
import cn from 'classnames'

import DialogTitle from './DialogTitle'
import { Flexbox, Text } from '../shared'

const useStyles = makeStyles(
  () =>
    createStyles({
      booking: {
        padding: '30px 0 20px',
        letterSpacing: '1.7px'
      },

      withoutBooking: {
        padding: '30px 0 20px',
        letterSpacing: '1.7px',

        '& .MuiTypography-h3': {
          fontSize: '28px',
          lineHeight: 1.28
        }
      }
    }),
  {
    name: 'ModalDialog'
  }
)

type Props = {
  isBookingAvailable?: boolean
  in: boolean
  onClose?: () => void
  onBooking?: () => void
  onBookingLater?: () => void
  startBookingText?: string
  laterBookingText?: string
  title?: string
  text?: string
  textWithColor?: string
}

const BookingDialog: React.FC<Props & DialogProps> = ({
  isBookingAvailable,
  in: open,
  onClose,
  onBooking,
  onBookingLater,
  startBookingText,
  laterBookingText,
  title = '',
  text = '',
  textWithColor = '',
  ...rest
}) => {
  const s = useStyles()
  const [action, setAction] = useState<boolean>(false)
  const isMobile = useMediaQuery('(max-width:490px)')

  const handleBooking = (): void => {
    if (!action) {
      setAction(true)
      onBooking()
      onClose()
    }
  }

  const handleBookingLater = (): void => {
    if (!action) {
      setAction(true)
      onBookingLater()
      onClose()
    }
  }

  return (
    <Dialog {...rest} maxWidth="sm" open={open} onClose={onClose}>
      <Box p={3} maxWidth={540}>
        <DialogTitle
          onClose={onClose}
          // @ts-ignore
          className={cn({
            [s.booking]: isBookingAvailable,
            [s.withoutBooking]: !isBookingAvailable
          })}
        >
          {title}
        </DialogTitle>

        <Text fontSize="16px" lineHeight="26px" textAlign="center" mb={2}>
          {text}{' '}
          <Text
            component="span"
            style={{ color: '#703965', fontWeight: 'bold' }}
          >
            {textWithColor}
          </Text>
        </Text>

        <Flexbox flexWrap="wrap" justifyContent="center">
          <Box minWidth={isMobile ? '100%' : 170} mb={isMobile && 2}>
            <Button
              variant="outlined"
              fullWidth
              size="large"
              color="primary"
              onClick={isBookingAvailable ? handleBookingLater : onClose}
            >
              {laterBookingText}
            </Button>
          </Box>
          <Box minWidth={isMobile ? '100%' : 170} ml={!isMobile && 2}>
            <Button
              data-cy="dialog-confirm-button"
              variant="contained"
              fullWidth
              size="large"
              color="primary"
              onClick={isBookingAvailable ? handleBooking : handleBookingLater}
              disabled={action}
            >
              {startBookingText}
            </Button>
          </Box>
        </Flexbox>
      </Box>
    </Dialog>
  )
}

export default BookingDialog
