import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { useHistory, useParams } from 'react-router-dom'

import cn from 'classnames'
import { Box, Paper, Button, Divider } from '@material-ui/core'

import { Title, Text, Flexbox } from '~/components/shared'
import PMK from '~/services/PMK'

import FullWidthMobile from '~/containers/FullWidthMobile'

import { getParentItineraryById } from '~/state/modules/itineraries'
import ActivitiesService from '~/services/ActivitiesService'
import ItineraryService from '~/services/ItineraryService'
import { sortByDate } from '~/utils/functions'
import { ININERARY_ACTIVITIES_STATUSES } from '~/constants/itineraryActivitiesStatuses'

import InfoDataBlock from '~/pages/itinerary/components/InfoDataBlock'

import { AppState } from '~/state/store'

import useStyles from './payment.styles'
import ROUTES from '~/constants/routes'

type ReduxProps = {
  data: Itinerary[]
  item: Partial<Itinerary>
  loading: boolean
  loaded: boolean
  activeTab: StatusesUnion
}

type dispatchProps = {
  getParentItineraryById: (id: string) => Promise<void>
}

type AllProps = ReduxProps & dispatchProps

const Payment: React.FC<AllProps> = ({ item, getParentItineraryById }) => {
  const s = useStyles()

  const { id } = useParams<{ id: string }>()
  const history = useHistory()

  useEffect(() => {
    if (id) {
      getParentItineraryById(id).catch(() => history.push(ROUTES.itineraries))
    }

    if (!id) {
      history.push(ROUTES.itineraries)
    }
  }, [id])

  const handleGoToPayment = async (): Promise<any> => {
    const { url } = await PMK.getStripeLink(item.id)
    if (url) {
      window.location = url
    }
  }

  const isAvailableForPayment = (item?.itinerary_activities || []).filter(
    (activity) =>
      activity?.payment_status ===
      ININERARY_ACTIVITIES_STATUSES.availableForPayment
  )

  const fields = item?.questionnaire?.fields || {}

  const { activitiesData, paymentsSum } = useMemo(
    () => ({
      activitiesData: ActivitiesService.generateParentItineraryActivities(
        sortByDate(isAvailableForPayment, 'start_date', false, 'activity')
      ),
      paymentsSum: ActivitiesService.generatePaymentActivitiesSum(
        isAvailableForPayment
      )
    }),
    [item]
  )

  return (
    <FullWidthMobile>
      <Box className={cn(s.container, 'withWidth')}>
        <Title variant="h4" mb={3}>
          Payment Summary
        </Title>

        <Paper className={s.paper}>
          {activitiesData?.length &&
            activitiesData.map((activity) => (
              <Box key={activity.id} mb={2}>
                <Box className={s.withPrice}>
                  <Text className={cn(s.descriptionTex, s.wrapText)}>
                    {activity.subtitle}
                  </Text>
                  <Text className={s.mainTex} component="span">
                    {activity.prices}
                  </Text>
                </Box>
                <Text className={cn(s.mainTex, s.wrapText)}>
                  {activity.title}
                </Text>
                {activity.sku && (
                  <Flexbox>
                    <Text className={s.descriptionTex} mr={0.5}>
                      SKU:{' '}
                    </Text>
                    <Text className={cn(s.mainTex, s.wrapText)}>
                      {activity.sku}
                    </Text>
                  </Flexbox>
                )}
                <Box fontSize="16px">
                  <Text className={s.descriptionTex} component="span">
                    Date:{' '}
                  </Text>
                  <Text className={s.mainTex} component="span">
                    {activity.date}
                  </Text>
                  <Text className={s.tooltipTex} component="span">
                    {' '}
                    ({activity.duration})
                  </Text>
                </Box>
              </Box>
            ))}

          <Box className={s.withPrice} fontSize="18px !important">
            <Text className={s.mainTex} component="span">
              Total
            </Text>
            <Text className={s.mainTex} component="span">
              {paymentsSum}
            </Text>
          </Box>
        </Paper>
        <Box display="flex" justifyContent="center" mb={6}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleGoToPayment}
          >
            Checkout
          </Button>
        </Box>
        <Divider className={s.divider} />
        {fields && (
          <Box mb={6}>
            <Paper>
              <InfoDataBlock
                title="Child info"
                forParentPayment
                status={item?.status}
                data={ItineraryService.generateChildData(fields)}
              />
            </Paper>
          </Box>
        )}
      </Box>
    </FullWidthMobile>
  )
}

const mapStateToProps = ({
  itineraries: {
    parents: { data, ...itineraries }
  }
}: AppState): ReduxProps => {
  return {
    data: data[itineraries.activeTab],
    ...itineraries
  }
}

const withConnect = connect(mapStateToProps, {
  getParentItineraryById
})

export default compose<React.FC>(withConnect)(Payment)
