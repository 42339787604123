import React, { useCallback } from 'react'
import { Dialog, DialogContent, Button } from '@material-ui/core'

import { Flexbox, Text } from '../shared'
import DialogTitle from './DialogTitle'

type Props = {
  in?: boolean
  onClose: () => {}
  onConfirm: () => void
}

const WelcomeDialog: React.FC<Props> = ({
  in: open,
  onClose,
  onConfirm,
  ...rest
}) => {
  const handleConfirm = useCallback(() => {
    onConfirm()
    onClose()
  }, [onClose, onConfirm])

  return (
    <Dialog {...rest} fullWidth open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>Welcome to PlanMyKids!</DialogTitle>

      <DialogContent>
        <Text color="textSecondary" textAlign="center" mb={1}>
          Thanks for allowing us to plan your kid&apos;s activities!
        </Text>

        <Text color="textSecondary" textAlign="center" mb={1}>
          To help us provide the best quality service, we&apos;d like to walk
          you through a brief, self guided survey to get to know you. Your
          privacy is a top priority, no information will be shared with other
          parties. Once submitted, we&apos;ll follow up with a personalized
          itinerary for your review.
        </Text>

        <Text mb={3} textAlign="center" fontWeight={600}>
          This form will take not more than 8 minutes to complete.
        </Text>

        <Flexbox justifyContent="center">
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={handleConfirm}
          >
            Fill in the form
          </Button>
        </Flexbox>
      </DialogContent>
    </Dialog>
  )
}

export default WelcomeDialog
