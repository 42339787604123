import React, { FC } from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
  createStyles
} from '@material-ui/core'

import { Text } from '~/components/shared'

import { ReactComponent as ArrowDownIcon } from '~/assets/icons/arrow-down.svg'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      accordion: {
        '&::before': {
          opacity: 0
        },

        '& .MuiButtonBase-root.MuiAccordionSummary-root': {
          padding: '0 10px',
          minHeight: '30px'
        }
      },

      content: {
        paddingRight: theme.spacing(1),
        overflow: 'hidden'
      },

      nowrapText: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: 'bold'
      }
    }),
  {
    name: 'ProgramsAccordion'
  }
)

interface CancellationPolicyAccordionProps {
  cancellationPolicy?: string
}

const CancellationPolicyAccordion: FC<CancellationPolicyAccordionProps> = ({
  cancellationPolicy
}) => {
  const s = useStyles()

  return (
    <Accordion className={s.accordion}>
      <AccordionSummary
        expandIcon={<ArrowDownIcon />}
        classes={{
          content: s.content
        }}
      >
        <Text color="textSecondary" className={s.nowrapText}>
          Cancellation Policy:
        </Text>
      </AccordionSummary>
      <AccordionDetails>
        <Text color="textSecondary" p="10px" whiteSpace="pre-wrap">
          {cancellationPolicy}
        </Text>
      </AccordionDetails>
    </Accordion>
  )
}

export default CancellationPolicyAccordion
