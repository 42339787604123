import React, { FC, useEffect, useCallback, useState, useRef } from 'react'
import { Box, Card, Collapse, Grid, Tab, Tabs } from '@material-ui/core'
import cn from 'classnames'

import { Text, Title } from '~/components/shared'

import { HowItWorksProps } from './HowItWorksBlock'

import { useDesktopStyles } from './HowItWorksBlock.styles'

const tabsListClass = 'tabsList'
const animationTimeout = 200

const HowItWorksDesktop: FC<HowItWorksProps> = ({ data }) => {
  const s = useDesktopStyles()
  const tabsRef = useRef(null)

  const [activeTab, setActiveTab] = useState<number>(0)
  const [indicatorTop, setIndicatorTop] = useState<number>(0)

  const handleChangeTab = useCallback(
    (_event: React.ChangeEvent<{}>, newValue: number): void => {
      setActiveTab(newValue)
    },
    [tabsRef]
  )

  useEffect(() => {
    if (tabsRef?.current) {
      const list = document.getElementsByClassName(tabsListClass)
      const tabActiveNode = list[0].children[activeTab]

      setTimeout(() => {
        const tabsScrolTop = tabsRef?.current?.scrollTop
        const tabsTop = tabsRef?.current?.getBoundingClientRect().top
        const tabActiveTop = tabActiveNode.getBoundingClientRect().top

        setIndicatorTop(tabActiveTop - tabsTop + tabsScrolTop)
      }, animationTimeout)
    }
  }, [tabsRef, activeTab, data])

  return (
    <Grid container spacing={5}>
      <Grid item xs={6}>
        <Box className={s.imagesWrapper}>
          {data.map(({ value, image, painting, paintingStyles }) => (
            <Box
              key={value}
              className={cn(s.imageRoot, {
                active: activeTab === value
              })}
            >
              <Card className={s.card}>
                <img src={image} alt="step" />
              </Card>

              <img
                src={painting}
                className={s.painting}
                style={paintingStyles.desktop}
                alt="painting"
              />
            </Box>
          ))}
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Tabs
          ref={tabsRef}
          orientation="vertical"
          indicatorColor="primary"
          scrollButtons="off"
          value={activeTab}
          onChange={handleChangeTab}
          TabIndicatorProps={{
            style: {
              top: indicatorTop,
              height: 55
            }
          }}
          classes={{
            root: s.tabs,
            indicator: s.indicator,
            flexContainer: tabsListClass
          }}
        >
          {data.map(({ value, label, title, description }) => (
            <Tab
              key={value}
              value={value}
              disableRipple
              label={
                <>
                  <Text color="primary" mb={0.5} fontWeight={600}>
                    {label}
                  </Text>
                  <Title textAlign="start" variant="h3" mb={1.5}>
                    {title}
                  </Title>
                  <Collapse in={activeTab === value} timeout={animationTimeout}>
                    <Text color="textSecondary">{description}</Text>
                  </Collapse>
                </>
              }
              classes={{
                root: s.tab,
                wrapper: s.wrapper,
                selected: s.selected
              }}
            />
          ))}
        </Tabs>
      </Grid>
    </Grid>
  )
}

export default HowItWorksDesktop
