import React, { FC } from 'react'
import cn from 'classnames'
import { List, ListItem, makeStyles, createStyles } from '@material-ui/core'

import { Text } from '~/components/shared'

const useStyles = makeStyles(
  ({ spacing, palette, breakpoints }) =>
    createStyles({
      list: {
        display: 'flex',
        flexWrap: 'wrap',

        [breakpoints.down('sm')]: {
          flexDirection: 'column'
        }
      },

      item: {
        padding: spacing(0, 0, 2, 0),
        alignItems: 'flex-start',
        width: '50%',

        '&.isSmallPadding': {
          padding: spacing(0, 0, 1, 0)
        },

        '& img': {
          maxWidth: 27,
          minWidth: 25,
          maxHeight: 20,
          margin: spacing('3px', 1, 0, 0)
        },

        '& span': {
          color: palette.text.primary
        },

        '&:last-child': {
          paddingBottom: 0
        },

        [breakpoints.down('xs')]: {
          padding: spacing(0, 0, 1.5, 0),
          width: '100%'
        }
      }
    }),
  {
    name: 'DetailsList'
  }
)

type DetailsListProps = {
  items: CourseDetail[]
  isSmallPadding?: boolean
}

const DetailsList: FC<DetailsListProps> = ({ items, isSmallPadding }) => {
  const s = useStyles()

  return (
    <List disablePadding className={s.list}>
      {items.map(({ icon, label, value }, idx) => (
        <ListItem key={idx} className={cn(s.item, { isSmallPadding })}>
          <img src={icon} alt={label} />

          <Text color="textSecondary" mr={1}>
            {label}: <Text component="span">{value || '-'}</Text>
          </Text>
        </ListItem>
      ))}
    </List>
  )
}

export default DetailsList
