import React, { FC } from 'react'
import {
  makeStyles,
  Card,
  CardContent,
  createStyles,
  Box
} from '@material-ui/core'
import { CroppedTextTooltip, Text } from '~/components/shared'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        width: '100%',
        marginBottom: theme.spacing(2)
      },

      content: {
        '&::after': {
          content: '""',
          display: 'block',
          clear: 'both'
        }
      },

      text: {
        marginBottom: theme.spacing(1),
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      },

      img: {
        maxWidth: 350,
        maxHeight: 200,
        height: 'auto',
        margin: '0 20px 5px 0',
        float: 'left',
        objectFit: 'cover',
        borderRadius: 10,

        [theme.breakpoints.down('sm')]: {
          maxWidth: '100%',
          margin: '0 0 10px 0',
          float: 'initial'
        }
      }
    }),
  {
    name: 'SpecialistCard'
  }
)

type Props = {
  message: string
}

const SpecialistCard: FC<Props & Partial<User>> = ({
  name,
  email,
  photo,
  message
}) => {
  const s = useStyles()

  return (
    <Card className={s.root} variant="outlined">
      <CardContent>
        <Text variant="h5" mb={1.5}>
          Specialist info
        </Text>
        <Box className={s.content}>
          {photo?.url && <img src={photo?.url} className={s.img} />}

          <Box>
            <Text variant="subtitle1">Name</Text>
            <Text className={s.text}>{name}</Text>

            <Text variant="subtitle1">Email</Text>

            <CroppedTextTooltip title={email}>
              <Text>{email}</Text>
            </CroppedTextTooltip>

            {message && (
              <>
                <Text variant="subtitle1" mb={0.8}>
                  Specialist message about this itinerary
                </Text>
                <Text>{message}</Text>
              </>
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

export default SpecialistCard
