import Geosuggest from '~/services/Geosuggest'

const geosuggestOptions = {
  country: 'us'
}

const getCoords = async (input: string): Promise<Coords> => {
  const geosuggest = new Geosuggest()

  try {
    const resp = await geosuggest.searchSuggests(input, geosuggestOptions)
    // eslint-disable-next-line @typescript-eslint/await-thenable
    const suggest = await geosuggest.geocodeSuggest(resp[0], geosuggestOptions)

    return suggest.location
  } catch (error) {
    console.error(error)
  }
}

const generateCoords = async (activity: Activity): Promise<Partial<Marker>> => {
  const markerInfo = {
    title: activity.name,

    description: activity?.description
  }

  if (activity?.latitude && activity?.longitude) {
    return {
      id: activity.id,
      location: activity?.location,
      position: {
        lat: activity?.latitude,
        lng: activity?.longitude
      },
      info: [markerInfo]
    }
  } else if (activity?.location) {
    const position = await getCoords(activity?.location)

    return {
      id: activity.id,
      location: activity?.location,
      position,
      info: [markerInfo]
    }
  } else {
    return {}
  }
}

export const generateCenter = async (
  questionnaire: Questionnaire
): Promise<Coords> | null => {
  if (questionnaire?.latitude && questionnaire?.longitude) {
    return {
      lat: questionnaire?.latitude,
      lng: questionnaire?.longitude
    }
  } else if (questionnaire?.fields?.address_street) {
    const position = await getCoords(questionnaire?.fields?.address_street)

    return position
  } else {
    return null
  }
}

export default generateCoords
