import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toastr } from 'react-redux-toastr'
import cn from 'classnames'
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'
import {
  Dialog,
  DialogContent,
  Button,
  Link as MuiLink,
  makeStyles,
  createStyles,
  Box
} from '@material-ui/core'

import { ExtendedButton, Flexbox, Text } from '~/components/shared'
import DialogTitle from '~/components/dialogs/DialogTitle'

import PMK from '~/services/PMK'

import NotFoundImage from '~/assets/images/404.png'

const useStyles = makeStyles(
  () =>
    createStyles({
      uploadBtn: {
        minWidth: 110,
        cursor: 'default',

        '& svg': {
          color: '#fff'
        }
      }
    }),
  {
    name: 'LoadActivitiesDialog'
  }
)

const options = {
  buttons: {
    showAutoplayButton: false,
    showCloseButton: true,
    showDownloadButton: false,
    showFullscreenButton: false,
    showNextButton: false,
    showPrevButton: false,
    showThumbnailsButton: false
  }
}

type Props = {
  in?: boolean
  activityProviderId?: string
  onClose: () => {}
  onConfirm?: () => void
}

const LoadActivitiesDialog: React.FC<Props> = ({
  in: open,
  activityProviderId,
  onClose,
  ...rest
}) => {
  const s = useStyles()

  const history = useHistory()

  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleInputChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    try {
      const { files } = e.target
      const formData = new FormData()

      setIsLoading(true)
      setErrors({})
      formData.append('file', files[0])

      const resp = await PMK.campDirectorUploadCsv(activityProviderId, formData)

      if (resp.success) {
        setIsLoading(false)
        toastr.success('Success:', 'The file was successfully uploaded')
        // @ts-ignore
        history.goBack()
      }
    } catch (error) {
      if ((error as { errors: [] })?.errors) {
        setErrors((error as { errors: [] })?.errors)
      } else {
        toastr.error('Error:', 'File failed upload')
      }
      setIsLoading(false)
    }
  }

  return (
    <Dialog {...rest} fullWidth open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>
        Upload activity with XLSX file
      </DialogTitle>

      <Box display="flex" justifyContent="center">
        <SimpleReactLightbox>
          <SRLWrapper options={options}>
            <a href={NotFoundImage}>
              <img
                src={NotFoundImage}
                style={{ objectFit: 'contain', objectPosition: 'center' }}
                alt="Example"
                width={200}
                height={100}
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </Box>

      <DialogContent>
        <Text color="textSecondary" textAlign="center" mb={1}>
          Here you can upload your activity using the file. Below you can
          download an example of a file as it should look.
        </Text>

        <Flexbox justifyContent="center" mb={1}>
          <Button
            size="medium"
            variant="contained"
            color="primary"
            component="a"
            href={`${process.env.REACT_APP_API}/api/v1/camp_directors/download_templates`}
          >
            Download example file
          </Button>
        </Flexbox>

        <Text color="textSecondary" textAlign="center" mb={1}>
          If you have any questions about downloading the file you can contact
          our mail:{' '}
          <MuiLink
            color="secondary"
            href="mailto:info@planmykids.com"
            style={{ fontWeight: 700 }}
          >
            info@planmykids.com
          </MuiLink>
        </Text>

        <Text textAlign="center" color="textSecondary" mb={1}>
          Below you can upload the already completed activity.
        </Text>
        <Flexbox justifyContent="center" mb={1.5}>
          <ExtendedButton
            loading={isLoading}
            fullWidth={false}
            variant="contained"
            component="label"
            className={cn({ [s.uploadBtn]: isLoading })}
          >
            Upload file
            <input
              type="file"
              accept=".xlsx"
              onChange={handleInputChange}
              hidden
            />
          </ExtendedButton>
        </Flexbox>

        {Object.entries(errors || {}).map(([key, value]) => {
          return (
            <>
              <Text mb={1.5} textAlign="center" color="error">
                <Text component="span" fontWeight={600} color="error">
                  Error in {key}:
                </Text>{' '}
                {value}
              </Text>
            </>
          )
        })}
      </DialogContent>
    </Dialog>
  )
}

export default LoadActivitiesDialog
