import queryString from 'query-string'
import isEmpty from 'lodash.isempty'
import moment from 'moment'

import { humanizeDate } from '~/utils/functions'

/**
 * Create initialValues from queryParams
 *
 * @param search query string
 */
const createValues = (
  search: string,
  { min_price: defaultMinPrice, max_price: defaultMaxPrice }: ActivitiesMeta
): {
  initialValues: Partial<SearchInput>
  priceInQuery: priceQuery
} => {
  const queryValues = queryString.parse(search, { arrayFormat: 'comma' })

  let initialValues = {}

  let priceInQuery: priceQuery = {
    min: false,
    max: false
  }

  if (isEmpty(queryValues)) {
    initialValues = {
      price: [defaultMinPrice, defaultMaxPrice],
      radius: 500,
      page: 1
    }
  } else {
    const {
      query,
      start_date,
      end_date,
      min_price,
      max_price,
      camp_type,
      page,
      distance,
      interests,
      skill_development,
      ...values
    } = queryValues

    const dates = [
      start_date ? moment(start_date) : null,
      end_date ? moment(end_date) : null
    ]

    const prices = [
      min_price ? Number(min_price) : defaultMinPrice,
      max_price ? Number(max_price) : defaultMaxPrice
    ]

    priceInQuery = {
      min: !!min_price,
      max: !!max_price
    }

    initialValues = values
    initialValues['search'] = query
    initialValues['date'] = JSON.stringify(dates)
    initialValues['price'] = prices
    initialValues['type'] = camp_type
    initialValues['radius'] = distance ? Number(distance) : 500
    initialValues['page'] = page ? Number(page) : 1

    if (interests) {
      initialValues['interests'] = Array.isArray(interests)
        ? interests
        : [interests]
    }

    if (skill_development) {
      initialValues['skill_development'] = Array.isArray(skill_development)
        ? skill_development
        : [skill_development]
    }
  }

  return {
    initialValues,
    priceInQuery
  }
}

/**
 * Create searchDTO object
 *
 * @param values formValues
 */
const createDTO = (
  values: Partial<SearchInput>,
  priceInQuery?: priceQuery,
  location?: Coords
): Partial<SearchDTO> => {
  const {
    search,
    date,
    price,
    age,
    type,
    grade_level,
    radius,
    page,
    activity_provider,
    interests,
    skill_development,
    available_seats
  } = values

  const dateData = date ? JSON.parse(date) : [null, null]

  const withAvailableSeats = available_seats === 'Yes' ? true : undefined

  const withoutAvailableSeats = available_seats === 'No' ? true : undefined

  const searchDTO = {
    by_search: search || undefined,
    by_start_date: dateData?.[0]
      ? humanizeDate(dateData?.[0], 'YYYY-MM-DD')
      : undefined,
    by_end_date: dateData?.[1]
      ? humanizeDate(dateData?.[1], 'YYYY-MM-DD')
      : undefined,
    by_min_price: priceInQuery.min ? price?.[0] : undefined,
    by_max_price: priceInQuery.max ? price?.[1] : undefined,
    by_camp_type: type || undefined,
    by_age_level: age || undefined,
    by_grade_level: grade_level || undefined,
    by_distance:
      location && radius !== 500
        ? {
            coordinates: [location?.lat, location?.lng],
            radius
          }
        : undefined,
    page: page !== 1 ? page : undefined,
    by_activity_provider: activity_provider || undefined,
    by_interests: interests || undefined,
    by_skill_development: skill_development || undefined,
    by_avaible_seats: withAvailableSeats,
    by_unavaible_seats: withoutAvailableSeats
  }

  return searchDTO
}

/**
 * Create query object
 *
 * @param values formValues
 */
const createQuery = (
  values: SearchInput,
  { min_price: defaultMinPrice, max_price: defaultMaxPrice }: ActivitiesMeta
): string => {
  const {
    search,
    date,
    price,
    age,
    type,
    grade_level,
    radius,
    page,
    activity_provider,
    interests,
    skill_development,
    available_seats
  } = values

  const dateData = date ? JSON.parse(date) : [null, null]

  const queryDTO = {
    query: search || undefined,
    start_date: dateData?.[0]
      ? humanizeDate(dateData?.[0], 'YYYY-MM-DD')
      : undefined,
    end_date: dateData?.[1]
      ? humanizeDate(dateData?.[1], 'YYYY-MM-DD')
      : undefined,
    min_price:
      price?.[0] && price?.[0] !== defaultMinPrice ? price?.[0] : undefined,
    max_price:
      price?.[1] && price?.[1] !== defaultMaxPrice ? price?.[1] : undefined,
    camp_type: type || undefined,
    age: age || undefined,
    grade_level: grade_level || undefined,
    distance: radius !== 500 ? radius : undefined,
    page: page !== 1 ? page : undefined,
    activity_provider: activity_provider || undefined,
    interests: interests || undefined,
    skill_development: skill_development || undefined,
    available_seats: available_seats || undefined
  }

  return queryString.stringify(queryDTO, { arrayFormat: 'comma' })
}

export default {
  createValues,
  createDTO,
  createQuery
}
