import { makeStyles, createStyles } from '@material-ui/core'

export const useDesktopStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        position: 'fixed',
        top: 0,
        width: '100vw',
        zIndex: 1301,
        backgroundColor: theme.palette.common.white,
        boxShadow: '0px 4px 8px rgba(27, 27, 27, 0.1)',
        transition: 'all 0.4s ease-in-out',

        '&.gradient': {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          transition: 'all 0.4s ease-in-out',

          '& .link': {
            color: theme.palette.common.white
          }
        }
      },

      container: {
        height: 84,
        alignItems: 'center'
      },

      links: {
        display: 'flex',
        flex: 1,
        padding: '0 47px',

        '& > a:not(:last-child)': {
          marginRight: theme.spacing(4),

          '@media(max-width: 1200px)': {
            marginRight: theme.spacing(2.5)
          },

          '@media(max-width: 970px)': {
            marginRight: theme.spacing(1.5)
          }
        },

        '@media(max-width: 1200px)': {
          padding: theme.spacing(0, 3)
        },

        '@media(max-width: 970px)': {
          padding: theme.spacing(0, 1)
        }
      },

      btns: {
        '& > *:not(:last-child)': {
          marginRight: theme.spacing(2)
        }
      },

      logOut: {
        backgroundColor: theme.palette.grey[100]
      }
    }),
  {
    name: 'HeaderDesktop'
  }
)

export const useMobileStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        position: 'fixed',
        top: 0,
        zIndex: 1301,
        width: '100%',
        backgroundColor: theme.palette.common.white,
        boxShadow: '0px 4px 8px rgba(27, 27, 27, 0.1)',
        transition: 'all 0.4s ease-in-out',

        '&.gradient': {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          transition: 'all 0.4s ease-in-out',

          '& svg path': {
            stroke: theme.palette.common.white
          }
        },

        '&.isLowered': {
          zIndex: 1299
        }
      },

      container: {
        padding: '0px 3px 0px 15px',
        minHeight: 64,
        alignItems: 'center',
        justifyContent: 'space-between'
      },

      logo: {
        [theme.breakpoints.down('sm')]: {
          height: 40
        }
      },

      header: {
        borderBottom: `1px solid ${theme.palette.text.disabled}`
      },

      paper: {
        width: '100%'
      },

      modal: {
        zIndex: ('1302 !important' as unknown) as number
      },

      linksWrapper: {
        padding: '17.5px 0',
        borderBottom: `1px solid ${theme.palette.text.disabled}`
      },

      btnsWrapper: {
        margin: theme.spacing(3, 2),

        '& > *:not(:last-child)': {
          marginBottom: theme.spacing(1)
        }
      },

      tabItem: {
        position: 'relative',
        padding: 0,

        '& > *': {
          padding: '12.5px 20px',
          width: '100%',

          '& p': {
            fontWeight: 600
          }
        }
      },

      logOut: {
        backgroundColor: theme.palette.grey[100]
      }
    }),
  { name: 'HeaderMobile' }
)
