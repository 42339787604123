import { makeStyles, createStyles } from '@material-ui/core'

const useInfoBlockStyles = makeStyles(
  ({ spacing, palette }) =>
    createStyles({
      infoBlock: {
        border: `1px solid ${palette.text.disabled}`,
        borderRadius: '10px'
      },

      infoImg: {
        width: '100%',
        height: '180px',
        borderRadius: '10px',
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        objectFit: 'cover'
      },

      infoDescription: {
        padding: spacing(2)
      },

      infoTitle: {
        fontWeight: 'bold'
      },

      infoBtn: {
        padding: spacing(2),
        paddingTop: spacing(1)
      }
    }),
  {
    name: 'InfoBlock'
  }
)

export default useInfoBlockStyles
