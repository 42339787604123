import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toastr } from 'react-redux-toastr'

import { Loader } from '~/components/shared'
import PMK from '~/services/PMK'

import { CAMP_DIRECTOR_STATUSES } from '~/constants'
import { campDirectorStatuses } from '~/state/modules/user'
import { login } from '~/state/modules/user/user.slice'
import { useHistory } from 'react-router-dom'
import ROUTES from '~/constants/routes'

const CheckStatus: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { zoho_profile } = useSelector(campDirectorStatuses)

  useEffect(() => {
    const updateStatus = async (): Promise<any> => {
      if (zoho_profile === CAMP_DIRECTOR_STATUSES.show_form) {
        try {
          const resp = await PMK.updateStatusDirtector()
          dispatch(login({ zoho_profile: resp?.status }))
        } catch (error) {
          toastr.error('Error', 'Oops something went wrong!')

          history.push(ROUTES.home)

          console.error(error)
        }
      }
    }

    updateStatus()

    if (zoho_profile !== CAMP_DIRECTOR_STATUSES.show_form) {
      history.push(ROUTES.campDirectorCongratulations)
    }
  }, [zoho_profile])

  return <Loader />
}

export default CheckStatus
