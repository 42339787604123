import React from 'react'
import {
  Box,
  BoxProps,
  CircularProgress,
  CircularProgressProps
} from '@material-ui/core'

type Props = {
  noGutters?: boolean
  height?: string
  boxProps?: BoxProps
}

const Loader: React.FC<Props & CircularProgressProps> = ({
  noGutters = false,
  height = '150px',
  boxProps,
  ...props
}) => (
  <Box
    width="100%"
    m={noGutters ? 0 : 2}
    display="flex"
    justifyContent="center"
    alignItems="center"
    minHeight={height}
    {...boxProps}
  >
    <CircularProgress {...props} />
  </Box>
)

export default Loader
