import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useModal } from 'react-modal-hook'
import {
  Button,
  useMediaQuery,
  makeStyles,
  createStyles
} from '@material-ui/core'

import { ConfirmDialog } from '~/components/dialogs'

import {
  assignItinerary,
  approveItinerary,
  cancelItinerary
} from '~/state/modules/dashboard'

import { STATUSES } from '~/constants/statusesItinerary'
import ROUTES from '~/constants/routes'
import { Flexbox, Text } from '~/components/shared'

export const useAcceptedStyles = makeStyles(
  (theme) =>
    createStyles({
      btnsRow: {
        flexDirection: 'row',
        width: 'fit-content',

        '& > *:not(:last-child)': {
          marginRight: theme.spacing(1)
        }
      },

      btnsColumn: {
        flexDirection: 'column',
        width: '100%',

        '& > *': {
          marginTop: theme.spacing(1)
        }
      }
    }),
  {
    name: 'DashboardTable'
  }
)

type Props = {
  id: string
  activeTab: StatusesUnion
  isMobile?: boolean
  hasChild?: boolean
  hasPaid?: boolean
}

const ItineraryButtons: FC<Props> = ({
  id,
  activeTab,
  hasChild,
  isMobile,
  hasPaid
}) => {
  const s = useAcceptedStyles()
  const dispatch = useDispatch()
  const isHorizontalButtonsLayout = useMediaQuery('(max-width:1082px)')

  const [showApproveModal, hideApproveModal] = useModal(
    (props: any) => (
      <ConfirmDialog
        onClose={hideApproveModal}
        onConfirm={(): void => {
          dispatch(approveItinerary(id))
        }}
        text="Are you sure want to approve this itinerary?"
        {...props}
      />
    ),
    [id]
  )

  const [showCancelModal, hideCancelModal] = useModal(
    (props: any) => (
      <ConfirmDialog
        onClose={hideCancelModal}
        onConfirm={(): void => {
          dispatch(cancelItinerary(id))
        }}
        cancelText="Repeal"
        text="Are you sure want to cancel this itinerary?"
        {...props}
      />
    ),
    [id]
  )

  return ((): JSX.Element => {
    switch (activeTab) {
      case STATUSES.draft:
        if (hasChild) {
          if (hasPaid)
            return (
              <Button
                data-cy="itinerary-assign-button"
                size="small"
                variant="contained"
                color="secondary"
                onClick={(): void => {
                  dispatch(assignItinerary(id))
                }}
                fullWidth={isMobile}
              >
                Assign to me
              </Button>
            )

          if (!hasPaid) return <Text>Not Paid</Text>
        }

        return (
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            fullWidth={isMobile}
            onClick={showCancelModal}
          >
            Cancel
          </Button>
        )

      case STATUSES.in_progress:
        return (
          <>
            <Button
              component={Link}
              to={`${ROUTES.itinerary}/${id}`}
              size="small"
              variant="contained"
              color="secondary"
              fullWidth={isMobile}
            >
              Create itinerary
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              fullWidth={isMobile}
              onClick={showCancelModal}
            >
              Cancel
            </Button>
          </>
        )
      case STATUSES.accepted:
        return (
          <Flexbox
            className={isHorizontalButtonsLayout ? s.btnsColumn : s.btnsRow}
          >
            <Button
              component={Link}
              to={`${ROUTES.itinerary}/${id}`}
              size="small"
              variant="contained"
              color="secondary"
              fullWidth={isMobile}
            >
              View itinerary
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              fullWidth={isMobile}
              onClick={showApproveModal}
            >
              Approve
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              fullWidth={isMobile}
              onClick={showCancelModal}
            >
              Cancel
            </Button>
          </Flexbox>
        )
      case STATUSES.canceled:
        return (
          <Button
            component={Link}
            to={`${ROUTES.itinerary}/${id}`}
            size="small"
            variant="contained"
            color="secondary"
            fullWidth={isMobile}
          >
            View itinerary
          </Button>
        )
      default:
        return (
          <>
            <Button
              component={Link}
              to={`${ROUTES.itinerary}/${id}`}
              size="small"
              variant="contained"
              color="secondary"
              fullWidth={isMobile}
            >
              View itinerary
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              fullWidth={isMobile}
              onClick={showCancelModal}
            >
              Cancel
            </Button>
          </>
        )
    }
  })()
}

export default ItineraryButtons
