import { Overrides as CoreOverrides } from '@material-ui/core/styles/overrides'
import {
  AutocompleteClassKey,
  PaginationClassKey,
  PaginationItemClassKey
} from '@material-ui/lab'
import { CSSProperties } from '@material-ui/styles'

import palette from './palette'

interface Overrides extends CoreOverrides {
  MuiPagination?:
    | Partial<Record<PaginationClassKey, CSSProperties | (() => CSSProperties)>>
    | undefined
  MuiPaginationItem?:
    | Partial<
        Record<PaginationItemClassKey, CSSProperties | (() => CSSProperties)>
      >
    | undefined
  MuiAutocomplete?:
    | Partial<
        Record<AutocompleteClassKey, CSSProperties | (() => CSSProperties)>
      >
    | undefined
}

const overrides: Overrides = {
  MuiCssBaseline: {
    '@global': {
      'body, #root': {
        height: '100%',
        margin: 0
      },
      body: {
        overflowX: 'hidden'
      },
      img: {
        // width: '100%',
        height: '100%'
      },
      a: {
        fontSize: 16,
        textDecoration: 'none'
      },
      ul: {
        margin: '5px 0',
        fontSize: '16px',
        lineHeight: '26px',
        padding: '0 20px'
      },
      ol: {
        margin: '5px 0'
      }
    }
  },

  // Button
  MuiButton: {
    root: {
      fontSize: 16,
      fontWeight: 700,
      textTransform: 'initial',
      borderRadius: 10,
      minHeight: 42,
      padding: '7px 30px'
    },
    sizeLarge: {
      minHeight: 50,
      padding: '10px 30px'
    },
    sizeSmall: {
      minHeight: 36,
      padding: '3px 15px',
      fontSize: 16,
      fontWeight: 600
    },
    contained: {
      boxShadow: 'none'
    },
    outlined: {
      padding: '5px 30px',
      borderColor: palette.text.disabled,
      color: palette.text.secondary
    },
    outlinedPrimary: {
      backgroundColor: 'rgba(112, 57, 80, 0.1)'
    },
    outlinedSizeSmall: {
      '&:not(.MuiButton-outlinedPrimary)': {
        // @ts-ignore
        fontWeight: '400 !important'
      }
    }
  },

  // Input
  MuiInputBase: {
    root: {
      '&$disabled': {
        color: palette.text.hint,
        backgroundColor: palette.background.default
      }
    },
    input: {
      '&::placeholder': {
        color: palette.text.secondary,
        opacity: 1
      }
    }
  },
  MuiOutlinedInput: {
    root: {
      borderRadius: 10
    },
    input: {
      padding: '15.5px 14px'
    },
    notchedOutline: {
      borderColor: palette.text.disabled
    }
  },

  // Label
  MuiInputLabel: {
    outlined: {
      transform: 'translate(14px, 17px) scale(1)'
    }
  },
  MuiFormControlLabel: {
    root: {
      marginRight: 20
    }
  },

  // Autocomplete
  MuiAutocomplete: {
    inputRoot: {
      minHeight: 50,
      paddingTop: '5px !important',
      paddingBottom: '5px !important'
    },
    tag: {
      padding: '7px 15px',
      color: palette.text.secondary,
      backgroundColor: palette.grey[100],
      border: `1px solid ${palette.text.disabled}`,
      borderRadius: 16,

      '& svg': {
        width: 8,
        minWidth: 8,
        color: palette.text.hint
      }
    },
    popupIndicator: {
      color: palette.text.hint
    },
    clearIndicator: {
      color: palette.text.hint
    }
  },

  //Paper
  MuiPaper: {
    elevation1: {
      boxShadow: '5px 5px 15px rgba(35, 35, 35, 0.15)'
    },
    rounded: {
      borderRadius: 10
    },
    outlined: {
      borderColor: palette.text.disabled,
      boxShadow: 'none !important'
    }
  },

  // Card
  MuiCard: {
    root: {
      boxShadow: '5px 5px 15px rgba(35, 35, 35, 0.15)'
    }
  },

  // CardContent
  MuiCardContent: {
    root: {
      padding: 20,

      '&:last-child': {
        paddingBottom: '-'
      }
    }
  },

  // Divider
  MuiDivider: {
    root: {
      backgroundColor: palette.text.disabled
    }
  },

  // SvgIcon
  MuiSvgIcon: {
    colorDisabled: {
      color: palette.text.hint
    }
  },

  // Dialog
  MuiDialog: {
    root: {
      zIndex: ('1302 !important' as unknown) as number
    }
  },
  MuiDialogTitle: {
    root: {
      padding: '50px 30px 30px'
    }
  },
  MuiDialogContent: {
    root: {
      padding: '0 30px 15px',
      marginBottom: 35,
      overflowX: 'hidden',

      '&.MuiPickersModalDialog-dialog': {
        borderRadius: 10
      },

      '&::-webkit-scrollbar': {
        '@media(max-width: 600px)': {
          display: 'none'
        }
      }
    }
  },

  // Chip
  MuiChip: {
    root: {
      height: 36,
      fontSize: 16,
      padding: '7px 15px 7px 20px',
      maxWidth: 300
    },
    label: {
      paddingLeft: 0,
      paddingRight: 0
    },
    deleteIcon: {
      marginLeft: 10,
      marginRight: '0!important'
    }
  },

  // Pagination
  MuiPagination: {
    root: {
      display: 'flex',
      justifyContent: 'center'
    },
    ul: {
      border: `1px solid ${palette.text.disabled}`,
      borderRadius: 4,
      overflow: 'hidden',

      '& li:not(:last-child)': {
        borderRight: `1px solid ${palette.text.disabled}`
      }
    }
  },
  MuiPaginationItem: {
    root: {
      height: 42,
      minWidth: 45,
      margin: 0,
      borderRadius: 'inherit'
    },
    ellipsis: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 42
    }
  },

  // Switch
  MuiSwitch: {
    track: {
      opacity: 1,
      backgroundColor: palette.text.disabled
    }
  },

  // Accordion
  MuiAccordion: {
    root: {
      boxShadow: 'none',
      border: `1px solid ${palette.text.disabled}`,
      borderRadius: '10px !important'
    }
  },
  MuiAccordionSummary: {
    root: {
      padding: '15px 20px 15px 15px',

      '&$expanded': {
        minHeight: 'auto'
      }
    },
    content: {
      margin: 0,

      '&$expanded': {
        margin: 0
      }
    },
    expandIcon: {
      margin: -12,

      '& svg path': {
        stroke: palette.text.hint
      }
    }
  },
  MuiAccordionDetails: {
    root: {
      display: 'block',
      padding: 0
    }
  },

  // Checkbox
  MuiCheckbox: {
    root: {
      color: palette.text.disabled,

      '&$checked': {
        color: 'inherit'
      }
    }
  },

  // Tooltip
  MuiTooltip: {
    tooltip: {
      backgroundColor: 'rgba(97, 97, 97, 0.97)',
      fontSize: 14
    }
  }
}

export default overrides
