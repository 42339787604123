import { useMemo, useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router'

const usePagination = <T>(
  routerPage: string,
  data: T[],
  numberPerPage: number,
  defaultPath?: Partial<Location> | string
): {
  renderData: T[]
  currentPage: number
  countPages: number
  handleChange: (value: number) => void
} => {
  const h = useHistory()

  const page = useMemo(() => {
    let paramsPage = 1

    if (routerPage) {
      const resultRegex = routerPage?.match(/^\?page-(.+)/)?.[1]

      const pageNumber = resultRegex ? Number(resultRegex) : 1
      paramsPage = pageNumber

      if (!resultRegex && !!defaultPath) {
        h.push(defaultPath)
      }
    }

    return paramsPage
  }, [routerPage, defaultPath])

  const [currentPage, setCurrentPage] = useState<number>(page)
  const [countPages, setCountPages] = useState<number>(0)

  const handleChange = useCallback((value: number) => {
    setCurrentPage(value)
  }, [])

  useEffect(() => {
    if (isNaN(page) || page < 1) setCurrentPage(1)
    else if (countPages && page > countPages) setCurrentPage(countPages)
    else setCurrentPage(page)
  }, [page, countPages])

  useEffect(() => {
    if (data) setCountPages(Math.ceil(data?.length / numberPerPage))
  }, [data])

  const renderData = useMemo(() => {
    const begin = (currentPage - 1) * numberPerPage
    const end = begin + numberPerPage

    return data?.slice(begin, end)
  }, [data, currentPage])

  return { renderData, currentPage, countPages, handleChange }
}

export default usePagination
