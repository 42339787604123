import { makeStyles, createStyles } from '@material-ui/core'

const useItinerariesStyles = makeStyles(
  ({ palette, breakpoints, spacing }) =>
    createStyles({
      btns: {
        '& > *:not(:last-child)': {
          marginRight: spacing(1)
        }
      },

      titleWrapper: {
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: spacing(5),
        width: '100%',

        '& h4': {
          width: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'start'
        },

        [breakpoints.down('xs')]: {
          flexDirection: 'column-reverse',
          alignItems: 'flex-start',

          '& label': {
            margin: '0 0 10px 0'
          },

          '& button': {
            width: '100%',
            marginBottom: spacing(2)
          },

          '&.buttons': {
            flexDirection: 'column'
          }
        }
      },

      radiosWrapper: {
        alignItems: 'center',
        marginBottom: spacing(3),

        [breakpoints.down('xs')]: {
          flexDirection: 'column',
          alignItems: 'flex-start',

          '& p': {
            marginBottom: spacing(1)
          }
        }
      },

      labelMap: {
        whiteSpace: 'nowrap'
      },

      labelGroup: {
        margin: '0 -10px -10px -19px'
      },

      label: {
        height: 20,
        margin: '0 10px 10px',

        '& svg': {
          width: 16,
          height: 16
        },

        '&.checked span': {
          fontWeight: 600
        }
      },

      total: {
        color: palette.text.primary,
        fontWeight: 600
      },

      labelText: {
        display: '-webkit-box',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        '-webkit-line-clamp': '1',
        '-webkit-box-orient': 'vertical',
        wordBreak: 'break-word',
        maxWidth: 400
      }
    }),
  {
    name: 'Itineraries'
  }
)

export default useItinerariesStyles
