import React from 'react'
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router'

// Pages
import Programs from './programs'
import Orders from './orders'
import Profile from './profile'
import EditProgram from './programs/[id]/program.component'
import CreateProgram from './create-program'
import CreateActivity from './programs/[id]/create-activity'
import EditActivity from './programs/[id]/edit-activity/[activityId]'
import CongratulationsDirector from './congratulations'
import StripeInformation from './stripe'
import CheckStatus from './check-status'

import withProtectCampDirector from '~/containers/withProtectCampDirector'
import StripePending from './stripe-pending'
import StripeFailure from './stripe-failure'
import DuplicateActivity from './programs/[id]/duplicate-activity'

const CampDirectorRoute: React.FC<RouteComponentProps> = ({
  match: { path }
}): JSX.Element => {
  return (
    <Switch>
      <Route
        exact
        path={`${path}/programs`}
        component={withProtectCampDirector(Programs)}
      />
      <Route
        exact
        path={`${path}/profile`}
        component={withProtectCampDirector(Profile)}
      />
      <Route
        exact
        path={`${path}/orders`}
        component={withProtectCampDirector(Orders)}
      />
      <Route
        exact
        path={`${path}/create-program`}
        component={withProtectCampDirector(CreateProgram)}
      />
      <Route
        exact
        path={`${path}/program/:id`}
        component={withProtectCampDirector(EditProgram)}
      />
      <Route
        exact
        path={`${path}/program/:id/edit-activity/:activityId`}
        component={withProtectCampDirector(EditActivity)}
      />
      <Route
        exact
        path={`${path}/program/:id/create-activity`}
        component={withProtectCampDirector(CreateActivity)}
      />
      <Route
        exact
        path={`${path}/program/:id/duplicate-activity/:activityId`}
        component={withProtectCampDirector(DuplicateActivity)}
      />
      <Route
        exact
        path={`${path}/congratulations`}
        component={withProtectCampDirector(CongratulationsDirector)}
      />
      <Route
        exact
        path={`${path}/stripe`}
        component={withProtectCampDirector(StripeInformation)}
      />
      <Route exact path={`${path}/check-status`} component={CheckStatus} />
      <Route exact path={`${path}/stripe-pending`} component={StripePending} />
      <Route exact path={`${path}/stripe-failure`} component={StripeFailure} />
      <Redirect from={path} to={path} />
    </Switch>
  )
}

export default CampDirectorRoute
