import React, { FC } from 'react'
import { toastr } from 'react-redux-toastr'
import { useSelector } from 'react-redux'
import debounce from 'lodash.debounce'
import { useLocation } from 'react-router'
import { Button, Container, Grid, Paper } from '@material-ui/core'

import FullWidthMobile from '~/containers/FullWidthMobile'

import { Text, Title, CroppedTextTooltip } from '~/components/shared'

import { sendResendEmail, sendResendDirectorEmail } from '~/state/modules/user'

import { STORAGE } from '~/constants/storage'
import { AppState } from '~/state/store'

import useStaticStyles from '~/styles/static-pages.styles'

const Verify: FC = () => {
  const s = useStaticStyles()
  const location = useLocation()

  const email = localStorage.getItem(STORAGE.userEmail)
  const userEmail = useSelector<AppState, string>((state) => state.user.email)

  const currentEmail = email || userEmail

  return (
    <FullWidthMobile>
      <Container maxWidth="md" className={s.container}>
        <Paper className={s.paper}>
          <Grid container spacing={3} direction="column" alignItems="center">
            <Grid item xs={12}>
              <Title>Verification Email Sent</Title>
            </Grid>

            <Grid item xs={12}>
              <Text textAlign="center" color="textSecondary">
                To confirm it&apos;s you, we&apos;ve sent an email to{' '}
                <CroppedTextTooltip title={email}>
                  <b>{email}.</b>
                </CroppedTextTooltip>
                <br />
                Once you&apos;ve located it, click the link and you are off!
              </Text>
            </Grid>

            {!location.state?.hide && email && (
              <Grid item xs={12}>
                <Button
                  size="large"
                  variant="outlined"
                  color="primary"
                  onClick={debounce((): void => {
                    if (location.state?.director) {
                      sendResendDirectorEmail(currentEmail).then(() => {
                        toastr.success(
                          'Email',
                          `You have been successfully sent an email to ${currentEmail} with a confirmation link`
                        )
                      })
                    } else {
                      sendResendEmail(currentEmail).then(() => {
                        toastr.success(
                          'Email',
                          `You have been successfully sent an email to ${currentEmail} with a confirmation link`
                        )
                      })
                    }
                  }, 800)}
                >
                  Resend email
                </Button>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Container>
    </FullWidthMobile>
  )
}

export default Verify
