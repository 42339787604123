import React, { FC } from 'react'
import { WrappedFieldProps } from 'redux-form'
import NumberFormat, { NumberFormatProps } from 'react-number-format'
import {
  InputProps,
  TextField as TextInput,
  TextFieldProps
} from '@material-ui/core'

type Props = {
  displayError?: boolean
  isOptional?: boolean
  helperText?: string
  prefix?: string
  isAllowed?: any
  startAdornment: any
}

type AllProps = WrappedFieldProps & TextFieldProps & InputProps & Props

interface NumberFormatCustomProps extends NumberFormatProps {
  inputRef: (instance: NumberFormat | null) => void
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const NumberFormatCustom: React.FC<NumberFormatCustomProps> = (props) => {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values): void => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      thousandSeparator
      isNumericString
    />
  )
}

const NumberField: FC<AllProps> = ({
  input: { value, onChange, ...input },
  meta: { touched, error },
  displayError,
  helperText,
  isOptional,
  prefix,
  isAllowed,
  startAdornment,
  ...rest
}) => {
  const errorMessage = (touched && error) || displayError
  const required = isOptional ? false : true

  return (
    <TextInput
      data-cy="text-field"
      variant="outlined"
      error={!!errorMessage}
      helperText={errorMessage || helperText}
      InputLabelProps={{ shrink: true, ...rest.InputLabelProps }}
      value={value || ''}
      InputProps={{
        inputComponent: NumberFormatCustom as any,
        startAdornment,
        ...rest.InputProps
      }}
      inputProps={{
        'data-required': required,
        prefix,
        isAllowed,
        ...rest.inputProps
      }}
      onChange={(values): void => {
        onChange(values.target.value)
      }}
      fullWidth
      {...input}
      {...rest}
    />
  )
}

export default NumberField
