import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getOrders } from '.'
import { markOrderAsProcessed } from '.'

export interface OrdersState {
  item: Partial<Orders>
  data: Orders[]
  loading: boolean
  loaded: boolean
  error: any
  ordersMeta: PagyModel
}

const initialState: OrdersState = {
  data: [],
  item: {},
  loading: false,
  loaded: false,
  ordersMeta: { count: 0, pages: 1, page: 1 },
  error: null
}

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    deleteOrders(state): void {
      state.data = []
    },
    changeOrdersPage(state, { payload }: PayloadAction<number>): void {
      state.ordersMeta.page = payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getOrders.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getOrders.fulfilled, (state, { payload }) => {
      state.loading = false
      state.loaded = true
      state.data = payload.data
      state.ordersMeta = payload.meta
    })
    builder.addCase(getOrders.rejected, (state) => {
      state.loading = false
      state.error = true
    })
    builder.addCase(markOrderAsProcessed.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(markOrderAsProcessed.fulfilled, (state, { payload }) => {
      state.loading = false
      state.loaded = true
      const foundIndex = state.data.findIndex(({ id }) => id === payload.id)
      state.data[foundIndex].processed = payload.processed
    })
    builder.addCase(markOrderAsProcessed.rejected, (state) => {
      state.loading = false
      state.error = true
    })
  }
})

export const { changeOrdersPage, deleteOrders } = ordersSlice.actions

export default ordersSlice.reducer
