import ROUTES from '~/constants/routes'

export const ProfileArray: Option[] = [
  {
    label: 'Profile',
    value: ROUTES.profile
  },
  {
    label: 'Calendar',
    value: ROUTES.calendar
  },
  {
    label: 'Itineraries',
    value: ROUTES.itineraries
  },
  {
    label: 'Questionnaire',
    value: ROUTES.questionnaire
  },
  {
    label: 'Share with me',
    value: ROUTES.itinerariesFriends
  }
]
