import { makeStyles, createStyles } from '@material-ui/core'

export const useProgramActivitiesStyles = makeStyles(
  ({ spacing, breakpoints, palette }) =>
    createStyles({
      card: {
        display: 'flex',
        boxShadow: 'none',
        border: `1px solid ${palette.text.disabled}`,
        marginBottom: 20,

        [breakpoints.down('md')]: {
          flexDirection: 'column'
        }
      },

      titleBlock: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        padding: spacing(2),
        backgroundColor: palette.grey[100],

        [breakpoints.down('md')]: {
          flex: 1
        }
      },

      duplicate: { backgroundColor: palette.grey[300] },

      duplicateInfo: {
        [breakpoints.down('xs')]: {
          margin: '0 auto',
          marginTop: 15
        }
      },

      activityName: {
        wordBreak: 'break-all'
      },

      seeMore: {
        display: 'inline-flex',
        alignItems: 'center',
        marginRight: spacing(1.5),

        '& svg': {
          marginRight: spacing(1)
        }
      },

      optionsBlock: {
        display: 'flex',
        flexDirection: 'column',
        flex: 2,
        padding: spacing(2),

        [breakpoints.down('md')]: {
          flex: 1,
          flexDirection: 'column',
          borderTop: `1px solid ${palette.text.disabled}`
        }
      },

      firstRowDetails: {
        flex: 1,
        margin: spacing(0, 2, 0, 0),

        [breakpoints.down('md')]: {
          margin: spacing(0, 0, 2, 0)
        }
      },

      duration: {
        color: `${palette.text.secondary}`
      },

      campInfoTitle: {
        [breakpoints.down('sm')]: {
          whiteSpace: 'nowrap'
        }
      },

      campInfoTime: {
        [breakpoints.down('sm')]: {
          textOverflow: 'clip',
          maxWidth: '95px',
          whiteSpace: 'nowrap',
          overflow: 'hidden'
        }
      },

      iconBtn: {
        minHeight: 0,
        minWidth: 10,
        width: 20,
        height: 20,
        padding: 0,
        alignItems: 'end'
      },

      startIcon: {
        marginRight: 0
      },

      infoNote: {
        position: 'absolute',
        top: 0,
        right: -3,
        lineHeight: 0
      },

      providerInfo: {
        fontWeight: 'bold',
        marginBottom: spacing(1),
        color: palette.text.secondary,
        marginRight: spacing(1)
      },

      activitiesInfo: {
        fontWeight: 'bold',
        marginBottom: spacing(1.5),
        color: palette.common.black,
        marginRight: spacing(0.5)
      },

      wrapText: {
        display: '-webkit-box',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        '-webkit-line-clamp': '1',
        '-webkit-box-orient': 'vertical',
        wordBreak: 'break-all'
      },

      actionBtn: {
        maxWidth: 150,
        minWidth: 150,

        [breakpoints.down('xs')]: {
          maxWidth: '100%'
        }
      },

      actionContainer: {
        justifyContent: 'space-between',

        [breakpoints.down('lg')]: {
          justifyContent: 'start'
        },

        [breakpoints.down('xs')]: {
          flexDirection: 'column'
        }
      }
    }),
  {
    name: 'ProgramActivities'
  }
)
