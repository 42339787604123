import React, { FC, useCallback, useState } from 'react'
import { Box, Button, Card, CardContent, Grid } from '@material-ui/core'
import cn from 'classnames'

import { Flexbox, Text, Title } from '~/components/shared'

import { ReactComponent as CheckIcon } from '~/assets/icons/check-icon.svg'
import { ReactComponent as CheckActiveIcon } from '~/assets/icons/check-active-icon.svg'

import usePaymentPlanCardStyles, {
  CustomSwitch
} from './PaymentPlanCard.styles'
import { PACKAGES_MESSAGES, PACKAGES_TYPE } from '~/constants/packages'
import { commonAdvantages } from '~/utils/constants/packages.constants'

export type PaymentPlanProps = {
  title: string
  price?: string
  subtitle?: string
  currentAdvantages: string[]
  buttonText: string
  isPremium?: boolean
  onClickBtn: () => void
  onChangePackage?: (isAnnually: boolean) => void
}

const PaymentPlanCard: FC<PaymentPlanProps> = ({
  title,
  price,
  subtitle,
  currentAdvantages,
  buttonText,
  isPremium,
  onClickBtn,
  onChangePackage
}) => {
  const s = usePaymentPlanCardStyles()

  const [isAnnually, setIsAnnually] = useState(false)

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const value = event.target.checked

      setIsAnnually(value)
      onChangePackage(value)
    },
    []
  )

  const packageBenefits = isAnnually
    ? 'Unlimited Planing for a year'
    : 'Single Plan'

  return (
    <Card
      className={cn(s.card, {
        isPremium
      })}
    >
      <CardContent className={s.cardContent}>
        <Flexbox alignItems="center" flexDirection="column" height="100%">
          <Text mb={2} variant="h5" fontWeight="600">
            {title}
          </Text>

          {isPremium && (
            <Grid
              component="label"
              container
              alignItems="center"
              justify="center"
              spacing={1}
            >
              <Grid item>
                <Text variant="subtitle1" className={s.switchText}>
                  {PACKAGES_MESSAGES[PACKAGES_TYPE.one_time]}
                </Text>
              </Grid>
              <Grid item>
                <CustomSwitch checked={isAnnually} onChange={handleChange} />
              </Grid>
              <Grid item>
                <Text variant="subtitle1" className={s.switchText}>
                  {PACKAGES_MESSAGES[PACKAGES_TYPE.annually]}
                </Text>
              </Grid>
            </Grid>
          )}

          <Title
            customClassName={cn(s.title, {
              withTopPadding: !isPremium
            })}
          >
            {isPremium ? (isAnnually ? '$99 ' : '$36') : price}
          </Title>

          <Box className={s.subtitleWrapper}>
            <Text variant="body2" fontWeight="600" color="primary">
              {isPremium ? packageBenefits : subtitle}
            </Text>
          </Box>

          <Box minHeight="180px" mb={3} flex={1}>
            {currentAdvantages.map((advantage, idx) => (
              <Flexbox mb={1} key={`current-${idx}`}>
                <CheckActiveIcon />
                <Text color="textSecondary" ml={1}>
                  {advantage}
                </Text>
              </Flexbox>
            ))}
            {commonAdvantages.map((advantage, idx) => (
              <Flexbox mb={1} key={`common-${idx}`}>
                <CheckIcon />
                <Text color="textSecondary" ml={1}>
                  {advantage}
                </Text>
              </Flexbox>
            ))}
          </Box>

          <Button
            size="large"
            variant={isPremium ? 'contained' : 'outlined'}
            color="primary"
            onClick={onClickBtn}
          >
            {buttonText}
          </Button>
        </Flexbox>
      </CardContent>
    </Card>
  )
}

export default PaymentPlanCard
