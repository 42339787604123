import React, { FC, useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import { shallowEqual, useSelector } from 'react-redux'
import { createStyles, makeStyles, Paper } from '@material-ui/core'

import withPremium from '~/containers/withPremium'
import { Flexbox } from '~/components/shared'

import { generateIframe } from './questionnaire.utils'

import { AppState } from '~/state/store'
import {
  QuestionnaireFormStatus,
  ZOHO_FORM_CONFIG
} from './questionnaire.config'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      container: {
        width: '100%',
        margin: theme.spacing(3, 'auto', 6),
        padding: theme.spacing(5, 12.5),

        [theme.breakpoints.down('lg')]: {
          padding: '50px'
        },

        [theme.breakpoints.down('sm')]: {
          padding: '50px 0'
        },

        [theme.breakpoints.down('xs')]: {
          width: '100vw',
          position: 'relative',
          left: '50%',
          right: '50%',
          margin: '100px -50vw 0',
          borderRadius: 'inherit',
          boxShadow: 'none'
        }
      }
    }),
  {
    name: 'Questionnaire'
  }
)

type RouterState = {
  itineraryId: string
}

type RouteProps = RouteComponentProps<{}, {}, RouterState>

const Questionnaire: FC<RouteProps> = ({ location }) => {
  const s = useStyles()

  const itineraryId = location.state?.itineraryId

  const userData = useSelector<AppState, Partial<User>>(
    (state) => state.user,
    shallowEqual
  )

  const {
    has_not_completed_questionnaire: hasNotCompletedQuestionnaire
  } = userData

  const userProgressStatus = hasNotCompletedQuestionnaire
    ? QuestionnaireFormStatus.COMPLETED
    : QuestionnaireFormStatus.UNCOMPLETED

  const zohoForm = ZOHO_FORM_CONFIG[userProgressStatus]

  const questionary = useSelector<AppState, Questionnaire>(
    (state) =>
      state.questionnaires.data.find(({ id }) => id === itineraryId)
        ?.questionnaire,
    shallowEqual
  )

  useEffect(() => {
    generateIframe(userData, itineraryId ? questionary?.fields : false)
  }, [userData.id, itineraryId, questionary])

  return (
    <Paper className={s.container}>
      <Flexbox justifyContent="center" id={zohoForm.divId()} />
    </Paper>
  )
}

export default withPremium(true)(Questionnaire)
