import React, { FC, useState } from 'react'
import { WrappedFieldProps } from 'redux-form'
import {
  InputAdornment,
  IconButton,
  TextField,
  FormHelperText
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'

type Props = {
  isOptional?: boolean
  hideHelper?: boolean
}

const PasswordField: FC<WrappedFieldProps & Props> = ({
  input,
  meta: { touched, error },
  isOptional,
  hideHelper = false,
  ...rest
}) => {
  const required = isOptional ? false : true
  const [showPassword, setShowPassword] = useState(false)

  return (
    <>
      <TextField
        {...input}
        {...rest}
        variant="outlined"
        fullWidth
        type={showPassword ? 'text' : 'password'}
        error={!!(touched && error)}
        helperText={touched && error}
        inputProps={{
          'data-required': required
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                color="secondary"
                edge="end"
                aria-label="toggle password visibility"
                onClick={(): void => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <Visibility color="disabled" />
                ) : (
                  <VisibilityOff color="disabled" />
                )}
              </IconButton>
            </InputAdornment>
          )
        }}
      />

      {!hideHelper && (
        <FormHelperText variant="filled">
          Password must be between 8 and 50 characters.
        </FormHelperText>
      )}
    </>
  )
}

export default PasswordField
