import React, { FC, useEffect, useState } from 'react'
import { Snackbar, Button } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import * as serviceWorkerRegistration from '~/serviceWorkerRegistration'

const ServiceWorkerWrapper: FC = () => {
  const [showReload, setShowReload] = useState(false)
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null)

  const onSWUpdate = (registration: ServiceWorkerRegistration): void => {
    setShowReload(true)
    setWaitingWorker(registration.waiting)
  }

  useEffect(() => {
    serviceWorkerRegistration.register({ onUpdate: onSWUpdate })
  }, [])

  const reloadPage = (): void => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' })
    setShowReload(false)
    window.location.reload()
  }

  return (
    <Snackbar
      open={showReload}
      onClick={reloadPage}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert
        severity="info"
        variant="filled"
        action={
          <Button color="inherit" size="small" onClick={reloadPage}>
            Reload
          </Button>
        }
      >
        A new version is available!
      </Alert>
    </Snackbar>
  )
}

export default ServiceWorkerWrapper
