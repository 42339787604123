import { makeStyles, createStyles } from '@material-ui/core'

const useCalendarSidebarStyles = makeStyles(
  (theme) =>
    createStyles({
      listItem: {
        paddingTop: 0,
        paddingBottom: 0,
        marginBottom: 10
      },

      labelRoot: {
        color: theme.palette.text.secondary,
        width: '100%'
      },

      label: {
        display: '-webkit-box',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        '-webkit-line-clamp': '1',
        '-webkit-box-orient': 'vertical',
        wordBreak: 'break-word',
        fontSize: 14
      }
    }),
  {
    name: 'CalendarSidebar'
  }
)

export default useCalendarSidebarStyles
