import { makeStyles, createStyles } from '@material-ui/core'

export const useDesktopStyles = makeStyles(
  ({ spacing, palette }) =>
    createStyles({
      imagesWrapper: {
        position: 'relative',
        width: '100%',
        height: '100%'
      },

      imageRoot: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        height: 500,
        opacity: 0,
        transition: 'opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

        '&.active': {
          opacity: 1
        }
      },

      card: {
        height: '100%',
        borderRadius: 20,

        '& img': {
          width: '100%',
          objectFit: 'contain'
        }
      },

      painting: {
        position: 'absolute',
        height: 'auto',
        objectFit: 'cover'
      },

      tabs: {
        position: 'relative',

        '&:after': {
          content: '""',
          position: 'absolute',
          background: palette.grey[200],
          top: 0,
          left: 0,
          height: '100%',
          width: 4,
          borderRadius: 2
        }
      },

      indicator: {
        zIndex: 1,
        left: 0,
        right: 'auto',
        width: 4,
        borderRadius: 2
      },

      tab: {
        padding: spacing(0, 3),
        maxWidth: 'initial',
        textAlign: 'left',
        textTransform: 'initial',
        opacity: 0.7,

        '&:not(:last-child)': {
          marginBottom: spacing(4)
        }
      },

      wrapper: {
        alignItems: 'flex-start'
      },

      selected: {
        opacity: 1
      }
    }),
  {
    name: 'HowItWorksDesktop'
  }
)

export const useMobileStyles = makeStyles(
  ({ spacing, breakpoints, palette }) =>
    createStyles({
      imageWrapper: {
        padding: spacing(2, 2, 0)
      },

      button: {
        display: 'flex !important',
        alignItems: 'center',
        justifyContent: 'center',
        height: 40,
        width: 30,
        zIndex: 1,
        top: '78%',

        '&.slick-prev': {
          left: 'auto',
          right: 51
        },

        '&.slick-next': {
          right: 11
        },

        '&.slick-disabled svg path': {
          fill: palette.text.hint
        },

        '&:before': {
          display: 'none'
        },

        [breakpoints.down('xs')]: {
          top: '71%'
        }
      }
    }),
  {
    name: 'HowItWorksMobile'
  }
)
