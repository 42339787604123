import React, { FC, useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useModal } from 'react-modal-hook'
import { toastr } from 'react-redux-toastr'
import { Button, Chip } from '@material-ui/core'
import { Done, HourglassEmpty } from '@material-ui/icons'
import cn from 'classnames'

import { Flexbox, Text, Title } from '~/components/shared'
import { ConfirmDialog } from '~/components/dialogs'
import ParentProfileForm from './components/ParentProfileForm'
import ChangePasswordForm from './components/ChangePasswordForm'
import ChildrensProfile from './components/ChildrensProfile'
import ProfileTemplate from '../profile.template'

import {
  deleteUser,
  invalidateUser,
  isPaid,
  logoutUser
} from '~/state/modules/user'

import { ORDERS, PACKAGES, PACKAGES_TYPE } from '~/constants/packages'
import ROUTES from '~/constants/routes'

import useEditProfileStyles from './edit.styles'
import { AppState } from '~/state/store'

const Edit: FC = () => {
  const h = useHistory()
  const s = useEditProfileStyles()
  const dispatch = useDispatch()

  const { package: userPackage, order_status, package_type } = useSelector(
    (state: AppState) => state.user
  )
  const paid = useSelector(isPaid)

  const logout = useCallback((): void => {
    dispatch(logoutUser())
    h.push(ROUTES.home)
  }, [h, dispatch])

  const [showModal, hideModal] = useModal((props: any) => (
    <ConfirmDialog
      onClose={hideModal}
      onConfirm={async (): Promise<void> => {
        try {
          await dispatch<any>(deleteUser())

          toastr.warning('Delete', 'Your account was successfully deleted!')
          h.push(ROUTES.signIn)
        } catch (error) {
          console.error(error)
        }
      }}
      text="Are you sure want to delete your account?"
      {...props}
    />
  ))

  const isDraft = order_status === ORDERS.draft
  const isPending = order_status === ORDERS.pending
  const isMonthlyType = package_type?.toLowerCase() === PACKAGES_TYPE.monthly
  const isOneTimeType = package_type === PACKAGES_TYPE.one_time
  const showContinueMonthlyButton = isDraft && isMonthlyType

  const showUpgradeButton = userPackage === PACKAGES.Basic && !isPending
  const showPaidChip = userPackage === PACKAGES.Premium && paid

  const currentLabel = isOneTimeType ? 'One-time' : 'Annually'
  const paidLabel = isMonthlyType ? 'Monthly' : currentLabel
  const chipLabel = showPaidChip ? paidLabel : 'Not Subscribed'

  useEffect(() => {
    dispatch<any>(invalidateUser())
  }, [])

  return (
    <ProfileTemplate>
      <Flexbox className={s.titleWrapper}>
        <Title variant="h4" textAlign="start">
          Profile
        </Title>

        <Flexbox className={s.subWrapper}>
          <Text className={s.subType}>Current subscription type:</Text>

          <Flexbox alignItems="center">
            <Chip
              color="primary"
              variant={userPackage === PACKAGES.Basic ? 'outlined' : 'default'}
              label={chipLabel}
              className={s.chip}
            />

            {showContinueMonthlyButton && (
              <Button
                component={Link}
                to={`${ROUTES.checkout}/${PACKAGES_TYPE.monthly}`}
                color="primary"
                variant="outlined"
                size="small"
                style={{ marginLeft: 10 }}
              >
                Continue Monthly plan
              </Button>
            )}

            {showUpgradeButton && (
              <Button
                component={Link}
                to={ROUTES.packages}
                color="primary"
                variant="outlined"
                size="small"
                style={{ marginLeft: 10 }}
              >
                Upgrade
              </Button>
            )}

            {showPaidChip && (
              <Chip
                label={
                  <Flexbox alignItems="center">
                    <Text component="span">Paid</Text>
                    <Done />
                  </Flexbox>
                }
                variant="outlined"
                className={cn(s.chipPending, { paid: paid })}
              />
            )}

            {isPending && (
              <Chip
                label={
                  <Flexbox alignItems="center">
                    <Text component="span">Pending payment</Text>
                    <HourglassEmpty />
                  </Flexbox>
                }
                variant="outlined"
                className={s.chipPending}
              />
            )}
          </Flexbox>
        </Flexbox>
      </Flexbox>

      <Text mb={2} fontWeight={600}>
        Parent / Guardian
      </Text>

      <ParentProfileForm />

      <ChildrensProfile />

      <Text mt={5} mb={2} fontWeight={600}>
        Change password
      </Text>

      <ChangePasswordForm />

      <Flexbox justifyContent="space-between" mt={5} width="100%">
        <Button color="primary" onClick={logout}>
          Log out
        </Button>

        <Button
          color="primary"
          data-cy="profile-delete-account"
          onClick={showModal}
        >
          Delete account
        </Button>
      </Flexbox>
    </ProfileTemplate>
  )
}

export default Edit
