import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
  createStyles,
  Button
} from '@material-ui/core'

import { Text, Flexbox } from '~/components/shared'

import ROUTES from '~/constants/routes'

import { ReactComponent as ArrowDownIcon } from '~/assets/icons/arrow-down.svg'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      content: {
        paddingRight: theme.spacing(1),
        overflow: 'hidden'
      },

      nowrapText: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      },

      actionEditBtn: {
        minWidth: 150,
        maxHeight: 45,
        marginRight: 15,

        [theme.breakpoints.down('xs')]: {
          minWidth: 130
        }
      },
      actionDeleteBtn: {
        minWidth: 150,
        maxHeight: 45,

        [theme.breakpoints.down('xs')]: {
          minWidth: 130
        }
      }
    }),
  {
    name: 'ProgramsAccordion'
  }
)

interface ProgramsAccordionProps {
  name: string
  id: string
  location?: string
  handleDelete: (id: string) => void
}

const ProgramsAccordion: FC<ProgramsAccordionProps> = ({
  name,
  location,
  id,
  handleDelete
}) => {
  const s = useStyles()

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ArrowDownIcon />}
        classes={{
          content: s.content
        }}
      >
        <Flexbox flexDirection="column">
          <Text color="textSecondary" className={s.nowrapText}>
            <b>Program: </b> {name}
          </Text>
          <Text color="textSecondary" className={s.nowrapText}>
            <b>Location: </b> {location}
          </Text>
        </Flexbox>
      </AccordionSummary>

      <AccordionDetails>
        <Flexbox alignItems="center" mt={0.5} mb={1.5} ml={1.5}>
          <Button
            component={Link}
            to={`${ROUTES.campDirectorProgram}/${id}`}
            variant="contained"
            color="secondary"
            className={s.actionEditBtn}
          >
            <Text fontWeight={600}>Edit</Text>
          </Button>
          <Button
            variant="outlined"
            color="primary"
            className={s.actionDeleteBtn}
            onClick={(): void => {
              handleDelete(id)
            }}
          >
            <Text fontWeight={600}>Delete</Text>
          </Button>
        </Flexbox>
      </AccordionDetails>
    </Accordion>
  )
}

export default ProgramsAccordion
