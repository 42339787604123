import React from 'react'
import cn from 'classnames'
import { Box, Paper } from '@material-ui/core'

import AccountProfile from './components/AccountProfile'
import ItineraryCalendar from './components/ItineraryCalendar'
import MiaQuestions from './components/MiaQuestions'
import PlanFriends from './components/PlanFriends'
import { Title } from '~/components/shared'
import FullWidthMobile from '~/containers/FullWidthMobile'

import useStaticStyles from '~/styles/static-pages.styles'

const Faq: React.FC = () => {
  const s = useStaticStyles()

  return (
    <FullWidthMobile>
      <Box className={cn(s.container, 'withWidth')}>
        <Paper className={s.paper}>
          <Title textAlign="left" variant="h1" mb={5}>
            FAQs
          </Title>

          <Title textAlign="left" variant="h4" mb={3}>
            Account and Profile
          </Title>

          <AccountProfile />

          <Title textAlign="left" variant="h4" mb={3} mt={3}>
            Itinerary and Calendar
          </Title>

          <ItineraryCalendar />

          <Title textAlign="left" variant="h4" mb={3} mt={3}>
            Plan with friends
          </Title>

          <PlanFriends />

          <Title textAlign="left" variant="h4" mb={3} mt={3}>
            MIA
          </Title>

          <MiaQuestions />
        </Paper>
      </Box>
    </FullWidthMobile>
  )
}

export default Faq
