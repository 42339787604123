import React, { FC, useCallback, useMemo } from 'react'
import NativeListener from 'react-native-listener'
import { useModal } from 'react-modal-hook'
import { useSelector } from 'react-redux'

import PremiumDialog from '~/components/dialogs/PremiumDialog'

import { isPremiumPaid, userPackagesType } from '~/state/modules/user'

type Props = {
  customBlock?: {
    useBlock: true
    isBlock: boolean
  }
}

const Premium: FC<Props> = ({ customBlock, children }) => {
  const premium = useSelector(isPremiumPaid)
  const isPackagesType = useSelector(userPackagesType)

  const [showModal, hideModal] = useModal((props: any) => (
    <PremiumDialog onClose={hideModal} {...props} />
  ))

  const handleClick = useCallback((e) => {
    e.preventDefault()
    showModal()
  }, [])

  const blockClick = useMemo(() => {
    if (customBlock?.useBlock) {
      return customBlock.isBlock
    } else {
      return !premium && !isPackagesType
    }
  }, [customBlock, premium, isPackagesType])

  return (
    <>
      {blockClick ? (
        <NativeListener stopClick onClick={handleClick}>
          {children}
        </NativeListener>
      ) : (
        children
      )}
    </>
  )
}

export default Premium
