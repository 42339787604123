import React, { FC } from 'react'
import { compose } from 'redux'
import {
  Field,
  InjectedFormProps,
  reduxForm,
  SubmissionError
} from 'redux-form'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { Dialog, DialogContent, Grid, Box } from '@material-ui/core'

import { AutocompleteField, TextField } from '~/components/fields'
import { ExtendedButton, FormControl } from '~/components/shared'
import DialogTitle from '~/components/dialogs/DialogTitle'

import { sendInviteFriend } from '~/state/modules/itineraries'
import { asyncValidate } from '~/utils/asyncValidate'
import { createOptions } from '~/utils/functions'
import InviteFriendSchema from '~/schemas/invite-friend'

import { AppState } from '~/state/store'

type Props = {
  in: boolean
  onClose: () => {}
}

type ReduxProps = {
  itineraryId: string
  activities: Activity[]
}

type AllProps = Props & ReduxProps & InjectedFormProps

const InviteFriendDialog: FC<AllProps> = ({
  in: open,
  onClose,

  activities,

  submitting,
  handleSubmit
}) => {
  return (
    <Dialog maxWidth="sm" open={open} onClose={onClose} fullWidth>
      <DialogTitle onClose={onClose}>Invite a friend</DialogTitle>

      <DialogContent>
        <Box
          component="form"
          onSubmit={handleSubmit}
          data-cy="invite-friend-dialog-form"
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl label="Activities">
                <Field
                  name="activity_ids"
                  placeholder="Activities"
                  component={AutocompleteField}
                  options={createOptions(activities)}
                  onChangeKey="value"
                  limitTags={3}
                  multiple
                  disableCloseOnSelect
                  isBigZIndex
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl label="Email">
                <Field
                  id="email"
                  name="email"
                  placeholder="Email"
                  autoComplete="email"
                  component={TextField}
                  normalize={(value): string => value?.toLowerCase()}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl label="Message">
                <Field
                  name="text"
                  placeholder="Write a message for your friend"
                  component={TextField}
                  rowsMax={10}
                  rows={4}
                  multiline
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <ExtendedButton
                data-cy="invite-friend-dialog-button"
                size="large"
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                loading={submitting}
              >
                Send invite
              </ExtendedButton>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

const mapStateToProps = ({
  itineraries: {
    parents: { item }
  }
}: AppState): ReduxProps => {
  return {
    itineraryId: item?.id,
    activities: item?.activities
  }
}

const withConnect = connect(mapStateToProps)

const withForm = reduxForm<InviteFriendInput, Props & ReduxProps>({
  form: 'InviteFriend_Form',
  shouldAsyncValidate: () => true,
  initialValues: {
    text:
      'Hey [Friend name],\nI think the kids would enjoy these activities! I’m writing to see if you wanted to coordinate sending the kids together. Take a look and let me know your thoughts.'
  },
  asyncValidate: asyncValidate(InviteFriendSchema),
  onSubmit: async (values, dispatch, { itineraryId }) => {
    try {
      await dispatch<any>(
        sendInviteFriend({
          itinerary_id: itineraryId,
          ...values
        })
      )

      return values.email
    } catch (error) {
      throw new SubmissionError(error.errors)
    }
  },
  onSubmitSuccess: (email, _dispatch, { onClose, reset }) => {
    toastr.success(
      'Invite friend',
      `Your invitation has been successfully sent to ${email}!`
    )

    reset()
    onClose()
  }
})

export default compose<React.FC<Props>>(
  withConnect,
  withForm
)(InviteFriendDialog)
