import { toastr } from 'react-redux-toastr'

class ErrorHandler extends Error {
  errors: any
  _message = ''

  constructor(data: any, hideToastr?: boolean) {
    super('Error')
    this.errors = data?.errors
    this._message = data?.errors?._base
    const isDataString = data && typeof data === 'string'

    if (!hideToastr) {
      if (this.errors?._base) {
        toastr.error('Error', this.errors?._base)
      } else if (isDataString) {
        toastr.error('Error', data)
      } else {
        toastr.error('Error', 'Something went wrong')
      }
    }
  }
}

export default ErrorHandler
