import { makeStyles, createStyles } from '@material-ui/core'

const useFiltersDrawerStyles = makeStyles(
  ({ spacing, palette }) =>
    createStyles({
      icon: {
        marginRight: 12
      },

      paper: {
        height: '100%'
      },

      header: {
        padding: '12px 9px 12px 15px',
        height: 64,
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${palette.text.disabled}`
      },

      logo: {
        height: 40
      },

      content: {
        height: '100%',
        padding: spacing(3, 2),
        overflowY: 'auto'
      },

      footer: {
        padding: spacing(2),
        borderTop: `1px solid ${palette.text.disabled}`
      }
    }),

  { name: 'FiltersDrawer' }
)

export default useFiltersDrawerStyles
