import { STORAGE } from '~/constants/storage'

import actioncable from 'actioncable'

const cableUrl = process.env.REACT_APP_WEBSOCKET_CABLE_URL

class Actioncable {
  static cable = actioncable?.createConsumer(
    `${cableUrl}?token=${localStorage.getItem(STORAGE.authToken) || ''}`
  )

  static updateConsumer(token?: string) {
    try {
      this.cable = actioncable?.createConsumer(`${cableUrl}?token=${token}`)

      this.cable.ensureActiveConnection()
    } catch (error) {
      console.error(error)
    }
  }

  static disconnectConsumer() {
    this.cable.disconnect()

    this.cable = null
  }
}

export default Actioncable
