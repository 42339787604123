const personalOptions: Option[] = [
  {
    value: 'Self-Esteem',
    label: 'Self-Esteem'
  },
  {
    value: 'Independence',
    label: 'Independence'
  },
  {
    value: 'Leadership',
    label: 'Leadership'
  },
  {
    value: 'Friendship Skills',
    label: 'Friendship Skills'
  },
  {
    value: 'Social Comfort',
    label: 'Social Comfort'
  },
  {
    value: 'Peer Relationships',
    label: 'Peer Relationships'
  },
  {
    value: 'Adventure and Exploration',
    label: 'Adventure and Exploration'
  },
  {
    value: 'Environmental Awareness',
    label: 'Environmental Awareness'
  },
  {
    value: 'Values and Decisions',
    label: 'Values and Decisions'
  },
  {
    value: 'Sprituality',
    label: 'Sprituality'
  }
]

export default personalOptions
