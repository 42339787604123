import React, { FC, useState } from 'react'
import { Box, Card, Collapse, IconButton } from '@material-ui/core'
import cn from 'classnames'

import { DetailsList, Flexbox, Text } from '~/components/shared'
import ActivityCardLinks from './ActivityCardLinks'
import ActivityButtons from '../ActivityButtons'

import { ReactComponent as ArrowDownIcon } from '~/assets/icons/arrow-down.svg'

import { useActivityCardMobileStyles } from './ActivityCard.style'

import { ActivityCardProps } from './ActivityCard'

const ActivityCardMobile: FC<ActivityCardProps> = ({
  location,
  date,
  camp_info_time,
  camp_info_title,
  details,
  isAdded,
  isAdding,
  duration,
  onAddActivity,
  isHideButtons,
  isNoAvailableSeats,
  ...rest
}) => {
  const s = useActivityCardMobileStyles()
  const [open, setOpen] = useState(false)

  return (
    <Card className={s.card}>
      <Box
        className={cn({
          [s.titleBlock]: true,
          [s.opacity]: isNoAvailableSeats
        })}
      >
        <Flexbox
          className={s.header}
          onClick={(): void => {
            setOpen(!open)
          }}
        >
          <Flexbox className={s.activityCard}>
            <ActivityCardLinks
              isMobile
              {...rest}
              isNoAvailableSeats={isNoAvailableSeats}
            />
          </Flexbox>

          <IconButton className={cn(s.arrowUp, { open })}>
            <ArrowDownIcon />
          </IconButton>
        </Flexbox>

        <Flexbox mb={0.5}>
          <Text color="textSecondary" mr={1}>
            Location:
          </Text>
          <Text color="textPrimary">{location}</Text>
        </Flexbox>

        <Flexbox mb={1}>
          <Text color="textSecondary" mr={1}>
            Date:
          </Text>
          <Text color="textPrimary" mr={0.5}>
            {date}{' '}
            <Text component="span" className={s.duration}>
              ({duration})
            </Text>
          </Text>
        </Flexbox>

        <Flexbox mb={1}>
          <Text color="textSecondary" mr={1}>
            {camp_info_title}
          </Text>
          <Text color="textPrimary">{camp_info_time}</Text>
        </Flexbox>
      </Box>

      <Collapse in={open}>
        <Box
          className={cn({
            [s.optionsBlock]: true,
            [s.opacity]: isNoAvailableSeats
          })}
        >
          <DetailsList items={details} />
        </Box>
      </Collapse>

      {!isNoAvailableSeats && (
        <Box className={cn(s.btnsBlock, { open })}>
          <ActivityButtons
            isAdded={isAdded}
            isAdding={isAdding}
            onAddActivity={onAddActivity}
            isHideButtons={isHideButtons}
            fullWidth
            style={{ ...(isAdded && { cursor: 'default' }) }}
          />
        </Box>
      )}
    </Card>
  )
}

export default ActivityCardMobile
