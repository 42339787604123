import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useModal } from 'react-modal-hook'
import { Button, Theme, useMediaQuery } from '@material-ui/core'

import ProgramTemplate from '../../program.template'
import ActivityForm from '../components/ActivityForm'
import { Flexbox, Title } from '~/components/shared'
import LoadActivitiesDialog from '../components/LoadActivitiesDialog'

import ActivityService from '~/services/ActivitiesService'
import { getProgramById, getProgramSelector } from '~/state/modules/programs'

const CreateActivity: React.FC = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const dispatch = useDispatch()

  const { id } = useParams<{ id: string }>()

  const { program } = useSelector(getProgramSelector)

  useEffect(() => {
    if (program && id) {
      dispatch(getProgramById(id))
    }
  }, [])

  const [showModal, hideModal] = useModal(
    (props) => (
      <LoadActivitiesDialog
        {...props}
        activityProviderId={id}
        onClose={hideModal}
      />
    ),
    [id]
  )

  return (
    <ProgramTemplate title="Back to Activities" programId={id}>
      <Flexbox
        mb={2}
        justifyContent="space-between"
        alignItems={!isMobile ? 'center' : 'flex-start'}
        flexDirection={isMobile && 'column'}
      >
        <Title variant="h4" textAlign="center" mb={isMobile && 2}>
          Create activity
        </Title>

        <Button
          size="medium"
          variant="contained"
          color="secondary"
          onClick={showModal}
          fullWidth={isMobile}
        >
          Upload activity
        </Button>
      </Flexbox>

      <ActivityForm
        initialValues={ActivityService.generateCampDirectorNewActivityInitialValues(
          program || {}
        )}
      />
    </ProgramTemplate>
  )
}

export default CreateActivity
