import { makeStyles, createStyles } from '@material-ui/core'

const usePriceFieldStyles = makeStyles(
  ({ palette }) =>
    createStyles({
      wrapper: {
        position: 'relative',
        height: '100%'
      },

      btn: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        border: `1px solid ${palette.text.disabled}`,
        padding: '11px 10px 11px 14px',
        borderRadius: 10,
        height: '100%',

        '& .arrow': {
          color: palette.text.hint
        },

        '&.active': {
          '& .arrow': {
            transform: 'rotate(180deg)'
          }
        }
      },

      text: {
        width: '100%',
        marginRight: 10,
        color: palette.text.secondary
      },

      container: {
        position: 'absolute',
        top: 54,
        right: 0,
        zIndex: 101,
        width: '100%'
      },

      dropdown: {
        background: palette.common.white,
        padding: '8px 16px',
        boxShadow: '5px 5px 15px rgba(35, 35, 35, 0.15)',
        borderRadius: 10
      }
    }),
  {
    name: 'PriceField'
  }
)

export default usePriceFieldStyles
