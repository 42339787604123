import { ZOHO } from '~/constants/zoho'

export enum QuestionnaireFormStatus {
  COMPLETED = 'completedQuestionnaire',
  UNCOMPLETED = 'uncompletedQuestionnaire'
}

type QuestionnaireFormConfigProps = {
  isProduction: boolean
  status: QuestionnaireFormStatus
}

export const ZOHO_FORM_CONFIG = {
  completedQuestionnaire: ZOHO.questionnaireSecondPart,
  uncompletedQuestionnaire: ZOHO.questionnaire
}

const PRODUCTION_FORM_NAME_CONFIG = {
  completedQuestionnaire: 'FamilyQuestionnaireDetail',
  uncompletedQuestionnaire: 'FamilyQuestionnaireStart'
}

const DEVELOPMENT_FORM_NAME_CONFIG = {
  completedQuestionnaire: 'FamilyQuestionnaireDetailstaging',
  uncompletedQuestionnaire: 'FamilyQuestionnaireStartstaging'
}

export const getQuestionnaireFormConfig = ({
  isProduction,
  status
}: QuestionnaireFormConfigProps) => {
  const zohoForm = ZOHO_FORM_CONFIG[status]

  const formNameConfig = isProduction
    ? PRODUCTION_FORM_NAME_CONFIG
    : DEVELOPMENT_FORM_NAME_CONFIG

  const formName = formNameConfig[status]

  return {
    zohoForm,
    formName
  }
}
