import React from 'react'
import { useHistory } from 'react-router'
import { Container, makeStyles, Paper, Theme } from '@material-ui/core'

import { Link, Title } from '~/components/shared'
import FullWidthMobile from '~/containers/FullWidthMobile'
import ProgramForm from '../components/ProgramForm'

import { ReactComponent as BackIcon } from '~/assets/icons/back-icon.svg'

const useStyles = makeStyles((theme: Theme) => ({
  backButton: {
    display: 'inline-block',
    color: theme.palette.text.secondary,
    fontWeight: 400,
    marginBottom: theme.spacing(2),

    '& svg': {
      marginRight: theme.spacing(1)
    }
  },
  container: {
    padding: theme.spacing(5, 11),
    margin: theme.spacing(3, 0, 6),

    [theme.breakpoints.down('sm')]: {
      padding: '50px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
      margin: 0,
      borderRadius: 'inherit',
      boxShadow: 'none'
    }
  },
  btns: {
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1)
    }
  }
}))

const Program: React.FC = () => {
  const s = useStyles()
  const history = useHistory()

  return (
    <FullWidthMobile>
      <Container maxWidth="lg" disableGutters>
        <Paper className={s.container}>
          <Link
            to="#"
            className={s.backButton}
            underline="hover"
            onClick={(e: React.MouseEvent<HTMLElement>): void => {
              e.preventDefault()

              // @ts-ignore
              history.goBack()
            }}
          >
            <BackIcon />
            Back to Program
          </Link>

          <Title variant="h4" textAlign="start" mb={2}>
            Create program
          </Title>

          <ProgramForm />
        </Paper>
      </Container>
    </FullWidthMobile>
  )
}

export default Program
