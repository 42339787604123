import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { Redirect } from 'react-router-dom'

import { campDirectorStatuses } from '~/state/modules/user/user.selectors'
import { CAMP_DIRECTOR_STATUSES, STRIPE_ACCOUNT_STATUS } from '~/constants'
import ROUTES from '~/constants/routes'

const withProtectCampDirector = <P extends object>(
  Component: React.ComponentType<P>
) => (props): JSX.Element => {
  const location = useLocation()
  const { zoho_profile, stripe_account_status } = useSelector(
    campDirectorStatuses
  )

  const congratulations =
    location.pathname === ROUTES.campDirectorCongratulations

  const stripe = location.pathname === ROUTES.campDirectorStripe

  const stripeStatus =
    STRIPE_ACCOUNT_STATUS.waiting_for_details === stripe_account_status ||
    STRIPE_ACCOUNT_STATUS.waiting_for_stripe_connect_completion ===
      stripe_account_status

  if (zoho_profile === CAMP_DIRECTOR_STATUSES.show_form && !congratulations) {
    return <Redirect to={ROUTES.campDirectorProvider} />
  }

  if (
    zoho_profile === CAMP_DIRECTOR_STATUSES.show_congratulations &&
    !congratulations
  ) {
    return <Redirect to={ROUTES.campDirectorCongratulations} />
  }

  if (
    zoho_profile === CAMP_DIRECTOR_STATUSES.form_completed &&
    stripeStatus &&
    location.pathname !== ROUTES.campDirectorStripe
  ) {
    return <Redirect to={ROUTES.campDirectorStripe} />
  }

  if (
    zoho_profile === CAMP_DIRECTOR_STATUSES.form_completed &&
    STRIPE_ACCOUNT_STATUS.connect_completed === stripe_account_status &&
    (stripe || congratulations)
  ) {
    return <Redirect to={ROUTES.campDirectorPrograms} />
  }

  return <Component {...props} />
}

export default withProtectCampDirector
