import axios from 'axios'
import crypto from 'crypto'

import Api from '~/utils/Api'

const calculateChecksum = (file: any): Promise<any> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    const md5sum = crypto.createHash('md5')

    reader.readAsBinaryString(file)

    reader.onload = (e: any): void => {
      // @ts-ignore
      md5sum.update(e.target.result, 'binary')
      resolve(md5sum.digest('base64'))
    }

    reader.onerror = (e: any): void => {
      reject(e)
    }
  })

class PMK extends Api {
  /**
   *
   * PARENTS
   *
   */

  /**
   * User login
   */
  login = (user: SignInInput): Promise<{ jwt: string }> =>
    this.post('/api/v1/parents/sessions', {
      data: user
    })

  /**
   * Create a new user
   */
  createUser = (parent: SignUpInput): Promise<ResponseObject<User>> =>
    this.post('/api/v1/parents', { data: { parent } })

  /**
   * Confirm user link
   */
  confirmUser = (confirmation_token: string): Promise<{ jwt: string }> =>
    this.post(`/api/v1/parents/confirmations`, { data: { confirmation_token } })

  /**
   * Send reset link to email
   */
  sendResetLink = (forgot_password: ForgotPasswordInput): Promise<void> =>
    this.post('/api/v1/parents/passwords/emails', {
      data: forgot_password
    })

  /**
   * Send resend confirm email
   */
  sendResendEmail = (email: string): Promise<void> =>
    this.post('/api/v1/parents/resend_confirmation', {
      data: {
        email
      }
    })

  /**
   * Reset password
   */
  resetPassword = (values: ResetPasswordDTO): Promise<{ jwt: string }> =>
    this.post(`/api/v1/parents/passwords`, { data: values })

  /**
   * Get user data
   */
  getUser = (id: string): Promise<ResponseObject<User>> =>
    this.get(`/api/v1/specialists/parents/${id}`)

  /**
   * Delete parent account
   */
  deleteUser = (id: string): Promise<void> =>
    this.del(`/api/v1/parents`, {
      params: {
        id
      }
    })

  /**
   * signin with google
   */
  signinWithSocial = (auth: AuthThirdPartyDTO): Promise<any> =>
    this.post('/api/v1/parents/third_party_auth', {
      data: {
        auth
      }
    })

  /**
   * Update parent data
   */
  updateUser = (
    parent: Partial<UpdateUserInput>
  ): Promise<ResponseObject<User>> =>
    this.put('/api/v1/parents', {
      data: {
        parent
      }
    })

  /**
   * Change parent password
   */
  changePassword = (values: ChangePasswordInput): Promise<void> =>
    this.post(`/api/v1/parents/passwords`, { data: values })

  /**
   * Get questionnaires
   */
  getQuestionnaires = (): Promise<ResponseObject<Questionnaire[]>> =>
    this.get('/api/v1/parents/questionnaires')

  /**
   * Update questionnaire
   */
  updateQuestionaire = (
    id: string,
    fields: Fields
  ): Promise<ResponseObject<Questionnaire>> =>
    this.put(`/api/v1/parents/questionnaires/${id}`, {
      data: {
        fields
      }
    })

  /**
   * Get parent itineraries
   */
  getParentItineraries = (): Promise<ResponseObject<Itinerary[]>> =>
    this.get('/api/v1/parents/itineraries')
  /**
   * Show parent itinerary
   */
  getParentItineraryById = (id: string): Promise<ResponseObject<Itinerary>> =>
    this.get(`/api/v1/parents/itineraries/${id}`)

  /**
   * Get chatbot messages
   */
  getChatbotMessages = (): Promise<ResponseObject<ChatbotMessageModel[]>> =>
    this.get(`/api/v1/openai/messages`)
  /**
   * Send chatbot message
   */
  createChatbotMessage = (message: ChatbotMessageDTO): Promise<void> =>
    this.post(`/api/v1/openai/messages`, {
      data: {
        message
      }
    })

  /**
   *  Update itinerary (add comment or change status)
   */
  updateItinerary = (
    id: string,
    itinerary?: any
  ): Promise<ResponseObject<Itinerary>> =>
    this.put(`/api/v1/parents/itineraries/${id}`, {
      data: {
        id,
        itinerary
      }
    })

  /**
   * Send Invite Friend
   */
  sendInviteFriend = (values: InviteFriendInput): Promise<void> =>
    this.post(`/api/v1/parents/invite_friends`, { data: values })

  /**
   * Get friends itineraries
   */
  getFriendsItineraries = (): Promise<ResponseObject<Itinerary[]>> =>
    this.get('/api/v1/parents/friends_itineraries')

  /**
   *  Update friend itinerary (add comment or change status)
   */
  updateFriendItinerary = (
    id: string,
    values: ItineraryFriendDTO
  ): Promise<ResponseObject<Itinerary>> =>
    this.put(`/api/v1/parents/friends_itineraries/${id}`, {
      data: values
    })

  /**
   * Get payment packages
   */
  getPaymentPackages = (): Promise<Package[]> => this.get('/api/v1/packages')

  /**
   * Check coupon on valid
   */
  checkCoupon = (data: any): Promise<any> =>
    this.post('/api/v1/payment/coupons', {
      data
    })

  /**
   * Create strapi subscripton
   */
  createSubscripton = (data: PaymentDTO): Promise<any> =>
    this.post('/api/v1/payment/subscriptions', { data })

  /**
   *
   * SPECIALISTS
   *
   */

  /**
   * Specialist login
   */
  loginSpecialist = (user: SignInInput): Promise<{ jwt: string }> =>
    this.post('/api/v1/specialists/sessions', {
      data: user
    })

  /**
   * Get specialist data
   */
  getSpecialist = (id: string): Promise<ResponseObject<User>> =>
    this.get(`/api/v1/specialist/${id}`)

  /**
   * Get itineraries
   */
  getItineraries = (
    params: ItinerariesDTO
  ): Promise<{
    itineraries: ResponseObjectData<Itinerary[]>
    pagy: ItinerariesMetaResponse
  }> =>
    this.get('/api/v1/specialists/itineraries', {
      params
    })

  /**
   * Get Itinerary
   */
  getItineraryById = (
    id: string
  ): Promise<ResponseObjectWithInclude<Itinerary>> =>
    this.get(`/api/v1/specialists/itineraries/${id}`)

  /**
   * Assign Itinerary
   */
  assignItinerary = (id: string): Promise<void> =>
    this.get(`/api/v1/specialists/itineraries/${id}/apply`)

  /**
   *  Update itinerary
   */
  updateItinerarySpecialist = (
    id: string,
    itinerary: any,
    itinerary_action?: ItineraryActionUnion
  ): Promise<ResponseObject<Itinerary>> =>
    this.put(`/api/v1/specialists/itineraries/${id}`, {
      data: {
        itinerary,
        itinerary_action
      }
    })

  /**
   * Get activities
   */
  getActivities = (
    params: SearchDTO
  ): Promise<{
    activities: ResponseObjectData<Activity[]>
    pagy: ActivitiesMetaResponse
  }> =>
    this.get('/api/v1/specialists/activities', {
      params
    })

  /**
   * Get Activity
   */
  getActivityById = (id: string): Promise<ResponseObject<Activity>> =>
    this.get(`/api/v1/specialists/activities/${id}`)

  /**
   *  Update Activity
   */
  updateActivity = (
    id: string,
    activity: LeaveNoteInput
  ): Promise<ResponseObject<Itinerary>> =>
    this.put(`/api/v1/specialists/activities/${id}`, {
      data: {
        activity
      }
    })

  /**
   * Get Activity Provider
   */
  getActivityProviderById = (
    id: string
  ): Promise<ResponseObject<ActivityProvider>> =>
    this.get(`/api/v1/specialists/activity_providers/${id}`)

  /**
   *  Update Activity Provider
   */
  updateActivityProvider = (
    id: string,
    activity_provider: LeaveNoteInput
  ): Promise<ResponseObject<Itinerary>> =>
    this.put(`/api/v1/specialists/activity_providers/${id}`, {
      data: {
        activity_provider
      }
    })

  /**
   * Add activity to itinerary
   */
  addActivity = (
    itinerary_activity: ItineraryActivityDTO
  ): Promise<ItineraryActivity> =>
    this.post('/api/v1/specialists/itinerary_activities', {
      data: {
        itinerary_activity
      }
    })

  /**
   * Remove activity from itinerary
   */
  removeActivity = (activityId: string): Promise<void> =>
    this.del(`/api/v1/specialists/itinerary_activities/${activityId}`)

  /**
   *
   * SPECIALISTS
   *
   */

  /**
   * Create Camp Director
   */
  loginDirector = (user: SignInInput): Promise<{ jwt: string }> =>
    this.post('/api/v1/camp_directors/sessions', {
      data: user
    })

  /**
   * Create Camp Director
   */
  createDirector = (user: SignInInput): Promise<ResponseObject<User>> =>
    this.post('/api/v1/camp_directors', {
      data: { camp_director: user }
    })

  /**
   *
   * Get camp director info
   */
  getDirector = (): Promise<ResponseObject<User>> =>
    this.get('/api/v1/camp_directors')
  /**
   *
   * Update camp director info
   */
  updateDirector = (values): Promise<ResponseObject<User>> =>
    this.put('/api/v1/camp_directors', { data: { camp_director: values } })

  /**
   * Reset Camp director password
   */
  resetDirectorPassword = (
    values: ResetPasswordDTO
  ): Promise<{ jwt: string }> =>
    this.post(`/api/v1/camp_directors/passwords`, { data: values })

  /**
   * Send reset link to email
   */
  sendResetDirectorLink = (
    forgot_password: ForgotPasswordInput
  ): Promise<void> =>
    this.post('/api/v1/camp_directors/passwords/emails', {
      data: forgot_password
    })

  /**
   * Confirm Camp-director link
   */
  confirmDirector = (confirmation_token: string): Promise<{ jwt: string }> =>
    this.post(`/api/v1/camp_directors/confirmations`, {
      data: { confirmation_token }
    })
  /**
   * signin with google
   */
  signinDirectorWithSocial = (auth: AuthThirdPartyDTO): Promise<any> =>
    this.post('/api/v1/camp_directors/third_party_auth', {
      data: {
        auth
      }
    })
  /**
   * Resend director email confirmation
   */
  sendResendDirectorEmail = (email: string): Promise<any> =>
    this.post('/api/v1/camp_directors/resend_confirmation', {
      data: {
        email
      }
    })

  /**
   * Direct upload helper
   */
  _directUpload = async (
    file: any
  ): Promise<{ signed_id: string; origin: object }> => {
    try {
      const checksum = await calculateChecksum(file)

      const {
        direct_upload: { url, headers },
        // id,
        signed_id
      } = await this.post('/rails/active_storage/direct_uploads', {
        data: {
          blob: {
            filename: file.name || 'image',
            byte_size: file.size,
            content_type: file.type,
            checksum
          }
        }
      })

      await axios.put(url, file, {
        headers
      })

      return { signed_id, origin: file }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  /**
   * Direct upload of one file
   */
  directUploadOne = (file: any): Promise<any> => this._directUpload(file)

  /**
   * Create stripe link
   */
  createStripeLink = (): Promise<any> =>
    this.post('/api/v1/payment/connect/account_links', {
      data: {}
    })
  /**
   * Get stripe link
   */
  getStripeLink = (itineraryId: string): Promise<any> =>
    this.post('/api/v1/payment/checkout_sessions', {
      data: { itinerary_id: itineraryId }
    })
  /**
   * Update status director
   */
  updateStatusDirtector = (): Promise<any> =>
    this.post('/api/v1/camp_directors/profile_form_fillings', {
      data: {}
    })
  /**
   * Upload activity-file director
   */
  campDirectorUploadCsv = (id: string, formData: any): Promise<any> =>
    this.post(`/api/v1/camp_directors/activity_providers/${id}/imports`, {
      data: formData,
      headers: {
        'content-Type': 'multipart/form-data'
      }
    })
  /**
   *  Get all programs
   */
  getPrograms = (page: number): Promise<any> =>
    this.get('/api/v1/camp_directors/activity_providers', { params: { page } })
  /**
   *  Create program
   */
  createProgram = (values: ProgramsFormValues): Promise<any> =>
    this.post('/api/v1/camp_directors/activity_providers', {
      data: { activity_provider: values }
    })
  /**
   *  Delete program
   */
  deleteProgram = (id: string): Promise<any> =>
    this.del(`/api/v1/camp_directors/activity_providers/${id}`)
  /**
   *  Get program by id
   */
  getProgramById = (id: string): Promise<any> =>
    this.get(`/api/v1/camp_directors/activity_providers/${id}`)
  /**
   *  Update program by id
   */
  updateProgramById = (id: string, values: ProgramsFormValues): Promise<any> =>
    this.put(`/api/v1/camp_directors/activity_providers/${id}`, {
      data: { activity_provider: values }
    })
  /**
   *  Update program by id
   */
  getCampDirectorActivities = (
    id: string,
    params: ActivitySearchDto
  ): Promise<any> =>
    this.get(`/api/v1/camp_directors/activity_providers/${id}/activities`, {
      params
    })
  /**
   *  Create activity
   */
  createCampDirectorActivity = (
    id: string,
    values: ActivityFormValues
  ): Promise<any> =>
    this.post(`/api/v1/camp_directors/activity_providers/${id}/activities`, {
      data: { activity: values }
    })
  /**
   *  Update Camp director activity
   */
  updateCampDirectorActivity = (
    id: string,
    activityId: string,
    values: ActivityFormValues | ActivityFormDuplicateValues
  ): Promise<any> =>
    this.put(
      `/api/v1/camp_directors/activity_providers/${id}/activities/${activityId}`,
      {
        data: { activity: values }
      }
    )
  /**
   *  Duplicate Camp director activity
   */
  duplicateCampDirectorActivity = (
    values: Partial<ActivityDuplicate>
  ): Promise<any> =>
    this.post(`/api/v1/camp_directors/duplicate_activities`, {
      data: { activity: values }
    })
  /**
   *  Get Camp director activity by id
   */
  getCampDirectorActivity = (
    id: string,
    activityId: string
  ): Promise<Activity> =>
    this.get(
      `/api/v1/camp_directors/activity_providers/${id}/activities/${activityId}/edit`
    )
  /**
   *  Get Camp director activity by id
   */
  deleteActivity = (id: string, activityId: string): Promise<Activity> =>
    this.del(
      `/api/v1/camp_directors/activity_providers/${id}/activities/${activityId}`
    )
  /**
   *  Get Camp director activity by id
   */
  getCampDirectorInterests = (): Promise<Activity> =>
    this.get(`/api/v1/camp_directors/interests`)
  /**
   *  Get Camp director oreders by id
   */
  getCampDirectorOrders = (page: number): Promise<OrdersPagination> =>
    this.get(`/api/v1/camp_directors/orders`, {
      params: { page }
    })
  /**
   *  To mark as processed Camp director oreder
   */
  markAsProcessedCampDirectorOrder = (
    id: string,
    processed: boolean
  ): Promise<any> =>
    this.post(`/api/v1/camp_directors/processed_itinerary_activities`, {
      data: { itinerary_activity_id: +id, processed }
    })

  /**
   * Get Stripe url for check profile
   */
  getStripeLinkAccount = (): Promise<any> =>
    this.post('/api/v1/camp_directors/login_link')

  /**
   * Confirm parent new account from Zoho
   */
  confirmParentAccountFromZoho = (formId: string): Promise<{ jwt: string }> =>
    this.post(`/api/v1/parents/passwordless_sessions`, {
      data: { form_id: formId }
    })
}

export default new PMK({
  baseURL: process.env.REACT_APP_API,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
})
