import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

const withStripe = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<P> => (props): JSX.Element => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY)

  return (
    <Elements stripe={stripePromise}>
      <Component {...props} />
    </Elements>
  )
}

export default withStripe
