import { makeStyles, createStyles } from '@material-ui/core'

const useItineraryCardStyles = makeStyles(
  (theme) =>
    createStyles({
      dottedLine: {
        position: 'relative',
        borderLeft: `1px dashed ${theme.palette.text.hint}`,
        margin: '20px 22px 5px 15px'
      },

      pointIcon: {
        width: '20px',
        color: theme.palette.text.hint,
        position: 'absolute',
        top: '-20px',
        left: '-10.5px'
      },

      contentWrapper: {
        alignItems: 'flex-start',

        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column-reverse'
        }
      },

      details: {
        flex: 1,

        '& p': {
          marginBottom: theme.spacing(0.5)
        },

        '& span': {
          color: theme.palette.text.primary
        },

        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column'
        }
      },

      location: {
        marginBottom: theme.spacing(1),

        '& span': {
          color: theme.palette.text.primary
        }
      },

      imgWrapper: {
        width: 340,
        height: 200,
        overflow: 'hidden',
        borderRadius: 6,
        cursor: 'pointer',

        '& img': {
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        },

        [theme.breakpoints.down('sm')]: {
          width: '100%',
          minWidth: 'auto',
          height: 250,
          margin: theme.spacing(0, 0, 2, 0)
        },

        [theme.breakpoints.down('xs')]: {
          height: 200
        }
      },

      player: {
        width: '100% !important',
        height: '101% !important'
      },

      arrowUp: {
        transform: 'rotate(180deg)'
      },

      duration: {
        color: `${theme.palette.text.hint} !important`
      },

      openCampButton: {
        display: 'block',
        width: 'fit-content',
        fontWeight: 700,
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(2)
      },

      waiverFormUrl: {
        display: 'block',
        width: 'fit-content',
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(2),
        marginLeft: 20,
        padding: 5,
        fontWeight: 700,
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '10px'
      },

      paymentStatus: {
        padding: '3px 15px',
        fontWeight: 600,
        fontSize: '13px',
        lineHeight: '18px',
        color: theme.palette.common.white,
        borderRadius: '20px',
        backgroundColor: `${theme.palette.primary.main}`,
        alignSelf: 'baseline'
      },

      wrapText: {
        display: '-webkit-box',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        '-webkit-line-clamp': '1',
        '-webkit-box-orient': 'vertical',
        wordBreak: 'break-all'
      },

      wrapComponent: {
        flexDirection: 'row',
        alignItems: 'center',

        [theme.breakpoints.down('xs')]: {
          alignItems: 'start',
          flexDirection: 'column'
        }
      },

      buttonMore: {
        maxWidth: 340,
        margin: '0 auto',
        marginTop: '20px',
        width: '100%',

        [theme.breakpoints.down('sm')]: {
          marginBottom: '20px'
        }
      }
    }),
  {
    name: 'ItineraryCard'
  }
)

export default useItineraryCardStyles
