import React from 'react'
import { Box, Button, Container, Grid } from '@material-ui/core'
import cn from 'classnames'

import { Flexbox, Text, Title } from '~/components/shared'
import ContainerFull from '~/components/layouts/ContainerFull'
import HelpBlock from './components/HelpBlock'
import VideoBlock from './components/VideoBlock'
import ParentCard from './components/ParentCard'
import Banner from './components/Banner'
import HowItWorksBlock from './components/HowItWorksBlock'
import ZohoCampaignEmail from './components/ZohoCampaignEmail'
import ProgramSpotlightSection from './components/ProgramSpotlightSection'
import MainAdvantagesSection from './components/MainAdvantagesSection'

import useWelcomeDialog from '~/hooks/useWelcomeDialog'

import videoPainting2 from '~/assets/images/home/video-painting-2.png'
import videoPainting1 from '~/assets/images/home/video-painting-1.png'
import programPainting1 from '~/assets/images/home/program-painting-1.png'
import programPainting2 from '~/assets/images/home/program-painting-2.png'
import painting2 from '~/assets/images/home/title-painting-2.png'
import painting3 from '~/assets/images/home/title-painting-3.png'

import helpCards from '~/data/home/helpCards'
import parents from '~/data/home/parents'

import useHomeStyles from './home.styles'
import Premium from '~/containers/Premium'
import { useSelector } from 'react-redux'
import { isBlockQuestionnaire, isLogged } from '~/state/modules/user'

const Index: React.FC = () => {
  const s = useHomeStyles()
  const { handleWelcomeDialog } = useWelcomeDialog()
  const isLoggedUser = useSelector(isLogged)
  const isQuestionnaireBlock = useSelector(isBlockQuestionnaire)

  const isBlockPlanButton = isQuestionnaireBlock && isLoggedUser

  return (
    <>
      <Banner />

      <Box className={s.container}>
        <Box className={s.helpTitle}>
          <Title variant="h1">Your personal concierge</Title>
        </Box>

        <HelpBlock data={helpCards} />

        <Flexbox justifyContent="center" mt={{ xs: 7, sm: 5 }}>
          <Premium
            customBlock={{
              isBlock: isBlockPlanButton,
              useBlock: true
            }}
          >
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={handleWelcomeDialog}
            >
              Start my plan
            </Button>
          </Premium>
        </Flexbox>
      </Box>

      <ContainerFull
        className={cn(s.container, s.worksContainer)}
        withContainer="xl"
        enableGutters
      >
        <Title
          variant="h1"
          paintingOptions={{
            image: painting2
          }}
          mb={{ xs: 3, md: 5 }}
        >
          How it works
        </Title>

        <HowItWorksBlock />

        <Flexbox justifyContent="center" mt={5}>
          <Premium
            customBlock={{
              isBlock: isBlockPlanButton,
              useBlock: true
            }}
          >
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={handleWelcomeDialog}
            >
              Start my plan
            </Button>
          </Premium>
        </Flexbox>
      </ContainerFull>

      <ContainerFull className={s.container} withContainer enableGutters>
        <Title variant="h1" mb={5}>
          Watch video about PlanMyKids
        </Title>

        <img
          src={videoPainting1}
          alt="painting-1"
          className={cn(s.videoPainting, s.videoPainting1)}
        />

        <img
          src={videoPainting2}
          alt="painting-2"
          className={cn(s.videoPainting, s.videoPainting2)}
        />

        <VideoBlock />
      </ContainerFull>

      <ContainerFull
        className={cn(s.container, s.parentsContainer)}
        withContainer="lg"
        enableGutters
      >
        <Title
          variant="h1"
          paintingOptions={{
            image: painting3,
            customStyles: {
              width: '100%'
            }
          }}
          mb={5}
        >
          Approved by parents
        </Title>

        <Grid container spacing={4} justify="center">
          {parents.map((parent, idx) => (
            <Grid key={idx} item>
              <ParentCard {...parent} />
            </Grid>
          ))}
        </Grid>
      </ContainerFull>

      <ContainerFull
        className={cn(s.container, s.spotlightContainer)}
        withContainer="xl"
        enableGutters
      >
        <img
          src={programPainting1}
          alt="painting-2"
          className={cn(s.videoPainting, s.videoPainting2, 'camp')}
        />

        <img
          src={programPainting2}
          alt="painting-1"
          className={cn(s.videoPainting, s.spotlightPainting, 'camp')}
        />

        <ProgramSpotlightSection />
      </ContainerFull>

      <ContainerFull
        className={cn(s.container, s.advantagesContainer)}
        withContainer="xl"
        enableGutters
      >
        <MainAdvantagesSection />
      </ContainerFull>

      <Flexbox justifyContent="center" mt={3} mb={8}>
        <Premium
          customBlock={{
            isBlock: isBlockPlanButton,
            useBlock: true
          }}
        >
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={handleWelcomeDialog}
          >
            Start my plan
          </Button>
        </Premium>
      </Flexbox>

      <ContainerFull className={s.connectedContainer} id="scroll-top-trigger">
        <Container maxWidth="md" className={s.connectedWrapper}>
          <Box mr={{ md: 2 }} mb={{ xs: 3, md: 0 }}>
            <Title mb={1} textAlign="start">
              Join our newsletter
            </Title>
            <Text>
              Get the latest informational resources about child enrichment and
              parent time saving tips. Only knowledge, never spam.
            </Text>
          </Box>

          <ZohoCampaignEmail />
        </Container>
      </ContainerFull>
    </>
  )
}

export default Index
