type Props = {
  eventType: TooltipEventType
  open: boolean
  enabled: boolean
  showTooltip: (event: React.MouseEvent<HTMLElement>) => void
}

export type TooltipEventType = 'touch' | 'hover'

export const getTooltipProps = ({
  eventType,
  open,
  enabled,
  showTooltip
}: Props) => {
  const props = {
    touch: {
      open,
      disableHoverListener: true,
      disableTouchListener: true,
      disableFocusListener: true,
      onClick: showTooltip
    },
    hover: {
      onMouseOver: showTooltip,
      disableHoverListener: !enabled
    }
  }

  return props[eventType]
}
