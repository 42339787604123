import PMK from '~/services/PMK'
import Deserializer from '~/services/Deserializer'
import { generateCenter } from '~/services/MapService'
import ErrorHandler from '~/utils/errorHandler'

import { STATUSES } from '~/constants/statusesItinerary'
import { AppThunk } from '~/state/store'

import {
  request,
  success,
  successItem,
  updateItemMessage,
  changeItineraryStatus,
  addActivityToItinerary,
  removeActivityFromItinerary,
  changeActiveTab,
  failure
} from './dashboard.slice'

// Actions

/**
 * Get itineraries action
 */
export const getItineraries = (
  params: ItinerariesDTO
): AppThunk<Promise<void>> => async (dispatch): Promise<void> => {
  dispatch(request())

  try {
    const { itineraries, pagy } = await PMK.getItineraries(params)
    const payload = await Deserializer.deserialize(itineraries)

    dispatch(
      success({
        data: payload,
        meta: {
          pages: pagy.pages,
          amount: pagy.vars.statuses
        }
      })
    )
  } catch (error) {
    throw new ErrorHandler(error)
  }
}

/**
 * Get itinerary action
 */
export const getItineraryById = (id: string): AppThunk<Promise<void>> => async (
  dispatch
): Promise<void> => {
  dispatch(request())

  try {
    const res = await PMK.getItineraryById(id)

    const { attributes, ...parent } = res.included.find(
      ({ type }) => type === 'parent'
    ) || { attributes: {} }

    const payload = await Deserializer.deserialize(res)

    const location = await generateCenter(payload?.questionnaire)

    dispatch(
      successItem({
        ...payload,
        parent: {
          ...parent,
          ...attributes
        },
        location
      })
    )
  } catch (error) {
    dispatch(failure(true))
  }
}

/**
 * Update itinerary action
 */
export const updateItinerary = (
  id: string,
  values: MessageItineraryInput
): AppThunk<Promise<void>> => async (dispatch): Promise<void> => {
  try {
    await PMK.updateItinerarySpecialist(id, values)

    dispatch(updateItemMessage(values.message))
  } catch (error) {
    throw new ErrorHandler(error)
  }
}

/**
 * Assign itinerary action
 */
export const assignItinerary = (id: string): AppThunk<Promise<void>> => async (
  dispatch
): Promise<void> => {
  try {
    await PMK.assignItinerary(id)

    dispatch(
      changeItineraryStatus({
        itineraryId: id,
        fromStatus: STATUSES.draft,
        toStatus: STATUSES.in_progress
      })
    )

    dispatch(changeActiveTab(STATUSES.in_progress))
  } catch (error) {
    throw new ErrorHandler(error)
  }
}

/**
 * Add activity to itinerary
 */
export const addActivity = (
  values: ItineraryActivityDTO
): AppThunk<Promise<void>> => async (dispatch): Promise<void> => {
  try {
    const res = await PMK.addActivity(values)
    const payload = await Deserializer.deserialize(res)

    dispatch(
      addActivityToItinerary({
        itineraryActivity: payload,
        itineraryId: values.itinerary_id
      })
    )
  } catch (error) {
    throw new ErrorHandler(error)
  }
}

/**
 * Remove activity from itinerary
 */
export const removeActivity = (id: string): AppThunk<Promise<void>> => async (
  dispatch
): Promise<void> => {
  try {
    await PMK.removeActivity(id)

    dispatch(removeActivityFromItinerary(id))
  } catch (error) {
    throw new ErrorHandler(error)
  }
}

/**
 * Review itinerary action
 */
export const reviewItinerary = (
  id: string,
  isUpdate: boolean
): AppThunk<Promise<void>> => async (dispatch): Promise<void> => {
  try {
    await PMK.updateItinerarySpecialist(
      id,
      {
        status: STATUSES.review
      },
      isUpdate ? 'update' : 'create'
    )

    dispatch(changeActiveTab(STATUSES.review))
  } catch (error) {
    throw new ErrorHandler(error)
  }
}

/**
 * Approve itinerary action
 */
export const approveItinerary = (id: string): AppThunk<Promise<void>> => async (
  dispatch
): Promise<void> => {
  try {
    await PMK.updateItinerarySpecialist(id, {
      status: STATUSES.approved
    })

    dispatch(
      changeItineraryStatus({
        itineraryId: id,
        fromStatus: STATUSES.accepted,
        toStatus: STATUSES.approved
      })
    )
  } catch (error) {
    throw new ErrorHandler(error)
  }
}

/**
 * Cancel itinerary action
 */
export const cancelItinerary = (
  itineraryId: string
): AppThunk<Promise<void>> => async (dispatch, getState): Promise<void> => {
  const fromStatus = getState().dashboard.data.find(
    ({ id }) => id === itineraryId
  )?.status

  try {
    await PMK.updateItinerarySpecialist(itineraryId, {
      status: STATUSES.canceled
    })

    dispatch(
      changeItineraryStatus({
        itineraryId,
        fromStatus,
        toStatus: STATUSES.canceled
      })
    )
  } catch (error) {
    throw new ErrorHandler(error)
  }
}
