import React from 'react'
import { Container, Grid, Paper, Divider, Box } from '@material-ui/core'

import FullWidthMobile from '~/containers/FullWidthMobile'
import { Flexbox, Link, Text, Title } from '~/components/shared'
import { GoogleButton } from '~/components/forms'
import SignUpForm from './components/SignUpDirector'

import ROUTES from '~/constants/routes'

import useAuthStyles from '~/styles/auth.styles'

const SignUp: React.FC = () => {
  const s = useAuthStyles()

  return (
    <FullWidthMobile>
      <Container maxWidth="sm" className={s.container}>
        <Paper className={s.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Title mb={1}>Sign up Provider</Title>
            </Grid>

            <Grid item xs={12}>
              <SignUpForm />
            </Grid>

            <Grid item xs={12}>
              <Flexbox justifyContent="center" alignItems="center">
                <Text variant="body2" mr={1}>
                  Already have an account?
                </Text>
                <Link to={ROUTES.campDirectorSignIn} color="primary">
                  Sign in
                </Link>
              </Flexbox>
            </Grid>

            <Grid item xs={12}>
              <Box mt={2}>
                <Divider />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <GoogleButton isDirector>Sign up with Google</GoogleButton>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </FullWidthMobile>
  )
}

export default SignUp
