import { INTERESTS } from '~/constants/interests'

import interestsOptions from '~/data/profile/interestsOptions'

/**
 * Create initialValues from Fields
 *
 * @param values formValues
 */
const createValues = (fields: Fields): Partial<ChildrenInput> => {
  const {
    age,
    child_first_name,
    rising_grade_level,
    personal_skills,
    special_needs
  } = fields

  const interests = []

  Object.values(INTERESTS).forEach((key) => {
    const interestsData: string[] = fields[key]

    interestsData.forEach((value) => {
      const interestsValues = interestsOptions.find(
        (interest) => interest.value === value
      )

      if (interestsValues) {
        interests.push(interestsValues)
      }
    })
  })

  return {
    age,
    name: child_first_name,
    level: rising_grade_level,
    skills: personal_skills,
    needs: special_needs,
    interests
  }
}

/**
 * Create fieldsDTO object
 *
 * @param values formValues
 * @param fields Fields
 */
const createDTO = (values: ChildrenInput, fields: Fields): Fields => {
  const { name, level, skills, needs, interests, ...valuesData } = values

  const interestsData = {}
  let interestsArray = []

  Object.values(INTERESTS).forEach((key) => {
    interestsData[key] = []
  })

  interests.forEach(({ value, interest, interestLabel }) => {
    interestsData[interest].push(value)
    interestsArray = Array.from(new Set([...interestsArray, interestLabel]))
  })

  const fieldsDTO = {
    ...fields,
    ...valuesData,
    ...interestsData,
    child_first_name: name,
    rising_grade_level: level,
    personal_skills: skills,
    special_needs: needs,
    interests: interestsArray
  }

  return fieldsDTO
}

export default {
  createValues,
  createDTO
}
