import React, { FC } from 'react'
import { compose } from 'redux'
import {
  Field,
  InjectedFormProps,
  reduxForm,
  SubmissionError
} from 'redux-form'
import { toastr } from 'react-redux-toastr'
import { Dialog, DialogContent, Grid, Box } from '@material-ui/core'

import { TextField } from '~/components/fields'
import { ExtendedButton, FormControl, Text } from '~/components/shared'
import DialogTitle from '~/components/dialogs/DialogTitle'

import {
  acceptedFriendItinerary,
  rejectFriendItinerary
} from '~/state/modules/itineraries'

type Props = {
  in: boolean
  onClose: () => {}
  isAccept?: boolean
  itinerary: {
    id: string
    name: string
  }
}

type AllProps = Props & InjectedFormProps

const ItineraryFriendDialog: FC<AllProps> = ({
  in: open,
  onClose,
  itinerary: { name },
  isAccept,

  submitting,
  handleSubmit
}) => {
  return (
    <Dialog maxWidth="sm" open={open} onClose={onClose} fullWidth>
      <DialogTitle onClose={onClose}>
        {isAccept ? 'Accept' : 'Decline'} {name}
      </DialogTitle>

      <DialogContent>
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Text>
                {isAccept
                  ? 'If you like, you can leave a comment for parent'
                  : 'If you like, you can leave a comment why you want to decline this itinerary'}
              </Text>
            </Grid>

            <Grid item xs={12}>
              <FormControl label="Comment">
                <Field
                  name="comment"
                  placeholder="Comment"
                  component={TextField}
                  rowsMax={10}
                  rows={4}
                  multiline
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <ExtendedButton
                data-cy="friend-dialog-button"
                size="large"
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                loading={submitting}
              >
                {isAccept ? 'Accept' : 'Decline'}
              </ExtendedButton>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

const withForm = reduxForm<ItineraryFriendInput, Props>({
  form: 'ItineraryFriend_Form',
  onSubmit: async (values, dispatch, { itinerary, isAccept }) => {
    const request = isAccept ? acceptedFriendItinerary : rejectFriendItinerary

    try {
      await dispatch<any>(request(itinerary?.id, values))
    } catch (error) {
      throw new SubmissionError(error.errors)
    }
  },
  onSubmitSuccess: (
    _values,
    _dispatch,
    { onClose, reset, itinerary: { name }, isAccept }
  ) => {
    isAccept
      ? toastr.success('Itinerary', `${name} was successfully accepted!`)
      : toastr.warning('Itinerary', `${name} was successfully declined!`)

    reset()
    onClose()
  }
})

export default compose<React.FC<Props>>(withForm)(ItineraryFriendDialog)
